import { memo } from "react";
import { IconProps } from ".";

export interface WalletIconProps extends IconProps {}
const WalletIcon = (props: WalletIconProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 24,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1" />
      <path d="M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4" />
    </svg>
  );
};

const MemoWalletIcon = memo(WalletIcon);
export default MemoWalletIcon;
