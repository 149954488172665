"use client";
import { memo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// UI IMPORT
import { Avatar, Icon } from "../../ui";

// PROJECT IMPORT
import { toggleDrawer, useIsDrawerOpen } from "../common/commonSlice";
import { MenuItemsTypes } from "./menuItems";
import { useAppDispatch } from "../../utills/hooks";
import { logout } from "../../helperFunctions";

// THIRD - PARTY IMPORT
import ConnectToBinanceModal from "../common/ConnectToBinanceModal";
import { updateKeys, useKeys } from "../exchange/exchangeSlice";
import { useRole, useUser } from "../auth/authSlice";
import { EmailIcon } from "../../ui/icons/icons";
import { prepareAllMenu } from "../../utills/helperFunctions";

const initialModalDetails = {
  isConfirm: false,
  type: "",
  isForm: false,
};
const Header = () => {
  const pathname = useLocation()?.pathname;
  const pathnames = pathname?.split("/");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const keys = useKeys();

  const isDrawerOpen = useIsDrawerOpen();
  const role = useRole();
  const user = useUser();
  const [modalDetails, setModalDetails] = useState(initialModalDetails);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const prepareHeaderTitle = () => {
    const currentPage = prepareAllMenu(role).find(
      (item: MenuItemsTypes) => item?.url === `/${pathnames[1]}`
    );
    return {
      title: currentPage?.title || "",
      icon: currentPage?.icon || "DashboardIcon",
    };
  };

  const onHide = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div
        className={`bg-secondary-white fixed top-0 left-0 right-0 pr-5 flex items-center justify-between border-b border-secondary-200 h-12 dark:border-bb-dark backdrop-blur-sm w-full z-10 transition-all ${
          isDrawerOpen ? "pl-[230px]" : "pl-[88px]"
        }`}
      >
        <div className="flex items-center gap-5 justify-center">
          {!isDrawerOpen ? (
            <Icon
              name="MenuBarIcon"
              onClick={() => {
                dispatch(toggleDrawer(!isDrawerOpen));
              }}
            />
          ) : null}
          <Icon
            name={prepareHeaderTitle()?.icon}
            className="stroke-primary-950"
          />
          <span className="font-bold text-primary-950">
            {prepareHeaderTitle()?.title}
          </span>
        </div>

        {keys?.currentKey?.is_connect === 1 ? (
          <>
            <div className={`flex gap-4 justify-center items-center select-none`}>
              <p
                className={`relative cursor-pointer activetab_hover !px-2 ${
                  keys?.currentKey?.is_tab_connect === 1
                    ? "activetab font-semibold"
                    : "font-normal text-primary-950 opacity-40"
                } text-primary-950 capitalize `}
                onClick={() => {
                  dispatch(
                    updateKeys({
                      currentKey: {
                        ...(keys?.currentKey || {}),
                        is_tab_connect: 1,
                      },
                    })
                  );
                }}
              >
                {keys?.currentKey?.exchange_name}
              </p>
              <p
                className={`relative cursor-pointer activetab_hover !px-2 ${
                  keys?.currentKey?.is_tab_connect === 0
                    ? "activetab font-semibold"
                    : "font-normal text-primary-950 opacity-40"
                } text-primary-950 `}
                onClick={() => {
                  dispatch(
                    updateKeys({
                      currentKey: {
                        ...(keys?.currentKey || {}),
                        is_tab_connect: 0,
                      },
                    })
                  );
                }}
              >
                AI
              </p>
            </div>
          </>
        ) : null}

        <div className="flex items-center gap-4">
          {/*  {prepareHeaderTitle()?.title === "Dashboard" ? (
            <>
              <Button
                className="!px-5 !h-8 w-full"
                onClick={() => {
                  setModalDetails((modalDetails) => ({
                    ...modalDetails,
                    isForm: true,
                  }));
                }}
              >
                Connect to binance
              </Button>
            </>
          ) : null} */}

          {/* <div className="switchbox">
            <label className="switch">
              <input type="checkbox" id="toggle" checked={!!isThemeOpen}
                onChange={handleChange} />
              <span className="slider"></span>
            </label>
          </div> */}
          <div className="cursor-pointer" onClick={() => setModalOpen(true)}>
            <Avatar name={user?.data?.user?.username} />
          </div>
        </div>
      </div>

      {/* Modal */}
      <ConnectToBinanceModal
        modalDetails={modalDetails}
        setModalDetails={setModalDetails}
        initialModalDetails={initialModalDetails}
      />

      {modalOpen ? (
        <>
          <div className="fixed inset-0 z-50 flex">
            <div
              className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
              onClick={() => {
                onHide();
              }}
            />
            <div className="modal-container-logout bg-secondary-50 w-2/10 2xl:w-2/10 xl:w-3/10 lg:w-4/10 md:w-6/10 sm:w-6/10 md:max-w-md shadow-lg z-50 overflow-y-auto">
              <div className="modal-content py-3 px-2">
                <ul>
                  <li className="py-2">
                    <div className="flex items-center gap-2">
                      <Avatar name={user?.data?.user?.username} />{" "}
                      <strong>{user?.data?.user?.username}</strong>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center gap-2">
                      <EmailIcon size={20} /> {user?.data?.user?.email}
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex gap-2">
                      <Icon
                        name="LogoutIcon"
                        className="cursor-pointer"
                        size={20}
                        onClick={async () => {
                          try {
                            await logout(dispatch);
                            navigate("/");
                          } catch (err) {}
                        }}
                      />
                      <span
                        className="cursor-pointer"
                        onClick={async () => {
                          try {
                            await logout(dispatch);
                            navigate("/");
                          } catch (err) {}
                        }}
                      >
                        Logout
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default memo(Header);
