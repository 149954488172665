import React, { useEffect, useState } from "react";
import useWebSocketContext from "../../../context/useWebSocketContext";
import FutureOrderHistory from "./FutureOrderHistory";
import { FILTER, tradeEnum } from "../../../constant";
import {
  Button,
  ControlledDatePicker,
  ControlledDropDown,
} from "../../../ui";
import { useForm } from "react-hook-form";
import {
  useGetTradePositionHistoryMutation,
  useGetTradePositionMutation,
} from "../../trade/tradeApi";
import FutureOpenOrder from "./FutureOpenOrder";
import FuturePosition from "./FuturePosition";
import { useKeys } from "../../exchange/exchangeSlice";
import { dateFormatter1 } from "../../../helperFunctions";
import {
  useGetPlatformsMutation,
  useGetSymbolsMutation,
} from "../../common/commonApi";
import { usePlatforms, useSymbols } from "../../common/commonSlice";
import { useLocation } from "react-router-dom";
import { useRole } from "../../auth/authSlice";

const initialFilter = {
  ...FILTER,
  symbol: "All",
  platform: "All",
  trade: "All",
};

const FutureOrder = () => {
  const { binanceOpenOrders, emitOpenOrder, emitOrderHistory } =
    useWebSocketContext();
  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();

  const [getTradPosition, { data: positions, isLoading: isPositionLoading }] =
    useGetTradePositionMutation();
  const [
    getTradPositionHistory,
    { data: positionsHistory, isLoading: isPositionHistoryLoading },
  ] = useGetTradePositionHistoryMutation();
  const allSymbols = useSymbols();
  const [getSymbols] = useGetSymbolsMutation();
  const [getPlatforms] = useGetPlatformsMutation();
  const platforms = usePlatforms();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const role = useRole("future-order");

  // const orderHistory = [
  //   {
  //     label: "Exchange",
  //     key: "is_connect_order",
  //   },
  //   {
  //     label: "Date",
  //     key: "created_at",
  //   },
  //   {
  //     label: "Pair",
  //     key: "symbol",
  //   },
  //   {
  //     label: "Type",
  //     key: "type",
  //   },
  //   {
  //     label: "Side",
  //     key: "side",
  //   },
  //   {
  //     label: "Average",
  //     key: "exchange_rate",
  //   },
  //   {
  //     label: "Price",
  //     key: "stock_price",
  //   },
  //   {
  //     label: "Executed",
  //     key: "executedQty",
  //   },
  //   {
  //     label: "Amount",
  //     key: "amount",
  //   },
  //   {
  //     label: "Total",
  //     key: "total",
  //   },
  //   {
  //     label: "Status",
  //     key: "order_status",
  //     Cell: (data: any) => {
  //       return (
  //         <div
  //           className={`${getStatusTypes(data?.order_status)}`}
  //         >{`${data?.order_status}`}</div>
  //       );
  //     },
  //   },
  // ];

  // const openOrder = [
  //   {
  //     label: "Date",
  //     key: "created_at",
  //   },
  //   {
  //     label: "Pair",
  //     key: "symbol",
  //   },
  //   {
  //     label: "Type",
  //     key: "type",
  //   },
  //   {
  //     label: "Side",
  //     key: "side",
  //   },
  //   {
  //     label: "Price",
  //     key: "stock_price",
  //   },
  //   {
  //     label: "Amount",
  //     key: "amount",
  //   },
  //   {
  //     label: "Status",
  //     key: "status",
  //     Cell: (data: any) => {
  //       return (
  //         <div
  //           className={`${getStatusTypes(data?.status)}`}
  //         >{`${data?.status}`}</div>
  //       );
  //     },
  //   },
  //   {
  //     label: "Total",
  //     key: "total",
  //   },
  // ];
  // const position = [
  //   {
  //     label: "Position Time",
  //     key: "created_at",
  //   },
  //   {
  //     label: "Symbol",
  //     key: "symbol",
  //   },
  //   {
  //     label: "Size",
  //     key: "size",
  //   },
  //   {
  //     label: "Entry Price",
  //     key: "current_price",
  //   },
  //   {
  //     label: "Mark Price",
  //     key: "stock_price",
  //   },
  //   {
  //     label: "Trade Value",
  //     key: "trade_value",
  //   },
  //   {
  //     label: "Side",
  //     key: "side",
  //   },
  //   {
  //     label: "PNL(ROI)",
  //     key: "amount",
  //   },
  //   {
  //     label: "PNL(ROI %)",
  //     key: "amount_percentage",
  //   },
  // ];
  // const positionHistory = [
  //   {
  //     label: "Position Time",
  //     key: "created_at",
  //   },
  //   {
  //     label: "Symbol",
  //     key: "symbol",
  //   },
  //   {
  //     label: "Size",
  //     key: "size",
  //   },
  //   {
  //     label: "Entry Price",
  //     key: "current_price",
  //   },
  //   {
  //     label: "Mark Price",
  //     key: "stock_price",
  //   },
  //   {
  //     label: "Trade Value",
  //     key: "trade_value",
  //   },
  //   {
  //     label: "Side",
  //     key: "side",
  //   },
  //   {
  //     label: "PNL(ROI)",
  //     key: "amount",
  //   },
  //   {
  //     label: "Fees",
  //     key: "fees",
  //   },
  //   {
  //     label: "Market Value",
  //     key: "market_value",
  //   },
  // ];

  const tabs: any[] = [];

  if (role.includes("View Future Position History")) {
    tabs.push({
      name: `Positions`,
      label: `Open Positions(${
        is_tab_connect
          ? positions?.data?.["exchangeData"]?.position_history?.length || 0
          : positions?.data?.["aiData"]?.position_history?.length || 0
      })`,
    });
  }

  if (role.includes("View Future Open Order")) {
    tabs.push({
      name: `Open Orders`,
      label: `Open Orders(${binanceOpenOrders?.[is_tab_connect]?.length || 0})`,
    });
  }

  if (role.includes("View Future Order History")) {
    tabs.push({ name: "Order History", label: "Order History" });
  }

  if (role.includes("View Future Position History")) {
    tabs.push({ name: "Positions History", label: "Positions History" });
  }

  const [activeTab, setActiveTab] = useState(tabs?.[0]?.name);
  const [filter, setFilter] = useState<any>({
    ...initialFilter,
    isCall: false,
  });

  const onGet = async () => {
    try {
      await getSymbols({
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err: any) {}
  };

  const initialValues = {
    date: [null, null],
    symbol: "All",
    platform: "All",
    trade: "All",
  };

  const {
    control,
    formState: { errors },
    getValues,
    reset,
    handleSubmit,
  } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  const prepareMarketOptions = () => {
    try {
      let filteredMarkets: any = [];
      allSymbols?.data?.forEach((symbol: string) => {
        filteredMarkets.push({
          value: symbol,
          label: symbol,
        });
      });
      filteredMarkets.unshift({ value: "All", label: "All" });
      return filteredMarkets;
    } catch (error) {
      console.error("Error handling ticker data:", error);
      return {};
    }
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...FILTER,
      ...values,
      isCall: true,
    });
  };

  const onGetPlatforms = async () => {
    try {
      await getPlatforms({
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err: any) {}
  };

  const preparePlatformOptions = () => {
    try {
      let arr: any = [];
      platforms?.data?.forEach((symbol: string) => {
        arr.push({
          value: symbol,
          label: symbol,
        });
      });
      arr.unshift({ value: "All", label: "All" });
      return arr;
    } catch (error) {
      console.error("Error:", error);
      return {};
    }
  };

  const prepareTradeOptions = () => {
    try {
      let arr: any = [];
      Object.entries(tradeEnum)?.forEach(([key, value]: any) => {
        arr.push({
          value: key,
          label: value,
        });
      });
      arr.unshift({ value: "All", label: "All" });
      return arr;
    } catch (error) {
      console.error("Error:", error);
      return {};
    }
  };

  const onGetTabsData = async (tabs: any[]) => {
    const { date } = filter;
    try {
      if (tabs?.includes("Open Orders")) {
        setTimeout(() => {
          emitOpenOrder({
            start_date: dateFormatter1(date?.[0], "start"),
            end_date: dateFormatter1(date?.[1], "end", date?.[0]),
            // end_date: dateFormatter(
            //   filter?.end_date ? filter?.end_date : new Date(),
            //   "end"
            // ),
            limit: filter?.limit,
            page: filter?.page,
            symbol: filter?.symbol,
            is_connect: is_connect,
            order_type: 1,
            is_tab_connect: is_tab_connect,
            exchange_name: exchange_name,
            platform: filter?.platform,
            trade_type: filter?.trade,
            group_name: pathnames?.[1],
          });
        }, 300);
      }
      if (tabs?.includes("Order History")) {
        const payload = {
          is_connect: is_connect,
          start_date: dateFormatter1(date?.[0], "start"),
          end_date: dateFormatter1(date?.[1], "end", date?.[0]),
          limit: filter?.limit,
          page: filter?.page,
          symbol: filter?.symbol,
          order_type: 1,
          exchange_name: exchange_name,
          platform: filter?.platform,
          trade_type: filter?.trade,
          group_name: pathnames?.[1],
        };
        emitOrderHistory(payload);
      }
      if (tabs?.includes("Positions History")) {
        const payload: any = {
          limit: filter?.limit,
          page: filter?.page,
          start_date: dateFormatter1(date?.[0], "start"),
          end_date: dateFormatter1(date?.[1], "end", date?.[0]),
          is_connect: is_connect,
          exchange_name: exchange_name,
          platform: filter?.platform,
          trade_type: filter?.trade,
          symbol: filter?.symbol,
        };
        delete payload["isFilter"];
        await getTradPositionHistory({
          ...payload,
          group_name: pathnames?.[1],
        }).unwrap();
      }

      if (tabs?.includes("Positions")) {
        await getTradPosition({
          start_date: dateFormatter1(date?.[0], "start"),
          end_date: dateFormatter1(date?.[1], "end", date?.[0]),
          limit: filter?.limit,
          page: filter?.page,
          is_connect: is_connect,
          exchange_name: exchange_name,
          platform: filter?.platform,
          trade_type: filter?.trade,
          symbol: filter?.symbol,
          group_name: pathnames?.[1],
        }).unwrap();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    onGet();
    onGetPlatforms();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filter?.isCall) {
      onGetTabsData([activeTab]);
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setFilter({
      ...filter,
      ...FILTER,
      isCall: false,
    });
    onGetTabsData(tabs?.map((item) => item?.name));
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`space-y-5`}>
      <div className="card_rounded flex gap-2 items-end pt-4 flex-wrap">
        <ControlledDropDown
          className="w-[200px]"
          name="symbol"
          label="Symbol"
          control={control}
          errors={errors}
          options={prepareMarketOptions()}
        />
        <ControlledDropDown
          className="w-[200px]"
          name="platform"
          label="Platform"
          control={control}
          errors={errors}
          options={preparePlatformOptions()}
        />
        <ControlledDropDown
          className="w-[200px]"
          name="trade"
          label="Trade"
          control={control}
          errors={errors}
          options={prepareTradeOptions()}
        />
        <ControlledDatePicker
          inputClassName="!w-fit"
          name="date"
          placeholder="Select Date"
          label="Date"
          maxDate={new Date()}
          selectsRange
          control={control}
        />
        {/* <ControlledDatePicker
          name="start_date"
          label="Start Date"
          selectesStart
          control={control}
          startDate={watch("start_date")}
          endDate={watch("end_date")}
          inputClassName="h-8 text-xs"
        />
        <ControlledDatePicker
          name="end_date"
          label="End Date"
          selectsEnd
          startDate={watch("start_date")}
          endDate={watch("end_date")}
          control={control}
          inputClassName="h-8 text-xs"
        /> */}
        <Button className="!h-8 !text-sm" onClick={handleSubmit(onSubmit)}>
          Search
        </Button>
        <Button
          className="!h-8 !text-sm"
          onClick={() => {
            reset();
            setFilter({
              ...initialFilter,
              isCall: true,
            });
          }}
        >
          Reset
        </Button>
        {/* {role.includes("Export Future Order") ? (
          <>
            <Button
              className="!h-8 !text-sm"
              onFetchCSV={async () => {
                const payload: any = {
                  symbol: filter?.symbol,
                  start_date: dateFormatter1(filter?.date?.[0], "start"),
                  end_date: dateFormatter1(filter?.date?.[1], "end", filter?.date?.[0]),
                  exchange_name: exchange_name,
                };
                if (activeTab === "Open Orders") {
                  payload["order_status"] = 1;
                  payload["order_type"] = 1;
                  const res: any = await getAllOrder({
                    ...payload,
                    group_name: pathnames?.[1],
                  }).unwrap();
                  const data = res?.data?.map((item: any) => ({
                    ...item,
                    type: typeEnum?.[item?.type],
                    side: sideEnum?.[item?.side],
                    stock_price: ["8", "9"]?.includes(item?.type.toString())
                      ? item?.type?.toString() === "8"
                        ? onFixed(item?.take_profit)
                        : onFixed(item?.stop_loss)
                      : onFixed(
                          item?.stock_price ||
                            (item?.type === "STOP_MARKET" ||
                            item?.type === "TAKE_PROFIT_MARKET"
                              ? item?.stopPrice
                              : item?.price)
                        ),
                    amount: onFixed(item?.amount),
                    total: onFixed(item?.total),
                    order_status: orderStatuses?.[item?.order_status],
                  }));
                  return {
                    filename: "Open Order",
                    headers: openOrder,
                    data: data || [],
                  };
                } else if (activeTab === "Order History") {
                  payload["order_type"] = 1;
                  const res: any = await getAllOrder({
                    ...payload,
                    group_name: pathnames?.[1],
                  }).unwrap();
                  const data = res?.data?.map((item: any) => ({
                    ...item,
                    is_connect_order: exchange_name || "AI",
                    type: typeEnum?.[item?.type],
                    side: sideEnum?.[item?.side],
                    exchange_rate: onFixed(item?.exchange_rate),
                    stock_price:
                      item?.type === 1
                        ? "Market"
                        : ["8", "9"]?.includes(item?.type.toString())
                        ? item?.type?.toString() === "8"
                          ? onFixed(item?.take_profit)
                          : onFixed(item?.stop_loss)
                        : onFixed(item?.stock_price),
                    executedQty: onFixed(item?.executed),
                    amount: onFixed(item?.amount),
                    total: onFixed(item?.total),
                    order_status: orderStatuses?.[item?.order_status],
                  }));
                  return {
                    filename: "Order History",
                    headers: orderHistory,
                    data: data || [],
                  };
                } else if (activeTab === "Positions") {
                  payload["position_status"] = 1;
                  const res: any = await getAllFuturePosition({
                    ...payload,
                    group_name: pathnames?.[1],
                  }).unwrap();
                  const data = res?.data?.map((item: any) => {
                    const oldValue =
                      (+item?.trade_itemue || 0) * (+item?.current_price || 0);
                    const currentValue =
                      (+item?.trade_itemue || 0) * (+item?.ticker?.c || 0);

                    const diffValue =
                      item?.side === "buy"
                        ? currentValue - oldValue
                        : oldValue - currentValue;
                    const per = (diffValue / currentValue) * 100;
                    return {
                      ...item,
                      size: onFixed(item?.total),
                      current_price: onFixed(item?.current_price),
                      stock_price: onFixed(item?.stock_price),
                      trade_value: onFixed(item?.trade_value),
                      side: sideEnum?.[item?.side],
                      amount:
                        item?.tickerIndex > -1
                          ? Math.abs(+onFixed(diffValue))
                          : 0,
                      amount_percentage: `${
                        item?.tickerIndex > -1 ? onFixed(Math.abs(per), 2) : 0
                      } %`,
                    };
                  });
                  return {
                    filename: "Positions",
                    headers: position,
                    data: data || [],
                  };
                } else {
                  const res: any = await getAllFuturePosition({
                    ...payload,
                    group_name: pathnames?.[1],
                  }).unwrap();

                  const data = res?.data?.map((item: any) => {
                    return {
                      ...item,
                      created_at: item?.created_at,
                      symbol: item?.symbol,
                      size: onFixed(item?.total),
                      current_price: onFixed(item?.current_price),
                      stock_price: onFixed(item?.stock_price),
                      trade_value: onFixed(item?.trade_value),
                      side: sideEnum?.[item?.side],
                      amount: onFixed(item?.profit_loss),
                      fees: onFixed(item?.fees),
                      market_value: onFixed(item?.market_value),
                    };
                  });
                  return {
                    filename: "Position History",
                    headers: positionHistory,
                    data: data || [],
                  };
                }
              }}
            >
              Export
            </Button>
          </>
        ) : null} */}
      </div>
      <div className="card_rounded">
        <div className="flex gap-4">
          {tabs?.map((item: any, index: any) => {
            return (
              <div key={index}>
                <div
                  className={`cursor-pointer relative flex flex-row justify-center items-center activetab_hover ${
                    item?.name === activeTab
                      ? "activetab font-semibold text-primary-950 "
                      : "font-normal text-primary-950 opacity-50"
                  }`}
                  onClick={() => {
                    setActiveTab(item?.name);
                    setFilter({
                      ...filter,
                      ...FILTER,
                      isCall: true,
                    });
                  }}
                >
                  <p>{item?.label}</p>
                </div>
              </div>
            );
          })}
        </div>
        {activeTab === `Positions` ? (
          <FuturePosition
            positions={positions}
            isPositionLoading={isPositionLoading}
            isOpenPosition={true}
            setFilter={setFilter}
            filter={filter}
          />
        ) : activeTab === `Open Orders` ? (
          <FutureOpenOrder setFilter={setFilter} filter={filter} />
        ) : activeTab === "Order History" ? (
          <FutureOrderHistory setFilter={setFilter} filter={filter} />
        ) : activeTab === "Positions History" ? (
          <FuturePosition
            positions={positionsHistory}
            isPositionLoading={isPositionHistoryLoading}
            isOpenPosition={false}
            setFilter={setFilter}
            filter={filter}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FutureOrder;
