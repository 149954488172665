// UI IMPORT
import { Button, Input, Loader, Password } from "../../ui";

// PROJECT IMPORT
import { useLoginMutation } from "./authApi";
import { responseToaster } from "../../helperFunctions";
import { LoginFormTypes } from "./types";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const Login = () => {
  const [login, { isLoading }] = useLoginMutation();

  const schema: any = yup.object({
    email: yup.string().required("Please enter a email Id"),
    password: yup.string().required("Please enter a password"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<LoginFormTypes>(schema),
  });

  const onSubmit = async (values: LoginFormTypes) => {
    try {
      const res = await login(values).unwrap();
      responseToaster(res);
    } catch (err: any) {}
  };

  if (isLoading) {
    return (
      <>
        <div className="w-[100vw] h-[100vh] z-[9999] flex items-center justify-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      <form
        className="grid  h-screen login-wrapper "
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex">
          <div className="login-main bg-secondary-white rounded-sm w-4/12 md:w-7/12 sm:w-11/12  m-auto ">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center w-[140px] ">
                {/* <Image
                  name="LogoBlue"
                /> */}
                {/* <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    outerLogo
                  )}`}
                  alt="Logo"
                /> */}
              </div>
            </div>
            <div className="welc-text mb-6">
              <h4>Welcome Back !</h4>
              <p>Please Login to your account </p>
              {/* <p className="mt-1">
                Start managing your finance faster and better
              </p> */}
            </div>
            <div className="space-y-5 pb-10">
              <Input
                className="!w-full"
                inputClassName="!h-12 "
                icon="EmailIcon"
                name="email"
                placeholder="Email Address"
                label="Email"
                errors={errors}
                register={register}
              />
              <Password
                className="!w-full"
                inputClassName="!h-12"
                name="password"
                placeholder="password"
                label="Password"
                errors={errors}
                register={register}
              />
              {/* <div>
              <p className="cursor-pointer text-primary-950" onClick={() => navigate("/register")}>new here?</p>
            </div> */}
            </div>
            <div className="">
              <Button type="submit" className="!px-20 !h-10 w-full">
                Sign in
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
