import React, { memo } from "react";
import { IconProps } from ".";

export interface SearchIconProps extends IconProps {}
const SearchIcon = (props: SearchIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "var(--primary-600--)",
    fill = "var(--primary-600--)",
    strokeWidth = 0.5,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <g clipPath="url(#clip0_6518_429)" className={`${pathClassNames?.[0] || ""}`} >
        <mask x="0" y="0" width="24" height="24" className={`${pathClassNames?.[1] || ""}`} >
          <path d="M24 0H0V24H24V0Z" fill={fill}  className={`${pathClassNames?.[2] || ""}`} />
        </mask>
        <g mask="url(#mask0_6518_429)" className={`${pathClassNames?.[3] || ""}`} >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5012 2C9.14582 2.00012 7.81009 2.32436 6.60549 2.94569C5.40087 3.56703 4.36231 4.46741 3.57646 5.57176C2.79061 6.67609 2.28023 7.95235 2.08794 9.29404C1.89563 10.6357 2.02698 12.004 2.47101 13.2846C2.91503 14.5652 3.65887 15.7211 4.64046 16.6557C5.62206 17.5904 6.81293 18.2768 8.11374 18.6576C9.41455 19.0384 10.7876 19.1027 12.1182 18.8449C13.4489 18.5872 14.6986 18.0149 15.7632 17.176L19.4152 20.828C19.6038 21.0101 19.8564 21.1109 20.1186 21.1087C20.3808 21.1064 20.6316 21.0012 20.817 20.8159C21.0025 20.6304 21.1076 20.3796 21.1098 20.1175C21.1121 19.8552 21.0114 19.6027 20.8292 19.414L17.1772 15.762C18.1652 14.5087 18.7804 13.0024 18.9524 11.4157C19.1242 9.82905 18.846 8.22603 18.1494 6.79009C17.4529 5.35417 16.3661 4.14336 15.0136 3.29623C13.6609 2.44911 12.0972 1.99989 10.5012 2ZM4.00122 10.5C4.00122 8.77609 4.68605 7.12279 5.90503 5.9038C7.12402 4.68481 8.77731 4 10.5012 4C12.2251 4 13.8784 4.68481 15.0974 5.9038C16.3164 7.12279 17.0012 8.77609 17.0012 10.5C17.0012 12.2239 16.3164 13.8772 15.0974 15.0961C13.8784 16.3152 12.2251 17 10.5012 17C8.77731 17 7.12402 16.3152 5.90503 15.0961C4.68605 13.8772 4.00122 12.2239 4.00122 10.5Z"
            fill={fill} className={`${pathClassNames?.[4] || ""}`} 
          />
        </g>
      </g>
      <defs className={`${pathClassNames?.[5] || ""}`} >
        <clipPath id="clip0_6518_429" className={`${pathClassNames?.[6] || ""}`} >
          <rect width="24" height="24" fill={fill} className={`${pathClassNames?.[7] || ""}`}  />
        </clipPath>
      </defs>
    </svg>
  );
};

const MemoSearchIcon = memo(SearchIcon);
export default MemoSearchIcon;
