import { io, Socket } from "socket.io-client";

let laravelSocket: Socket | null = null;
const laravelUrl = process.env.REACT_APP_LARAVEL_WEBSOCKET_URL || "";

export const initializeLaravelSocket = (token: string): Socket => {
  // const urlWithToken = `${laravelUrl}?token=${token}`;
  // laravelSocket = io(urlWithToken, {
  laravelSocket = io(laravelUrl, {
    auth: {
      // token: `Bearer ${token}`,
      token,
    },
  });

  laravelSocket.on("connect", () => {
    console.log("Laravel Socket.IO connected");
  });

  laravelSocket.on("disconnect", () => {
    console.log("Laravel Socket.IO disconnected");
  });

  laravelSocket.on("error", (error: any) => {
    console.error("Laravel Socket.IO error:", error);
  });

  return laravelSocket;
};

export const getLaravelSocket = (): Socket | null => {
  return laravelSocket;
};

export const disconnectLaravelSocket = () => {
  if (laravelSocket) {
    laravelSocket.close();
    laravelSocket = null;
  }
};
