import { memo } from "react";
import { IconProps } from ".";

export interface UsersIconProps extends IconProps {}
const UsersIcon = (props: UsersIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 24,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"
        className={`${pathClassNames?.[0] || ""}`}
      />
      <circle cx="9" cy="7" r="4" className={`${pathClassNames?.[1] || ""}`} />
      <path
        d="M22 21v-2a4 4 0 0 0-3-3.87"
        className={`${pathClassNames?.[2] || ""}`}
      />
      <path
        d="M16 3.13a4 4 0 0 1 0 7.75"
        className={`${pathClassNames?.[3] || ""}`}
      />
    </svg>
  );
};

const MemoUsersIcon = memo(UsersIcon);
export default MemoUsersIcon;
