import { memo } from "react";
import { IconProps } from ".";

export interface TransferHistoryIconProps extends IconProps {}
const TransferHistoryIcon = (props: TransferHistoryIconProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 24,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M2 18h1.4c1.3 0 2.5-.6 3.3-1.7l6.1-8.6c.7-1.1 2-1.7 3.3-1.7H22" />
      <path d="m18 2 4 4-4 4" />
      <path d="M2 6h1.9c1.5 0 2.9.9 3.6 2.2" />
      <path d="M22 18h-5.9c-1.3 0-2.6-.7-3.3-1.8l-.5-.8" />
      <path d="m18 14 4 4-4 4" />
    </svg>
  );
};

const MemoTransferHistoryIcon = memo(TransferHistoryIcon);
export default MemoTransferHistoryIcon;
