import React, { memo } from "react";
import { IconProps } from ".";

export interface FutureOrderIconProps extends IconProps {}
const FutureOrderIcon = (props: FutureOrderIconProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 20,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path d="M10 2v8l3-3 3 3V2" />
      <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" />
    </svg>
  );
};

const MemoFutureOrderIcon = memo(FutureOrderIcon);
export default MemoFutureOrderIcon;
