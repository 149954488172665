import { Fragment, useCallback, useEffect, useMemo } from "react";
import { Loader, Table, TableRow } from "../../ui";
import { SideEnumKeys, sideEnum } from "../../constant";
import useWebSocketContext from "../../context/useWebSocketContext";
import {
  groupBy,
  onFixed,
  preparePriceStyle,
  removeTimeFromDate,
} from "../../helperFunctions";

import { useKeys } from "../exchange/exchangeSlice";
import useStrategyContext from "../../context/StrategyContext";
import { useUser } from "../auth/authSlice";
import StrategyStatistics from "./StrategyStatistics";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";
import { useUpdatePositionMutation } from "../trade/tradeApi";

const StrategyPosition = (props: any) => {
  const { isPositionList = true } = props;
  const { tickers } = useWebSocketContext();
  const {
    filter,
    positions,
    isPositionLoading,
    isUpdatePositionLoading,
    onUpdatePosition,
    marketId,
    setChartData,
    setSymbolWisePnL,
  } = useStrategyContext();

  const [updatePosition] = useUpdatePositionMutation();

  const {
    currentKey: { is_tab_connect, is_connect },
  } = useKeys();

  // const [getAllFuturePosition, { data: allPositions }] =
  //   useGetTradPositionHistoryMutation();

  const user = useUser();

  const columnsPositions = useMemo(
    () => [
      {
        title: "Position Time",
        name: "created_at",

        prepareValue: (data: any) =>
          `${!isPositionList ? data?.children?.created_at + " | " : ""}${
            data?.created_at
          }`,

        Cell: (data: any) => (
          // <>{`${new Date(data?.created_at * 1000).toLocaleString()}`}</>
          <>
            {!isPositionList ? <p>{`${data?.children?.created_at}`}</p> : null}
            <p>{`${data?.created_at}`}</p>
          </>
        ),
      },
      {
        title: "Symbol",
        prepareValue: (data: any) =>
          `${!isPositionList ? data?.children?.symbol + " | " : ""}${
            data?.symbol
          }`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? <p>{`${data?.children?.symbol}`}</p> : null}
            <p>{`${data?.symbol}`}</p>
          </>
        ),
      },
      {
        title: "Size",
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.children?.total) + " | " : ""
          }${onFixed(data?.total)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.total)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.total)}`}</p>
          </>
        ),
      },
      {
        title: "Entry Price",
        name: "current_price",
        prepareValue: (data: any) =>
          `${
            !isPositionList
              ? onFixed(data?.children?.current_price) + " | "
              : ""
          }${onFixed(data?.current_price)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.current_price)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.current_price)}`}</p>
          </>
        ),
      },
      // {
      //   title: "Break Even Price",
      //   name: "current_price",
      //   // Cell: (data: any) => (
      //   //   <>{`${new Date(data?.created_at * 1000).toLocaleString()}`}</>
      //   // ),
      // },
      {
        title: "Mark Price",
        name: "stock_price",
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.children?.stock_price) + " | " : ""
          }${onFixed(data?.stock_price)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.stock_price)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.stock_price)}`}</p>
          </>
        ),
      },
      {
        title: "Trade Value",
        name: "trade_value",
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.children?.trade_value) + " | " : ""
          }${onFixed(data?.trade_value)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.trade_value)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.trade_value)}`}</p>
          </>
        ),
      },
      {
        title: "Side",
        prepareValue: ({
          side,
          children,
        }: {
          side: SideEnumKeys;
          children: { side: SideEnumKeys };
        }) =>
          `${!isPositionList ? sideEnum?.[children?.side] + " | " : ""}${
            sideEnum?.[side]
          }`,
        Cell: ({
          side,
          children,
        }: {
          side: SideEnumKeys;
          children: { side: SideEnumKeys };
        }) => (
          <>
            {!isPositionList ? (
              <>
                {/* {`${sideEnum?.[children?.side]}`} */}
                <div className="flex gap-2 items-center">
                  {sideEnum?.[children?.side] === "Buy" ? (
                    <GoArrowUpRight size={20} color="var(--primary-green--)" />
                  ) : (
                    <GoArrowDownRight size={20} color="var(--primary-red--)" />
                  )}
                  <p>{`${sideEnum?.[children?.side]}`}</p>
                </div>
              </>
            ) : null}
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
      },
      {
        title: "Platform",
        name: "platform",
      },
      {
        title: "Trade Type",
        name: "trade_type",
      },
      {
        title: "PNL(ROI %)",
        name: "amount",
        showColumn: isPositionList,
        prepareValue: (data: any) => {
          const oldValue =
            (+data?.trade_value || 0) * (+data?.current_price || 0);
          const currentValue =
            (+data?.trade_value || 0) * (+data?.ticker?.c || 0);
          const diffValue =
            data?.side === "buy"
              ? currentValue - oldValue
              : oldValue - currentValue;
          const per = (diffValue / currentValue) * 100;

          return `${
            data?.tickerIndex > -1 ? Math.abs(+onFixed(diffValue)) : 0
          } = ${data?.tickerIndex > -1 ? onFixed(Math.abs(per), 2) : 0} %`;
        },
        Cell: (data: any) => {
          const oldValue =
            (+data?.trade_value || 0) * (+data?.current_price || 0);
          const currentValue =
            (+data?.trade_value || 0) * (+data?.ticker?.c || 0);

          const diffValue =
            data?.side === "buy"
              ? currentValue - oldValue
              : oldValue - currentValue;
          const per = (diffValue / currentValue) * 100;
          return (
            <div className="flex flex-col gap-2">
              <span
                className={`${
                  preparePriceStyle(+onFixed(diffValue))?.className
                }`}
              >
                {data?.tickerIndex > -1 ? Math.abs(+onFixed(diffValue)) : 0}
              </span>
              <span className={`${preparePriceStyle(+per)?.className}`}>
                {data?.tickerIndex > -1 ? onFixed(Math.abs(per), 2) : 0} %
              </span>
            </div>
          );
        },
      },
      {
        title: "PNL(ROI %)",
        name: "profit_loss",
        showColumn: !isPositionList,
        prepareValue: (data: any) =>
          `${onFixed(data?.children?.profit_loss) || 0}`,
        Cell: (data: any) => {
          return (
            <div className="flex flex-col gap-2">
              <span
                className={`${
                  preparePriceStyle(+data?.children?.profit_loss)?.className
                }`}
              >
                {onFixed(data?.children?.profit_loss) || 0}
              </span>
            </div>
          );
        },
      },
      {
        title: "Fees",
        name: "fees",
        showColumn: !isPositionList,
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.children?.fees) + " | " : ""
          }${onFixed(data?.fees)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.fees)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.fees)}`}</p>
          </>
        ),
      },
      {
        title: "Close All Positions",
        name: "close_all_positions",
        isButton: true,
        showColumn:
          isPositionList &&
          user?.data?.user?.user_rights &&
          user?.data?.user?.user_rights?.split(",")?.includes(filter?.strategy),
        hide: ["ALL"],
        onTableHeaderClick: async () => {
          await updatePosition({
            type: "all",
            strategy_name: filter?.strategy,
          });
        },
        Cell: (data: any) => {
          return (
            <>
              <span
                className="cursor-pointer text-primary-500 font-semibold hover:text-primary-950"
                onClick={async () => {
                  if (isUpdatePositionLoading) {
                    return;
                  }
                  await onUpdatePosition(
                    data?.position_uuid,
                    data?.ticker?.c,
                    data?.side
                  );
                }}
              >
                {isUpdatePositionLoading && data?.position_uuid === marketId ? (
                  <Loader loaderClassName="w-[15px] " />
                ) : (
                  "Market"
                )}
              </span>
            </>
          );
        },
      },
      {
        title: "Market Value",
        name: "market_value",
        showColumn: !isPositionList,
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.children?.market_value) + " | " : ""
          }${onFixed(data?.market_value)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.market_value)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.market_value)}`}</p>
          </>
        ),
      },
    ],
    // eslint-disable-next-line 
    [positions, isPositionList, marketId, isUpdatePositionLoading]
  );

  const preparePositions = () => {
    const newItems: any[] = [];

    (is_tab_connect
      ? positions?.data?.exchangeData?.position_history || []
      : positions?.data?.aiData?.position_history || []
    )?.forEach((item: any) => {
      let tickerIndex;

      if (is_connect) {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.split(":")?.[0]?.replace("/", "")
        );
      } else {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.replace("/", "")
        );
      }

      newItems.push({
        ...item,
        tickerIndex,
        ticker: tickers?.[tickerIndex],
      });
    });

    return newItems;
  };

  // TODO: if pagination will be add in table.
  // const prepareAllPositions = () => {
  //   const newItems: any[] = [];
  //   (is_tab_connect
  //     ? allPositions?.data?.exchangeData?.position_history || []
  //     : allPositions?.data?.aiData?.position_history || []
  //   )?.forEach((item: any) => {
  //     let tickerIndex;
  //     if (is_connect) {
  //       tickerIndex = tickers?.findIndex(
  //         (i: any) => i?.s === item?.symbol?.split(":")?.[0]?.replace("/", "")
  //       );
  //     }
  //     if (!is_connect) {
  //       tickerIndex = tickers?.findIndex(
  //         (i: any) => i?.s === item?.symbol?.replace("/", "")
  //       );
  //     }

  //     newItems.push({
  //       ...item,
  //       tickerIndex,
  //       ticker: tickers?.[tickerIndex],
  //     });
  //   });

  //   return newItems;
  // };

  const prepareTotalOrders = useCallback(() => {
    return {
      total: is_tab_connect
        ? positions?.data?.exchangeData?.position_history?.length || 0
        : positions?.data?.aiData?.position_history?.length || 0,
      long:
        preparePositions()?.filter((item: any) => item?.side === "buy")
          ?.length || 0,
      short:
        preparePositions()?.filter((item: any) => item?.side === "sell")
          ?.length || 0,
      title: "Total Positions",
    };
    // eslint-disable-next-line 
  }, [positions, is_tab_connect]);

  useEffect(() => {
    const arr: any[] = preparePositions();
    const preparedArr = arr.map((obj) => {
      const oldValue = (+obj?.trade_value || 0) * (+obj?.current_price || 0);
      const currentValue = (+obj?.trade_value || 0) * (+obj?.ticker?.c || 0);

      const diffValue =
        obj?.side === "buy" ? currentValue - oldValue : oldValue - currentValue;
      return {
        ...obj,
        created_at: removeTimeFromDate(new Date(obj.created_at)),
        diffValue,
      };
    });
    const result = groupBy(preparedArr, ({ created_at }: any) => created_at);
    const result1 = Object.entries(result || {})?.map(([key, value]: any) => [
      key,
      value?.reduce((acc: any, obj: any) => acc + +obj.diffValue, 0),
    ]);

    // const data = result1?.length
    //   ? {
    //       labels: result1.map(([key, _]) => key),
    //       datasets: [
    //         {
    //           label: "PnL",
    //           data: result1.map(([_, value]) => value),
    //           backgroundColor: ["rgba(255, 99, 132, 0.2)"],
    //           borderColor: ["red"],
    //         },
    //       ],
    //     }
    //   : null;

    setChartData(result1);

    const groupBySymbol = groupBy(preparedArr, ({ symbol }: any) => symbol);
    const newData: any[] = [];
    Object.entries(groupBySymbol || {})?.forEach(([key, value]: any) => {
      newData.push({
        contracts: key,
        pnl: value?.reduce(
          (acc: any, obj: any) => acc + +obj.children?.profit_loss,
          0
        ),
      });
    });
    setSymbolWisePnL(newData);
    // eslint-disable-next-line
  }, [positions]);

  // useEffect(() => {
  //   let payload: any = {
  //     strategy_name: filter?.strategy,
  //     symbol: filter?.symbol,
  //     start_date: filter?.start_date
  //       ? dateFormatter(filter?.start_date, "start")
  //       : null,
  //     end_date: filter?.end_date
  //       ? dateFormatter(filter?.end_date, "end")
  //       : null,
  //     exchange_name: exchange_name,
  //     is_connect: is_connect,
  //     position_status: 1
  //   };

  //   const fun = async () => {
  //     await getAllFuturePosition(payload).unwrap();
  //   };

  //   fun();
  // }, [filter, exchange_name]);

  return (
    <div className="flex flex-col mt-5">
      <StrategyStatistics totalOrders={prepareTotalOrders()} />
      <div className="pt-3">
        <Table
          fileName="Strategy_open_positions"
          isLoading={!!(!tickers || isPositionLoading)}
          columns={columnsPositions}
          data={preparePositions() || []}
          tableDataCount={preparePositions()?.length}
          isExpendable={false}
          className="!overflow-scroll"
          minHeight={300}
          count={preparePositions()?.length}
        >
          {preparePositions()?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columnsPositions}
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default StrategyPosition;
