import { Icon, IconKeys } from "../icons";
import { OptionTypes } from "../theme";

interface SelectProps {
  className?: string;
  lableClassName?: string;
  name?: any;
  label?: string;
  value?: any;
  options: OptionTypes[];
  icon?: IconKeys;
  placeholder?: string;
  errors?: any;
  err?: string;
  register?: any;
  disabled?: boolean;
  isDefaultOption?: boolean;
  onChange?: (e?: any, label?: any) => void;
}

const Select = (props: SelectProps) => {
  const {
    className = "",
    label,
    icon,
    options = [],
    errors,
    err,
    register,
    placeholder = "Select ",
    name,
    lableClassName = "",
    isDefaultOption = true,
    onChange,
    ...rest
  } = props;

  const getRegister = (register: any) =>
    register && name ? { ...register?.(name) } : {};

  const message = errors?.[name]?.message || err || "";

  return (
    <div className={`${className} w-full flex flex-col relative`}>
      {label ? (
        <label className={`pb-1 text-sm text-primary-950 ${lableClassName}`}>
          {label}
        </label>
      ) : null}
      <div className="flex flex-col justify-center items-center select-img">
        {icon ? <Icon className="absolute m-4 text-xl" name={icon} /> : null}
        <select
          name={name}
          id={name}
          className={`${className} text-sm ${
            message ? "!border-red-500" : ""
          } ${
            icon ? "pl-11" : "pl-3"
          } pr-7 appearance-none w-full text-ev-Quinary bg-transparent h-9 rounded-sm outline-none bg-opacity-5`}
          {...getRegister(register)}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onChange={(e) => {
            const label = options?.find(
              (item) => item?.value === e.target.value
            )?.label;
            onChange?.(e, label);
          }}
          {...rest}
        >
          {isDefaultOption ? (
            <option value="" className="bg-secondary-100 text-primary-950">
              {placeholder}
            </option>
          ) : null}

          {options.map((option) => (
            <option
              disabled={!!option?.disabled}
              key={option.value}
              value={option.value}
              className="bg-secondary-100 text-primary-950"
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default Select;
