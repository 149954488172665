import { api } from "../../utills/api";
import { LoginFormTypes, RegisterFormTypes } from "./types";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<any, LoginFormTypes>({
      query: (params) => ({
        url: "/login",
        body: params,
        method: "POST",
      }),
    }),
    register: build.mutation<any, RegisterFormTypes>({
      query: (params) => ({
        url: "/register",
        body: params,
        method: "POST",
      }),
    }),
    getUserPermissions: build.mutation<any, void>({
      query: (params) => ({
        url: "/get-site-access",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useGetUserPermissionsMutation,
} = authApi;
export const {
  endpoints: { login, register, getUserPermissions },
} = authApi;
