import { api } from "../../utills/api";

export const accountStatementApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllAccountStatement: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/account-statements",
        body: params,
        method: "POST",
      }),
    }),
    getOrderHistoryList: build.mutation<{ data: any }, void>({
      query: (params: any) => ({
        url: "/get-order-history",
        body: {
          order_type: 0,
          order_status: 0,
          ...(params || {}),
        },
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetAllAccountStatementMutation,
  useGetOrderHistoryListMutation,
} = accountStatementApi;
export const {
  endpoints: { getAllAccountStatement, getOrderHistoryList },
} = accountStatementApi;
