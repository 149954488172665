import { Fragment, useEffect, useState } from "react";
import { Button, Table, TableRow } from "../../ui";
import { useGetExchangeMutation } from "./exchangeApi";
import { useExchangeList } from "./exchangeSlice";
import { FILTER } from "../../constant";
import ConnectToBinanceModal from "../common/ConnectToBinanceModal";
import { useLocation } from "react-router-dom";
import { useRole } from "../auth/authSlice";
import { CiEdit } from "react-icons/ci";

const initialModalDetails = {
  isConfirm: false,
  type: "",
  isForm: false,
  // exchange_name: null,
};

const Exchange = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [getExchange, { isLoading: isExchangeLoading }] =
    useGetExchangeMutation();

  const [filter, setFilter] = useState<any>(FILTER);
  const [modalDetails, setModalDetails] = useState(initialModalDetails);
  const exchangeList = useExchangeList();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const role = useRole("exchange");

  /*  const onUpdateStatus = async (payload: any) => {
    try {
      setStatusLoading(payload?.exchange_uuid);
      const res = await updateExchangeStatus(payload).unwrap();

      const index = tableData?.findIndex(
        (item: { exchange_uuid: any }) =>
          item?.exchange_uuid === payload?.exchange_uuid
      );
      if (index > -1) {
        const newData = [...tableData];
        newData[index] = {
          ...newData[index],
          is_active: payload?.is_active,
        };
        dispatch(setExchange({ ...exchangeList, data: {exchange_list:newData} }));
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  }; */

  const columns = [
    {
      title: "Symbol",
      name: "symbol",
      exportHide: ["ALL"],
      Cell: (data: any) => (
        <>
          <img
            className="h-[40px] w-[80px]"
            src={`${process.env.REACT_APP_IMAGE_URL}${data?.exchange_logo}`}
            alt="symbol"
          />
        </>
      ),
    },
    {
      title: "Name",
      name: "exchange_name",
    },
    {
      title: "Status",
      name: "exchange_status",
      prepareValue: (data: any) => {
        const status = !data?.is_active
          ? "In Development"
          : data?.is_key_active
          ? "Active"
          : "Disabled";
        return status;
      },
      Cell: (data: any) => {
        const status = !data?.is_active
          ? "In Development"
          : data?.is_key_active
          ? "Active"
          : "Disabled";
        return (
          <div
            className={`items-center flex justify-center rounded-sm ${
              status === "In Development"
                ? "bg-gray-400 text-black"
                : status === "Active"
                ? "bg-green-400 text-black"
                : "bg-red-400"
            }`}
          >
            {status}
          </div>
        );
      },
    },
    {
      title: "Action",
      name: "exchange_name",
      showColumn: role.includes("Add Key") || role.includes("Update Key"),
      exportHide: ["ALL"],
      Cell: (data: any) => {
        const isConnectedToExchange = data?.secret_key && data?.api_key;
        return isConnectedToExchange ? (
          role.includes("Update Key") ? (
            <Button
              className="!h-8 !w-8 !p-0"
              onClick={() => {
                setModalDetails((modalDetails) => ({
                  ...modalDetails,
                  ...(data || {}),
                  isForm: true,
                  exchange_name: data?.exchange_name,
                }));
              }}
            >
              {/* Edit */}
              <CiEdit size={24} />
            </Button>
          ) : (
            <></>
          )
        ) : role.includes("Add Key") ? (
          <Button
            className="!h-8"
            onClick={() => {
              setModalDetails((modalDetails) => ({
                ...modalDetails,
                isForm: true,
                exchange_name: data?.exchange_name,
              }));
            }}
            disabled={!data?.is_active}
          >
            Connect
          </Button>
        ) : (
          <></>
        );
      },
    },
  ];

  const onGetExchange = async () => {
    try {
      const payload = {
        ...filter,
      };
      delete payload["isFilter"];
      await getExchange({
        ...payload,
        group_name: pathnames?.[1],
        is_active: 1,
      });
    } catch (error) {}
  };

  useEffect(() => {
    setTableData(exchangeList?.data?.exchanges?.exchange_list);
    // eslint-disable-next-line
  }, [exchangeList]);

  useEffect(() => {
    onGetExchange();
    // eslint-disable-next-line
  }, [filter]);

  return (
    <div>
      <div className={`card_rounded mb-4`}>
        <Table
          fileName="Echange"
          isLoading={isExchangeLoading}
          columns={columns}
          data={exchangeList?.data?.exchanges?.exchange_list || []}
          isExpendable={false}
          setData={setTableData}
          className="!overflow-scroll order_table"
          count={exchangeList?.data?.exchanges?.total}
          tableDataCount={tableData?.length}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          //   minHeight={300}
        >
          {tableData?.map?.((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  className="min-h-[350px]"
                  columns={columns}
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
      <ConnectToBinanceModal
        modalDetails={modalDetails}
        setModalDetails={setModalDetails}
        initialModalDetails={initialModalDetails}
        setFilter={setFilter}
      />
    </div>
  );
};

export default Exchange;
