import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from "react";

interface OTPInputProps {
  numInputs: number;
  onOtpChange: (otp: string) => void;
  isValid: boolean;
  triggerVibrate: boolean;
}

const OTPInput: React.FC<OTPInputProps> = ({
  numInputs,
  onOtpChange,
  isValid,
  triggerVibrate,
}) => {
  const [otp, setOtp] = useState<string[]>(new Array(numInputs).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    setOtp(new Array(numInputs).fill(""));
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
    // eslint-disable-next-line
  }, [numInputs]);

  useEffect(() => {
    if (!isValid && triggerVibrate) {
      inputRefs.current.forEach((input) => {
        if (input) {
          input.classList.add("vibrate");
          setTimeout(() => input.classList.remove("vibrate"), 300);
        }
      });
    }
    // eslint-disable-next-line
  }, [isValid, triggerVibrate]);

  const handleChange = (element: HTMLInputElement, index: number) => {
    const value = element.value.replace(/[^0-9]/g, "");
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    onOtpChange(newOtp.join(""));

    // Move to the next input field if there's a value
    if (value && index < numInputs - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (otp[index]) {
        newOtp[index] = "";
      } else if (index > 0) {
        inputRefs.current[index - 1]?.focus();
        newOtp[index - 1] = "";
      }
      setOtp(newOtp);
      onOtpChange(newOtp.join(""));
    }
  };

  return (
    <div className={`d-flex gap-2 justify-content-center`}>
      {otp.map((data, index) => (
        <input
          key={index}
          type="tel"
          maxLength={1}
          autoFocus={index === 0}
          value={data}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e.target, index)
          }
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
            handleKeyDown(e, index)
          }
          ref={(el) => (inputRefs.current[index] = el)}
          className={`d-flex justify-content-center align-items-center rounded border text-center ${
            isValid ? "border-secondary" : "border-danger"
          }`}
          style={{ width: "2.5rem", height: "2.5rem", fontSize: "1.125rem" }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
