import { api } from "../../utills/api";

export const strategyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTradPositionHistory: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/get-trade-positions",
        body: { status: 0, ...(params || {}) },
        method: "POST",
      }),
    }),
    getTradePosition: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/get-trade-positions",
        body: { status: 1, ...params },
        method: "POST",
      }),
    }),
    getStrategy: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/get-all-strategy",
        body: params,
        method: "POST",
      }),
    }),
    getAllStrategyHistory: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-all-positions-history",
        body: params,
        method: "POST",
      }),
    }),
    applyStrategy: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/strategy/apply",
        body: params,
        method: "POST",
      }),
    }),
    getAllOrder: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-all-order-history",
        body: params,
        method: "POST",
      }),
    }),
    getAllOrderConnected: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-all-order-history",
        body: { ...params, is_connect: 1 },
        method: "POST",
      }),
    }),
    uploadTradeImage: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/strategy/upload-trade-image",
        body: params,
        method: "POST",
      }),
    }),
    manualTrade: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/strategy/manual-trade",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetTradPositionHistoryMutation,
  useGetTradePositionMutation,
  useGetStrategyMutation,
  useGetAllStrategyHistoryMutation,
  useApplyStrategyMutation,
  useGetAllOrderMutation,
  useGetAllOrderConnectedMutation,
  useUploadTradeImageMutation,
  useManualTradeMutation,
} = strategyApi;

export const {
  endpoints: {
    getTradPositionHistory,
    getStrategy,
    getAllStrategyHistory,
    getTradePosition,
    applyStrategy,
    getAllOrder,
    getAllOrderConnected,
    uploadTradeImage,
    manualTrade,
  },
} = strategyApi;
