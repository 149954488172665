import { memo } from "react";
import { IconProps } from ".";

export interface stategyOrderProps extends IconProps {}
const stategyOrder = (props: stategyOrderProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 50,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 0.5,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M14.4395 18.2383C15.0867 18.2383 15.6113 17.7136 15.6113 17.0664C15.6113 16.4192 15.0867 15.8945 14.4395 15.8945C13.7922 15.8945 13.2676 16.4192 13.2676 17.0664C13.2676 17.7136 13.7922 18.2383 14.4395 18.2383Z"
        fill="#3D3D3D"
      />
      <path
        d="M37.4961 48.0469C35.1258 48.0469 33.1992 46.1203 33.1992 43.75C33.1992 41.3797 35.1258 39.4531 37.4961 39.4531C39.8664 39.4531 41.793 41.3797 41.793 43.75C41.793 46.1203 39.8664 48.0469 37.4961 48.0469ZM37.4961 41.7969C36.4195 41.7969 35.543 42.6734 35.543 43.75C35.543 44.8266 36.4195 45.7031 37.4961 45.7031C38.5727 45.7031 39.4492 44.8266 39.4492 43.75C39.4492 42.6734 38.5727 41.7969 37.4961 41.7969Z"
        fill="#3D3D3D"
      />
      <path
        d="M11.4023 48.0469C9.03203 48.0469 7.10547 46.1203 7.10547 43.75C7.10547 41.3797 9.03203 39.4531 11.4023 39.4531C13.7727 39.4531 15.6992 41.3797 15.6992 43.75C15.6992 46.1203 13.7727 48.0469 11.4023 48.0469ZM11.4023 41.7969C10.3258 41.7969 9.44922 42.6734 9.44922 43.75C9.44922 44.8266 10.3258 45.7031 11.4023 45.7031C12.4789 45.7031 13.3555 44.8266 13.3555 43.75C13.3555 42.6734 12.4789 41.7969 11.4023 41.7969Z"
        fill="#3D3D3D"
      />
      <path
        d="M14.4391 10.5469H9.63281V8.20312H14.4391C15.0859 8.20312 15.6109 8.72812 15.6109 9.375C15.6109 10.0219 15.0859 10.5469 14.4391 10.5469Z"
        fill="#3D3D3D"
      />
      <path
        d="M37.3273 35.7484H10.0898V33.4047H37.3273C38.8102 33.4047 40.1336 32.475 40.6352 31.0797L45.4961 17.5609C45.6336 17.1797 45.7039 16.7766 45.7039 16.3703V12.5C45.7039 11.4219 44.8289 10.5469 43.7508 10.5469H39.3836C38.7367 10.5469 38.2117 10.0219 38.2117 9.375C38.2117 8.72812 38.7367 8.20312 39.3836 8.20312H43.7508C46.1242 8.20312 48.0477 10.1266 48.0477 12.5V16.3672C48.0477 17.0438 47.9305 17.7172 47.7008 18.3547L42.8398 31.8734C42.0039 34.1984 39.7977 35.7484 37.3273 35.7484Z"
        fill="#3D3D3D"
      />
      <path
        d="M37.4961 41.7965H6.59453C5.11172 41.7965 3.73047 40.8434 3.36953 39.4043C2.97891 37.8449 3.71797 36.3465 5.17422 35.7074L9.23516 33.9293C9.38203 33.8652 9.47578 33.7168 9.46797 33.5559L8.38828 7.66367C8.31016 5.78086 6.76016 4.29492 4.87578 4.29492H4.29297C3.64609 4.29492 3.12109 3.76992 3.12109 3.12305C3.12109 2.47617 3.64609 1.95117 4.29297 1.95117H4.87422C8.01484 1.95117 10.5977 4.42773 10.7289 7.5668L11.8086 33.4574C11.8555 34.584 11.2055 35.6246 10.1727 36.0762L6.11172 37.8527C5.53672 38.1043 5.59766 38.6324 5.63047 38.7871C5.66328 38.9418 5.81797 39.4496 6.44609 39.4496H37.4961V41.7934V41.7965Z"
        fill="#3D3D3D"
      />
      <path
        d="M46.8742 18.2383H39.6445C38.9977 18.2383 38.4727 17.7133 38.4727 17.0664C38.4727 16.4195 38.9977 15.8945 39.6445 15.8945H46.8742V18.2383Z"
        fill="#3D3D3D"
      />
      <path
        d="M27.9082 34.575H25.5645V25.2344C25.5645 24.5875 26.0895 24.0625 26.7363 24.0625C27.3832 24.0625 27.9082 24.5875 27.9082 25.2344V34.575Z"
        fill="#3D3D3D"
      />
      <path
        d="M36.2598 34.575H33.916V22.5938C33.916 21.9469 34.441 21.4219 35.0879 21.4219C35.7348 21.4219 36.2598 21.9469 36.2598 22.5938V34.575Z"
        fill="#3D3D3D"
      />
      <path
        d="M19.5625 34.575H17.2188V22.5938C17.2188 21.9469 17.7437 21.4219 18.3906 21.4219C19.0375 21.4219 19.5625 21.9469 19.5625 22.5938V34.575Z"
        fill="#3D3D3D"
      />
      <path
        d="M27.043 21.4844C21.6586 21.4844 17.2773 17.1031 17.2773 11.7188C17.2773 6.33437 21.657 1.95312 27.043 1.95312C32.4289 1.95312 36.8086 6.33437 36.8086 11.7188C36.8086 17.1031 32.4273 21.4844 27.043 21.4844ZM27.043 4.29688C22.9508 4.29688 19.6211 7.62656 19.6211 11.7188C19.6211 15.8109 22.9508 19.1406 27.043 19.1406C31.1352 19.1406 34.4648 15.8109 34.4648 11.7188C34.4648 7.62656 31.1352 4.29688 27.043 4.29688Z"
        fill="#3D3D3D"
      />
      <path
        d="M25.7043 15.1203L23.1715 12.5875C22.7137 12.1297 22.7137 11.3875 23.1715 10.9297C23.6293 10.4719 24.3715 10.4719 24.8293 10.9297L26.3184 12.4187L29.7184 9.01719C30.1762 8.55938 30.9184 8.55938 31.3762 9.01719C31.834 9.475 31.834 10.2172 31.3762 10.6734L26.9309 15.1187C26.5918 15.4578 26.0434 15.4578 25.7059 15.1187L25.7043 15.1203Z"
        fill="#3D3D3D"
      />
    </svg>
  );
};

const MemostategyOrder = memo(stategyOrder);
export default MemostategyOrder;
