import { useContext } from "react";
import { WebSocketContext } from "./WebSocketProvider";

const useWebSocketContext = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocketContext must be used within a WebSocketProvider");
  }
  return context;
};

export default useWebSocketContext;
