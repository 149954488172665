import React from "react";

interface CheckboxProps {
  className?: string;
  containerClassName?: string;
  id?: string;
  name?: string;
  value?: string;
  label?: string;
  checked?: boolean | undefined;
  disabled?: boolean;
  onChange?: (check?: any) => void;
}
const CheckBox = (props: CheckboxProps) => {
  const {
    className = "",
    containerClassName = "",
    id,
    name,
    value,
    label,
    checked,
    disabled = false,
    onChange,
  } = props;
  return (
    <div
      className={`flex gap-1.5 ${
        containerClassName === "" ? "items-center" : containerClassName
      }`}
    >
      <input
        className={`${className} checkbox h-4 w-4 `}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      {label ? (
        <label
          className={` text-sm ${disabled ? "opacity-50" : "text-primary-950"}`}
        >
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default CheckBox;
