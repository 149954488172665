export interface SwitchProps {
  name: string;
  label?: string;
  value?: boolean;
  checked?: boolean;
  onChange?: (value?: any, e?: any) => void;
  // isLoading?: boolean;
  className?: string;
  sliderClass?: string;
  inputClassName?: string;
  disabled?: boolean;
}

const Switch = (props: SwitchProps) => {
  const {
    value,
    checked,
    label,
    onChange,
    // isLoading = false,
    className = "",
    sliderClass = "",
    inputClassName = "",
    disabled = false,
    ...rest
  } = props;

  return (
    <div
      className={`${className} switch`}
      onClick={(e) => {
        onChange?.(!value, e);
      }}
    >
      <input
        className={`${inputClassName}`}
        checked={!!checked}
        type="checkbox"
        disabled={disabled}
        {...rest}
      />
      <span className={`${sliderClass} slider`}></span>
    </div>
  );
};

export default Switch;
