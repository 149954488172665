import React, { memo } from "react";
import { IconProps } from ".";

export interface EyeOnIconProps extends IconProps{

}
const EyeOnIcon = (props: EyeOnIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path
        d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
        className={`${pathClassNames?.[0] || ""}`}
      />
      <circle cx="12" cy="12" r="3" />
    </svg>
  );
};

const MemoEyeOnIcon = memo(EyeOnIcon);
export default MemoEyeOnIcon;
