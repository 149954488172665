import { useRef } from "react";
import MarketList from "./MarketList";
import spotMarket from "../../data/spotMarket.json";
import useWebSocketContext from "../../context/useWebSocketContext";
import { useSpotMarket } from "../common/commonSlice";

const Markets = () => {
  const { tickers, allTickers } = useWebSocketContext();

  const allSpotMarket = useSpotMarket();

  const activeItem = useRef("USDT");

  const isActive = (menuItem: string) => {
    return activeItem.current === menuItem;
  };

  const setActive = (menuItem: string) => {
    activeItem.current = menuItem;
  };

  const prepareTickerData = () => {
    try {
      let filteredMarkets: any = {};
      Object.entries(spotMarket || {})?.forEach(([key, item]: any) => {
        Object.entries(item || {})?.forEach(([key2, item2]: any) => {
          if (item2?.active) {
            filteredMarkets = {
              ...filteredMarkets,
              [key]: {
                ...filteredMarkets[key],
                [key2]: item2,
              },
            };
          }
        });
      });

      const newData: any[] = [];

      allSpotMarket?.data?.[activeItem.current]?.forEach((item: any) => {
        let ticker = tickers?.find(
          (subItem: any) => subItem?.s === item?.symbol?.replace("/", "")
        );
        let current = allTickers.current;

        if (!!item?.symbol) {
          const lastTicker = allTickers?.[current - 1]?.find(
            (subItem: any) => subItem?.s === item?.symbol?.replace("/", "")
          );

          const currentMarketData: any = { ...(item || {}) };

          currentMarketData["price"] = ticker?.c || 0;
          currentMarketData["class"] =
            ticker?.c >= lastTicker?.c ? "text-primary-green" : "text-primary-red"; // TODO: color should remain same for no price change
          currentMarketData["change"] = ticker?.P || 0;
          currentMarketData["classChange"] =
            ticker?.P > 0 ? "text-primary-green" : "text-primary-red";

          newData.push(currentMarketData);
        }
      });

      return newData;
    } catch (error) {
      console.error("Error handling ticker data:", error);
      return {};
    }
  };

  return (
    <div className="Markets text-primary-950 relative hidden_scroll">
      <div className="mb-1 flex sticky top-0 z-[1]">
        <ul
          id="myTab"
          className="flex-cols flex overflow-x-hidden text-center text-sm font-medium w-full"
          role="tablist"
        >
          {Object.entries(allSpotMarket?.data || {})?.map(
            ([key]: any, index: any) => (
              <li key={index}>
                <small>
                  {/* <a
                    className={`inline-block px-2 py-1 border-r border-secondary-200 cursor-pointer ${
                      isActive(key) ? "text-ev-primary" : ""
                    }`}
                    onClick={() => setActive(key)}
                  >
                    {key}
                  </a> */}
                  <button
                    className={`inline-block px-2 py-1 border-r border-secondary-200 cursor-pointer ${
                      isActive(key) ? "text-ev-primary" : ""
                    }`}
                    onClick={() => setActive(key)}
                    type="button"
                  >
                    {key}
                  </button>
                </small>
              </li>
            )
          )}
        </ul>
      </div>
      <div id="myTabContent" className="marketlistheight hidden_scroll">
        <MarketList list={prepareTickerData()} />
      </div>
    </div>
  );
};

export default Markets;
