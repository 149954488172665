import { Fragment, useEffect, useState } from "react";
import { useGetExchangeMutation } from "../../exchange/exchangeApi";
import { useExchangeList } from "../../exchange/exchangeSlice";
import { Button, DropDown, Table, TableRow } from "../../../ui";
import {
  useApplyStrategyMutation,
  useGetStrategyMutation,
} from "../strategyApi";
import { useStrategyList } from "../strategySlice";
import { responseToaster } from "../../../helperFunctions";
import { useLocation } from "react-router-dom";
import UnfollowModal from "./UnfollowModal";

const ManageStrategy = () => {
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const [tableData, setTableData] = useState<any>([]);
  const [unfollowModal, setUnfollowModal] = useState(null);
  const exchangeList = useExchangeList();
  const strategyList = useStrategyList();

  const [getExchange] = useGetExchangeMutation();
  const [getStrategy, { isLoading: isStrategyLoading }] =
    useGetStrategyMutation();
  const [applyStrategy, { isLoading: isApplyLoading }] =
    useApplyStrategyMutation();

  const prepareExchangeOptions = () => {
    const options = exchangeList?.data?.exchanges?.exchange_list?.map(
      (item: any) => ({
        label: item?.exchange_name,
        value: item?.exchange_name,
      })
    );
    options.unshift({ label: "ai", value: "ai" });
    return options;
  };

  const onApplyStrategy = async (item: any) => {
    try {
      const payload = {
        strategy_name: item?.strategy_name,
        exchange_name: item?.user_strategy?.exchange_name || "ai",
        is_active: item?.is_active,
      };
      const res = await applyStrategy({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
      await onGetStrategy();
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const columns = [
    {
      title: "Strategy",
      name: "strategy_name",
    },
    {
      title: "Action",
      name: "action",
      Cell: (data: any) => {
        return (
          <>
            <Button
              onClick={() => {
                if (data?.user_strategy?.is_active === 1) {
                  setUnfollowModal(data);
                } else {
                  onApplyStrategy({
                    ...data,
                    is_active: !!data?.user_strategy?.is_active ? 0 : 1,
                  });
                }
              }}
            >
              {data?.user_strategy?.is_active === 1 ? "Disconnect" : "Apply"}
            </Button>
          </>
        );
      },
    },
    {
      title: "Exchange",
      name: "exchange",
      Cell: (data: any) => {
        return (
          <>
            <DropDown
              name="exchange"
              value={data?.user_strategy?.exchange_name || "ai"}
              options={prepareExchangeOptions()}
              onChange={(e) => {
                setTableData((tableData: any) => {
                  const newData = [...(tableData || [])];
                  newData[data?.index] = {
                    ...(newData[data?.index] || {}),
                    user_strategy: {
                      ...(newData[data?.index]?.user_strategy || {}),
                      exchange_name: e,
                    },
                  };
                  return newData;
                });
              }}
            />
          </>
        );
      },
    },
  ];

  const onGetExchange = async () => {
    try {
      await getExchange({
        group_name: pathnames?.[1],
        is_active: 1,
      });
    } catch (error) {}
  };

  const onGetStrategy = async () => {
    try {
      await getStrategy({
        group_name: pathnames?.[1],
      });
    } catch (error) {}
  };

  useEffect(() => {
    setTableData(strategyList?.data || []);
    // eslint-disable-next-line
  }, [strategyList]);

  useEffect(() => {
    onGetExchange();
    onGetStrategy();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col mt-5">
      <div className={`card_rounded mb-4`}>
        <Table
          isLoading={isStrategyLoading || isApplyLoading}
          columns={columns}
          data={tableData || []}
          isExpendable={false}
          setData={setTableData}
          className="!overflow-scroll order_table"
          tableDataCount={tableData?.length}
        >
          {tableData?.map?.((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  i={index}
                  className="min-h-[350px]"
                  columns={columns}
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
      {unfollowModal ? (
        <UnfollowModal
          unfollowModal={unfollowModal}
          onClose={() => setUnfollowModal(null)}
        />
      ) : null}
    </div>
  );
};

export default ManageStrategy;
