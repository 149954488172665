import { useEffect, useRef, useState } from "react";
import useWebSocketContext from "../../../context/useWebSocketContext";
import SpotOrderHistory from "./SpotOrderHistory";
import { FILTER, tradeEnum } from "../../../constant";
import { Button, ControlledDatePicker, ControlledDropDown } from "../../../ui";
import { useForm } from "react-hook-form";
import SpotOpenOrder from "./SpotOpenOrder";
import { useKeys } from "../../exchange/exchangeSlice";
import { dateFormatter1 } from "../../../helperFunctions";
import {
  useGetPlatformsMutation,
  useGetSymbolsMutation,
} from "../../common/commonApi";
import { usePlatforms, useSymbols } from "../../common/commonSlice";
import { useLocation } from "react-router-dom";
import { useRole } from "../../auth/authSlice";

const initialFilter = {
  ...FILTER,
  symbol: "All",
  platform: "All",
  trade: "All",
};

const SpotOrder = () => {
  const { binanceOpenOrders, emitOpenOrder, emitOrderHistory } =
    useWebSocketContext();

  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();

  const [filter, setFilter] = useState<any>({
    ...initialFilter,
    isCall: false,
  });
  const isInitialMount = useRef(true);

  const allSymbols = useSymbols();
  const [getSymbols] = useGetSymbolsMutation();
  const [getPlatforms] = useGetPlatformsMutation();
  const platforms = usePlatforms();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const role = useRole("spot-order");
  const tabs: any[] = [];

  if (role.includes("View Spot Open Order")) {
    tabs.push({
      name: `Open Orders`,
      label: `Open Orders(${binanceOpenOrders?.[is_tab_connect]?.length || 0})`,
    });
  }

  if (role.includes("View Spot Order History")) {
    tabs.push({ name: "Order History", label: "Order History" });
  }

  const [activeTab, setActiveTab] = useState(tabs?.[0]?.name);

  // const orderHistoryHeaders = [
  //   {
  //     label: "Exchange",
  //     key: "is_connect_order",
  //   },
  //   {
  //     label: "Date",
  //     key: "created_at",
  //   },
  //   {
  //     label: "Pair",
  //     key: "symbol",
  //   },
  //   {
  //     label: "Type",
  //     key: "type",
  //   },
  //   {
  //     label: "Side",
  //     key: "side",
  //   },
  //   {
  //     label: "Average",
  //     key: "exchange_rate",
  //   },
  //   {
  //     label: "Price",
  //     key: "stock_price",
  //   },
  //   {
  //     label: "Executed",
  //     key: "executedQty",
  //   },
  //   {
  //     label: "Amount",
  //     key: "amount",
  //   },
  //   {
  //     label: "Total",
  //     key: "total",
  //   },
  //   {
  //     label: "Status",
  //     key: "order_status",
  //   },
  // ];

  // const openOrderHeaders = [
  //   {
  //     label: "Date",
  //     key: "created_at",
  //   },
  //   {
  //     label: "Pair",
  //     key: "symbol",
  //   },
  //   {
  //     label: "Type",
  //     key: "type",
  //   },
  //   {
  //     label: "Side",
  //     key: "side",
  //   },
  //   {
  //     label: "Price",
  //     key: "stock_price",
  //   },
  //   {
  //     label: "Amount",
  //     key: "amount",
  //   },
  //   {
  //     label: "Status",
  //     key: "status",
  //   },
  //   {
  //     label: "Total",
  //     key: "total",
  //   },
  // ];

  const onGet = async () => {
    try {
      await getSymbols({
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err: any) {
      console.error("ERR", err);
    }
  };

  const initialValues = {
    date: [null, null],
    symbol: "All",
    platform: "All",
    trade: "All",
  };

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  const prepareMarketOptions = () => {
    try {
      let filteredMarkets: any = [];
      allSymbols?.data?.forEach((symbol: string) => {
        filteredMarkets.push({
          value: symbol,
          label: symbol,
        });
      });
      filteredMarkets.unshift({ value: "All", label: "All" });
      return filteredMarkets;
    } catch (error) {
      console.error("Error handling ticker data:", error);
      return {};
    }
  };

  const onSubmit = async (values: any) => {
    setFilter({
      ...filter,
      ...FILTER,
      ...values,
      isCall: true,
    });
  };

  const preparePlatformOptions = () => {
    try {
      let arr: any = [];
      platforms?.data?.forEach((symbol: string) => {
        arr.push({
          value: symbol,
          label: symbol,
        });
      });
      arr.unshift({ value: "All", label: "All" });
      return arr;
    } catch (error) {
      console.error("Error:", error);
      return {};
    }
  };

  const prepareTradeOptions = () => {
    try {
      let arr: any = [];
      Object.entries(tradeEnum)?.forEach(([key, value]: any) => {
        arr.push({
          value: key,
          label: value,
        });
      });
      arr.unshift({ value: "All", label: "All" });
      return arr;
    } catch (error) {
      console.error("Error:", error);
      return {};
    }
  };

  const onGetPlatforms = async () => {
    try {
      await getPlatforms({
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err: any) {}
  };

  useEffect(() => {
    onGet();
    onGetPlatforms();
    // eslint-disable-next-line
  }, []);

  const onGetTabsData = (tabs: any[]) => {
    const { date } = filter;

    if (tabs?.includes("Open Orders")) {
      setTimeout(() => {
        const payload = {
          start_date: dateFormatter1(date?.[0], "start"),
          end_date: dateFormatter1(date?.[1], "end", date?.[0]),
          limit: filter?.limit,
          page: filter?.page,
          symbol: filter?.symbol,
          is_connect: is_connect,
          order_type: 2,
          is_tab_connect: is_tab_connect,
          exchange_name: exchange_name,
          platform: filter?.platform,
          trade_type: filter?.trade,
          group_name: pathnames?.[1],
        };
        emitOpenOrder(payload);
      }, 300);
    }
    if (tabs?.includes("Order History")) {
      const payload = {
        is_connect: is_connect,
        start_date: dateFormatter1(date?.[0], "start"),
        end_date: dateFormatter1(date?.[1], "end", date?.[0]),
        limit: filter?.limit,
        page: filter?.page,
        symbol: filter?.symbol,
        order_type: 2,
        exchange_name: exchange_name,
        platform: filter?.platform,
        trade_type: filter?.trade,
        group_name: pathnames?.[1],
      };
      emitOrderHistory(payload);
    }
  };

  useEffect(() => {
    if (filter?.isCall) {
      onGetTabsData([activeTab]);
    }
    // eslint-disable-next-line
  }, [filter, isInitialMount.current]);

  useEffect(() => {
    setFilter({
      ...filter,
      ...FILTER,
      isCall: false,
    });
    onGetTabsData(tabs?.map((item) => item?.name));
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`space-y-5`}>
      <div className="card_rounded flex gap-2 items-end pt-4 flex-wrap">
        <ControlledDropDown
          className="w-[200px]"
          name="symbol"
          label="Symbol"
          control={control}
          errors={errors}
          options={prepareMarketOptions()}
        />
        <ControlledDropDown
          className="w-[200px]"
          name="platform"
          label="Platform"
          control={control}
          errors={errors}
          options={preparePlatformOptions()}
        />
        <ControlledDropDown
          className="w-[200px]"
          name="trade"
          label="Trade"
          control={control}
          errors={errors}
          options={prepareTradeOptions()}
        />
        <ControlledDatePicker
          inputClassName="!w-fit"
          name="date"
          placeholder="Select Date"
          label="Date"
          maxDate={new Date()}
          selectsRange
          control={control}
        />
        <Button className="!h-8 !text-sm" onClick={handleSubmit(onSubmit)}>
          Search
        </Button>
        <Button
          className="!h-8 !text-sm"
          onClick={() => {
            reset();
            setFilter({
              ...initialFilter,
              isCall: true,
            });
          }}
        >
          Reset
        </Button>
        {/* {role.includes("Export Spot Order") ? (
          <>
            <Button
              className="!h-8 !text-sm"
              onFetchCSV={async () => {
                const payload: any = {
                  symbol: filter?.symbol,
                  start_date: dateFormatter1(filter?.date?.[0], "start"),
                  end_date: dateFormatter1(filter?.date?.[1], "end", filter?.date?.[0]),
                  exchange_name: exchange_name,
                  group_name: pathnames?.[1],
                };
                if (activeTab === "Open Orders") {
                  payload["order_status"] = 1;
                  payload["order_type"] = 2;
                  const res: any = await getAllOrder({
                    ...payload,
                  }).unwrap();

                  const data = res?.data?.map((item: any) => ({
                    ...item,
                    type: typeEnum?.[item?.type],
                    side: sideEnum?.[item?.side],
                    stock_price: ["8", "9"]?.includes(item?.type.toString())
                      ? item?.type?.toString() === "8"
                        ? onFixed(item?.take_profit)
                        : onFixed(item?.stop_loss)
                      : onFixed(
                          item?.stock_price ||
                            (item?.type === "STOP_MARKET" ||
                            item?.type === "TAKE_PROFIT_MARKET"
                              ? item?.stopPrice
                              : item?.price)
                        ),
                    amount: onFixed(item?.amount),
                    total: onFixed(item?.total),
                    order_status: orderStatuses?.[item?.order_status],
                  }));
                  return {
                    filename: "Open Order",
                    headers: openOrderHeaders,
                    data: data || [],
                  };
                } else {
                  payload["order_type"] = 2;
                  const res: any = await getAllOrder({
                    ...payload,
                  }).unwrap();
                  const data = res?.data?.map((item: any) => ({
                    ...item,
                    is_connect_order: exchange_name,
                    type: typeEnum?.[item?.type],
                    side: sideEnum?.[item?.side],
                    exchange_rate: onFixed(item?.exchange_rate),
                    stock_price:
                      item?.type === 1
                        ? "Market"
                        : ["8", "9"]?.includes(item?.type.toString())
                        ? item?.type?.toString() === "8"
                          ? onFixed(item?.take_profit)
                          : onFixed(item?.stop_loss)
                        : onFixed(item?.stock_price),
                    executedQty: onFixed(item?.executed),
                    amount: onFixed(item?.amount),
                    total: onFixed(item?.total),
                    order_status: orderStatuses?.[item?.order_status],
                  }));
                  return {
                    filename: "Order History",
                    headers: orderHistoryHeaders,
                    data: data || [],
                  };
                }
              }}
            >
              Export
            </Button>
          </>
        ) : null} */}
      </div>
      <div className="card_rounded">
        <div className="flex gap-4">
          {tabs?.map((item: any, index: any) => {
            return (
              <div key={index}>
                <div
                  className={`cursor-pointer flex justify-center items-center relative activetab_hover ${
                    item?.name === activeTab
                      ? "activetab font-semibold text-primary-950 "
                      : "font-normal text-primary-950 opacity-50"
                  }`}
                  onClick={() => {
                    setActiveTab(item?.name);
                    setFilter({
                      ...filter,
                      ...FILTER,
                      isCall: true,
                    });
                  }}
                >
                  <p>{item?.label}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pt-4">
          {activeTab === `Open Orders` ? (
            <SpotOpenOrder setFilter={setFilter} filter={filter} />
          ) : activeTab === "Order History" ? (
            <SpotOrderHistory setFilter={setFilter} filter={filter} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SpotOrder;
