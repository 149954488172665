import { api } from "../../utills/api";

export const tradeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTradeHistory: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-trade-history",
        body: params,
        method: "POST",
      }),
    }),
    getTradePosition: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/get-trade-positions",
        body: { status: 1, ...params },
        method: "POST",
      }),
    }),
    getTradePositionHistory: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/get-trade-positions",
        body: { status: 0, ...(params || {}) },
        method: "POST",
      }),
    }),
    getTradePositionDetails: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/get-trade-positions",
        body: { ...params },
        method: "POST",
      }),
    }),
    buyTrade: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/buy-trade",
        body: params,
        method: "POST",
      }),
    }),
    sellTrade: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/sell-trade",
        body: params,
        method: "POST",
      }),
    }),
    getClosingData: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-closing-data",
        body: params,
        method: "POST",
      }),
    }),
    buyCoin: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/buy-coin",
        body: params,
        method: "POST",
      }),
    }),
    sellCoin: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/sell-coin",
        body: params,
        method: "POST",
      }),
    }),
    getOrderHistory: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/get-order-history",
        body: {
          order_type: 2,
          order_status: 0,
          ...(params || {}),
        },
        method: "POST",
      }),
    }),
    getFutureOrderHistory: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/get-order-history",
        body: {
          order_type: 1,
          order_status: 0,
          ...(params || {}),
        },
        method: "POST",
      }),
    }),
    getAllFilledOrderHistory: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-all-order-history",
        body: {
          order_type: 2,
          order_status: 5,
          ...(params || {}),
        },
        method: "POST",
      }),
    }),
    getAllOrder: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-all-order-history",
        body: params,
        method: "POST",
      }),
    }),
    getAllOrderDetails: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-all-order-history",
        body: params,
        method: "POST",
      }),
    }),
    getAllFuturePositionHistory: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-all-positions-history",
        body: params,
        method: "POST",
      }),
    }),
    getAllFuturePosition: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-all-positions-history",
        body: params,
        method: "POST",
      }),
    }),
    getAllTradeHistory: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/trade-history",
        body: params,
        method: "POST",
      }),
    }),
    // getAllDataTradeHistory: build.mutation<{ data: any }, any>({
    //   query: (params) => ({
    //     url: "/trade-history",
    //     body: params,
    //     method: "POST",
    //   }),
    // }),
    getOpenOrders: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-order-history",
        body: {
          order_type: 2,
          order_status: 1,
        },
        method: "POST",
      }),
    }),
    getFutureOpenOrders: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-order-history",
        body: {
          order_type: 1,
          order_status: 1,
        },
        method: "POST",
      }),
    }),
    updateOpenOrderPrice: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/update-limit-order",
        body: params,
        method: "POST",
      }),
    }),
    cancelOpenOrder: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/cancel-limit-order",
        body: params,
        method: "POST",
      }),
    }),
    updatePosition: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/update-position",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetTradeHistoryMutation,
  useGetTradePositionMutation,
  useGetTradePositionHistoryMutation,
  useBuyTradeMutation,
  useSellTradeMutation,
  useGetClosingDataMutation,
  useBuyCoinMutation,
  useSellCoinMutation,
  useGetAllOrderMutation,
  useGetAllFilledOrderHistoryMutation,
  useGetAllFuturePositionHistoryMutation,
  useGetAllTradeHistoryMutation,
  useGetAllFuturePositionMutation,
  // useGetAllDataTradeHistoryMutation,
  useUpdateOpenOrderPriceMutation,
  useCancelOpenOrderMutation,
  useUpdatePositionMutation,
  useGetTradePositionDetailsMutation,
  useGetAllOrderDetailsMutation,
} = tradeApi;

export const {
  endpoints: {
    getTradeHistory,
    getTradePosition,
    getTradePositionHistory,
    buyTrade,
    sellTrade,
    getClosingData,
    buyCoin,
    sellCoin,
    getAllOrder,
    getAllFilledOrderHistory,
    getAllFuturePositionHistory,
    getAllFuturePosition,
    getAllTradeHistory,
    // getAllDataTradeHistory,
    updateOpenOrderPrice,
    cancelOpenOrder,
    updatePosition,
    getTradePositionDetails,
    getAllOrderDetails,
  },
} = tradeApi;
