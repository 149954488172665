import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { rolesApi } from "./rolesApi";

interface RolesState {
  roles: any;
}

const initialState: RolesState = {
  roles: {},
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    clearRoles: () => initialState,
    setRoles: (state, { payload }) => {
      state.roles = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      rolesApi.endpoints.getRoles.matchFulfilled,
      (state: any, { payload }) => {
        state.roles = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default rolesSlice.reducer;
export const { clearRoles } = rolesSlice.actions;

export const selectRoles = (state: RootState) => state.roles.roles;
export const useRoles = () => {
  const data = useSelector(selectRoles);
  return useMemo(() => data, [data]);
};
