import { Fragment, useCallback, useEffect, useMemo } from "react";
import { Table, TableRow } from "../../ui";
import { SideEnumKeys, sideEnum } from "../../constant";
import useWebSocketContext from "../../context/useWebSocketContext";
import {
  onFixed,
  preparePriceStyle,
  dateFormatter,
  groupBy,
  removeTimeFromDate,
} from "../../helperFunctions";

import { useKeys } from "../exchange/exchangeSlice";
import useStrategyContext from "../../context/StrategyContext";
import StrategyStatistics from "./StrategyStatistics";
import { useGetTradPositionHistoryMutation } from "./strategyApi";
import { useLocation } from "react-router-dom";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";

const PositionHistory = (props: any) => {
  const { isPositionList = false } = props;
  const { tickers } = useWebSocketContext();
  const {
    filter,
    positionHistory,
    isPositionHistoryLoading,
    filterForPositionHistory,
    setFilterForPositionHistory,
    setChartData,
    setSymbolWisePnL,
  } = useStrategyContext();
  const [getAllFuturePosition, { data: allPositionHistory }] =
    useGetTradPositionHistoryMutation();

  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const columnsPositions = useMemo(
    () => [
      {
        title: "Position Time",
        name: "created_at",
        prepareValue: (data: any) =>
          `${!isPositionList ? data?.children?.created_at + " | " : ""}${
            data?.created_at
          }`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? <p>{`${data?.children?.created_at}`}</p> : null}
            <p>{`${data?.created_at}`}</p>
          </>
        ),
      },
      {
        title: "Symbol",
        prepareValue: (data: any) =>
          `${!isPositionList ? data?.children?.symbol + " | " : ""}${
            data?.symbol
          }`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? <p>{`${data?.children?.symbol}`}</p> : null}
            <p>{`${data?.symbol}`}</p>
          </>
        ),
      },
      {
        title: "Size",
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.children?.total) + " | " : ""
          }${onFixed(data?.total)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.total)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.total)}`}</p>
          </>
        ),
      },
      {
        title: "Entry Price",
        name: "current_price",
        prepareValue: (data: any) =>
          `${
            !isPositionList
              ? onFixed(data?.children?.current_price) + " | "
              : ""
          }${onFixed(data?.current_price)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.current_price)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.current_price)}`}</p>
          </>
        ),
      },
      {
        title: "Mark Price",
        name: "stock_price",
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.children?.stock_price) + " | " : ""
          }${onFixed(data?.stock_price)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.stock_price)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.stock_price)}`}</p>
          </>
        ),
      },
      {
        title: "Trade Value",
        name: "trade_value",
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.children?.trade_value) + " | " : ""
          }${onFixed(data?.trade_value)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.trade_value)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.trade_value)}`}</p>
          </>
        ),
      },
      {
        title: "Side",
        prepareValue: ({
          side,
          children,
        }: {
          side: SideEnumKeys;
          children: { side: SideEnumKeys };
        }) =>
          `${!isPositionList ? sideEnum?.[children?.side] + " | " : ""}${
            sideEnum?.[side]
          }`,
        Cell: ({
          side,
          children,
        }: {
          side: SideEnumKeys;
          children: { side: SideEnumKeys };
        }) => (
          <>
            {!isPositionList ? (
              <>
                {/* {`${sideEnum?.[children?.side]}`} */}
                <div className="flex gap-2 items-center">
                  {sideEnum?.[children?.side] === "Buy" ? (
                    <GoArrowUpRight size={20} color="var(--primary-green--)" />
                  ) : (
                    <GoArrowDownRight size={20} color="var(--primary-red--)" />
                  )}
                  <p>{`${sideEnum?.[children?.side]}`}</p>
                </div>
              </>
            ) : null}
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
      },
      {
        title: "Platform",
        name: "platform",
      },
      {
        title: "Trade Type",
        name: "trade_type",
      },
      {
        title: "PNL(ROI %)",
        name: "amount",
        showColumn: isPositionList,
        prepareValue: (data: any) => {
          const oldValue =
            (+data?.trade_value || 0) * (+data?.current_price || 0);
          const currentValue =
            (+data?.trade_value || 0) * (+data?.ticker?.c || 0);

          const diffValue =
            data?.side === "buy"
              ? currentValue - oldValue
              : oldValue - currentValue;
          const per = (diffValue / currentValue) * 100;
          return `${
            data?.tickerIndex > -1 ? Math.abs(+onFixed(diffValue)) : 0
          } = ${data?.tickerIndex > -1 ? onFixed(Math.abs(per), 2) : 0} %`;
        },
        Cell: (data: any) => {
          const oldValue =
            (+data?.trade_value || 0) * (+data?.current_price || 0);
          const currentValue =
            (+data?.trade_value || 0) * (+data?.ticker?.c || 0);

          const diffValue =
            data?.side === "buy"
              ? currentValue - oldValue
              : oldValue - currentValue;
          const per = (diffValue / currentValue) * 100;
          return (
            <div className="flex flex-col gap-2">
              <span
                className={`${
                  preparePriceStyle(+onFixed(diffValue))?.className
                }`}
              >
                {data?.tickerIndex > -1 ? Math.abs(+onFixed(diffValue)) : 0}
              </span>
              <span className={`${preparePriceStyle(+per)?.className}`}>
                {data?.tickerIndex > -1 ? onFixed(Math.abs(per), 2) : 0} %
              </span>
            </div>
          );
        },
      },
      {
        title: "PNL(ROI %)",
        name: "profit_loss",
        showColumn: !isPositionList,
        prepareValue: (data: any) =>
          `${onFixed(data?.children?.profit_loss) || 0}`,
        Cell: (data: any) => {
          return (
            <div className="flex flex-col gap-2">
              <span
                className={`${
                  preparePriceStyle(+data?.children?.profit_loss)?.className
                }`}
              >
                {onFixed(data?.children?.profit_loss) || 0}
              </span>
            </div>
          );
        },
      },
      {
        title: "Fees",
        name: "fees",
        showColumn: !isPositionList,
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.children?.fees) + " | " : ""
          }${onFixed(data?.fees)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.fees)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.fees)}`}</p>
          </>
        ),
      },
      {
        title: "Market Value",
        name: "market_value",
        showColumn: !isPositionList,
        prepareValue: (data: any) =>
          `${
            !isPositionList ? onFixed(data?.market_value) + " | " : ""
          }${onFixed(data?.market_value)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.market_value)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.market_value)}`}</p>
          </>
        ),
      },
    ],
    // eslint-disable-next-line 
    [positionHistory, isPositionList]
  );
  const columnsBinancePositions = useMemo(
    () => [
      {
        title: "Position Time",
        name: "created_at",
        prepareValue: (data: any) =>
          !isPositionList
            ? data?.opened + " " + data?.closed || "-"
            : dateFormatter(data?.datetime, "default"),
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <div className="flex flex-col">
                <p>{data?.opened}</p>
                <p>{data?.closed || "-"}</p>
              </div>
            ) : (
              <p>{`${dateFormatter(data?.datetime, "default")}`}</p>
            )}
          </>
        ),
      },
      {
        title: "Symbol",
        prepareValue: (data: any) =>
          !isPositionList ? data?.symbol : data?.symbol?.split(":")?.[0],
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${data?.symbol}`}</p>
            ) : (
              <p>{`${data?.symbol?.split(":")?.[0]}`}</p>
            )}
          </>
        ),
      },
      {
        title: "Size",
        showColumn: isPositionList,
        prepareValue: (data: any) => {
          const positionAmt = +data?.info?.positionAmt || 0;
          const currentPrice = +data?.ticker?.c || 0;
          const currentValue = positionAmt * currentPrice;

          return !isPositionList ? onFixed(data?.qty) : onFixed(currentValue);
        },
        Cell: (data: any) => {
          const positionAmt = +data?.info?.positionAmt || 0;
          const currentPrice = +data?.ticker?.c || 0;
          const currentValue = positionAmt * currentPrice;
          return (
            <>
              {!isPositionList ? (
                <p>{`${onFixed(data?.qty)}`}</p>
              ) : (
                <p>{`${onFixed(currentValue)}`}</p>
              )}
            </>
          );
        },
      },
      {
        title: "Entry Price",
        name: "current_price",
        showColumn: isPositionList,
        prepareValue: (data: any) =>
          !isPositionList ? onFixed(data?.price) : onFixed(data?.entryPrice),
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.price)}`}</p>
            ) : (
              <p>{`${onFixed(data?.entryPrice)}`}</p>
            )}
          </>
        ),
      },
      {
        title: "Price",
        name: "current_price",
        showColumn: !isPositionList,
        prepareValue: (data: any) =>
          `${onFixed(data?.avg_cost) || "-"} | ${
            onFixed(data?.avg_close_price) || "-"
          }`,
        Cell: (data: any) => (
          <>
            <div className="flex flex-col">
              <p>{onFixed(data?.avg_cost) || "-"}</p>
              <p>{onFixed(data?.avg_close_price) || "-"}</p>
            </div>
          </>
        ),
      },
      {
        title: "Mark Price",
        name: "stock_price",
        showColumn: isPositionList,
        prepareValue: (data: any) =>
          `${
            isPositionList ? onFixed(data?.children?.stock_price) + " | " : ""
          }${onFixed(data?.markPrice)}`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <p>{`${onFixed(data?.children?.stock_price)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.markPrice)}`}</p>
          </>
        ),
      },
      {
        title: "Trade Value",
        name: "initialMargin",
        prepareValue: (data: any) =>
          `${
            isPositionList
              ? onFixed(data?.max_open_interest) +
                " | " +
                onFixed(data?.closed_volume)
              : onFixed(data?.initialMargin)
          }`,
        Cell: (data: any) => (
          <>
            {!isPositionList ? (
              <div className="flex flex-col">
                <p>{onFixed(data?.max_open_interest) || "-"}</p>
                <p>{onFixed(data?.closed_volume) || "-"}</p>
              </div>
            ) : (
              <p>{`${onFixed(data?.initialMargin)}`}</p>
            )}
          </>
        ),
      },
      {
        title: "Side",
        name: "side",
        Cell: ({ side }: any) => (
          <>
            <p>{side}</p>
          </>
        ),
      },
      {
        title: "PNL(ROI %)",
        name: "profit_loss",
        showColumn: !isPositionList,
        prepareValue: (data: any) =>
          `${onFixed(data?.closing_pnl) || 0} | ${
            onFixed(data?.children?.profit_loss) || 0
          }`,
        Cell: (data: any) => {
          return (
            <div className="flex flex-col">
              <span
                className={`${
                  preparePriceStyle(+data?.closing_pnl)?.className
                }`}
              >
                {onFixed(data?.closing_pnl) || 0}
              </span>
              <span
                className={`${
                  preparePriceStyle(+data?.closing_pnl)?.className
                }`}
              >
                {onFixed(data?.children?.profit_loss) || 0}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line 
    [positionHistory, isPositionList]
  );

  const preparePositions = () => {
    const newItems: any[] = [];
    (is_tab_connect
      ? positionHistory?.data?.exchangeData?.position_history || []
      : positionHistory?.data?.aiData?.position_history || []
    )?.forEach((item: any) => {
      let tickerIndex;
      if (is_connect) {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.split(":")?.[0]?.replace("/", "")
        );
      }
      if (!is_connect) {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.replace("/", "")
        );
      }

      newItems.push({
        ...item,
        tickerIndex,
        ticker: tickers?.[tickerIndex],
      });
    });

    return newItems;
  };

  const prepareAllPositions = () => {
    const newItems: any[] = [];
    (is_tab_connect
      ? allPositionHistory?.data?.exchangeData?.position_history || []
      : allPositionHistory?.data?.aiData?.position_history || []
    )?.forEach((item: any) => {
      let tickerIndex;
      if (is_connect) {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.split(":")?.[0]?.replace("/", "")
        );
      }
      if (!is_connect) {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.replace("/", "")
        );
      }

      newItems.push({
        ...item,
        tickerIndex,
        ticker: tickers?.[tickerIndex],
      });
    });

    return newItems;
  };

  const prepareTotalOrders = useCallback(() => {
    return {
      total: is_tab_connect
        ? allPositionHistory?.data?.exchangeData?.position_history?.length || 0
        : allPositionHistory?.data?.aiData?.position_history?.length || 0,
      long:
        prepareAllPositions()?.filter((item: any) => item?.side === "buy")
          ?.length || 0,
      short:
        prepareAllPositions()?.filter((item: any) => item?.side === "sell")
          ?.length || 0,
    };
    // eslint-disable-next-line 
  }, [allPositionHistory, is_tab_connect]);

  const prepareWinRate = () => {
    const total = is_tab_connect
      ? allPositionHistory?.data?.exchangeData?.position_history?.length || 0
      : allPositionHistory?.data?.aiData?.position_history?.length || 0;

    const win =
      prepareAllPositions()?.filter(
        (item: any) => +item?.children?.profit_loss > 0
      ).length || 0;
    const loss =
      prepareAllPositions()?.filter(
        (item: any) => +item?.children?.profit_loss <= 0
      ).length || 0;

    return {
      total: +onFixed((win / total) * 100, 2) || 0,
      win,
      loss,
    };
  };

  const prepareLongPnL = () => {
    const total =
      prepareAllPositions()?.filter((item: any) => item?.side === "buy")
        .length || 0;
    const long =
      prepareAllPositions()?.filter(
        (item: any) => +item?.children?.profit_loss > 0 && item?.side === "buy"
      ).length || 0;
    return {
      usdPnL:
        +onFixed(
          prepareAllPositions()
            ?.filter((item: any) => item?.side === "buy")
            ?.reduce((acc, item) => (acc += +item?.children?.profit_loss), 0),
          2
        ) || 0,
      winRate: +onFixed((long / total) * 100, 2) || 0,
    };
  };

  const prepareShortPnL = () => {
    const total =
      prepareAllPositions()?.filter((item: any) => item?.side === "sell")
        .length || 0;
    const short =
      prepareAllPositions()?.filter(
        (item: any) => +item?.children?.profit_loss > 0 && item?.side === "sell"
      ).length || 0;

    return {
      usdPnL:
        +onFixed(
          prepareAllPositions()
            ?.filter((item: any) => item?.side === "sell")
            ?.reduce((acc, item) => (acc += +item?.children?.profit_loss), 0),
          2
        ) || 0,
      winRate: +onFixed((short / total) * 100, 2) || 0,
    };
  };

  useEffect(() => {
    const arr: any[] = prepareAllPositions();
    const preparedArr = arr.map((obj) => ({
      ...obj,
      created_at: removeTimeFromDate(new Date(obj.created_at)),
    }));
    const result = groupBy(preparedArr, ({ created_at }: any) => created_at);
    const result1 = Object.entries(result || {})?.map(([key, value]: any) => [
      key,
      value?.reduce(
        (acc: any, obj: any) => acc + +obj.children?.profit_loss,
        0
      ),
    ]);

    // const data = result1?.length
    //   ? {
    //       labels: result1.map(([key, _]) => key),
    //       datasets: [
    //         {
    //           label: "PnL",
    //           data: result1.map(([_, value]) => value),
    //           backgroundColor: ["rgba(255, 99, 132, 0.2)"],
    //           borderColor: ["red"],
    //         },
    //       ],
    //     }
    //   : null;

    setChartData(result1);

    const groupBySymbol = groupBy(arr, ({ symbol }: any) => symbol);
    const newData: any[] = [];
    Object.entries(groupBySymbol || {})?.forEach(([key, value]: any) => {
      newData.push({
        contracts: key,
        pnl: value?.reduce(
          (acc: any, obj: any) => acc + +obj.children?.profit_loss,
          0
        ),
      });
    });
    setSymbolWisePnL(newData);
    // eslint-disable-next-line
  }, [positionHistory]);

  const preparePayload = () => {
    return {
      strategy_name: filter?.strategy,
      symbol: filter?.symbol,
      start_date: filter?.start_date
        ? dateFormatter(filter?.start_date, "start")
        : null,
      end_date: filter?.end_date
        ? dateFormatter(filter?.end_date, "end")
        : null,
      exchange_name: exchange_name,
      is_connect: is_connect,
      platform: filter?.platform,
      group_name: pathnames?.[1],
      // trade_type: filter?.trade,
    };
  };

  useEffect(() => {
    const payload: any = preparePayload();

    const fun = async () => {
      await getAllFuturePosition({
        ...payload,
      }).unwrap();
    };

    fun();
    // eslint-disable-next-line
  }, [filter, exchange_name]);

  return (
    <>
      <div className="flex flex-col mt-5">
        <StrategyStatistics
          totalOrders={prepareTotalOrders()}
          winRate={prepareWinRate()}
          longPnL={prepareLongPnL()}
          shortPnL={prepareShortPnL()}
        />
        <div className="pt-3">
          <Table
            fileName="Strategy_position_history"
            isLoading={!!(!tickers || isPositionHistoryLoading)}
            columns={
              is_tab_connect ? columnsBinancePositions : columnsPositions
            }
            data={preparePositions() || []}
            tableDataCount={preparePositions()?.length}
            isExpendable={false}
            className="!overflow-scroll"
            minHeight={300}
            count={
              is_tab_connect
                ? positionHistory?.data?.exchangeData?.total || 0
                : positionHistory?.data?.aiData?.total || 0
            }
            {...(!isPositionList && {
              pagination: filterForPositionHistory,
              handlePaginationChange: (pagination) => {
                setFilterForPositionHistory({
                  ...filterForPositionHistory,
                  ...pagination,
                });
              },
            })}
            // pagination={filterForOrder}
          >
            {preparePositions()?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    // className="cursor-pointer"
                    columns={
                      is_tab_connect
                        ? columnsBinancePositions
                        : columnsPositions
                    }
                    item={item}
                    isExpendable={false}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      </div>
    </>
  );
};

export default PositionHistory;
