import { Fragment } from "react/jsx-runtime";
import {
  Button,
  ControlledDatePicker,
  getStatusTypes,
  Table,
  TableRow,
} from "../../../ui";
import {
  FILTER,
  SideEnumKeys,
  orderStatuses,
  sideEnum,
  typeEnum,
} from "../../../constant";
import { useEffect, useMemo, useState } from "react";
import binanceImg from "../../../assets/images/binanceIcon.svg";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import {
  dateFormatter,
  onFixed,
  prepareLastDate,
  showToaster,
} from "../../../helperFunctions";
import useWebSocketContext from "../../../context/useWebSocketContext";
import {
  useGetAllFuturePositionHistoryMutation,
  useGetAllOrderDetailsMutation,
  useGetAllOrderMutation,
} from "../tradeApi";
import { useKeys } from "../../exchange/exchangeSlice";
import { useLocation } from "react-router-dom";
import { useRole } from "../../auth/authSlice";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";

const tabs = [
  { name: `1 Day` },
  { name: "1 Week" },
  { name: "1 Month" },
  { name: "3 Month" },
  { name: "Time" },
];

interface OrderHistoryListProps {
  symbol?: any;
  strategy?: any;
}

const OrderHistoryList = (props: OrderHistoryListProps) => {
  const { symbol, strategy } = props;
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const isFutureTrade = pathname.pathname.includes("future-trade");
  const role = useRole(isFutureTrade ? "future-trade" : "spot-trade");

  const [activeTab, setActiveTab] = useState(0);
  const [tableData, setTableData] = useState<any>([]);
  const [filterForOrderHistory, setFilterForOrderHistory] =
    useState<any>(FILTER);
  const { loading, binanceOrderHistory, emitOrderHistory } =
    useWebSocketContext();
  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();

  const [getAllOrder] = useGetAllOrderMutation();
  const [getAllFuturePositionHistory] =
    useGetAllFuturePositionHistoryMutation();
  const [getAllOrderDetails] = useGetAllOrderDetailsMutation();

  // const binanceColOrderHistory = useMemo(
  //   () => [
  //     {
  //       title: "Order Time",
  //       name: "datetime",
  //       Cell: (data: any) => (
  //         <>{`${
  //           isFutureTrade
  //             ? timestampToDate(data?.time)
  //             : dateFormatter(data?.datetime, "default")
  //         }`}</>
  //       ),
  //     },
  //     {
  //       title: "Pair",
  //       name: "symbol",
  //       // Cell: (data: any) => <>{`${data?.to_coin}${data?.from_coin}`}</>,
  //     },
  //     {
  //       title: "Type",
  //       name: "type",
  //       Cell: ({ type }: any) => (
  //         <>
  //           <span>
  //             {isFutureTrade
  //               ? binanceTypeEnum1?.[type]
  //               : binanceTypeEnum?.[type]}
  //           </span>
  //         </>
  //       ),
  //       isMenu: true,
  //       enum: isFutureTrade ? binanceTypeEnum1 : binanceTypeEnum,
  //     },
  //     {
  //       title: "Side",
  //       name: "side",
  //       Cell: ({ side }: any) => (
  //         <>
  //           <span>
  //             {isFutureTrade
  //               ? binanceSideEnum1?.[side]
  //               : binanceSideEnum?.[side]}
  //           </span>
  //         </>
  //       ),
  //       isMenu: true,
  //       enum: isFutureTrade ? binanceSideEnum1 : binanceSideEnum,
  //     },
  //     {
  //       title: "Price",
  //       name: "price",
  //       Cell: (data: any) => (
  //         <span>
  //           {isFutureTrade
  //             ? ["Take Profit Market", "Stop market"]?.includes(
  //                 binanceTypeEnum1?.[data?.type]
  //               )
  //               ? onFixed(data?.stopPrice * data?.origQty)
  //               : onFixed(data?.price)
  //             : onFixed(data?.price)}
  //         </span>
  //       ),
  //     },
  //     {
  //       title: "Amount",
  //       name: "amount",
  //       Cell: (data: any) => (
  //         <span>{onFixed(data?.amount || data?.cumQuote)}</span>
  //       ),
  //     },
  //     {
  //       title: "Total",
  //       Cell: (data: any) => (
  //         <>
  //           <span>{onFixed(data?.info?.cummulativeQuoteQty)}</span>
  //           {/* <span>{onFixed(data?.info?.cummulativeQuoteQty || data?.origQty*data?.price)}</span> */}
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Status",
  //       name: "status",
  //       Cell: ({ status }: any) => (
  //         <span>
  //           {isFutureTrade
  //             ? binanceOrderStatuses1?.[status]
  //             : binanceOrderStatuses?.[status]}
  //         </span>
  //       ),
  //       isMenu: true,
  //       enum: isFutureTrade ? binanceOrderStatuses1 : binanceOrderStatuses,
  //       dropdownClassName: "!left-auto !right-0",
  //     },
  //   ],
  //   [tableData, isFutureTrade]
  // );

  const columnsOrderHistory = useMemo(
    () => [
      {
        title: "Exchange",
        name: "is_connect_order",
        exportHide: ["ALL"],
        Cell: ({ is_connect_order }: any) => (
          <div className="flex items-center justify-center">
            {is_connect_order ? (
              <img className="w-7" src={binanceImg} alt="" />
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        title: "Date",
        name: "created_at",
        Cell: (data: any) => <>{data?.created_at}</>,
      },
      {
        title: "Pair",
        name: "symbol",
        // Cell: (data: any) => <>{`${data?.to_coin}${data?.from_coin}`}</>,
      },
      {
        title: "Type",
        name: "type",
        prepareValue: ({ type }: any) => typeEnum?.[type],
        Cell: ({ type }: any) => (
          <>
            <span>{typeEnum?.[type]}</span>
          </>
        ),
        isMenu: true,
        enum: typeEnum,
      },
      {
        title: "Side",
        name: "side",
        prepareValue: ({ side }: { side: SideEnumKeys }) => sideEnum?.[side],

        Cell: ({ side }: { side: SideEnumKeys }) => (
          <>
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
        isMenu: true,
        enum: sideEnum,
      },
      {
        title: "Average",
        name: "exchange_rate",
        prepareValue: (data: any) => onFixed(data?.exchange_rate),
        Cell: (data: any) => <span>{onFixed(data?.exchange_rate)}</span>,
      },
      {
        title: "Price",
        name: "stock_price",
        prepareValue: (data: any) =>
          data?.type === 1
            ? "Market"
            : ["8", "9"]?.includes(data?.type.toString())
            ? data?.type?.toString() === "8"
              ? onFixed(data?.take_profit)
              : onFixed(data?.stop_loss)
            : onFixed(data?.stock_price),
        Cell: (data: any) => (
          <>
            <span>
              {data?.type === 1
                ? "Market"
                : ["8", "9"]?.includes(data?.type.toString())
                ? data?.type?.toString() === "8"
                  ? onFixed(data?.take_profit)
                  : onFixed(data?.stop_loss)
                : onFixed(data?.stock_price)}
            </span>
          </>
        ),
      },
      {
        title: "Executed",
        name: "executedQty",
        prepareValue: (data: any) => onFixed(data?.executed),
        Cell: (data: any) => <span>{onFixed(data?.executed)}</span>,
      },
      {
        title: "Amount",
        name: "amount",
        prepareValue: (data: any) => onFixed(data?.amount),
        Cell: (data: any) => <span>{onFixed(data?.amount)}</span>,
      },
      {
        title: "Total",
        name: "total",
        prepareValue: (data: any) => onFixed(data?.total),
        Cell: (data: any) => (
          <>
            <span>{onFixed(data?.total)}</span>
          </>
        ),
      },
      {
        title: "Status",
        name: "order_status",
        prepareValue: ({
          order_status,
        }: {
          order_status: "1" | "2" | "3" | "4";
        }) => orderStatuses?.[order_status],
        Cell: ({ order_status }: { order_status: "1" | "2" | "3" | "4" }) => (
          <div
            className={`${getStatusTypes(orderStatuses?.[order_status])}`}
          >{`${orderStatuses?.[order_status]}`}</div>
        ),
        isMenu: true,
        enum: orderStatuses,
        dropdownClassName: "!left-auto !right-0",
      },
    ],
    // eslint-disable-next-line 
    [tableData]
  );

  const prepareDatePaylaod = () => {
    let dates = {
      start_date: dateFormatter(new Date()),
      end_date: dateFormatter(new Date(), "end"),
    };

    if (activeTab === 1) {
      dates = {
        start_date: dateFormatter(prepareLastDate(7)),
        end_date: dateFormatter(new Date(), "end"),
      };
    }

    if (activeTab === 2) {
      dates = {
        start_date: dateFormatter(prepareLastDate(31)),
        end_date: dateFormatter(new Date(), "end"),
      };
    }

    if (activeTab === 3) {
      dates = {
        start_date: dateFormatter(
          prepareLastDate(is_connect ? 29 * 3 : 30 * 3)
        ),
        end_date: dateFormatter(new Date(), "end"),
      };
    }

    if (activeTab === 4) {
      dates = {
        start_date: dateFormatter(filterForOrderHistory?.start_date),
        end_date: dateFormatter(filterForOrderHistory?.end_date, "end"),
      };
    }

    return dates;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dates = prepareDatePaylaod();

        emitOrderHistory({
          is_connect: is_connect,
          start_date: dateFormatter(dates?.start_date || Date.now(), "start"),
          end_date: dateFormatter(dates?.end_date || Date.now(), "end"),
          limit: filterForOrderHistory?.limit,
          page: filterForOrderHistory?.page,
          order_type: isFutureTrade ? 1 : 2,
          is_tab_connect: is_tab_connect,
          exchange_name: exchange_name,
          ...(strategy && { strategy_name: strategy }),
          ...(symbol && { symbol }),
          group_name: pathnames?.[1],
        });

        if (isFutureTrade) {
          if (role.includes("View Future Trade Position History")) {
            await getAllFuturePositionHistory({
              group_name: pathnames?.[1],
            }).unwrap();
          }
        } else {
          if (role.includes("View Spot Trade Order History")) {
            const payload = {
              order_type: 2,
            };
            await getAllOrder({
              ...payload,
              group_name: pathnames?.[1],
            }).unwrap();
          }
        }
      } catch (err) {}
    };
    // setTimeout(() => {
    fetchData();
    // }, 900);
    // eslint-disable-next-line
  }, [
    filterForOrderHistory,
    activeTab,
    is_tab_connect,
    is_connect,
    strategy,
    symbol,
  ]);

  useEffect(() => {
    setTableData(prepareOrderHistory());
    // eslint-disable-next-line
  }, [binanceOrderHistory, is_tab_connect]);

  const prepareOrderHistory = () => {
    return is_tab_connect === 1
      ? binanceOrderHistory?.exchangeData || []
      : binanceOrderHistory?.AI || [];
  };

  const { control, handleSubmit, reset } = useForm({});

  const onClear = () => {
    reset();
    setFilterForOrderHistory(FILTER);
  };

  const onSubmit = async (values: any) => {
    if (!values?.date) {
      showToaster("Please select date", "Warning");
    }
    setActiveTab(4);
    setFilterForOrderHistory({
      ...filterForOrderHistory,
      start_date: values?.date?.[0],
      end_date: values?.date?.[1],
    });
  };

  return (
    <div className="flex flex-col mt-5">
      <div className="flex gap-4 items-center">
        {tabs?.map((item: any, index: any) => {
          return (
            <div key={index}>
              <div
                className={`cursor-pointer relative flex justify-center items-center text-sm text-nowrap activetab_hover ${
                  index === activeTab
                    ? "activetab font-semibold text-primary-950 !opacity-100 "
                    : "font-normal text-primary-950 opacity-50"
                }`}
                onClick={() => {
                  if (index === 4) {
                    return;
                  }
                  setActiveTab(index);
                  reset();
                }}
              >
                <p className={`  `}>{item?.name}</p>
              </div>
            </div>
          );
        })}
        <ControlledDatePicker
          inputClassName="!w-fit"
          name="date"
          placeholder="Select Date"
          minDate={is_connect ? prepareLastDate(29 * 3) : undefined}
          maxDate={new Date()}
          selectsRange
          control={control}
        />
        {/* <ControlledDatePicker
          name="start_date"
          selectesStart
          control={control}
          startDate={watch("start_date")}
          endDate={watch("end_date")}
          minDate={is_connect ? prepareLastDate(29 * 3) : undefined}
          inputClassName="h-8 text-xs"
        />
        <ControlledDatePicker
          name="end_date"
          selectsEnd
          startDate={watch("start_date")}
          endDate={watch("end_date")}
          control={control}
          minDate={is_connect ? prepareLastDate(29 * 3) : undefined}
          inputClassName="h-8 text-xs"
        /> */}
        <Button className="!h-8 !text-sm" onClick={handleSubmit(onSubmit)}>
          Search
        </Button>
        <Button
          className="!h-8 !text-sm"
          onClick={() => {
            onClear();
            setActiveTab(0);
          }}
        >
          Reset
        </Button>
      </div>
      <div className={`pt-3`}>
        <Table
          fileName="Trade_order_history"
          onFetchExportData={async () => {
            try {
              const dates = prepareDatePaylaod();
              const payload: any = {
                start_date: dateFormatter(
                  dates?.start_date || Date.now(),
                  "start"
                ),
                end_date: dateFormatter(dates?.end_date || Date.now(), "end"),
                symbol: "ALL",
                exchange_name: exchange_name,
                order_type: isFutureTrade ? 1 : 2,
                group_name: pathnames?.[1],
              };

              const res: any = await getAllOrderDetails(payload).unwrap();
              return { data: res?.data, columns: columnsOrderHistory };
            } catch (err) {
              return { data: [], columns: columnsOrderHistory };
            }
          }}
          isLoading={loading?.orderHistory}
          columns={
            // is_tab_connect === 1 ? binanceColOrderHistory : columnsOrderHistory
            columnsOrderHistory
          }
          data={prepareOrderHistory() || []}
          tableDataCount={tableData?.length}
          isExpendable={false}
          count={
            is_tab_connect === 1
              ? binanceOrderHistory?.exchangeCount
              : binanceOrderHistory?.aiCount
          }
          setData={setTableData}
          className="!overflow-scroll order_table"
          minHeight={300}
          pagination={filterForOrderHistory}
          handlePaginationChange={(pagination) => {
            setFilterForOrderHistory({
              ...filterForOrderHistory,
              ...pagination,
            });
          }}
        >
          {tableData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  className="min-h-[350px]"
                  columns={
                    // is_tab_connect === 1
                    //   ? binanceColOrderHistory
                    //   : columnsOrderHistory
                    columnsOrderHistory
                  }
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default OrderHistoryList;
