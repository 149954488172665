import React, { memo } from "react";
import { IconProps } from ".";

export interface ExchangeIconProps extends IconProps {}
const ExchangeIcon = (props: ExchangeIconProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 20,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
      <path d="M3 3v5h5" />
      <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
      <path d="M16 16h5v5" />
    </svg>
  );
};

const MemoExchangeIcon = memo(ExchangeIcon);
export default MemoExchangeIcon;
