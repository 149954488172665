import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { dashboardApi } from "./dashboardApi";

interface DashboardState {
  strategyReport: any;
}

const initialState: DashboardState = {
  strategyReport: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getStrategyReport.matchFulfilled,
      (state: any, { payload }) => {
        state.strategyReport = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default dashboardSlice.reducer;
export const { clearDashboard } = dashboardSlice.actions;

export const selectStrategyReport = (state: RootState) => state.dashboard.strategyReport;
export const useStrategyReport = () => {
  const data = useSelector(selectStrategyReport);
  return useMemo(() => data, [data]);
};
