import { api } from "../../utills/api";

export const convertHistoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getConvertHistory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/get-transfer-history",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetConvertHistoryMutation } = convertHistoryApi;
export const {
  endpoints: { getConvertHistory },
} = convertHistoryApi;
