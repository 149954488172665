import React, { memo } from "react";
import { IconProps } from ".";

export interface LockIconProps extends IconProps {
}
const LockIcon = (props: LockIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M16.4232 9.72051V7.57351C16.4232 5.06051 14.3852 3.02251 11.8722 3.02251C9.35925 3.01151 7.31325 5.03951 7.30225 7.55351V7.57351V9.72051"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[0] || ""}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.683 21.5223H8.042C5.948 21.5223 4.25 19.8253 4.25 17.7303V13.4413C4.25 11.3463 5.948 9.64929 8.042 9.64929H15.683C17.777 9.64929 19.475 11.3463 19.475 13.4413V17.7303C19.475 19.8253 17.777 21.5223 15.683 21.5223Z"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[1] || ""}`}
      />
      <path
        d="M11.8628 14.4755V16.6965"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[2] || ""}`}
      />
    </svg>
  );
};

const MemoLockIcon = memo(LockIcon);
export default MemoLockIcon;
