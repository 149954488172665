import { Fragment } from "react/jsx-runtime";
import {
  SideEnumKeys,
  orderStatuses,
  sideEnum,
  typeEnum,
} from "../../constant";
import { getStatusTypes, Table, TableRow } from "../../ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { onFixed } from "../../helperFunctions";
import useWebSocketContext from "../../context/useWebSocketContext";
import { useKeys } from "../exchange/exchangeSlice";
import StrategyStatistics from "./StrategyStatistics";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";

const StrategyOpenOrder = () => {
  const [tableData, setTableData] = useState<any>([]);
  const {
    currentKey: { is_tab_connect },
  } = useKeys();
  const { loading, binanceOpenOrders } = useWebSocketContext();

  const columnsOpenOrders = useMemo(
    () => [
      {
        title: "Date",
        name: "created_at",
        Cell: (data: any) => <>{`${data?.created_at}`}</>,
      },
      {
        title: "Pair",
        name: "symbol",
      },
      {
        title: "Type",
        name: "type",
        prepareValue: ({
          type = "1",
        }: {
          type: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
        }) => `${typeEnum?.[type]}`,
        Cell: ({
          type = "1",
        }: {
          type: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
        }) => (
          <>
            <span>{typeEnum?.[type]}</span>
          </>
        ),
        isMenu: true,
        enum: typeEnum,
      },
      {
        title: "Side",
        name: "side",
        prepareValue: ({ side }: { side: SideEnumKeys }) =>
          `${sideEnum?.[side]}`,
        Cell: ({ side }: { side: SideEnumKeys }) => (
          <>
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
        isMenu: true,
        enum: sideEnum,
      },
      {
        title: "Platform",
        name: "platform",
      },
      {
        title: "Trade Type",
        name: "trade_type",
      },
      {
        title: "Price",
        name: "stock_price",
        prepareValue: (data: any) =>
          `${
            ["8", "9"]?.includes(data?.type.toString())
              ? data?.type?.toString() === "8"
                ? onFixed(data?.take_profit)
                : onFixed(data?.stop_loss)
              : onFixed(
                  data?.stock_price ||
                    (data?.type === "STOP_MARKET" ||
                    data?.type === "TAKE_PROFIT_MARKET"
                      ? data?.stopPrice
                      : data?.price)
                )
          }`,
        Cell: (data: any) => (
          <>
            {["8", "9"]?.includes(data?.type.toString()) ? (
              <>
                <span>
                  {data?.type?.toString() === "8"
                    ? onFixed(data?.take_profit)
                    : onFixed(data?.stop_loss)}
                </span>
              </>
            ) : (
              <>
                {onFixed(
                  data?.stock_price ||
                    (data?.type === "STOP_MARKET" ||
                    data?.type === "TAKE_PROFIT_MARKET"
                      ? data?.stopPrice
                      : data?.price)
                )}
              </>
            )}
          </>
        ),
      },
      {
        title: "Amount",
        name: "amount",
        Cell: (data: any) => (
          <>
            <span>{data?.amount}</span>
          </>
        ),
      },
      {
        title: "Status",
        prepareValue: ({
          order_status,
        }: {
          order_status: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
        }) => `${orderStatuses?.[order_status]}`,
        Cell: ({
          order_status,
        }: {
          order_status: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
        }) => (
          <div
            className={`${getStatusTypes(orderStatuses?.[order_status])}`}
          >{`${orderStatuses?.[order_status]}`}</div>
        ),
      },
      {
        title: "Total",
        prepareValue: (data: any) => `${onFixed(data?.total)}`,
        Cell: (data: any) => (
          <>
            <span>{onFixed(data?.total)}</span>
          </>
        ),
      },
      // {
      //   title: "Cancel All",
      //   name: "cancelAll",
      //   Cell: (data: any) => {
      //     return (
      //       <>
      //         <span
      //           className="cursor-pointer"
      //           onClick={() => {
      //             cancelOpenOrder(data);
      //             setCancelOrderId(data?.order_uuid);
      //           }}
      //         >
      //           {cancelOrderId === data?.order_uuid && loading?.cancelOrder ? (
      //             <Loader loaderClassName="w-[15px] " />
      //           ) : (
      //             <MdDelete />
      //           )}
      //         </span>
      //       </>
      //     );
      //   },
      //   isMenu: true,
      //   menuType: "non-filterable",
      //   enum: cancelEnum,
      //   dropdownClassName: "!left-auto !right-0",
      // },
    ],
    // eslint-disable-next-line 
    [tableData]
  );

  const prepareOpenOrders = () => {
    return binanceOpenOrders?.[is_tab_connect] || [];
  };

  const prepareTotalOrders = useCallback(() => {
    return {
      total: prepareOpenOrders()?.length || 0,
      long:
        prepareOpenOrders()?.filter((item: any) => item?.side === "buy")
          ?.length || 0,
      short:
        prepareOpenOrders()?.filter((item: any) => item?.side === "sell")
          ?.length || 0,
    };
    // eslint-disable-next-line 
  }, [binanceOpenOrders, is_tab_connect]);

  useEffect(() => {
    setTableData(prepareOpenOrders());
    // eslint-disable-next-line
  }, [binanceOpenOrders, is_tab_connect]);

  return (
    <>
      <div className="flex flex-col mt-5">
        <StrategyStatistics totalOrders={prepareTotalOrders()} />
        <div className={`pt-3`} style={{ minHeight: "300px" }}>
          <Table
            fileName="Strategy_open_orders"
            isLoading={is_tab_connect === 1 && loading?.openOrder}
            columns={columnsOpenOrders}
            data={prepareOpenOrders() || []}
            tableDataCount={tableData?.length}
            isExpendable={false}
            count={prepareOpenOrders()?.length}
            setData={setTableData}
            className="!overflow-scroll order_table"
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columnsOpenOrders}
                    item={item}
                    isExpendable={false}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      </div>
    </>
  );
};

export default StrategyOpenOrder;
