import { api } from "../../utills/api";

export const rolesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/role/get",
        body: params,
        method: "POST",
      }),
    }),
    addRole: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/role/add",
        body: params,
        method: "POST",
      }),
    }),
    updateRole: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/role/update",
        body: params,
        method: "POST",
      }),
    }),
    deleteRole: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/role/delete",
        body: params,
        method: "POST",
      }),
    }),
    getPermissions: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/permission/get",
        body: params,
        method: "POST",
      }),
    }),
    getPermissionsByGroup: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/permission/get-by-group",
        body: params,
        method: "POST",
      }),
    }),
    getRoleByID: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/role/get-by-id",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetRolesMutation,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useGetPermissionsMutation,
  useGetPermissionsByGroupMutation,
  useGetRoleByIDMutation,
} = rolesApi;

export const {
  endpoints: {
    getRoles,
    addRole,
    updateRole,
    deleteRole,
    getPermissions,
    getPermissionsByGroup,
    getRoleByID,
  },
} = rolesApi;
