import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useWebSocketContext from "../../context/useWebSocketContext";
import { onFixed } from "../../helperFunctions";
import { FaArrowLeft } from "react-icons/fa";

const MarketInfo = () => {
  const [lastPrice, setLastPrice] = useState<any>("-");
  const [dayChange, setDayChange] = useState<any>("-");
  const [dayHigh, setDayHigh] = useState<any>("-");
  const [dayLow, setDayLow] = useState<any>("-");
  const [dayVolumeBase, setDayVolumeBase] = useState<any>("-");
  const [dayVolumeQuote, setDayVolumeQuote] = useState<any>("-");
  const [priceColor, setPriceColor] = useState("text-ev-green");
  const [priceChange, setPriceChange] = useState("text-ev-green");
  const navigate = useNavigate();

  const { base, quote } = useParams();
  const baseUppercase = base?.toUpperCase();
  const quoteUppercase = quote?.toUpperCase();
  const { tickers, allTickers } = useWebSocketContext();

  const updateTicker = () => {
    const index = tickers?.findIndex(
      (i: any) => i?.s === `${baseUppercase}${quoteUppercase}`
    );

    if (index > -1) {
      if (lastPrice !== onFixed(+tickers?.[index]?.c)) {
        if (lastPrice <= onFixed(+tickers?.[index]?.c)) {
          setPriceColor("text-primary-green");
        } else {
          setPriceColor("text-primary-red");
        }
      }
      setLastPrice(onFixed(+tickers?.[index]?.c));
      if (0 <= tickers?.[index]?.P) {
        setPriceChange("text-primary-green");
      } else {
        setPriceChange("text-primary-red");
      }
      setDayChange(`${onFixed(tickers?.[index]?.P, 2)}%`);
      setDayHigh(onFixed(tickers?.[index]?.h));
      setDayLow(onFixed(tickers?.[index]?.l));
      setDayVolumeBase(onFixed(tickers?.[index]?.v, 2));
      setDayVolumeQuote(onFixed(tickers?.[index]?.q, 2));
    }
  };

  useEffect(() => {
    updateTicker();
    // eslint-disable-next-line
  }, [allTickers?.current, base, quote]);

  return (
    <>
      <div className="Info  relative card_rounded mb-4">
        <div className="flex overflow-x-scroll text-xs text-primary-950 items-center space-x-1 p-1">
          <div className="flex items-center gap-2">
            <div
              className="bg-secondary-200 p-2 rounded-sm text-primary-600"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeft />
            </div>
            <div className="image-container flex items-center relative uppercase font-bold text-lg">
              {baseUppercase}/{quoteUppercase}
            </div>
          </div>
          <div className="info_detail">
            <div>
              <p>Last Price:</p>
              <h4 className={`${priceColor}`}>{lastPrice}</h4>
            </div>
          </div>
          <div className="info_detail">
            <div>
              <p>24h Change:</p>
              <h4 className={`${priceChange}`}>
                {dayChange}
                {/* <i className="bi" className="day_change_icon"></i> */}
              </h4>
            </div>
          </div>
          <div className="info_detail">
            <div>
              <p>{baseUppercase} Volume:</p>
              <h4>{dayVolumeBase}</h4>
            </div>
          </div>
          <div className="info_detail">
            <div>
              <p>{quoteUppercase} Volume:</p>
              <h4>{dayVolumeQuote}</h4>
            </div>
          </div>
          <div className="info_detail">
            <div>
              <p>24h High:</p>
              <h4>{dayHigh}</h4>
            </div>
          </div>
          <div className="info_detail !border-r-0">
            <div>
              <p>24h Low:</p>
              <h4>{dayLow}</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketInfo;
