import { api } from "../../utills/api";
import { ApiResponse } from "../common";

export const commonApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCoins: build.mutation<ApiResponse, any>({
      query: (params) => ({
        url: "/get-all-coins",
        body: {},
        method: "POST",
      }),
    }),
    getActiveCoins: build.mutation<ApiResponse, any>({
      query: (params) => ({
        url: "/get-all-coins",
        body: { is_active: 1 },
        method: "POST",
      }),
    }),
    // getUsers: build.mutation<ApiResponse, any>({
    //   query: (params) => ({
    //     url: "/listUsers",
    //     body: params,
    //     method: "POST",
    //   }),
    // }),
    getSpotMarket: build.mutation<ApiResponse, any>({
      query: (params) => ({
        url: "/get-all-spot-market",
        body: params,
        method: "POST",
      }),
    }),
    getSymbols: build.mutation<ApiResponse, any>({
      query: (params) => ({
        url: "/get-symbols",
        body: params,
        method: "POST",
      }),
    }),
    getPlatforms: build.mutation<ApiResponse, any>({
      query: (params) => ({
        url: "/get-all-platforms",
        body: params,
        method: "POST",
      }),
    }),
   
  }),
});

export const {
  useGetCoinsMutation,
  useGetActiveCoinsMutation,
  useGetSpotMarketMutation,
  useGetSymbolsMutation,
  useGetPlatformsMutation,
} = commonApi;
export const {
  endpoints: {
    getCoins,
    getSpotMarket,
    getActiveCoins,
    getSymbols,
    getPlatforms,
  },
} = commonApi;
