import { api } from "../../utills/api";

export const walletHistoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDepositHistory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/get-wallet-deposits",
        body: params,
        method: "POST",
      }),
    }),
    getWithdrawalHistory: build.mutation<{ data: any }, void>({
      query: (params) => ({
        url: "/get-wallet-withdrawals",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetDepositHistoryMutation, useGetWithdrawalHistoryMutation } =
  walletHistoryApi;
export const {
  endpoints: { getDepositHistory, getWithdrawalHistory },
} = walletHistoryApi;
