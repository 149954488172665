import { useEffect, useState, Fragment, useMemo } from "react";

// UI IMPORT
import { Input, Table, TableRow } from "../../ui";

// PROJECT IMPORT
import { WalletBalance } from "./WalletBalance";
import { useGetActiveCoinsMutation } from "../common/commonApi";
import { useActiveCoins } from "../common/commonSlice";
import { useLazyGetAllWalletQuery } from "./walletApi";
import { useWalletData } from "./walletSlice";
import useWebSocketContext from "../../context/useWebSocketContext";
import { useGetClosingDataMutation } from "../trade/tradeApi";
import { useClosingData } from "../trade/tradeSlice";
import { NavLink, useLocation } from "react-router-dom";
import { onFixed, seperator, seperator2 } from "../../helperFunctions";
import { getLaravelSocket } from "../../utills/laravelSocketConnection";
import { BINANCE_ORDER_TAX } from "../../constant";
import { useKeys } from "../exchange/exchangeSlice";
import { useRole } from "../auth/authSlice";

const Wallet = () => {
  const activeCoins = useActiveCoins();
  const wallet = useWalletData();
  const closingData = useClosingData();
  const {
    currentKey: { is_tab_connect, is_connect, is_valid_api_key, exchange_name },
  } = useKeys();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const role = useRole("wallet");
  const spotRole = useRole("spot-trade");
  const futureRole = useRole("future-trade");

  const { tickers, emitClosingDataEvent, loading, binanceClosingData } =
    useWebSocketContext();

  const [tableData, setTableData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalUSD, setTotalUSD] = useState<any>(0);
  const [totalPnL, setTotalPnL] = useState<any>(0);
  const [totalPnLPer, setTotalPnLPer] = useState<any>(0);
  const [tickersData, setTickersData] = useState<any>();
  const [search, setSearch] = useState<string>("");

  const [getActiveCoins] = useGetActiveCoinsMutation();
  const [getAllWallet] = useLazyGetAllWalletQuery();
  const [getClosingData] = useGetClosingDataMutation();

  const prepareCoins = () => {
    let totalUsd = 0;
    let totalpnl = 0;
    const arr: any[] = [];
    const loadingArr: any[] = [];

    (activeCoins?.data || [])?.forEach?.((val: any) => {
      let currentmoney: any;
      let closeItem: any = {};
      if (is_tab_connect) {
        Object.entries(wallet?.data?.exchangeData || {})?.forEach?.(
          ([key, val2]: any) => {
            if (key?.toLowerCase() === val?.code?.toLowerCase()) {
              currentmoney = {
                balance: val2?.free,
                on_hold: val2?.used,
                coin: {
                  symbol: val?.code,
                  name: val?.name,
                },
              };
            }
          }
        );

        if (binanceClosingData?.data) {
          const midnightPriceObj =
            binanceClosingData?.data?.midnightPrices?.find(
              (item: any) => item?.symbol === val?.code
            );
          closeItem = {
            quantity:
              +midnightPriceObj?.walletFreeQuantity ||
              0 + +midnightPriceObj?.walletLockedQuantity ||
              0,
            current_rate: midnightPriceObj?.prevClosingPrice || 0,
            orders: binanceClosingData?.data?.allOrder
              ?.filter?.(
                (order: any) =>
                  order.status === "closed" || order.status === "filled"
              )
              ?.filter(
                (item: any) =>
                  item?.symbol?.toString()?.split("/")?.[0] === val?.code
              )
              ?.map((item: any) => ({
                executed:
                  +item?.info?.executedQty * (1 - BINANCE_ORDER_TAX / 100),
                exchange_rate: +item?.average,
                side: item?.side === "buy" ? 1 : 2,
              })),
            wallet_transactions: [
              ...binanceClosingData?.data?.depositAveragePrices
                ?.filter((item: any) => item?.depositCurrency === val?.code)
                ?.map((item: any) => ({
                  transaction_type: 0,
                  amount: item?.depositAmount,
                  exchange_rate: val?.code === "USDT" ? 1 : +item?.averagePrice,
                })),
              ...binanceClosingData?.data?.withdrawalAveragePrices
                ?.filter((item: any) => item?.withdrawalCurrency === val?.code)
                ?.map((item: any) => ({
                  transaction_type: 1,
                  amount: item?.withdrawalAmount,
                  exchange_rate: val?.code === "USDT" ? 1 : +item?.averagePrice,
                })),
            ],
          };
        }
      } else {
        currentmoney = wallet?.data?.aiData?.find(
          (val2: any) => val2?.coin?.coin_uuid === val?.coin_uuid
        );

        closeItem = (
          Array.isArray(closingData?.data) ? closingData?.data : []
        )?.find(
          (element: any) => element?.wallet_name === currentmoney?.coin?.symbol
        );

        closeItem = {
          ...closeItem,
          orders: closeItem?.orders?.map((item: any) => ({
            ...item,
            side: item?.side === "buy" ? 1 : 2,
          })),
        };
      }

      const ticker = tickersData?.find?.(
        (i: any) => i?.s === `${val?.code}USDT`
      );

      const price = val?.code === "USDT" ? 1 : ticker?.c || 0;
      const coinTotalUsd = onFixed(
        price * (+currentmoney?.balance + +currentmoney?.on_hold)
      );
      totalUsd +=
        price * (+currentmoney?.balance + +currentmoney?.on_hold) || 0;

      const netInflow1 = closeItem?.orders?.reduce((sum: any, item: any) => {
        if (item?.side === 1) {
          // 1 for Buy
          return sum + +item?.executed * +item?.exchange_rate;
        } else {
          return sum - +item?.executed * +item?.exchange_rate;
        }
      }, 0);

      const netInflow2 = closeItem?.wallet_transactions?.reduce(
        (sum: any, item: any) => {
          if (item?.transaction_type === 0) {
            // 0 for deposit
            return sum + item?.amount * item?.exchange_rate;
          } else if (item?.transaction_type === 1) {
            // 1 for withdrawal
            return sum - item?.amount * item?.exchange_rate;
          }
          return sum
        },
        0
      );

      const pnl =
        currentmoney?.coin?.symbol === "USDT"
          ? 0
          : onFixed(
              price * (+currentmoney?.balance + +currentmoney?.on_hold || 0) -
                +closeItem?.quantity * +closeItem?.current_rate -
                (netInflow1 + netInflow2)
            );

      totalpnl += +pnl;
      if (
        search
          ? currentmoney?.coin?.symbol
              ?.toLowerCase()
              ?.includes(search?.toLowerCase())
          : true
      ) {
        arr.push({
          symbol: currentmoney ? currentmoney?.coin?.symbol : val?.symbol,
          name: currentmoney ? currentmoney?.coin?.name : val?.name,
          amount: currentmoney
            ? `${onFixed(+currentmoney?.balance + +currentmoney?.on_hold)}`
            : "0",
          available: currentmoney ? `${onFixed(+currentmoney?.balance)}` : "0",
          perUnitUsd: price,
          coinTotalUsd,
          pnl: currentmoney?.coin?.symbol === "USDT" ? "-" : pnl.toString(),
          pnlLoading:
            loading?.closingData ||
            (is_tab_connect && !binanceClosingData?.data),
        });
      }

      if (price) {
        loadingArr.push(0);
      }
    });

    let totalBeginningAssets = 0;
    let totalDeposit = 0;
    let totalWithdrawal = 0;

    if (is_tab_connect) {
      if (binanceClosingData?.data) {
        totalBeginningAssets = (
          binanceClosingData?.data?.midnightPrices || []
        )?.reduce((sum: any, item: any) => {
          return (
            sum +
            (+item?.walletFreeQuantity + +item?.walletLockedQuantity) *
              +item?.prevClosingPrice
          );
        }, 0);

        binanceClosingData?.data?.depositAveragePrices?.forEach((item: any) => {
          totalDeposit +=
            +item?.depositAmount *
            (item?.depositCurrency === "USDT" ? 1 : +item?.averagePrice);
        });

        binanceClosingData?.data?.withdrawalAveragePrices?.forEach(
          (item: any) => {
            totalWithdrawal +=
              +item?.withdrawalAmount *
              (item?.withdrawalCurrency === "USDT" ? 1 : +item?.averagePrice);
          }
        );
      }
    } else {
      totalBeginningAssets = (closingData?.data || [])?.reduce(
        (sum: any, item: any) => sum + +item?.quantity * +item?.current_rate,
        0
      );
      (closingData?.data || []).forEach((parentItem: any) => {
        parentItem?.wallet_transactions?.forEach((item: any) => {
          totalDeposit += +item?.amount * +item?.exchange_rate;
        });
      });
    }

    setTotalUSD(onFixed(totalUsd));
    setTotalPnL(onFixed(totalpnl));
    setTotalPnLPer(
      (totalpnl / (totalBeginningAssets + totalDeposit - totalWithdrawal)) * 100
    );
    return {
      data:
        arr?.sort((a: any, b: any) => b?.coinTotalUsd - a?.coinTotalUsd) || [],
      isLoading: loadingArr?.length <= 1,
    };
  };

  const onGetAllWallet = async () => {
    try {
      const payload: any = {
        is_connect: is_connect,
        exchange_name: exchange_name,
      };
      await getAllWallet({
        ...payload,
        group_name: pathnames?.[1],
      });
    } catch (err: any) {}
  };

  useEffect(() => {
    const fn = async () => {
      getActiveCoins({
        group_name: pathnames?.[1],
      });
      if (role.includes("View AI PnL")) {
        getClosingData({
          group_name: pathnames?.[1],
        });
      }
    };
    fn();
    onGetAllWallet();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const data = prepareCoins();

    setTableData(data?.data || []);
    setIsLoading(data?.isLoading);
    // setTableData(prepareCoins()?.data || []);
    // eslint-disable-next-line
  }, [
    activeCoins,
    closingData,
    wallet,
    tickersData,
    is_tab_connect,
    binanceClosingData,
    search,
  ]);

  useEffect(() => {
    if (!!tickers?.length && !tickersData?.length) {
      setTickersData(tickers);
    }
    // eslint-disable-next-line
  }, [tickers, tickersData]);

  const columns = useMemo(
    () => [
      {
        title: "Coin",
        name: "symbol",
        sortable: true,
        sortableKey: "symbol",
        Cell: (data: any) => (
          <>
            <div className="flex gap-4 items-start">
              <img
                className="w-6"
                src={`/images/cryptoCurrency/${data?.symbol?.toLowerCase()}.png`}
                alt=""
              />
              <div className="block">
                <span className="font-semibold text-base leading-5">
                  {data?.symbol}
                </span>
                <p className="text-nowrap text-xs">{data?.name}</p>
              </div>
            </div>
          </>
        ),
      },
      {
        title: "Amount",
        name: "amount",
        sortable: true,
        sortableKey: "amount",
        prepareValue: (data: any) =>
          `${data?.amount} = ${
            data?.coinTotalUsd !== "$ 0"
              ? seperator2(data?.coinTotalUsd, true)
              : ""
          }`,
        Cell: (data: any) => (
          <>
            <span className="font-semibold text-base">{data?.amount}</span>
            {data?.coinTotalUsd !== "$ 0" ? (
              <div className="text-xs">
                {seperator(data?.coinTotalUsd, true)}
              </div>
            ) : null}
          </>
        ),
      },
      {
        title: "Available",
        name: "available",
        sortable: true,
        sortableKey: "available",
        Cell: (data: any) => (
          <>
            <span className="font-semibold text-base">{data?.available}</span>
          </>
        ),
      },
      {
        title: "Per Unit",
        name: "perUnitUsd",
        sortable: true,
        sortableKey: "perUnitUsd",
        prepareValue: (data: any) => `${seperator2(data?.perUnitUsd, true)}`,
        Cell: (data: any) => (
          <>
            <span className="font-semibold text-base">
              {seperator(data?.perUnitUsd, true)}
            </span>
          </>
        ),
      },
      {
        title: "Today's PnL",
        name: "pnl",
        showColumn: role.includes("View AI PnL"),
        sortable: true,
        sortableKey: "pnl",
        Cell: (data: any) => (
          <>
            <span className="font-semibold text-base">{data?.pnl}</span>
          </>
        ),
      },
      {
        title: "Action",
        showColumn: spotRole?.length || futureRole?.length,
        exportHide: ["ALL"],
        Cell: (data: any) => {
          return (
            <>
              {data?.symbol !== "USDT" ? (
                <>
                  <div className="flex gap-2">
                    {spotRole?.length ? (
                      <>
                        <NavLink
                          className="cursor-pointer text-primary-theme font-semibold hover:text-primary-950"
                          // onClick={() => navigate(`/spot-trade/${data?.symbol}/USDT`)}
                          to={`/spot-trade/${data?.symbol}/USDT`}
                        >
                          Spot
                        </NavLink>
                      </>
                    ) : null}
                    {futureRole?.length ? (
                      <>
                        <NavLink
                          className="cursor-pointer text-primary-theme font-semibold hover:text-primary-950"
                          // onClick={() =>
                          //   navigate(`/future-trade/${data?.symbol}/USDT`)
                          // }
                          to={`/future-trade/${data?.symbol}/USDT`}
                        >
                          Trade
                        </NavLink>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    ],
    [role, spotRole, futureRole]
  );

  const binanceColumns = [
    {
      title: "Coin",
      name: "symbol",
      sortable: true,
      sortableKey: "symbol",
      Cell: (data: any) => (
        <>
          <div className="flex gap-4 items-start">
            <img
              className="w-6"
              src={`/images/cryptoCurrency/${data?.symbol?.toLowerCase()}.png`}
              alt=""
            />
            <div className="block">
              <span className="font-semibold text-base leading-5">
                {data?.symbol}
              </span>
              <p className="text-nowrap text-xs">{data?.name}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Amount",
      name: "amount",
      sortable: true,
      sortableKey: "amount",
      prepareValue: (data: any) =>
        `${data?.amount} = ${
          data?.coinTotalUsd !== "$ 0"
            ? seperator2(data?.coinTotalUsd, true)
            : ""
        }`,
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">{data?.amount}</span>
          {data?.coinTotalUsd !== "$ 0" ? (
            <div className="text-xs">{seperator(data?.coinTotalUsd, true)}</div>
          ) : null}
        </>
      ),
    },
    {
      title: "Available",
      name: "available",
      sortable: true,
      sortableKey: "available",
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">{data?.available}</span>
        </>
      ),
    },
    {
      title: "Per Unit",
      name: "perUnitUsd",
      sortable: true,
      sortableKey: "perUnitUsd",
      prepareValue: (data: any) => `${seperator2(data?.perUnitUsd, true)}`,
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">
            {seperator(data?.perUnitUsd, true)}
          </span>
        </>
      ),
    },
    {
      title: "Today's PnL",
      name: "pnl",
      sortable: true,
      sortableKey: "pnl",
      showColumn: role.includes("View Exchange PnL"),
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">
            {data?.pnlLoading ? "-" : data?.pnl}
          </span>
        </>
      ),
    },
    {
      title: "Action",
      showColumn: spotRole?.length || futureRole?.length,
      exportHide: ["ALL"],
      Cell: (data: any) => {
        return (
          <>
            {data?.symbol !== "USDT" ? (
              <>
                <div className="flex gap-2">
                  {spotRole?.length ? (
                    <>
                      <NavLink
                        className="cursor-pointer text-primary-theme font-semibold hover:text-primary-950"
                        // onClick={() => navigate(`/spot-trade/${data?.symbol}/USDT`)}
                        to={`/spot-trade/${data?.symbol}/USDT`}
                      >
                        Spot
                      </NavLink>
                    </>
                  ) : null}
                  {futureRole?.length ? (
                    <>
                      <NavLink
                        className="cursor-pointer text-primary-theme font-semibold hover:text-primary-950"
                        // onClick={() =>
                        //   navigate(`/future-trade/${data?.symbol}/USDT`)
                        // }
                        to={`/future-trade/${data?.symbol}/USDT`}
                      >
                        Trade
                      </NavLink>
                    </>
                  ) : null}
                </div>
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
  ];

  const socket = getLaravelSocket();
  useEffect(() => {
    if (
      socket?.connected &&
      is_valid_api_key &&
      role.includes("View Exchange PnL")
    ) {
      emitClosingDataEvent({
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
      });
    }
    // eslint-disable-next-line
  }, [socket?.connected, exchange_name]);

  return (
    <>
      <WalletBalance
        totalUSD={totalUSD}
        totalPnL={totalPnL || ""}
        totalPnLPer={totalPnLPer || ""}
        is_tab_connect={is_tab_connect}
      />
      <div className="card_rounded mb-4">
        {/* <div className="p-2 text-primary-950">
          <strong>Spot</strong>
        </div> */}
        <div className="relative">
          <div className="absolute right-0 z-[1]">
            <Input
              icon="SearchIcon"
              name="search"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              className="!w-[300px]"
            />
          </div>
          <div className="App tableborder">
            <Table
              columns={is_tab_connect ? binanceColumns : columns}
              fileName="Wallet"
              isLoading={!tickers || !!isLoading}
              data={tableData || []}
              tableDataCount={tableData?.length}
              setData={setTableData}
              isExpendable={false}
              tableClassName="!h-[500px] overflow-y-scroll hidden_scroll"
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      className="!align-middle"
                      columns={is_tab_connect ? binanceColumns : columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
