import { useState, useEffect, useRef } from "react";

// UI IMPORT
import { Button, ControlledInput, Input } from "../../ui";

// PROJECT IMPORT
import { useLazyGetAllWalletQuery } from "../wallet/walletApi";
import { onFixed } from "../../helperFunctions";
import { useWalletData } from "../wallet/walletSlice";
import useWebSocketContext from "../../context/useWebSocketContext";
import { useRole, useUser } from "../auth/authSlice";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useKeys } from "../exchange/exchangeSlice";
import DisabledDiv from "../../ui/elements/DisabledDiv";

interface BuySellFormTypes {}

const Order = ({
  onOrderPlaced,
  callBuySocket,
  callSellSocket,
  getTradPosition,
}: any) => {
  const { tickers, loading, emitCreateFutureTradeEvent } =
    useWebSocketContext();
  const navigate = useNavigate();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const isFutureTrade = pathname.pathname.includes("future-trade");
  const role = useRole(isFutureTrade ? "future-trade" : "spot-trade");
  const { base, quote } = useParams();

  const baseUppercase = base?.toUpperCase();
  const quoteUppercase = quote?.toUpperCase();

  const wallet = useWalletData();
  const user = useUser();
  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();

  const [activeItem, setActiveItem] = useState(0);
  const [calculatedTotal, setCalculatedTotal] = useState<any>("");
  const [calculatedTotalSell, setCalculatedTotalSell] = useState<any>();
  const updatingField = useRef<any>(null);
  const updatingFieldSell = useRef<any>(null);
  const [getAllWallet, { error }]: any = useLazyGetAllWalletQuery();

  const schema: any = yup.object({
    from_amount: yup.string().required("Please enter a amount"),
  });

  const sellSchema: any = yup.object({
    from_amount: yup.string().required("Please enter a amount"),
  });

  const initialValues: any = {};

  const sellInitialValues: any = {};

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver<BuySellFormTypes>(schema),
    defaultValues: initialValues,
  });

  const {
    control: sellControl,
    formState: { errors: sellErrors },
    getValues: sellGetValues,
    handleSubmit: sellHandleSubmit,
    setValue: setValueSell,
    watch: watchSell,
  } = useForm({
    resolver: yupResolver<BuySellFormTypes>(sellSchema),
    defaultValues: sellInitialValues,
  });

  useEffect(() => {
    if (activeItem === 1) {
      const { amount, from_amount } = getValues();

      if (updatingField.current === "total") {
        updatingField.current = null;
        return;
      }

      updatingField.current = "from_amount";

      if (amount && from_amount) {
        setCalculatedTotal(onFixed(amount * from_amount));
      }

      if (!from_amount) {
        setCalculatedTotal("");
      }
    }
    // eslint-disable-next-line
  }, [watch("amount"), watch("from_amount")]);

  useEffect(() => {
    if (activeItem === 1) {
      const { amount, from_amount } = sellGetValues();

      if (updatingFieldSell.current === "total") {
        updatingFieldSell.current = null;
        return;
      }

      updatingFieldSell.current = "from_amount";
      if (amount && from_amount) {
        setCalculatedTotalSell(onFixed(amount * from_amount));
      }

      if (!from_amount) {
        setCalculatedTotalSell("");
      }
    }
    // eslint-disable-next-line
  }, [watchSell("amount"), watchSell("from_amount")]);

  const handleTotalChange = (e: any) => {
    const newTotal = parseFloat(e.target.value);
    const { amount } = getValues();
    updatingField.current = "total";
    if (amount && newTotal && e.target.value) {
      setValue("from_amount", onFixed(newTotal / amount));
    }
    if (!e.target.value) {
      setValue("from_amount", "");
    }
    setCalculatedTotal(e.target.value);
  };

  const handleTotalChangeSell = (e: any) => {
    const newTotal = parseFloat(e.target.value);
    const { amount } = sellGetValues();
    updatingFieldSell.current = "total";
    if (amount && newTotal && e.target.value) {
      setValueSell("from_amount", onFixed(newTotal / amount));
    }
    if (!e.target.value) {
      setValueSell("from_amount", "");
    }
    setCalculatedTotalSell(e.target.value);
  };

  const onSubmit = async () => {
    try {
      const values = getValues();

      if (isFutureTrade) {
        const index = tickers?.findIndex(
          (i: any) => i?.s === `${baseUppercase}${quoteUppercase}`
        );
        const payload = {
          order_amount: calculatedTotal,
          type: activeItem === 0 ? 1 : 2,
          from_coin: quote,
          to_coin: baseUppercase,
          stock_price: activeItem === 0 ? tickers?.[index]?.c : values?.amount,
          take_profit: !!values?.take_profit ? values?.take_profit : 0,
          stop_loss: !!values?.stop_loss ? values?.stop_loss : 0,
          side: "buy",
          user_uuid: user?.data?.user?.user_uuid,
          is_connect: is_connect,
          exchange_name: exchange_name,
          group_name: pathnames?.[1],
        };

        await emitCreateFutureTradeEvent(payload, async (res: any) => {
          if (res?.status) {
            setValue("from_amount", "");
            setValue("take_profit", "");
            setValue("stop_loss", "");
            setCalculatedTotal("");
          }
          await Promise.all([
            getAllWallet({
              is_connect: is_connect,
              exchange_name: exchange_name,
            }),
            getTradPosition({
              group_name: pathnames?.[1],
            }),
            onOrderPlaced(["All Order History"]),
          ]);
        });
      } else {
        const payload = {
          limit_price: activeItem === 1 ? values?.amount : 0,
          order_amount:
            activeItem === 1 ? calculatedTotal : values?.from_amount,
          type: activeItem === 0 ? 1 : 2,
          from_coin: quoteUppercase,
          to_coin: baseUppercase,
        };
        await callBuySocket(payload, async (res: any) => {
          if (res?.status) {
            setValue("from_amount", "");
            setValue("take_profit", "");
            setValue("stop_loss", "");
          }

          await Promise.all([
            getAllWallet({
              is_connect: is_connect,
              exchange_name: exchange_name,
            }),
            onOrderPlaced(["All Order History"]),
          ]);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSellSubmit = async () => {
    try {
      const values = await sellGetValues();
      if (isFutureTrade) {
        const index = tickers?.findIndex(
          (i: any) => i?.s === `${baseUppercase}${quoteUppercase}`
        );

        const payload = {
          order_amount: calculatedTotalSell,
          type: activeItem === 0 ? 1 : 2,
          from_coin: quoteUppercase,
          to_coin: baseUppercase,
          stock_price: activeItem === 0 ? tickers?.[index]?.c : values?.amount,
          take_profit: !!values?.take_profit ? values?.take_profit : 0,
          stop_loss: !!values?.stop_loss ? values?.stop_loss : 0,
          side: "sell",
          user_uuid: user?.data?.user?.user_uuid,
          is_connect: is_connect,
          exchange_name: exchange_name,
          group_name: pathnames?.[1],
        };
        await emitCreateFutureTradeEvent(payload, async (res: any) => {
          if (res?.status) {
            setCalculatedTotalSell("");
            setValueSell("from_amount", "");
            setValueSell("take_profit", "");
            setValueSell("stop_loss", "");
          }
          await Promise.all([
            getAllWallet({
              is_connect: is_connect,
              exchange_name: exchange_name,
            }),
            getTradPosition(),
          ]);
        });
      } else {
        const payload = {
          limit_price: activeItem === 1 ? values?.amount : 0,
          order_amount: values?.from_amount,
          type: activeItem === 0 ? 1 : 2,
          to_coin: quoteUppercase,
          from_coin: baseUppercase,
        };
        await callSellSocket(payload, async (res: any) => {
          if (res?.status) {
            setValueSell("from_amount", "");
            setValueSell("take_profit", "");
            setValueSell("stop_loss", "");
          }

          await Promise.all([
            getAllWallet({
              is_connect: is_connect,
              exchange_name: exchange_name,
            }),
            onOrderPlaced(["All Order History"]),
          ]);
        });
      }
    } catch (err) {
      console.error("Got Error in Sell: ", err);
    }
  };

  const prepareQuotePrice = () => {
    if (is_tab_connect) {
      let balanceObj: any;
      Object.entries(wallet?.data?.exchangeData || {})?.forEach(
        ([key, item]: any) => {
          if (key === quoteUppercase) {
            balanceObj = item;
          }
        }
      );
      return +balanceObj?.["free"] || 0;
    } else {
      return onFixed(
        wallet?.data?.aiData?.find?.(
          (item: any) => item?.coin?.code === quoteUppercase
        )?.balance
      );
    }
  };

  const preparebaseUppercasePrice = () => {
    if (is_tab_connect) {
      let balanceObj: any;
      Object.entries(wallet?.data?.exchangeData || {})?.forEach(
        ([key, item]: any) => {
          if (key === baseUppercase) {
            balanceObj = item;
          }
        }
      );
      return +balanceObj?.["free"] || 0;
    } else {
      return onFixed(
        wallet?.data?.aiData?.find?.(
          (item: any) => item?.coin?.code === baseUppercase
        )?.balance
      );
    }
  };

  useEffect(() => {
    getAllWallet({ is_connect: is_connect, exchange_name: exchange_name });
    // eslint-disable-next-line
  }, [is_connect, exchange_name]);

  const isActive = (menuItem: any) => {
    return activeItem === menuItem;
  };

  const setActive = (menuItem: any) => {
    if (menuItem === 1) {
      const index = tickers?.findIndex(
        (i: any) => i?.s === `${baseUppercase}${quoteUppercase}`
      );

      if (index > -1) {
        setValue("amount", onFixed(tickers?.[index].c));
        setValueSell("amount", onFixed(tickers?.[index].c));
      }
    }
    setValue("from_amount", "");
    setValueSell("from_amount", "");
    setValue("take_profit", "");
    setValueSell("take_profit", "");
    setValue("stop_loss", "");
    setValueSell("stop_loss", "");

    setCalculatedTotal("");
    setCalculatedTotalSell("");
    setActiveItem(menuItem);
  };

  const prepareValue = () => {
    const index = tickers?.findIndex(
      (i: any) => i?.s === `${baseUppercase}${quoteUppercase}`
    );
    return onFixed(+calculatedTotal / tickers?.[index]?.c) || "";
  };

  const prepareSellValue = () => {
    const index = tickers?.findIndex(
      (i: any) => i?.s === `${baseUppercase}${quoteUppercase}`
    );
    return onFixed(+calculatedTotalSell / tickers?.[index]?.c) || "";
  };

  useEffect(() => {
    if (isFutureTrade && activeItem === 0) {
      const index = tickers?.findIndex(
        (i: any) => i?.s === `${baseUppercase}${quoteUppercase}`
      );
      setValue(
        "from_amount",
        onFixed(+calculatedTotal / tickers?.[index]?.c) || ""
      );
      setValueSell(
        "from_amount",
        onFixed(+calculatedTotalSell / tickers?.[index]?.c) || ""
      );
    }
    // eslint-disable-next-line
  }, [tickers]);

  useEffect(() => {
    const index = tickers?.findIndex(
      (i: any) => i?.s === `${baseUppercase}${quoteUppercase}`
    );

    if (tickers && index > -1) {
      setValue("amount", onFixed(tickers?.[index].c));
      setValueSell("amount", onFixed(tickers?.[index].c));
    }
    // eslint-disable-next-line
  }, [base, quote]);

  return (
    <>
      <DisabledDiv
        isDisabled={
          !role.includes(
            isFutureTrade ? "Create Future Trade Order" : "Create Spot Order"
          )
        }
        message="You don't have permission to create order"
      >
        <div className="Order text-primary-950">
          <div className="w-full">
            <ul className="nf flex-cols -mb-px flex overflow-x-hidden text-center">
              <li>
                <button
                  className={`inline-block py-2 pl-3 pr-4 text-xs font-medium ${
                    isActive(0)
                      ? "active-tab border-b border-secondary-200"
                      : "inactive-tab"
                  }`}
                  type="button"
                  onClick={() => {
                    if (
                      !role.includes(
                        isFutureTrade
                          ? "Create Future Trade Order"
                          : "Create Spot Order"
                      )
                    ) {
                      return;
                    }
                    setActive(0);
                  }}
                >
                  Market
                </button>
              </li>
              <li>
                <button
                  className={`inline-block py-2 pl-3 pr-4 text-xs font-medium ${
                    isActive(1)
                      ? "active-tab border-b border-secondary-200"
                      : "inactive-tab"
                  }`}
                  type="button"
                  onClick={() => {
                    if (
                      !role.includes(
                        isFutureTrade
                          ? "Create Future Trade Order"
                          : "Create Spot Order"
                      )
                    ) {
                      return;
                    }
                    setActive(1);
                  }}
                >
                  Limit
                </button>
              </li>
            </ul>
          </div>
          <div id="myTabContent" className="px-6 py-3">
            <div className="grid grid-cols-2 sm:grid-cols-1 gap-10">
              {isFutureTrade ? (
                <>
                  {/* future */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center gap-2 pb-2 text-primary-950">
                      <span className="text-sm">Available : </span>{" "}
                      <span className="text-sm font-semibold">
                        {prepareQuotePrice()} {quoteUppercase}
                      </span>
                    </div>
                    <div className="space-y-3 pb-4">
                      {activeItem === 1 ? (
                        <div className="relative">
                          <ControlledInput
                            name="amount"
                            placeholder="Price"
                            type="number"
                            disabled={
                              !role.includes("Create Future Trade Order")
                            }
                            control={control}
                            errors={errors}
                          />
                          <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                            {quoteUppercase}
                          </span>
                        </div>
                      ) : null}
                      {activeItem === 0 ? (
                        <>
                          <div className="relative">
                            <ControlledInput
                              name="from_amount"
                              placeholder="Amount"
                              type="number"
                              disabled
                              value={prepareValue()}
                              control={control}
                              errors={errors}
                            />
                            <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                              {baseUppercase}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="relative">
                            <ControlledInput
                              name="from_amount"
                              placeholder="Amount"
                              type="number"
                              disabled={
                                !role.includes("Create Future Trade Order")
                              }
                              control={control}
                              errors={errors}
                            />
                            <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                              {baseUppercase}
                            </span>
                          </div>
                        </>
                      )}

                      <div className="relative">
                        <Input
                          name="total"
                          type="number"
                          placeholder="Total"
                          disabled={!role.includes("Create Future Trade Order")}
                          value={calculatedTotal}
                          onChange={handleTotalChange}
                        />
                        <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                          {quoteUppercase}
                        </span>
                      </div>
                      <div className="relative">
                        <ControlledInput
                          name="take_profit"
                          placeholder="Take Profit"
                          type="number"
                          disabled={!role.includes("Create Future Trade Order")}
                          control={control}
                          errors={errors}
                        />
                      </div>
                      <div className="relative">
                        <ControlledInput
                          name="stop_loss"
                          placeholder="Stop Loss"
                          type="number"
                          disabled={!role.includes("Create Future Trade Order")}
                          control={control}
                          errors={errors}
                        />
                      </div>
                      {error?.data?.message === "Unauthorized" ||
                      !user?.data?.token ? (
                        <>
                          <Button
                            className="w-full !text-sm !text-primary-400 font-semibold !bg-secondary-100 !border-0"
                            onClick={() => navigate("/login")}
                          >
                            Log In
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            isLoading={loading?.buy}
                            disabled={
                              loading?.buy ||
                              loading?.sell ||
                              !role.includes("Create Future Trade Order")
                            }
                            className="w-full !text-sm font-semibold !bg-secondary-green !border-0"
                            type="submit"
                          >
                            Buy{" "}
                            <span className="uppercase pl-1">
                              {baseUppercase}
                            </span>
                          </Button>
                        </>
                      )}
                    </div>
                  </form>
                  <form onSubmit={sellHandleSubmit(onSellSubmit)}>
                    <div className="flex items-center gap-2 pb-2 text-primary-950 invisible">
                      <span className="text-sm">Available : </span>{" "}
                      <span className="text-sm font-semibold">
                        {preparebaseUppercasePrice()} {baseUppercase}
                      </span>
                    </div>
                    <div className="space-y-3 pb-4">
                      {activeItem === 1 ? (
                        <div className="flex items-center justify-between relative">
                          <ControlledInput
                            name="amount"
                            placeholder="Price"
                            type="number"
                            disabled={
                              !role.includes("Create Future Trade Order")
                            }
                            control={sellControl}
                            errors={sellErrors}
                          />
                          <span className="port_position">
                            {quoteUppercase}
                          </span>
                        </div>
                      ) : null}
                      {activeItem === 0 ? (
                        <>
                          <div className="relative">
                            <Input
                              name="from_amount"
                              placeholder="Amount"
                              type="number"
                              disabled
                              value={prepareSellValue()}
                            />
                            <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                              {baseUppercase}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="relative">
                            <ControlledInput
                              name="from_amount"
                              placeholder="Amount"
                              type="number"
                              disabled={
                                !role.includes("Create Future Trade Order")
                              }
                              control={sellControl}
                              errors={sellErrors}
                            />
                            <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                              {baseUppercase}
                            </span>
                          </div>
                        </>
                      )}
                      <div className="relative">
                        <Input
                          name="total"
                          type="number"
                          placeholder="Total"
                          disabled={!role.includes("Create Future Trade Order")}
                          value={calculatedTotalSell}
                          onChange={handleTotalChangeSell}
                        />
                        <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                          {quoteUppercase}
                        </span>
                      </div>
                      <div className="relative">
                        <ControlledInput
                          name="take_profit"
                          placeholder="Take Profit"
                          type="number"
                          disabled={!role.includes("Create Future Trade Order")}
                          control={sellControl}
                          errors={sellErrors}
                        />
                      </div>
                      <div className="relative">
                        <ControlledInput
                          name="stop_loss"
                          placeholder="Stop Loss"
                          type="number"
                          disabled={!role.includes("Create Future Trade Order")}
                          control={sellControl}
                          errors={sellErrors}
                        />
                      </div>
                      {error?.data?.message === "Unauthorized" ||
                      !user?.data?.token ? (
                        <>
                          <Button
                            className="w-full !text-primary-400 font-semibold !bg-secondary-100 !border-0"
                            onClick={() => navigate("/login")}
                          >
                            Log In
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            className="w-full !text-sm font-semibold !bg-secondary-red  !border-0"
                            type="submit"
                            disabled={
                              loading?.buy ||
                              loading?.sell ||
                              !role.includes("Create Future Trade Order")
                            }
                            isLoading={loading?.sell}
                          >
                            Sell{" "}
                            <span className="uppercase pl-1">
                              {baseUppercase}
                            </span>
                          </Button>
                        </>
                      )}
                    </div>
                  </form>
                </>
              ) : (
                <>
                  {/* spot */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center gap-2 pb-2 text-primary-950">
                      <span className="text-sm">Available : </span>{" "}
                      <span className="text-sm font-semibold">
                        {prepareQuotePrice()} {quoteUppercase}
                      </span>
                    </div>
                    <div className="space-y-3 pb-4">
                      {activeItem === 1 ? (
                        <div className="relative">
                          <ControlledInput
                            name="amount"
                            placeholder="Price"
                            type="number"
                            disabled={!role.includes("Create Spot Order")}
                            control={control}
                            errors={errors}
                          />
                          <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                            {quoteUppercase}
                          </span>
                        </div>
                      ) : null}
                      <div className="relative">
                        <ControlledInput
                          name="from_amount"
                          // label="Amount"
                          placeholder="Amount"
                          type="number"
                          disabled={!role.includes("Create Spot Order")}
                          control={control}
                          errors={errors}
                        />
                        <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                          {activeItem === 0 ? quoteUppercase : baseUppercase}
                        </span>
                      </div>
                      {activeItem === 1 ? (
                        <div className="relative">
                          <Input
                            name="total"
                            type="number"
                            placeholder="Total"
                            disabled={!role.includes("Create Spot Order")}
                            value={calculatedTotal}
                            onChange={handleTotalChange}
                          />
                          <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                            {quoteUppercase}
                          </span>
                        </div>
                      ) : null}
                      <Button
                        isLoading={loading?.buy}
                        disabled={
                          loading?.buy ||
                          loading?.sell ||
                          !role.includes("Create Spot Order")
                        }
                        className="w-full !text-sm font-semibold !bg-secondary-green !border-0"
                        type="submit"
                      >
                        Buy{" "}
                        <span className="uppercase pl-1">{baseUppercase}</span>
                      </Button>
                    </div>
                  </form>
                  <form onSubmit={sellHandleSubmit(onSellSubmit)}>
                    <div className="flex items-center gap-2 pb-2 text-primary-950">
                      <span className="text-sm">Available : </span>{" "}
                      <span className="text-sm font-semibold">
                        {preparebaseUppercasePrice()} {baseUppercase}
                      </span>
                    </div>
                    <div className="space-y-3 pb-4">
                      {activeItem === 1 ? (
                        <div className="flex items-center justify-between relative">
                          <ControlledInput
                            name="amount"
                            placeholder="Price"
                            type="number"
                            disabled={!role.includes("Create Spot Order")}
                            control={sellControl}
                            errors={sellErrors}
                          />
                          <span className="port_position">
                            {quoteUppercase}
                          </span>
                        </div>
                      ) : null}
                      <div className="relative">
                        <ControlledInput
                          name="from_amount"
                          // label="Amount"
                          placeholder="Amount"
                          type="number"
                          disabled={!role.includes("Create Spot Order")}
                          control={sellControl}
                          errors={sellErrors}
                        />
                        <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                          {baseUppercase}
                        </span>
                      </div>
                      {activeItem === 1 ? (
                        <div className="relative">
                          <Input
                            name="total"
                            type="number"
                            placeholder="Total"
                            disabled={!role.includes("Create Spot Order")}
                            value={calculatedTotalSell}
                            onChange={handleTotalChangeSell}
                          />
                          <span className="text-primary-950 text-sm font-semibold uppercase port_position">
                            {quoteUppercase}
                          </span>
                        </div>
                      ) : null}
                      <Button
                        className="w-full !text-sm font-semibold !bg-secondary-red  !border-0"
                        type="submit"
                        isLoading={loading?.sell}
                        disabled={
                          loading?.buy ||
                          loading?.sell ||
                          !role.includes("Create Spot Order")
                        }
                      >
                        Sell{" "}
                        <span className="uppercase pl-1">{baseUppercase}</span>
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </DisabledDiv>
    </>
  );
};

export default Order;
