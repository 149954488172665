"use client";
import { Fragment, memo, useState } from "react";
import { MenuItemsTypes } from "./menuItems";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../ui";
import { toggleDrawer, useIsDrawerOpen } from "../common/commonSlice";
import { useUser } from "../auth/authSlice";
import { useAppDispatch } from "../../utills/hooks";

// UI IMPORT

// PROJECT IMPORT

/* ============================== DRAWER ============================== */

interface DrawerProps {
  menuItems: MenuItemsTypes[];
}

interface ShowMenuProps extends MenuItemsTypes {
  selected: string;
  isDrawerOpen: boolean;
  badge?: number;
  user: any | null;
}

interface NavigateProps extends Pick<MenuItemsTypes, "subItems" | "url"> {
  className?: string;
  isDrawerOpen: boolean;
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const Navigate = (props: NavigateProps) => {
  const {
    className = "",
    url,
    subItems,
    isDrawerOpen,
    children,
    onClick,
  } = props;
  return (
    <div>
      {subItems ? (
        <button
          className={`${className} ${
            isDrawerOpen ? "" : "justify-center"
          } items-center cursor-pointer w-full flex`}
          onClick={onClick}
        >
          {children}
        </button>
      ) : (
        <Link
          to={url}
          className={`${className} ${
            isDrawerOpen ? "" : "justify-center"
          } items-center cursor-pointer w-full flex`}
        >
          {children}
        </Link>
      )}
    </div>
  );
};

const ShowMenu = (props: ShowMenuProps) => {
  const {
    url,
    subItems,
    selected,
    icon,
    title,
    isDrawerOpen,
    badge = 1,
    user,
  } = props;

  const [isExtended, setIsExtended] = useState<boolean>(false);

  const subUrls = subItems?.map((item: MenuItemsTypes) => item?.url);
  const current = !!(
    `/${selected}` === `${url}` ||
    isExtended ||
    subUrls?.includes(`/${selected}`)
  );

  return (
    <>
      <Navigate
        className={`${badge === 2 ? "pl-6" : ""}`}
        url={url}
        subItems={subItems}
        isDrawerOpen={isDrawerOpen}
        onClick={() => setIsExtended(!isExtended)}
      >
        <div
          className={`hover-slide mb-1 px-4 flex gap-2 rounded-sm items-center py-3 group w-full ${
            !isDrawerOpen ? "justify-center !px-0" : ""
          } ${current ? "active bg-primary-theme" : ""} ${
            badge === 2
              ? `pl-2 ${current ? "border-primary-400" : "border-gray-500"}`
              : ""
          }`}
        >
          {!isDrawerOpen && subItems ? null : (
            <Icon
              name={icon}
              className={` ${
                current ? "stroke-primary-white" : "stroke-primary-950"
              } group-hover:!stroke-primary-white`}
            />
          )}
          {isDrawerOpen ? (
            <span
              className={`text-sm text-primary-950 group-hover:!text-primary-white transition-colors duration-500 ${
                current ? "!text-primary-white font-bold" : ""
              }`}
            >
              {title}
            </span>
          ) : null}
          {subItems ? (
            <Icon
              name={current ? "UpArrowIcon" : "DownArrowIcon"}
              className={`${
                current ? "stroke-primary-white" : "stroke-primary-950"
              } group-hover:!stroke-primary-white`}
            />
          ) : null}
        </div>
      </Navigate>
      {subItems?.length && current
        ? subItems.map((subItem: MenuItemsTypes, index: number) => {
            return (
              <Fragment key={index}>
                <ShowMenu
                  {...subItem}
                  badge={2}
                  selected={selected}
                  isDrawerOpen={isDrawerOpen}
                  user={user}
                />
              </Fragment>
            );
          })
        : null}
    </>
  );
};

const Drawer = ({ menuItems }: DrawerProps) => {
  const isDrawerOpen = useIsDrawerOpen();
  const dispatch = useAppDispatch();

  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const user = useUser();

  return (
    <>
      <div
        className={`fixed h-full  scrollbar-hide z-20 shadow-xl transition-all border-r border-secondary-200 dark:border-bb-dark ${
          isDrawerOpen ? "translate-x w-[210px]" : "w-[70px]"
        }`}
      >
        <div className="flex h-full px-2 pb-[200px] flex-col overflow-y-scroll hidden_scroll bg-secondary-white">
          <div
            className={`bg-secondary-white z-[1] flex items-center justify-between py-4 sticky top-0  ${
              isDrawerOpen ? "pl-4 pr-5" : "!justify-center"
            }`}
          >
            {/* <div>
            <Icon name="Logo" className="!w-[100px]" />
            <h4 className="text-primary-950">Trading</h4>
        </div> */}
            {isDrawerOpen && (
              <div
                onClick={() => {
                  dispatch(toggleDrawer(!isDrawerOpen));
                }}
              >
                <Icon name="MenuBarIcon" className="stroke-primary-950" />
              </div>
            )}
          </div>
          {menuItems.map((item, index: number) => {
            return (
              <Fragment key={index}>
                <ShowMenu
                  {...item}
                  selected={pathnames?.[1]}
                  isDrawerOpen={isDrawerOpen}
                  user={user}
                />
              </Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default memo(Drawer);
