"use client";
import { Outlet } from "react-router-dom";

// PROJECT IMPORTS
import Header from "./Header";
import Drawer from "./Drawer";
import { useIsDrawerOpen } from "../common/commonSlice";
import { useRole } from "../auth/authSlice";
import { prepareMenuItems } from "../../utills/helperFunctions";

/* ============================== MAIN LAYOUT ============================== */

const Layout = () => {
  const isDrawerOpen = useIsDrawerOpen();
  const role = useRole();
  const menuItems = prepareMenuItems(role);

  return (
    <>
      <Header />
      <Drawer menuItems={menuItems} />
      <main
        className={`pt-[65px] pr-5 ${
          isDrawerOpen ? "pl-[230px]" : "pl-[98px]"
        }`}
      >
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
