import { Fragment } from "react/jsx-runtime";
import { getStatusTypes, Table, TableRow } from "../../ui";
import {
  SideEnumKeys,
  orderStatuses,
  sideEnum,
  typeEnum,
} from "../../constant";
import { useCallback, useEffect, useMemo, useState } from "react";

// THIRD - PARTY IMPORT
import { dateFormatter, onFixed } from "../../helperFunctions";
import useWebSocketContext from "../../context/useWebSocketContext";
import { useKeys } from "../exchange/exchangeSlice";
import useStrategyContext from "../../context/StrategyContext";
import StrategyStatistics from "./StrategyStatistics";
import {
  useGetAllOrderConnectedMutation,
  useGetAllOrderMutation,
} from "./strategyApi";
import { useLocation } from "react-router-dom";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";

const StrategyOrderHistory = () => {
  const { filter, filterForOrderHistory, setFilterForOrderHistory } =
    useStrategyContext();

  const [tableData, setTableData] = useState<any>([]);
  const { loading, binanceOrderHistory } = useWebSocketContext();
  const {
    currentKey: { is_tab_connect, exchange_name },
  } = useKeys();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const [getAllOrder, { data: allOrderHistory }] = useGetAllOrderMutation();
  const [getAllOrderConnected, { data: allOrderHistoryConnected }] =
    useGetAllOrderConnectedMutation();

  const columnsOrderHistory = useMemo(
    () => [
      {
        title: "Exchange",
        name: "is_connect_order",
        prepareValue: ({ is_connect_order, exchange_name }: any) =>
          is_connect_order ? exchange_name : "-",
        Cell: ({ is_connect_order, exchange_name }: any) => (
          <div className="flex items-center justify-center">
            {is_connect_order ? <span>{exchange_name}</span> : "-"}
          </div>
        ),
      },
      {
        title: "Date",
        name: "created_at",
        Cell: (data: any) => <>{data?.created_at}</>,
      },
      {
        title: "Pair",
        name: "symbol",
        // Cell: (data: any) => <>{`${data?.to_coin}${data?.from_coin}`}</>,
      },
      {
        title: "Type",
        name: "type",
        prepareValue: ({ type }: any) => typeEnum?.[type],
        Cell: ({ type }: any) => (
          <>
            <span>{typeEnum?.[type]}</span>
          </>
        ),
        isMenu: true,
        enum: typeEnum,
      },
      {
        title: "Side",
        name: "side",
        prepareValue: ({ side }: { side: SideEnumKeys }) => sideEnum?.[side],
        Cell: ({ side }: { side: SideEnumKeys }) => (
          <>
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
        isMenu: true,
        enum: sideEnum,
      },
      {
        title: "Platform",
        name: "platform",
      },
      {
        title: "Trade Type",
        name: "trade_type",
      },
      {
        title: "Average",
        name: "exchange_rate",
        prepareValue: (data: any) => onFixed(data?.exchange_rate),
        Cell: (data: any) => <span>{onFixed(data?.exchange_rate)}</span>,
      },
      {
        title: "Price",
        name: "stock_price",
        prepareValue: (data: any) =>
          data?.type === 1
            ? "Market"
            : ["8", "9"]?.includes(data?.type.toString())
            ? data?.type?.toString() === "8"
              ? onFixed(data?.take_profit)
              : onFixed(data?.stop_loss)
            : onFixed(data?.stock_price),
        Cell: (data: any) => (
          <>
            <span>
              {data?.type === 1
                ? "Market"
                : ["8", "9"]?.includes(data?.type.toString())
                ? data?.type?.toString() === "8"
                  ? onFixed(data?.take_profit)
                  : onFixed(data?.stop_loss)
                : onFixed(data?.stock_price)}
            </span>
          </>
        ),
      },
      {
        title: "Executed",
        name: "executedQty",
        prepareValue: (data: any) => onFixed(data?.executed),
        Cell: (data: any) => <span>{onFixed(data?.executed)}</span>,
      },
      {
        title: "Amount",
        name: "amount",
        prepareValue: (data: any) => onFixed(data?.amount),
        Cell: (data: any) => <span>{onFixed(data?.amount)}</span>,
      },
      {
        title: "Total",
        name: "total",
        prepareValue: (data: any) => onFixed(data?.total),
        Cell: (data: any) => (
          <>
            <span>{onFixed(data?.total)}</span>
          </>
        ),
      },
      {
        title: "Status",
        name: "order_status",
        prepareValue: ({
          order_status,
        }: {
          order_status: "1" | "2" | "3" | "4";
        }) => orderStatuses?.[order_status],
        Cell: ({ order_status }: { order_status: "1" | "2" | "3" | "4" }) => (
          <div
            className={`${getStatusTypes(orderStatuses?.[order_status])}`}
          >{`${orderStatuses?.[order_status]}`}</div>
        ),
        isMenu: true,
        enum: orderStatuses,
        dropdownClassName: "!left-auto !right-0",
      },
    ],
    // eslint-disable-next-line 
    [tableData]
  );

  const prepareAllOrderHistory = () => {
    return is_tab_connect
      ? allOrderHistoryConnected?.data
      : allOrderHistory?.data || [];
  };

  const prepareTotalOrders = useCallback(() => {
    return {
      total: prepareAllOrderHistory()?.length || 0,
      long:
        prepareAllOrderHistory()?.filter((item: any) => item?.side === "buy")
          ?.length || 0,
      short:
        prepareAllOrderHistory()?.filter((item: any) => item?.side === "sell")
          ?.length || 0,
    };
    // eslint-disable-next-line 
  }, [allOrderHistory, is_tab_connect]);

  useEffect(() => {
    setTableData(prepareOrderHistory());
    // eslint-disable-next-line
  }, [binanceOrderHistory, is_tab_connect]);

  const prepareOrderHistory = () => {
    return is_tab_connect === 1
      ? binanceOrderHistory?.exchangeData || []
      : binanceOrderHistory?.AI || [];
  };

  useEffect(() => {
    let payload: any = {
      strategy_name: filter?.strategy,
      symbol: filter?.symbol,
      start_date: filter?.start_date
        ? dateFormatter(filter?.start_date, "start")
        : null,
      end_date: filter?.end_date
        ? dateFormatter(filter?.end_date, "end")
        : null,
      exchange_name: exchange_name,
      order_type: 1,
      platform: filter?.platform,
      // trade_type: filter?.trade,
    };

    const fun = async () => {
      try {
        await getAllOrder({
          ...payload,
          group_name: pathnames?.[1],
        }).unwrap();
        await getAllOrderConnected({
          ...payload,
          group_name: pathnames?.[1],
        }).unwrap();
      } catch (err) {}
    };

    fun();
    // eslint-disable-next-line
  }, [filter, exchange_name]);

  return (
    <div className="flex flex-col mt-5">
      <StrategyStatistics totalOrders={prepareTotalOrders()} />
      <div className={`pt-3`}>
        <Table
          fileName="Strategy_order_history"
          isLoading={loading?.orderHistory}
          columns={
            // is_tab_connect === 1 ? binanceColOrderHistory : columnsOrderHistory
            columnsOrderHistory
          }
          data={prepareOrderHistory() || []}
          tableDataCount={tableData?.length}
          isExpendable={false}
          count={
            is_tab_connect === 1
              ? binanceOrderHistory?.exchangeCount
              : binanceOrderHistory?.aiCount
          }
          setData={setTableData}
          className="!overflow-scroll order_table"
          minHeight={300}
          pagination={filterForOrderHistory}
          handlePaginationChange={(pagination) => {
            setFilterForOrderHistory({
              ...filterForOrderHistory,
              ...pagination,
            });
          }}
        >
          {tableData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  className="min-h-[350px]"
                  columns={
                    // is_tab_connect === 1
                    //   ? binanceColOrderHistory
                    //   : columnsOrderHistory
                    columnsOrderHistory
                  }
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default StrategyOrderHistory;
