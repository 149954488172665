import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { exchangeApi } from "./exchangeApi";

const initialKeys = {
  keys: [],
  currentKey: {
    is_connect: 0,
    is_valid_api_key: 0,
    is_tab_connect: 0,
    is_active: 0,
    exchange_name: null,
  },
};

interface ExchangeState {
  exchangeList: any;
  keys: any;
}

const initialState: ExchangeState = {
  exchangeList: {},
  keys: initialKeys,
};

const prepareCurrentKey = (action: any, keys: any) => {
  const apiPayload = action?.meta?.arg?.originalArgs;
  const newPayload = {
    ...(keys || {}),
    ...(apiPayload || {}),
  };

  const newData = {
    is_valid_api_key: keys?.secret_key && keys?.api_key ? 1 : 0,
    is_connect: keys?.is_active ? 1 : 0,
    is_tab_connect: keys?.is_active ? keys?.is_tab_connect ?? 1 : 0,
  };

  return {
    ...(newPayload || {}),
    ...(newData || {}),
  };
};

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    clearExchange: () => initialState,
    setExchange: (state, { payload }) => {
      state.exchangeList = payload;
    },
    updateKeys: (state, { payload }) => {
      state.keys = {
        ...state.keys,
        ...(payload || {}),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      exchangeApi.endpoints.getExchange.matchFulfilled,
      (state: any, { payload }) => {
        const newArray: any[] = [];

        payload?.data?.exchanges?.exchange_list?.forEach((item: any) => {
          const keys = payload?.data?.exchange_keys?.find(
            (subItem: any) => subItem?.exchange_name === item?.exchange_name
          );
          newArray.push({
            ...(item || {}),
            secret_key: keys?.secret_key,
            api_key: keys?.api_key,
            is_key_active: keys?.is_active,
          });
        });

        const newObj = {
          data: {
            exchange_keys: payload?.data?.exchange_keys,
            exchanges: {
              ...(payload?.data?.exchanges || {}),
              exchange_list: newArray,
            },
          },
        };

        state.keys = {
          keys: payload?.data?.exchange_keys,
          currentKey: prepareCurrentKey(
            {},
            payload?.data?.exchange_keys?.find(
              (item: any) => item?.is_active === 1
            )
          ),
        };
        state.exchangeList = newObj;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default exchangeSlice.reducer;
export const { clearExchange, setExchange, updateKeys } = exchangeSlice.actions;

export const selectExchangeList = (state: RootState) =>
  state.exchange.exchangeList;
export const useExchangeList = () => {
  const data = useSelector(selectExchangeList);
  return useMemo(() => data, [data]);
};

export const selectKeys = (state: RootState) => state.exchange.keys;
export const useKeys = () => {
  const keys = useSelector(selectKeys);
  return useMemo(() => keys, [keys]);
};
