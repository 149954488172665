import { Fragment, useEffect, useMemo, useState } from "react";
import {
  Button,
  ControlledDatePicker,
  ControlledDropDown,
  Icon,
  Table,
  TableRow,
} from "../../ui";
import { useForm } from "react-hook-form";
import PositionHistory from "./PositionHistory";
import { useGetStrategyMutation } from "./strategyApi";
import { useStrategyList } from "./strategySlice";
import StrategyOpenOrder from "./StrategyOpenOrder";
import StrategyPosition from "./StrategyPosition";
import StrategyOrderHistory from "./StrategyOrderHistory";
import useStrategyContext from "../../context/StrategyContext";
import { compareDates, dateFormatter, onFixed } from "../../helperFunctions";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetPlatformsMutation,
  useGetSymbolsMutation,
} from "../common/commonApi";
import { usePlatforms, useSymbols } from "../common/commonSlice";
import { monthNames } from "../../constant";
import { useRole } from "../auth/authSlice";
import StrategySymbolModal from "./StrategySymbolModal";
import { useKeys } from "../exchange/exchangeSlice";
import ManualTradeModal from "./manualTrade/ManualTradeModal";

const Strategy = () => {
  const navigate = useNavigate();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const strategyList = useStrategyList();
  const allSymbols = useSymbols();
  const [getSymbols] = useGetSymbolsMutation();
  const [getStrategy] = useGetStrategyMutation();
  const [getPlatforms] = useGetPlatformsMutation();
  const platforms = usePlatforms();
  const [currentSymbol, setCurrentSymbol] = useState(false);
  const [openManualTradeModal, setOpenManualTradeModal] = useState(false);
  const {
    currentKey: { is_connect, exchange_name },
  } = useKeys();

  const {
    activeTab,
    setActiveTab,
    filter,
    setFilter,
    onClear,
    chartData,
    symbolWisePnL,
    setSymbolWisePnL,
  } = useStrategyContext();

  const role = useRole("strategy");

  const tabs: any = [];

  if (role.includes("View Strategy Position History")) {
    tabs.push({ name: "Open Positions", Component: StrategyPosition });
  }

  if (role.includes("View Strategy Open Order")) {
    tabs.push({ name: "Open Orders", Component: StrategyOpenOrder });
  }

  if (role.includes("View Strategy Position History")) {
    tabs.push({ name: "Position History", Component: PositionHistory });
  }

  if (role.includes("View Strategy Order History")) {
    tabs.push({ name: "Orders History", Component: StrategyOrderHistory });
  }

  const i = tabs?.findIndex((item: any) => {
    return item?.name === activeTab;
  });

  const Component = tabs?.[i < 0 ? 0 : i]?.Component;

  const contractsColumns = useMemo(
    () => [
      {
        title: "Action",
        name: "action",
        exportHide: ["ALL"],
        Cell: (data: any) => (
          <>
            <div
              onClick={() => {
                setCurrentSymbol(data?.contracts);
              }}
            >
              <Icon name="EyeOnIcon" />
            </div>
          </>
        ),
      },
      {
        title: "Contracts",
        name: "contracts",
      },
      {
        title: "Progress",
        name: "progress",
        exportHide: ["ALL"],
        Cell: (data: any) => (
          <>
            <div className="w-[250px]">
              <ProgressBar
                value={Math.abs(data?.pnl)}
                maxValue={Math.max(
                  ...(symbolWisePnL.map((item: any) => Math.abs(item?.pnl)) ||
                    [])
                )}
                color={data?.pnl > 0 ? "green" : "red"}
              />
            </div>
          </>
        ),
      },
      {
        title: "P&L (USD)",
        name: "pnl",
        sortable: true,
        sortableKey: "pnl",
        prepareValue: (data: any) =>
          `${data?.pnl > 0 ? "+" : ""}${onFixed(data?.pnl)}`,
        Cell: (data: any) => (
          <>
            <div>{`${data?.pnl > 0 ? "+" : ""}` + onFixed(data?.pnl)}</div>
          </>
        ),
      },
    ],
    [symbolWisePnL]
  );

  const prepareStrategyOptions = () => {
    const options = strategyList?.data?.map?.(({ _, strategy_name }: any) => {
      return { value: strategy_name, label: strategy_name };
    });
    return options || [];
  };

  const initialValues = {
    start_date: new Date(),
    end_date: new Date(),
    strategy: prepareStrategyOptions()?.[0]?.value,
    symbol: "All",
    platform: "All",
    // trade: "All",
  };

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
    reset,
    handleSubmit,
  } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
    });
  };

  const onGet = async () => {
    try {
      await getSymbols({
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err: any) {}
  };

  const onGetPlatforms = async () => {
    try {
      await getPlatforms({
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err: any) {}
  };

  const onGetStrategy = async () => {
    try {
      const res = await getStrategy({
        group_name: pathnames?.[1],
      }).unwrap();
      if (res?.data?.length) {
        setValue("strategy", res?.data?.[0]?.strategy_name);
      }
    } catch (err) {}
  };
  // const onGetAllStrategyHistory = async () => {
  //   try {
  //     const values = getValues();
  //     if (!values?.strategy || !values?.symbol) {
  //       return;
  //     }
  //     const payload = {
  //       strategy_name: values?.strategy,
  //       symbol: values?.symbol,
  //     };
  //     await getAllStrategyHistory(payload).unwrap();
  //   } catch (err) {
  //     console.error("Err =-=>", err);
  //   }
  // };

  // useEffect(() => {
  //   onGetAllStrategyHistory();
  // }, [filter, watch("symbol"), watch("strategy")]);

  useEffect(() => {
    onGetStrategy();
    onGet();
    onGetPlatforms();
    setFilter({ ...filter, ...initialValues });
    // eslint-disable-next-line
  }, []);

  // const prepareMarkerData = () => {
  //   const newData: any[] = [];

  //   sortByDate(
  //     allStrategyHistory?.data || [],
  //     "created_at_ist",
  //     "Desc"
  //   )?.forEach((item: any) => {
  //     const tradeTime = new Date(item?.created_at_ist).getTime() / 1000;

  //     if (+tradeTime) {
  //       newData.unshift({
  //         time: +tradeTime,
  //         position: item?.side === "buy" ? "belowBar" : "aboveBar",
  //         color: item?.side === "buy" ? "green" : "red",
  //         shape: item?.side === "buy" ? "arrowUp" : "arrowDown",
  //         text: item?.side === "buy" ? "BUY" : "SELL",
  //       });
  //     }
  //   });
  //   return newData;
  // };

  const prepareMarketOptions = () => {
    try {
      let filteredMarkets: any = [];
      allSymbols?.data?.forEach((symbol: string) => {
        filteredMarkets.push({
          value: symbol,
          label: symbol,
        });
      });
      filteredMarkets.unshift({ value: "All", label: "All" });
      return filteredMarkets;
    } catch (error) {
      console.error("Error handling ticker data:", error);
      return {};
    }
  };

  const preparePlatformOptions = () => {
    try {
      let arr: any = [];
      platforms?.data?.forEach((symbol: string) => {
        arr.push({
          value: symbol,
          label: symbol,
        });
      });
      arr.unshift({ value: "All", label: "All" });
      return arr;
    } catch (error) {
      console.error("Error:", error);
      return {};
    }
  };

  // const prepareTradeOptions = () => {
  //   try {
  //     let arr: any = [];
  //     Object.entries(tradeEnum)?.forEach(([key, value]: any) => {
  //       arr.push({
  //         value: key,
  //         label: value,
  //       });
  //     });
  //     arr.unshift({ value: "All", label: "All" });
  //     return arr;
  //   } catch (error) {
  //     console.error("Error:", error);
  //     return {};
  //   }
  // };

  useEffect(() => {
    setActiveTab(tabs?.[0]?.name);
    // eslint-disable-next-line
  }, []);

  const prepareCalendarData = () => {
    let date = new Date(filter?.start_date);
    let lastDate = new Date(filter?.end_date);

    date.setDate(1);
    lastDate.setDate(1);

    const newArray: any[] = [];

    while (date <= lastDate) {
      const lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();
      newArray.push({
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        lastDay: lastDay,
      });
      date.setMonth(date.getMonth() + 1);
    }

    return newArray;
  };

  const preparePayload = () => {
    return {
      strategy_name: filter?.strategy,
      symbol: filter?.symbol,
      start_date: filter?.start_date
        ? dateFormatter(filter?.start_date, "start")
        : null,
      end_date: filter?.end_date
        ? dateFormatter(filter?.end_date, "end")
        : null,
      exchange_name: exchange_name,
      is_connect: is_connect,
      platform: filter?.platform,
      group_name: pathnames?.[1],
    };
  };

  return (
    <>
      <div>
        <div className="card_rounded mb-4">
          <div className="flex justify-end gap-2">
            <div className="mb-4 flex justify-end">
              {role.includes("Upload Trade Image") ? (
                <>
                  <Button
                    className="!h-8 !text-sm"
                    onClick={() => {
                      setOpenManualTradeModal(true);
                    }}
                  >
                    Manual trade
                  </Button>
                </>
              ) : null}
            </div>
            <div className="mb-4 flex justify-end">
              {role.includes("Apply Strategy") ? (
                <>
                  <Button
                    className="!h-8 !text-sm"
                    onClick={() => {
                      navigate("/strategy/manage-strategy");
                    }}
                  >
                    Manage Strategy
                  </Button>
                </>
              ) : null}
            </div>
          </div>
          <div className="flex gap-2 items-end flex-wrap">
            <ControlledDropDown
              className="w-[200px]"
              name="strategy"
              label="Strategy"
              control={control}
              errors={errors}
              options={prepareStrategyOptions()}
            />
            <ControlledDropDown
              className="w-[200px]"
              name="symbol"
              label="Symbol"
              control={control}
              errors={errors}
              options={prepareMarketOptions()}
            />
            <ControlledDropDown
              className="w-[200px]"
              name="platform"
              label="Platform"
              control={control}
              errors={errors}
              options={preparePlatformOptions()}
            />
            {/* <ControlledDropDown
            className="w-[200px]"
            name="trade"
            label="Trade"
            control={control}
            errors={errors}
            options={prepareTradeOptions()}
          /> */}
            {/* {activeTab !== 0 && activeTab !== 1 ? (
            <> */}
            <ControlledDatePicker
              name="date"
              label="Date"
              selectsRange
              control={control}
              value={[watch("start_date"), watch("end_date")]}
              inputClassName="h-8 text-xs"
              onChange={(e) => {
                setValue("start_date", e?.[0]);
                setValue("end_date", e?.[1]);
              }}
            />
            {/* <ControlledDatePicker
              name="end_date"
              label="End Date"
              selectsEnd
              startDate={watch("start_date")}
              endDate={watch("end_date")}
              control={control}
              inputClassName="h-8 text-xs"
            /> */}
            {/* </>
          ) : null} */}
            <Button className="!h-8 !text-sm" onClick={handleSubmit(onSubmit)}>
              Search
            </Button>
            <Button
              className="!h-8 !text-sm"
              onClick={() => {
                reset();
                setFilter(initialValues);
                onClear();
                // setFilterActiveTab(0);
              }}
            >
              Reset
            </Button>
          </div>
        </div>

        {(activeTab === "Position History" || activeTab === "Positions") &&
        !!prepareCalendarData()?.length &&
        chartData?.length ? (
          <>
            <div className="card_rounded mb-4">
              <div className="flex gap-2 overflow-y-scroll pr-[50px]">
                {prepareCalendarData()?.map((item) => {
                  return (
                    <div>
                      {monthNames?.[item?.month - 1]} - {item?.year}
                      <div className="w-[248px] flex flex-wrap gap-2">
                        {Array.from({ length: item?.lastDay })?.map(
                          (_, day) => {
                            const currentDay = day + 1;
                            const currentDate = new Date(
                              item?.year,
                              item?.month,
                              currentDay
                            );

                            const value: any = chartData?.find((c: any) => {
                              return compareDates(
                                new Date(c?.[0]),
                                currentDate
                              );
                            });

                            return (
                              <>
                                <div className={`relative relative-container`}>
                                  <p
                                    className={`${
                                      value?.[1] > 0
                                        ? "bg-green-500"
                                        : value?.[1] < 0
                                        ? "bg-red-500"
                                        : "bg-secondary-100"
                                    } flex items-center justify-center rounded-sm text-xs h-7 w-7  cursor-pointer`}
                                  >
                                    {currentDay}
                                  </p>
                                  {value?.[1] ? (
                                    <p className="scale-up text-nowrap">
                                      <span className="profit_meseg">
                                        {value?.[1]}
                                      </span>
                                    </p>
                                  ) : null}
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {symbolWisePnL?.length ? (
              <div className="card_rounded mb-4">
                <Table
                  // isLoading={}
                  fileName="Strategy_contracts"
                  columns={contractsColumns}
                  data={symbolWisePnL || []}
                  tableDataCount={symbolWisePnL?.length}
                  count={symbolWisePnL?.length}
                  isExpendable={false}
                  tableClassName="!h-[500px] overflow-y-scroll hidden_scroll"
                  setData={setSymbolWisePnL}
                >
                  {symbolWisePnL?.map((item: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <TableRow
                          columns={contractsColumns}
                          item={item}
                          isExpendable={false}
                        />
                      </Fragment>
                    );
                  })}
                </Table>
              </div>
            ) : null}
          </>
        ) : null}
        <div className="card_rounded">
          <div className="flex gap-4">
            {tabs?.map?.((item: any, index: any) => {
              return (
                <div key={index}>
                  <div
                    className={`cursor-pointer relative flex justify-center items-center activetab_hover ${
                      item?.name === activeTab
                        ? "activetab font-semibold text-primary-950 "
                        : "font-normal text-primary-950 opacity-50"
                    }`}
                    onClick={() => {
                      setActiveTab(item?.name);
                    }}
                  >
                    <p>{item?.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {!!Component ? (
            <>
              <Component />
            </>
          ) : null}
        </div>
      </div>
      <StrategySymbolModal
        isOpen={currentSymbol}
        preparePayload={preparePayload}
        onClose={() => {
          setCurrentSymbol(false);
        }}
      />
      <ManualTradeModal
        isOpen={openManualTradeModal}
        onClose={() => {
          setOpenManualTradeModal(false);
        }}
      />
    </>
  );
};

export default Strategy;
