import Select from "react-select";

export interface DropDownProps {
  defaultValue?:any;
  options:any[]
  name:string;
  className?:string;
  inputClassName?:string;
  label?:string;
  value?:any;
  styles?:any;
  isMulti?:boolean;
  onChange?:(value?:any,option?:any[],e?:any) => void;
  errors?:any;
  width?:number;
  placeholder?:string;
  isClearable?:boolean;
  isDisabled?:boolean;
  err?:any;
  noOptionsMessage?:string;
}
const DropDown = (props:DropDownProps) => {
  const {
    defaultValue,
    options = [],
    name,
    className = "",
    inputClassName = "",
    label,
    value,
    styles = {},
    isMulti,
    onChange,
    errors,
    width,
    placeholder,
    isClearable = false,
    isDisabled = false,
    err,
    noOptionsMessage = "No Options",
    ...rest
  } = props;
  const message = errors?.[name]?.message || err || "";
  const getOptions = () => {
    return placeholder
      ? [{ value: "", label: placeholder }, ...options]
      : options;
  };
  const customNoOptionsMessage = () => {
    return <div>{noOptionsMessage}</div>;
  };

  return (
    <div className={`${className} select-div`}>
      {label ? (
        <label className={`text-sm text-primary-950 pb-1`}>{label}</label>
      ) : null}
      <Select
        name={name}
        isDisabled={isDisabled}
        menuPosition="fixed"
        styles={{
          control: (provided) => ({
            ...provided,
            width: width,
            minHeight: "32px",
            height: "32px",
            // color: "var(--chatlook-950--)",
            borderRadius: "2px",
            boxShadow: "none",
            borderWidth: "1px",
            borderColor: "var(--secondary-200--)",
            background: "transparent",
            fontSize: 12,
            outline: "none",
            "&:active": {
              borderColor: "var(--primary-500--)",
            },
            "&:hover": {
              borderColor: "var(--primary-500--)",
            },
            "&:focus": {
              borderColor: "var(--primary-500--)",
            },
            // zIndex: 99999,
          }),
          valueContainer: (provided) => ({
            ...provided,
            overflowX: "scroll",
            flexWrap: "nowrap",
            marginRight: "0px",
            fontSize: 12,
            "&:focus-visible": {
              border: "none",
              boxShadow: "none",
              outline: "none",
            },
          }),
          multiValue: (provided) => ({
            ...provided,
            minWidth: "unset",
            // background: "#1b2130",
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            // color: "var(--ev-tertiary--)",
          }),
          multiValueRemove: (provided, state) => ({
            ...provided,
            color: "var(--primary-950--)", // Change the color to red
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            background: "none",
          }),
          menu: (style) => ({
            ...style,
            zIndex: 999999,
            borderRadius: "2px",
            width: "fit-content",
            padding: "1px 0",
            background: "var(--secondary-50--)",
          }),
          menuList: (style) => ({
            ...style,
            // zIndex: 99999,
            padding: "0 1px",
            borderRadius: "2px",
            background: "var(--secondary-50--)",
          }),
          input: (style) => ({
            ...style,
            fontSize: 14,
            color: "var(--primary-950--)",
          }),
          select: (style:any) => ({
            ...style,
            background: "var(--secondary-950--)",
          }),
          singleValue: (style) => ({
            ...style,
            fontSize: 14,
            color: "var(--primary-950--)",
          }),
          placeholder: (style) => ({
            ...style,
            fontSize: 12,
            color: "var(--primary-950--)",
          }),
          option: (style,state) => ({
            ...style,
            padding: "4px 10px",
            "&:active": {
              backgroundColor: "var(--secondary-200--)",
            },
            fontSize: 13,
            whiteSpace: "nowrap",
            width: "100%",
            color: "var(--primary-950--)",
            // backgroundColor: "var(--secondary-50--)",
            backgroundColor: state.isSelected ? "var(--secondary-200--)" : "var(--secondary-50--)",
          }),
          // menuPortal: (style) => ({
          //   ...style,
          //   zIndex: 9999999,
          // }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999999, // Your existing zIndex adjustment
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: "0 5px",
          }),
          container: (style) => ({
            ...style,
            marginRight: 0,
          }),
          ...styles,
        }}
        onChange={(e, option:any) => {
          if (isMulti) {
            onChange?.(
              e?.map((item:any) => item?.value),
              option,
              e
            );
          } else {
            onChange?.(e.value, option, e);
          }
        }}
        className={`${inputClassName} w-100`}
        options={getOptions()}
        isMulti={isMulti}
        isClearable={isClearable}
        value={
          isMulti
            ? options?.filter((option) => value?.includes(option?.value))
            : options?.find((option) => option?.value === value) || ""
        }
        defaultValue={options?.[0]?.label}
        placeholder={placeholder}
        noOptionsMessage={customNoOptionsMessage}
        {...rest}
      />
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default DropDown;
