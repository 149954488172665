// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const tabViseApis = [
  "getAllOpenOrder",
  "getAllFilledOrderHistory",
  "getAllOrder",
  "getAllOrderDetails",
  "getAllOpenOrder",
  "getAllFuturePositionHistory",
];
// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { endpoint, getState }) => {
      if (endpoint !== "login") {
        const state: any = getState();
        headers.set("Authorization", `Bearer ${state.auth.user?.data?.token}`);
        const key = state?.exchange?.keys?.currentKey;
        headers.set(
          "is_connect",
          `${tabViseApis?.includes(endpoint)
            ? key?.is_tab_connect
            : key?.is_connect
          }`
        );
        // headers.set("exchange_name", state.common.keys?.exchange_name || null);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ["User", "SMSLog"],
});
