import React, { memo } from "react";
import { IconProps } from ".";

export interface DashboardIconProps extends IconProps {}
const DashboardIcon = (props: DashboardIconProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 24,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    // <svg
    //   className={`${className}`}
    //   width={size}
    //   height={size}
    //   viewBox="0 0 25 24"
    //   fill={fill}
    //   stroke={stroke}
    //   strokeWidth={strokeWidth}
    //   {...rest}
    // >
    //   <path
    //     d="M18.29 22.75H6.71C3.97 22.75 1.75 20.52 1.75 17.78V10.37C1.75 9.00997 2.59 7.29997 3.67 6.45997L9.06 2.25997C10.68 0.999974 13.27 0.939974 14.95 2.11997L21.13 6.44997C22.32 7.27997 23.25 9.05997 23.25 10.51V17.79C23.25 20.52 21.03 22.75 18.29 22.75ZM9.98 3.43997L4.59 7.63997C3.88 8.19997 3.25 9.46997 3.25 10.37V17.78C3.25 19.69 4.8 21.25 6.71 21.25H18.29C20.2 21.25 21.75 19.7 21.75 17.79V10.51C21.75 9.54997 21.06 8.21997 20.27 7.67997L14.09 3.34997C12.95 2.54997 11.07 2.58997 9.98 3.43997Z"
    //     fill="var(--primary-950--)"
    //     className={`${pathClassNames?.[0] || ""}`}
    //   />
    //   <path
    //     d="M12.5 18.75C12.09 18.75 11.75 18.41 11.75 18V15C11.75 14.59 12.09 14.25 12.5 14.25C12.91 14.25 13.25 14.59 13.25 15V18C13.25 18.41 12.91 18.75 12.5 18.75Z"
    //     fill="var(--primary-950--)"
    //     className={`${pathClassNames?.[1] || ""}`}
    //   />
    // </svg>
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <rect width="7" height="9" x="3" y="3" rx="1" />
      <rect width="7" height="5" x="14" y="3" rx="1" />
      <rect width="7" height="9" x="14" y="12" rx="1" />
      <rect width="7" height="5" x="3" y="16" rx="1" />
    </svg>
  );
};

const MemoDashboardIcon = memo(DashboardIcon);
export default MemoDashboardIcon;
