// UI IMPORT

import { IconKeys } from "../../ui";

// TYPES
export interface MenuItemsTypes {
  id?: string;
  icon: IconKeys;
  title: string;
  url: string;
  subItems?: MenuItemsTypes[];
}

export interface MenuItemHeaders {
  title: string;
  url: string;
  icon: IconKeys;
}
/* ============================== MENU ITEMS ============================== */

export const menuItems: MenuItemsTypes[] = [
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: "DashboardIcon",
  },
  {
    title: "Wallet",
    url: "/wallet",
    icon: "WalletIcon",
  },
  {
    title: "Statement",
    url: "/account-statement",
    icon: "AccountStatementIcon",
  },
  // {
  //   title: "Marker Chart",
  //   url: "/marker-chart",
  //   icon: "MarkerChartIcon",
  // },
  {
    title: "Wallet History",
    url: "/wallet-history",
    icon: "WalletHistoryIcon",
  },
  {
    title: "Convert History",
    url: "/convert-history",
    icon: "TransferHistoryIcon",
  },
  {
    title: "Strategy",
    url: "/strategy",
    icon: "StrategyIcon",
  },
  {
    title: "Exchange",
    url: "/exchange",
    icon: "ExchangeIcon",
  },
  {
    title: "Orders",
    url: "/orders",
    icon: "OrderIcon",
    subItems: [
      {
        title: "Spot Order",
        url: "/spot-order",
        icon: "OrderIcon",
      },
      {
        title: "Future Order",
        url: "/future-order",
        icon: "FutureOrderIcon",
      },
    ],
  },
  {
    title: "Member",
    url: "/users",
    icon: "UsersIcon",
  },
  {
    title: "Roles",
    url: "/roles",
    icon: "RolesIcon",
  },

  // {
  //   title: "Convert",
  //   url: "/convert",
  //   icon: "ConvertIcon",
  // },
  // {
  //   title: "BuySell",
  //   url: "/buy-sell",
  //   icon: "DashboardIcon",
  // },
  // {
  //   title: "Trading",
  //   url: "/trading",
  //   icon: "TradeIcon",
  // },
];
