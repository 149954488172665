// PROJECT IMPORT
import { FIXED_DECIMAL } from "./constant";
import { clearAuth } from "./features/auth/authSlice";
import { clearCommon } from "./features/common/commonSlice";
import { clearDashboard } from "./features/dashboard/dashboardSlice";
import { clearWallet } from "./features/wallet/walletSlice";
import { clearTrading } from "./features/trade/tradeSlice";
import { clearAccountStatement } from "./features/accountStatement/accountStatementSlice";
import { clearWalletHistory } from "./features/walletHistory/walletHistorySlice";
import { clearconvertHistory } from "./features/convertHistory/convertHistorySlice";

// THIRD - PARTY IMPORT
import { toast } from "react-hot-toast";
import { FiAlertTriangle } from "react-icons/fi";
import CryptoJS from "crypto-js";
import autoTable from "jspdf-autotable";
import { jsPDF } from "jspdf";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const root = window.document.documentElement;

export const kFormatter = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const logout = async (dispatch) => {
  dispatch(clearAuth());
  dispatch(clearCommon());
  dispatch(clearDashboard());
  dispatch(clearWallet());
  dispatch(clearTrading());
  dispatch(clearAccountStatement());
  dispatch(clearWalletHistory());
  dispatch(clearconvertHistory());
};

export const downloadFile = (url, type, name) => {
  let element = document.createElement("a");
  let file = new Blob([url], { type: `${type}/*` });
  element.href = URL.createObjectURL(file);
  element.download = name;
  element.click();
};

export const showToaster = (message, type = "Success") => {
  switch (type) {
    case "Error":
      toast.error(message || "Something Went Wrong!", {
        position: "top-right",
      });
      break;
    case "Success":
      toast.success(message, {
        position: "top-right",
      });
      break;
    case "Warning":
      toast.custom(
        (t) => (
          <div className="custom-toast">
            <FiAlertTriangle size={24} />
            <span>{message}</span>
          </div>
        ),
        {
          position: "top-right",
        }
      );
      break;
    default:
      toast.success(message, {
        position: "top-right",
      });
      break;
  }
};

export const changeFavicon = (newFaviconUrl) => {
  const favicon = window.document.querySelector('link[rel="icon"]') || {};
  favicon.href = newFaviconUrl;
};

export const setStyles = (varr, color) => {
  root?.style.setProperty(varr, color);
};

export const setFontFamily = (fontFamily) => {
  document.body.style.fontFamily = fontFamily;
};

export const dateFormatter = (d, type = "start") => {
  if (!d) {
    return null;
  }
  const date = new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const seconds = date.getSeconds();

  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")}${
    type === "end"
      ? " 23:59:59"
      : type === "start"
      ? " 00:00:00"
      : type === "Default"
      ? ""
      : " " + hour + ":" + minute + ":" + seconds
  }`;
};

export const dateFormatter1 = (d, type = "start", startDate = null) => {
  if (!d && !startDate) {
    return null;
  }
  const date =
    type === "end" && !!startDate && !d ? new Date(startDate) : new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours()?.toString()?.padStart(2, "0");
  const minute = date.getMinutes()?.toString()?.padStart(2, "0");
  const seconds = date.getSeconds()?.toString()?.padStart(2, "0");

  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")} ${
    type === "end"
      ? "23:59:59"
      : type === "start"
      ? "00:00:00"
      : hour + ":" + minute + ":" + seconds
  }`;
};

export const dateFormatterUTCToLocal = (utcDateString) =>
  new Date(new Date(utcDateString).getTime()).toLocaleString();

export const timestampToDate = (timestamp) => {
  const date = new Date(+timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateYMD = (dateObject) => {
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const seperator = (amount, isRupee = true, type = "") => {
  if (amount?.toString()?.includes(",")) {
    return `${isRupee ? "$ " : ""}${amount}`;
  }

  const isNagative = amount?.toString()?.[0] === "-";
  const initialZeroes = Array.from({ length: FIXED_DECIMAL })?.reduce(
    (t) => t + "0",
    ""
  );

  const f = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 8,
  });

  let newAmount = f.format(onFixed(+Math.abs(+amount)) || 0);

  const newAmounts = newAmount?.split(".");
  const isAllZeores = (newAmounts?.[1] || "") === initialZeroes;

  if (isAllZeores) {
    newAmount = newAmounts?.[0];
  }
  newAmount = `${isNagative && type !== "price" ? "- " : ""}${
    isRupee && (!isNagative || type === "price") ? "$ " : ""
  }${newAmount?.slice(1)}`;

  return newAmount;
};

export const seperator2 = (amount, isRupee = true, type = "") => {
  const isNagative = amount?.toString()?.[0] === "-";
  const initialZeroes = Array.from({ length: FIXED_DECIMAL })?.reduce(
    (t) => t + "0",
    ""
  );

  let newAmount = `${onFixed(+Math.abs(+amount))}`;

  const newAmounts = newAmount?.split(".");
  const isAllZeores = (newAmounts?.[1] || "") === initialZeroes;

  if (isAllZeores) {
    newAmount = newAmounts?.[0];
  }

  newAmount = `${isNagative && type !== "price" ? "- " : ""}${
    isRupee && (!isNagative || type === "price") ? "$ " : ""
  }${newAmount}`;

  return newAmount;
};

export const prepareMaxDate = (startDate, endDate, maxDay = 30) => {
  if (!startDate || (startDate && endDate)) {
    return new Date();
  }
  const d = new Date(startDate);
  d.setDate(d.getDate() + maxDay);
  return d > new Date() ? new Date() : d;
};
export const prepareBlobUrl = (svgString, type = "image/svg+xml") => {
  const blob = new Blob([svgString], { type });
  return URL.createObjectURL(blob);
};

export const responseToaster = (res) => {
  if (res) {
    showToaster(
      res.message,
      !!res?.error ? "Error" : !res?.status ? "Warning" : "Success"
    );
  } else {
    showToaster("Something Went Wrong!", "Error");
  }
};

export const setLocalData = (key, val, defaultValue = {}) => {
  localStorage.setItem(key, JSON.stringify(val || defaultValue));
};

export const getLocalData = (key, defaultValue = {}) => {
  return (
    JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue)) ??
    defaultValue
  );
};

export const sortByKey = (data, name, sort = "Asce") => {
  const newData = data?.length ? [...data] : [];
  const isDesc = sort === "Desc";
  const keys = name.split(".");

  const getValue = (obj, keys) => {
    return keys.reduce((acc, key) => acc?.[key], obj);
  };

  const compare = (a, b) => {
    const s = getValue(a, keys);
    const l = getValue(b, keys);

    // Check if s and l are numbers
    if (!isNaN(s) && !isNaN(l)) {
      return isDesc ? l - s : s - l;
    }

    // If not numbers, treat as strings and compare
    if (s?.toString()?.toLowerCase() < l?.toString()?.toLowerCase()) {
      return isDesc ? 1 : -1;
    }
    if (s?.toString()?.toLowerCase() > l?.toString()?.toLowerCase()) {
      return isDesc ? -1 : 1;
    }
    return 0;
  };

  return newData.sort(compare);
};

export const sortByDate = (data, name, sort = "Asce") => {
  const sortedAscending = data
    .slice()
    .sort((a, b) => new Date(a?.[name]) - new Date(b?.[name]));

  const sortedDescending = data
    .slice()
    .sort((a, b) => new Date(b?.[name]) - new Date(a?.[name]));
  return sort === "Asce" ? sortedAscending : sortedDescending;
};

export const filterByItem = (data, name, menuKey) => {
  if (menuKey === "all") {
    return data;
  }
  return (data || [])?.filter(
    (item) => item?.[name]?.toString() === menuKey?.toString()
  );
};

export const prepareCapitalCase = (text) => {
  const newArray = text?.split(" ");
  return newArray?.reduce((total, item, index, array) => {
    return (total +=
      item?.charAt(0)?.toUpperCase() +
      item?.slice(1) +
      (array?.length - 1 !== index ? " " : ""));
  }, "");
};

export const prepareDeepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj) || "{}");
};

export const prepareIsConflict = (...values) => {
  let isValid = true;
  let shouldReturn = false;

  values?.forEach((item, index, array) => {
    if (shouldReturn) {
      return;
    }

    if (!Array.isArray(item) || item?.length !== 2) {
      isValid = false;
      shouldReturn = true;
    }

    array?.forEach((subItem, subIndex) => {
      if (index < subIndex) {
        if (
          (+item?.[0] <= +subItem?.[0] && +item?.[1] >= +subItem?.[0]) ||
          (+item?.[0] <= +subItem?.[1] && +item?.[1] >= +subItem?.[1]) ||
          (+item?.[0] > +subItem?.[0] && +item?.[1] < +subItem?.[1])
        ) {
          isValid = false;
          shouldReturn = true;
        }
      }
    });
  });

  return !isValid;
};

export const prepareGapConflicts2 = (
  values,
  [fromKey, toKey, seperatorKey],
  seperatorKeys
) => {
  const conflicts = [];

  values?.forEach((item, index, array) => {
    let isValid = false;

    array?.forEach((subItem, subIndex) => {
      if (index !== subIndex) {
        if (
          +item?.[toKey] + 1 === +subItem?.[fromKey] ||
          prepareIsConflict(
            [item?.[fromKey], item?.[toKey]],
            [subItem?.[fromKey], subItem?.[toKey]]
          )
        ) {
          isValid = true;
        }
      }
    });

    if (!isValid) {
      conflicts.push({
        index: index,
        [fromKey]: item?.[fromKey],
        [toKey]: item?.[toKey],
        [seperatorKey]: item?.[seperatorKey],
      });
    }
  });

  const maxToValueArray = seperatorKeys?.map((s) => ({
    index: -1,
    value: 0,
    id: s,
  }));

  conflicts?.forEach((item, index) => {
    const i = maxToValueArray?.findIndex(
      (m) => m?.id?.toString() === item?.[seperatorKey]?.toString()
    );
    if (i > -1) {
      const v = maxToValueArray[i];

      if (+item?.[toKey] > +v?.value) {
        maxToValueArray[i] = {
          index: index,
          value: v?.value,
          id: v?.id,
        };
      }
    }
  });

  return conflicts?.filter((_, index) => {
    return !maxToValueArray?.find((subItem) => subItem?.index === index);
  });
};

export const prepareIsBetweenInRange = (value, from, to) => {
  return +value >= +from && +value <= +to;
};

export const prepareGapConflicts = (values, [fromKey, toKey]) => {
  values?.sort((a, b) => +a?.[fromKey] - +b?.[fromKey]);

  const newArray = [values?.[0]];
  const gaps = [];

  values?.forEach((item) => {
    const last = newArray?.at(-1);
    if (
      prepareIsBetweenInRange(
        item?.[fromKey],
        last?.[fromKey],
        last?.[toKey] + 1
      )
    ) {
      newArray[newArray?.length - 1] = {
        ...item,
        [fromKey]: Math.min(+item?.[fromKey], +last?.[fromKey]),
        [toKey]: Math.max(+item?.[toKey], +last?.[toKey]),
      };
    } else {
      newArray.push({
        ...item,
      });

      if (newArray?.length > 1) {
        gaps.push({
          gap: +newArray?.at(-1)?.[fromKey] - +newArray?.at(-2)?.[toKey],
          [fromKey]: +newArray?.at(-1)?.[fromKey],
          [toKey]: +newArray?.at(-2)?.[toKey],
        });
      }
    }
  });
  return gaps;
};

export const objectsAreEqual = (obj1, obj2, ignoreKeys = []) => {
  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== "object" ||
    typeof obj2 !== "object"
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (ignoreKeys.includes(key)) {
      continue;
    }
    if (
      !ignoreKeys.includes(key) &&
      (!obj2.hasOwnProperty(key) ||
        !deepEqual(obj1[key], obj2[key], ignoreKeys))
    ) {
      return false;
    }
  }

  return true;
};

export const deepEqual = (a, b, ignoreKeys) => {
  if (typeof a !== typeof b) {
    return false;
  }

  if (typeof a === "object" && typeof b === "object") {
    return objectsAreEqual(a, b, ignoreKeys);
  }

  return a === b;
};
export const onFixed = (value, decimal = FIXED_DECIMAL) => {
  if (!+value) {
    return 0;
  }

  const fixedValue = +(+value)?.toFixed(decimal);
  const stringValue = fixedValue.toString();
  if (stringValue.includes("e")) {
    return convertScientificToNormal(stringValue, decimal);
  }
  return fixedValue;
};

const convertScientificToNormal = (stringValue, decimal) => {
  const [base, exponent] = stringValue.split("e");
  const numExponent = parseInt(exponent, 10);

  let fixedString = "0.";
  for (let i = 0; i < Math.abs(numExponent) - 1; i++) {
    fixedString += "0";
  }
  fixedString += base.replace(".", "");

  return parseFloat(fixedString).toFixed(decimal);
};

export const preparePriceStyle = (price) => {
  if (!+price) {
    return {
      className: "",
    };
  }

  return (+price || 0) === 0
    ? { className: "" }
    : (+price || 0) > 0
    ? { className: "text-primary-green" }
    : { className: "text-primary-red" };
};

export const prepareDayes = (day) => {
  return (day || 0) * 24 * 60 * 60 * 1000;
};

export const prepareLastDate = (day) => {
  return new Date(new Date().getTime() - prepareDayes(day));
};

export const getConversionRate = (tickers, fromSymbol, toSymbol) => {
  let numeratorValue = 0;
  let denominatorValue = 0;

  if (fromSymbol === "USDT") {
    numeratorValue = 1;
    denominatorValue =
      tickers?.[tickers?.findIndex((i) => i?.s === `${toSymbol}USDT`)]?.c;
  } else if (toSymbol === "USDT") {
    denominatorValue = 1;
    numeratorValue =
      tickers?.[tickers?.findIndex((i) => i?.s === `${fromSymbol}USDT`)]?.c;
  } else {
    numeratorValue =
      tickers?.[tickers?.findIndex((i) => i?.s === `${fromSymbol}USDT`)]?.c;
    denominatorValue =
      tickers?.[tickers?.findIndex((i) => i?.s === `${toSymbol}USDT`)]?.c;
  }

  return onFixed(numeratorValue / denominatorValue);
};

export const getTimestamp = async () => {
  try {
    const res = await fetch(`https://api.binance.com/api/v3/time`);

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data?.serverTime;
  } catch (err) {
    console.error(err);
  }
};

export const getSignature = (apiSecret, params) => {
  if (!apiSecret) {
    throw new Error("apiSecret is required");
  }

  if (!params || typeof params !== "object") {
    throw new Error("params must be a valid object");
  }

  const queryString = new URLSearchParams(params).toString();

  if (!queryString) {
    throw new Error("queryString is empty");
  }

  try {
    const signature = CryptoJS.HmacSHA256(queryString, apiSecret).toString(
      CryptoJS.enc.Hex
    );
    return signature;
  } catch (error) {
    console.error("Error generating signature:", error);
    throw new Error("Failed to generate signature");
  }
};

export const clearBinanceAIRedux = async (dispatch) => {
  dispatch(clearWallet());
  dispatch(clearTrading());
  dispatch(clearAccountStatement());
  dispatch(clearWalletHistory());
  dispatch(clearconvertHistory());
};

export const prepareUTCtoIST = (time) => {
  const date = new Date(time);

  const newDate = new Date();
  newDate.setHours(date.getHours() + 5);
  newDate.setMinutes(date.getMinutes() + 30);

  return newDate;
};

export const convertBybitToBinanceTicker = (data) => {
  const lastPrice = parseFloat(data.lastPrice);
  const prevPrice = parseFloat(data.prevPrice24h);
  const priceChange = lastPrice - prevPrice;
  const priceChangePercent = parseFloat(data.price24hPcnt) * 100;
  const weightedAvgPrice =
    parseFloat(data.turnover24h) / parseFloat(data.volume24h);

  const currentTime = new Date();
  const openTime = new Date(
    currentTime.getTime() - 24 * 60 * 60 * 1000
  ).getTime();
  const closeTime = currentTime.getTime();
  // const firstTradeId = 247256253;
  // const lastTradeId = 247422175;
  // const numberOfTrades = 165923;

  return {
    e: "24hrTicker",
    E: closeTime,
    s: data.symbol,
    p: onFixed(priceChange),
    P: onFixed(priceChangePercent, 2),
    w: onFixed(weightedAvgPrice),
    x: data.prevPrice24h,
    c: data.lastPrice,
    // Q: "",
    // b: "",
    // B: "",
    // a: "",
    // A: "",
    o: data.prevPrice24h,
    h: data.highPrice24h,
    l: data.lowPrice24h,
    v: data.volume24h,
    q: data.turnover24h,
    O: openTime,
    C: closeTime,
    // F: firstTradeId,
    // L: lastTradeId,
    // n: numberOfTrades,
  };
};

// export const prepareAllMenu = (role) => {
//   const allRoutes = [];

//   prepareMenuItems(role)?.forEach((item) => {
//     if (item?.subItems?.length) {
//       allRoutes.push(...item?.subItems);
//     } else {
//       allRoutes.push(item);
//     }
//   });

//   return allRoutes;
// };

// export const prepareMenuItems = (role) => {
//   const allRoutes = [];
//   const keys = Object.keys(role);

//   console.log("🚀 ~ menuItems.forEach ~ menuItems:", menuItems)
//   menuItems.forEach((item) => {
//     let subs = [];

//     if (item?.subItems?.length) {
//       item?.subItems?.forEach((element) => {
//         if (keys.includes(element?.url?.split("/")?.[1])) {
//           subs?.push(element);
//         }
//       });
//     } else if (!keys.includes(item?.url?.split("/")?.[1])) {
//       return;
//     }

//     if (subs?.length) {
//       item.subItems = subs;
//     }

//     allRoutes.push(item);
//   });

//   console.log("🚀 ~ prepareMenuItems ~ allRoutes:", allRoutes)
//   return allRoutes;
// };

export const groupBy = (arr, keyFn) => {
  return arr.reduce((acc, obj) => {
    const key = keyFn(obj);
    acc[key] = (acc[key] || []).concat(obj);
    return acc;
  }, {});
};

export const removeTimeFromDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const compareDates = (date1, date2) => {
  const fYear = new Date(date1).getFullYear();
  const fMonth = new Date(date1).getMonth();
  const fDay = new Date(date1).getDate();

  const sYear = new Date(date2).getFullYear();
  const sMonth = new Date(date2).getMonth();
  const sDay = new Date(date2).getDate();

  return fYear === sYear && fMonth === sMonth && fDay === sDay;
};

export const exportPdf = (props) => {
  const { title, data, fileName, columns } = props;

  const newColumns = columns?.filter(
    (i) =>
      !i?.exportHide?.includes("ALL") &&
      !i?.exportHide?.includes("PDF") &&
      (Object.hasOwn("showColumn", i) ? !!i?.showColumn : true)
  );
  const newFileName = fileName || title || "";
  const newTitle = title || fileName || "";

  const head = newColumns?.map((item) => item?.title);
  const body = data?.map((item) => {
    return newColumns?.map((column) => {
      return column?.prepareValue
        ? column?.prepareValue(item)
        : item?.[column?.name] || item?.[column?.defaultValue] || "";
    });
  });

  console.log(body);
  

  const unit = "pt";
  const size = "A4";
  const orientation = "portrait";

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const content = {
    startY: 50,
    head: [head],
    body,
  };

  doc.text(newTitle, marginLeft, 40);
  autoTable(doc, content);
  doc.save(newFileName);
};

// const data = [
//   { id: 1, name: 'John Doe', age: 30, profession: 'Developer' },
//   { id: 2, name: 'Jane Smith', age: 25, profession: 'Designer' }
// ];

export const exportXLSX = (props) => {
  const { fileName, data, columns } = props;

  const newData = [];
  const newColumns = columns?.filter(
    (i) =>
      !i?.exportHide?.includes("ALL") &&
      !i?.exportHide?.includes("XLSX") &&
      (Object.hasOwn("showColumn", i) ? !!i?.showColumn : true)
  );
  const headers = newColumns?.map((item) => item?.title);

  data?.forEach((item) => {
    const newObj = {};

    newColumns?.forEach((column) => {
      newObj[column?.title] = column?.prepareValue
        ? column?.prepareValue(item)
        : item?.[column?.name] || item?.[column?.defaultValue] || "";
    });

    newData.push(newObj);
  });

  const worksheet = XLSX.utils.json_to_sheet(newData, { header: headers });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${fileName}.xlsx`);
};

export const copyContent = async (content) => {
  await navigator.clipboard.writeText(content);
};

export const convertToCSV = (objArray, columns) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  const newColumns = columns?.filter(
    (i) =>
      !i?.exportHide?.includes("ALL") &&
      !i?.exportHide?.includes("CSV") &&
      (Object.hasOwn(i, "showColumn") ? !!i?.showColumn : true)
  );

  newColumns?.forEach((column, index) => {
    str += `${index !== 0 ? "," : ""}${column?.title}`;
  });
  str += "\r\n";

  array?.forEach((item) => {
    let line = "";

    newColumns?.forEach((column) => {
      if (line !== "") line += ",";

      line += column?.prepareValue
        ? column?.prepareValue(item)
        : item?.[column?.name] || item?.[column?.defaultValue] || "";
    });
    str += line + "\r\n";
  });
  return str;
};

export const exportCSV = (props) => {
  const { fileName, data, columns } = props;

  const csvData = new Blob([convertToCSV(data, columns)], {
    type: "text/csv",
  });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement("a");
  link.href = csvURL;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
