import { api } from "../../utills/api";
import { ApiResponse } from "../common";

export const exchangeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExchange: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/exchange/get",
        body: params,
        method: "POSt",
      }),
    }),
    updateExchangeStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/exchange/update-status",
        body: params,
        method: "POST",
      }),
    }),
    addKeys: build.mutation<ApiResponse, any>({
      query: (params) => ({
        url: "/add-keys",
        body: params,
        method: "POST",
      }),
    }),
    editKeys: build.mutation<ApiResponse, any>({
      query: (params) => ({
        url: "/update-keys",
        body: params,
        method: "POST",
      }),
    }),
    deleteKeys: build.mutation<ApiResponse, any>({
      query: (params) => ({
        url: "/delete-keys",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetExchangeMutation,
  useUpdateExchangeStatusMutation,
  useAddKeysMutation,
  useEditKeysMutation,
  useDeleteKeysMutation,
} = exchangeApi;

export const {
  endpoints: {
    getExchange,
    updateExchangeStatus,
    addKeys,
    editKeys,
    deleteKeys,
  },
} = exchangeApi;
