import React, { useEffect, useState } from "react";
import { useGetPlatformsMutation } from "../../common/commonApi";
import { useLocation } from "react-router-dom";
import { usePlatforms } from "../../common/commonSlice";
import {
  Button,
  ControlledDropDown,
  ControlledInput,
  Loader,
} from "../../../ui";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  useGetStrategyMutation,
  useManualTradeMutation,
  useUploadTradeImageMutation,
} from "../strategyApi";
import { useStrategyList } from "../strategySlice";
import { responseToaster } from "../../../helperFunctions";
import ImagePicker from "../../../ui/forms/ImagePicker";
import { MdOutlineCancel } from "react-icons/md";
import { sideOptions, TypeOptions } from "../mock";
import { useKeys } from "../../exchange/exchangeSlice";
import RadioButton from "../../../ui/forms/RadioButton";
import { yupResolver } from "@hookform/resolvers/yup";

const ManualTradeModal = ({ isOpen, onClose }: any) => {
  const [getPlatforms] = useGetPlatformsMutation();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const platforms = usePlatforms();
  const {
    currentKey: { is_tab_connect, exchange_name },
  } = useKeys();
  const [getStrategy] = useGetStrategyMutation();
  const [uploadTradeImage, { isLoading: isUploadTradeLoading }] =
    useUploadTradeImageMutation();
  const strategyList = useStrategyList();
  const [image1, setImage1] = useState<any>("");
  const [imageValue, setImageValue] = useState<any>();
  const [manualTrade, { isLoading: isManualTradeLoading }] =
    useManualTradeMutation();
  const [error, setError] = useState<any>({
    transaction_id: "",
    image: "",
  });
  const [selectedType, setSelectedType] = useState<string>("1");

  const onGetPlatforms = async () => {
    try {
      await getPlatforms({
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err: any) {}
  };

  const prepareStrategyOptions = () => {
    const options = strategyList?.data?.map?.(({ _, strategy_name }: any) => {
      return { value: strategy_name, label: strategy_name };
    });
    return options || [];
  };

  const preparePlatformOptions = () => {
    try {
      let arr: any = [];
      platforms?.data?.forEach((symbol: string) => {
        arr.push({
          value: symbol,
          label: symbol,
        });
      });
      return arr;
    } catch (error) {
      console.error("Error:", error);
      return {};
    }
  };

  const onGetStrategy = async () => {
    try {
      const res = await getStrategy({
        group_name: pathnames?.[1],
      }).unwrap();
      if (res?.data?.length) {
        setValueForm1("strategy", res?.data?.[0]?.strategy_name);
      }
    } catch (err) {}
  };

  const onSubmitForm1 = async (values: any) => {
    try {
      const payload = new FormData();
      if (image1) {
        payload.append("trade_image", image1);
      }
      payload.append("strategy_name", values?.strategy_name);
      payload.append("platform", values?.platform);

      const res: any = await uploadTradeImage(payload).unwrap();
      responseToaster(res);

      // Set values for Form 2 fields after receiving the response
      if (res?.status) {
        reset1();
        setImage1("");
        setImageValue("");
        Object.entries(res?.data || {})?.forEach(([key, value]: any) => {
          return setValueForm2(key, value);
        });
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const onSubmitForm2 = async (values: any) => {
    try {
      const payload = {
        ...values,
        type: selectedType ? parseInt(selectedType) : null,
      };
      delete payload.current_price;
      const res: any = await manualTrade(payload).unwrap();
      if (res?.status) {
        onClose();
        responseToaster(res);
        reset2();
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  useEffect(() => {
    onGetPlatforms();
    onGetStrategy();
    // eslint-disable-next-line
  }, []);

  const initialValuesForm1 = {
    trade_image: null,
    strategy_name: prepareStrategyOptions()?.[0]?.value,
    platform: preparePlatformOptions()?.[0]?.value,
  };

  const schema: any = yup.object({
    strategy_name: yup.string().required("Please enter a strategy name"),
    platform: yup.string().required("Please enter a platform"),
  });

  const schema2: any = yup.object({
    symbol: yup.string().required("Please enter a symbol"),
    entry_price: yup.number().required("Please enter a entry price"),
    order_amount: yup.number().required("Please enter a order amount"),
    side: yup.string().required("Please enter a side"),
  });

  const initialValuesForm2 = {
    symbol: "",
    entry_price: null,
    stop_loss: null,
    take_profit: null,
    timeframe: "",
    from_coin: "",
    to_coin: "",
    manual_trade_uuid: "",
    strategy_name: "",
    platform: "",

    exchange_name: exchange_name || "",
    is_connect: is_tab_connect,

    order_amount: null,
    type: TypeOptions?.[0]?.value,
    side: "",
  };

  // Form 1 setup
  const {
    control: controlForm1,
    reset: reset1,
    handleSubmit: handleSubmitForm1,
    setValue: setValueForm1,
    formState: { errors: errorsForm1 },
  } = useForm<any>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      ...initialValuesForm1,
    },
  });

  // Form 2 setup
  const {
    control: controlForm2,
    reset: reset2,
    handleSubmit: handleSubmitForm2,
    setValue: setValueForm2, // Add setValue for Form 2
    watch: watch2,
    formState: { errors: errorsForm2 },
  } = useForm<any>({
    resolver: yupResolver<any>(schema2),
    defaultValues: {
      ...initialValuesForm2,
    },
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
        onClick={() => {
          onClose();
        }}
      />

      <div className="modal-container  w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12  md:w-7/12 sm:w-7/12 z-50 mx-auto ">
        <div className="modal-header">
          <h4 className="text-lg font-semibold text-primary-950">
            Manual Trade
          </h4>
          <MdOutlineCancel
            color="var(--primary-600--)"
            size={24}
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <div>
          {!watch2("manual_trade_uuid") ? (
            <form onSubmit={handleSubmitForm1(onSubmitForm1)}>
              <div className="p-6">
                <ControlledDropDown
                  name="strategy_name"
                  label="Strategy Name"
                  control={controlForm1}
                  errors={errorsForm1}
                  options={prepareStrategyOptions()}
                />
                <ControlledDropDown
                  className="py-2"
                  name="platform"
                  label="Platform"
                  control={controlForm1}
                  errors={errorsForm1}
                  options={preparePlatformOptions()}
                />
                <ImagePicker
                  image1={image1}
                  setImage={setImageValue}
                  setImage1={setImage1}
                  clearErrors={() => {
                    setError({
                      ...error,
                      image: "",
                    });
                  }}
                  image={imageValue}
                  err={error?.photo}
                  name={"image"}
                  label="Upload Image"
                  errors={errorsForm1}
                />
              </div>
              <div className="flex justify-end pt-2">
                {isUploadTradeLoading ? (
                  <div className="modal-footer w-full flex justify-center">
                    <Loader />
                  </div>
                ) : (
                  <div className="modal-footer w-full">
                    <Button type="submit">Submit</Button>
                  </div>
                )}
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmitForm2(onSubmitForm2)}>
              <div className="h-[500px] overflow-y-auto p-6">
                <ControlledInput
                  name="symbol"
                  className="mb-2"
                  placeholder="Symbol"
                  label="Symbol"
                  control={controlForm2}
                  errors={errorsForm2}
                  isShowError
                />
                <ControlledInput
                  type="number"
                  className="mb-2"
                  name="entry_price"
                  placeholder="Entry price"
                  label="Entry price"
                  control={controlForm2}
                  errors={errorsForm2}
                  isShowError
                />
                <ControlledInput
                  type="number"
                  name="stop_loss"
                  className="mb-2"
                  placeholder="Stop loss"
                  label="Stop loss"
                  control={controlForm2}
                  errors={errorsForm2}
                />
                <ControlledInput
                  type="number"
                  name="take_profit"
                  className="mb-2"
                  placeholder="Take profit"
                  label="Take profit"
                  control={controlForm2}
                  errors={errorsForm2}
                />
                <ControlledInput
                  name="timeframe"
                  className="mb-2"
                  placeholder="Timeframe"
                  label="Timeframe"
                  control={controlForm2}
                  errors={errorsForm2}
                />
                <ControlledInput
                  name="strategy_name"
                  className="mb-2"
                  placeholder="Strategy name"
                  label="Strategy name"
                  control={controlForm2}
                  errors={errorsForm2}
                />
                <ControlledInput
                  name="platform"
                  className="mb-2"
                  placeholder="Platform"
                  label="Platform"
                  control={controlForm2}
                  errors={errorsForm2}
                />
                <ControlledInput
                  type="number"
                  name="order_amount"
                  className="mb-2"
                  placeholder="Order amount"
                  label="Order Amount"
                  control={controlForm2}
                  errors={errorsForm2}
                  isShowError
                />
                {/* change in redio button */}
                <label className="pb-1 text-sm text-primary-950">Type</label>
                <div className="flex gap-2">
                  {TypeOptions?.map((type: any, index: any) => {
                    return (
                      <div key={index}>
                        <RadioButton
                          label={type?.label}
                          value={type?.value}
                          labelClassName="text-sm"
                          checked={parseInt(selectedType)  == type?.value}
                          onChange={(option: any) => {
                            setSelectedType(option?.target?.value);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <ControlledDropDown
                  name="side"
                  className="mb-2"
                  label="Side"
                  options={sideOptions}
                  control={controlForm2}
                  errors={errorsForm2}
                />
              </div>
              <div className="flex justify-end pt-2">
                {isManualTradeLoading ? (
                  <div className="modal-footer w-full flex justify-center">
                    <Loader />
                  </div>
                ) : (
                  <div className="modal-footer w-full">
                    <Button type="submit">Submit</Button>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualTradeModal;
