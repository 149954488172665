import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { authApi } from "./authApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../utills/hooks";

interface AuthState {
  user: any;
}

const initialState: AuthState = {
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.user = payload
      }
    );
    builder.addMatcher(
      authApi.endpoints.getUserPermissions.matchFulfilled,
      (state, { payload }) => {
        state.user.data.permissions = payload?.data?.permissions
      }
    );
  },
});

export default authSlice.reducer;
export const { clearAuth } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const useUser = () => {
  const user = useSelector(selectUser);
  return useMemo(() => user, [user]);
};

export const useRole = (page = "") => {
  const user = useAppSelector(selectUser);

  const role = useMemo(() => {
    return page ? user?.data?.permissions?.[page] || [] : user?.data?.permissions || [];
  }, [user, page]);

  return role;
};
