import { useEffect, useState } from "react";

// UI IMPORT
import { Fragment } from "react/jsx-runtime";
import {
  Button,
  ControlledDatePicker,
  ControlledDropDown,
  getStatusTypes,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import { useGetConvertHistoryMutation } from "./convertHistoryApi";
import { useConvertHistoryData } from "./convertHistorySlice";
import { FILTER, transactionStatusEnum } from "../../constant";
import {
  dateFormatter,
  onFixed,
  prepareLastDate,
  timestampToDate,
} from "../../helperFunctions";
import { useCoins } from "../common/commonSlice";
import { useForm } from "react-hook-form";
import { useGetCoinsMutation } from "../common/commonApi";
import { prepapreOptionsForDate } from "../common/mock";
import { useKeys } from "../exchange/exchangeSlice";
import { useLocation } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";

const ConvertHistory = () => {
  const [getConvertHistory, { isLoading }] = useGetConvertHistoryMutation();
  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();
  const coins = useCoins();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const convertHistory = useConvertHistoryData();
  const [tableData, setTableData] = useState<any[]>([]);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [getCoins] = useGetCoinsMutation();
  const [filter, setFilter] = useState<any>({
    ...FILTER,
  });

  const columns: any[] = [
    {
      title: "Time",
      name: "created_at",
      prepareValue: (data: any) => `${dateFormatter(data?.created_at, "")}`,
      Cell: (data: any) => (
        <>
          <span>{dateFormatter(data?.created_at, "")}</span>
        </>
      ),
    },
    {
      title: "Pair",
      name: "pair",
      prepareValue: (data: any) =>
        `${data?.pair?.toString()?.split("/")?.join("")}`,
      Cell: (data: any) => (
        <>
          <span>{data?.pair?.toString()?.split("/")?.join("")}</span>
        </>
      ),
    },
    {
      title: "From",
      name: "from",
      prepareValue: (data: any) =>
        `${data?.from_amount + " " + data?.pair?.toString()?.split("/")?.[0]}`,
      Cell: (data: any) => (
        <>
          <span>
            {data?.from_amount + " " + data?.pair?.toString()?.split("/")?.[0]}
          </span>
        </>
      ),
    },
    {
      title: "To",
      name: "to",
      prepareValue: (data: any) =>
        `${data?.to_amount + " " + data?.pair?.toString()?.split("/")?.[1]}`,
      Cell: (data: any) => (
        <>
          <span>
            {data?.to_amount + " " + data?.pair?.toString()?.split("/")?.[1]}
          </span>
        </>
      ),
    },
    {
      title: "Price",
      prepareValue: (data: any) =>
        `1 ${data?.pair?.toString()?.split("/")?.[0]} = ${onFixed(
          1 / data?.exchange_rate
        )} ${data?.pair?.toString()?.split("/")?.[1]} | 1 ${
          data?.pair?.toString()?.split("/")?.[1]
        } = ${onFixed(data?.exchange_rate)} ${
          data?.pair?.toString()?.split("/")?.[0]
        }`,
      Cell: (data: any) => (
        <>
          <p>
            1 {data?.pair?.toString()?.split("/")?.[0]} ={" "}
            {onFixed(1 / data?.exchange_rate)}{" "}
            {data?.pair?.toString()?.split("/")?.[1]}
          </p>
          <p>
            1 {data?.pair?.toString()?.split("/")?.[1]} ={" "}
            {onFixed(data?.exchange_rate)}{" "}
            {data?.pair?.toString()?.split("/")?.[0]}
          </p>
        </>
      ),
    },
    {
      title: "Status",
      prepareValue: ({ status }: { status: "0" | "1" | "2" }) =>
        `${transactionStatusEnum?.[status]}`,
      Cell: ({ status }: { status: "0" | "1" | "2" }) => (
        <>
          <div
            className={` ${getStatusTypes(
              transactionStatusEnum?.[status] as
                | "Pending"
                | "Confirmed"
                | "Failed"
            )}`}
          >
            {transactionStatusEnum?.[status]}
          </div>
        </>
      ),
    },
  ];

  const binanceColumns: any[] = [
    {
      title: "Trade Date",
      name: "created_at",
      prepareValue: (data: any) => `${timestampToDate(data?.timestamp)}`,
      Cell: (data: any) => (
        <>
          <span>{timestampToDate(data?.timestamp)}</span>
        </>
      ),
    },
    {
      title: "Wallet",
      name: "wallet",
      prepareValue: (data: any) => `Spot`,
      Cell: (data: any) => (
        <>
          <span>Spot</span>
        </>
      ),
    },
    {
      title: "Pair",
      name: "pair",
      prepareValue: (data: any) =>
        `${data?.toCurrency} | ${data?.fromCurrency}`,
      Cell: (data: any) => (
        <>
          <span>
            {data?.toCurrency}
            {data?.fromCurrency}
          </span>
        </>
      ),
    },
    {
      title: "Type",
      prepareValue: (data: any) => `${data?.info?.orderType}`,
      Cell: (data: any) => (
        <>
          <span>{data?.info?.orderType}</span>
        </>
      ),
    },
    {
      title: "From",
      prepareValue: (data: any) =>
        `${onFixed(data?.info?.fromAmount) + " " + data?.info?.fromAsset}`,
      Cell: (data: any) => (
        <>
          <span>
            {onFixed(data?.info?.fromAmount) + " " + data?.info?.fromAsset}
          </span>
        </>
      ),
    },
    {
      title: "To",
      prepareValue: (data: any) =>
        `${onFixed(data?.info?.toAmount) + " " + data?.info?.toAsset}`,
      Cell: (data: any) => (
        <>
          <span>
            {onFixed(data?.info?.toAmount) + " " + data?.info?.toAsset}
          </span>
        </>
      ),
    },
    {
      title: "Price",
      prepareValue: (data: any) =>
        `1 ${data?.fromCurrency} = ${onFixed(data?.info?.ratio)} ${
          data?.toCurrency
        } | 1 ${data?.toCurrency} = ${onFixed(data?.info?.inverseRatio)} ${
          data?.fromCurrency
        }`,
      Cell: (data: any) => (
        <>
          <p>
            1 {data?.fromCurrency} = {onFixed(data?.info?.ratio)}{" "}
            {data?.toCurrency}
          </p>
          <p>
            1 {data?.toCurrency} = {onFixed(data?.info?.inverseRatio)}{" "}
            {data?.fromCurrency}
          </p>
        </>
      ),
    },
    {
      title: "Status",
      prepareValue: (data: any) => `${data?.info?.orderStatus}`,
      Cell: (data: any) => {
        return (
          <>
            <div className={`${getStatusTypes(data?.info?.orderStatus)}`}>
              {data?.info?.orderStatus}
            </div>
          </>
        );
      },
    },
  ];
  const getConvertHistoryFn = async () => {
    try {
      const payload: any = {
        ...filter,
        is_connect: is_connect,
        exchange_name: exchange_name,
      };
      delete payload["isFilter"];
      await getConvertHistory({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err) {}
  };

  const prepareExchangeConvertHistory = () => {
    if ("bybit" === exchange_name) {
      const arr: any = [];
      convertHistory?.data?.exchangeData?.result?.orderBody?.forEach(
        (item: any) => {
          arr.push({
            timestamp: item?.createdTime,
            toCurrency: item?.toCoin,
            fromCurrency: item?.fromCoin,
            fromAmount: item?.fromAmount,
            toAmount: item?.toAmount,
            info: {
              orderType: "Market",
              fromAsset: item?.fromCoin,
              toAsset: item?.toCoin,
              fromAmount: item?.fromAmount,
              toAmount: item?.toAmount,
              orderStatus: "Successful",
              ratio: item?.exchangeRate,
              inverseRatio: onFixed(1 / +item?.exchangeRate),
            },
          });
        }
      );
      return arr;
    }
    return convertHistory?.data?.exchangeData;
  };

  useEffect(() => {
    setTableData(
      is_tab_connect
        ? prepareExchangeConvertHistory()
        : convertHistory?.data?.aiData?.transfer_history
    );
    // eslint-disable-next-line
  }, [convertHistory, is_tab_connect]);

  const { control, getValues, setValue, watch, reset }: any = useForm({
    defaultValues: {
      date: 1,
    },
  });

  useEffect(() => {
    if (filter?.start_date) {
      getConvertHistoryFn();
    }
    // eslint-disable-next-line
  }, [filter]);

  const prepareToCoinOptions = () => {
    const coinOptions: any = coins?.data
      ?.filter((coin: any) => coin?.name !== null)
      ?.slice()
      ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
      ?.map((val: any) => ({
        value: val?.code,
        label: val?.symbol,
      }));
    return coinOptions || [];
  };

  useEffect(() => {
    const dateValue = getValues()?.date;
    const codeValue = getValues()?.code;

    let dates = {
      start_date: dateFormatter(new Date()),
      end_date: dateFormatter(new Date(), "end"),
    };

    if (watch("date")?.toString()?.length || watch("code")?.length) {
      if (dateValue === 1) {
        dates = {
          start_date: dateFormatter(prepareLastDate(7)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      if (dateValue === 2) {
        dates = {
          start_date: dateFormatter(prepareLastDate(31)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      if (dateValue === 3) {
        dates = {
          start_date: dateFormatter(prepareLastDate(31 * 3)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      /*    if (dateValue === 4) {
        setOpenDateModal(true);
      } */

      if (dateValue !== 4) {
        setFilter({
          ...filter,
          start_date: dateFormatter(dates?.start_date || Date.now(), "start"),
          end_date: dateFormatter(dates?.end_date || Date.now(), "end"),
          since: new Date(dates?.start_date || Date.now()).getTime(),
          until: new Date(dates?.end_date || Date.now()).getTime(),
          code: codeValue,
        });
      }
    }
    // eslint-disable-next-line
  }, [watch("date"), watch("code")]);

  useEffect(() => {
    const getCoinsFn = async () => {
      await getCoins({
        group_name: pathnames?.[1],
      });
    };
    getCoinsFn();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="card_rounded">
        <div className="pb-2">
          <div className="flex gap-2 items-center">
            <>
              <ControlledDropDown
                name="date"
                width={200}
                options={prepapreOptionsForDate()}
                control={control}
                onChange={(e: any) => {
                  setValue("date", e);
                  if (e === 4) {
                    setOpenDateModal(true);
                  }
                }}
              />
              <ControlledDropDown
                name="code"
                width={200}
                placeholder="Select Coin"
                options={prepareToCoinOptions()}
                control={control}
              />
              <Button
                className="!h-8 !text-sm"
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </Button>
            </>
          </div>
        </div>
        <div className="App tableborder">
          <Table
            fileName="Convert_history"
            columns={is_tab_connect === 1 ? binanceColumns : columns}
            isLoading={!!isLoading}
            data={tableData || []}
            tableDataCount={tableData?.length}
            setData={setTableData}
            isExpendable={false}
            className="!overflow-scroll order_table"
            count={
              is_tab_connect
                ? convertHistory?.data?.exchangeData?.length
                : convertHistory?.data?.aiData?.transfer_history?.length
            }
            pagination={filter}
            minHeight={300}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
          >
            {(tableData || [])?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    className="!align-middle"
                    columns={is_tab_connect === 1 ? binanceColumns : columns}
                    item={item}
                    isExpendable={false}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      </div>
      {openDateModal ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
            onClick={() => {
              setOpenDateModal(false);
              // setValue("date", 2);
              setValue("start_date", null);
              setValue("end_date", null);
            }}
          />

          <div className="modal-container bg-secondary-50 w-fit mx-auto shadow-lg z-50">
            <div className="modal-header">
              <h4 className="text-lg font-semibold text-primary-950">
                Select a Time Range
              </h4>
              <MdOutlineCancel
                color="var(--primary-600--)"
                size={24}
                onClick={() => {
                  setOpenDateModal(false);
                  // setValue("date", 2);
                  setValue("start_date", null);
                  setValue("end_date", null);
                }}
              />
            </div>
            <div className="modal-content">
              <div className="flex gap-4 items-end justify-center my-5">
                <ControlledDatePicker
                  name="start_date"
                  selectesStart
                  control={control}
                  startDate={watch("start_date")}
                  endDate={watch("end_date")}
                  label="Start Date"
                  className="items-start text-primary-950"
                  inputClassName="h-8 text-xs"
                />
                <span className="text-primary-950">to</span>
                <ControlledDatePicker
                  name="end_date"
                  selectsEnd
                  startDate={watch("start_date")}
                  endDate={watch("end_date")}
                  control={control}
                  label="End Date"
                  className="items-start text-primary-950"
                  inputClassName="h-8 text-xs"
                />
              </div>
            </div>
            <div className="modal-footer w-full">
              <Button
                onClick={() => {
                  setFilter({
                    ...filter,
                    start_date: dateFormatter(
                      dateFormatter(watch("start_date")) || Date.now(),
                      "start"
                    ),
                    end_date: dateFormatter(
                      dateFormatter(watch("end_date")) || Date.now(),
                      "end"
                    ),
                    since: new Date(
                      dateFormatter(watch("start_date")) || Date.now()
                    ).getTime(),
                    until: new Date(
                      dateFormatter(watch("end_date")) || Date.now()
                    ).getTime(),
                  });
                  setOpenDateModal(false);
                  setValue("start_date", null);
                  setValue("end_date", null);
                }}
                className="w-full"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ConvertHistory;
