import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { onFixed } from "../../helperFunctions";
import { FaSort } from "react-icons/fa";

const MarketList = ({ list }: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const markets = (list || [])?.filter((item: any) =>
    item?.symbol?.toUpperCase()?.includes(searchTerm.toUpperCase())
  );

  const sortItems = (a: any, b: any) => {
    switch (filter) {
      case 1:
        return a.symbol.localeCompare(b.symbol);
      case 2:
        return b.symbol.localeCompare(a.symbol);
      case 3:
        return b.price - a.price;
      case 4:
        return a.price - b.price;
      case 5:
        return b.change - a.change;
      case 6:
        return a.change - b.change;
      default:
        return 0;
    }
  };

  return (
    <div>
      <div className="mb-3 flex items-center">
        <label htmlFor="simple-search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              aria-hidden="true"
              className="h-4 w-4 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            id="simple-search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
            className="block w-full rounded-sm outline-none border border-gray-300 bg-transparent p-1 pl-10 text-sm text-primary-950"
            placeholder="Search"
          />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>
              <button
                className={`text-primary-950 ${filter === 1 ? "text-warning" : ""}`}
                onClick={() => setFilter(filter === 1 ? 2 : 1)}
              >
                <div className="flex items-center gap-1 flex-nowrap">
                  <span>Market</span>
                  <FaSort />
                </div>
              </button>
            </td>
            <td className="text-center">
              <button
                className={`text-nowrap text-primary-950 ${
                  filter === 5 ? "text-warning" : ""
                }`}
                onClick={() => setFilter(filter === 5 ? 6 : 5)}
              >
                <div className="flex items-center gap-1 flex-nowrap">
                  <span>Change %</span>
                  <FaSort />
                </div>
              </button>
            </td>
            <td className="text-end">
              <button
                className={`text-primary-950 ${filter === 3 ? "text-warning" : ""}`}
                onClick={() => setFilter(filter === 3 ? 4 : 3)}
              >
                <div className="flex items-center gap-1 flex-nowrap">
                  <span>Price</span>
                  <FaSort />
                </div>
              </button>
            </td>
          </tr>
        </thead>
        <tbody>
          {markets.sort(sortItems).map((item: any) => (
            <tr key={item.symbol} className="align-middle">
              <td className="py-[4px] max-w-2 ">
                <div className="flex items-center gap-1">
                  {/* <button
                    type="button"
                    onClick={() => {
                    }}
                  >
                    <Icon name="StarIcon" />
                  </button> */}
                  <span
                    className="text-dark text-xs cursor-pointer"
                    onClick={() => {
                      const isFutureTrade =
                        location.pathname.includes("future-trade");
                      navigate(
                        `/${isFutureTrade ? "future-trade" : "spot-trade"}/${
                          item.currency || item.base
                        }/${item.pair || item.quote}`
                      );
                    }}
                  >
                    {item.currency || item.base}/{item.pair || item.quote}
                  </span>
                </div>
              </td>
              <td className="py-[4px] text-center">
                <span
                  className={`text-xs ${
                    0 <= item.change ? "text-primary-green" : "text-primary-red"
                  }`}
                >
                  {onFixed(item.change, 3) || 0}
                </span>
              </td>
              <td className="py-[4px] max-w-16 text-end">
                <span className={`text-end text-xs ${item.class}`}>
                  {onFixed(item.price) || 0}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarketList;
