import { useLayoutEffect, useState } from "react";

// UI IMPORT
import { Loader } from "./ui";

// PROJECT IMPORT
import { setFontFamily, setStyles } from "./helperFunctions";
import { AllRoutes } from "./components/routes";
import { DEFAULT_FONT_FAMILY } from "./constant";
import { useLogout } from "./hooks";
import "./App.css";

// THIRD - PARTY IMPORT
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import WebSocketProvider from "./context/WebSocketProvider";

const color: any = process.env.REACT_APP_COLOR;
const fontFamily = process.env.REACT_APP_FONT_FAMILY || DEFAULT_FONT_FAMILY;

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    if (color) {
      setIsLoading(true);
      setStyles("--chatlook-primary--", color);
      setStyles("--ev-primary--", color);
    }

    if (fontFamily) {
      setFontFamily(fontFamily);
    }

    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useLogout();

  // useEffect(() => {
  //   const body = document.body;
  //   body.classList.toggle("dark-theme", isThemeOpen);
  //   body.classList.toggle("light-theme", !isThemeOpen);
  // eslint-disable-next-line
  // }, [isThemeOpen]);

  if (isLoading) {
    return (
      <>
        <div className="w-[100vw] h-[100vh] z-[9999] flex items-center justify-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <div className={`App dark-theme min-h-[100vh]`}>
      <Helmet>
        {/* <link rel="icon" type="image/svg+xml" href={prepareBlobUrl(favicon)} /> */}
      </Helmet>
      <WebSocketProvider>
        <AllRoutes />
      </WebSocketProvider>
      <Toaster />
    </div>
  );
}

export default App;
