import { Fragment, useEffect, useState } from "react";
import {
  Button,
  ControlledDatePicker,
  ControlledDropDown,
  ControlledInput,
  Table,
  TableRow,
} from "../../ui";
import { useDeleteUserMutation, useGetUsersMutation } from "./usersApi";
import { useUsers } from "./usersSlice";
import { DROPDOWN_SEARCH_VALUE, FILTER } from "../../constant";
import { useForm } from "react-hook-form";
import { dateFormatter1, responseToaster } from "../../helperFunctions";
import DeleteModal from "../common/DeleteModal";
import UserModal from "./UserModal";
import ResetPasswordModal from "./ResetPasswordModal";
import { useLocation } from "react-router-dom";
import { GoTrash } from "react-icons/go";
import { CiEdit } from "react-icons/ci";

const searchDataOptions = [
  { value: "username", label: "Name" },
  { value: "email", label: "Email" },
];

const initialValues = {
  filter: DROPDOWN_SEARCH_VALUE,
  search: "",
  date: [null, null],
};

const Users = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>({ ...FILTER, isToast: false });
  const [deleteId, setDeleteId] = useState<string>("");
  const [isOpenUserModal, setIsOpenUserModal] = useState<any>(false);
  const [userResetPassword, setUserResetPassword] = useState<any>("");

  const [getUsers, { isLoading: isUsersLoading }] = useGetUsersMutation();
  const [deleteUser, { isLoading: isDeleteLoading }] = useDeleteUserMutation();
  const users = useUsers();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const { control, getValues, handleSubmit, reset }: any = useForm({
    defaultValues: initialValues,
  });

  const columns = [
    {
      title: "Date",
      name: "created_at",
    },
    {
      title: "Name",
      name: "username",
    },
    {
      title: "Email",
      name: "email",
    },
    {
      title: "Role",
      name: "role",
    },
    {
      title: "Action",
      exportHide: ["ALL"],
      Cell: (data: any) => (
        <div className="flex gap-2">
          <Button
            className="!h-8 !w-8 !p-0"
            onClick={() => {
              setIsOpenUserModal(data);
            }}
          >
            {/* Edit */}
            <CiEdit size={24} />
          </Button>
          <Button
            className="!h-8 !w-8 !p-0"
            onClick={() => {
              setDeleteId(data?.user_uuid);
            }}
          >
            {/* Delete */}
            <GoTrash size={20} />
          </Button>
          <Button
            color="primary"
            onClick={() => setUserResetPassword(data?.user_uuid)}
          >
            Reset Password
          </Button>
        </div>
      ),
    },
  ];

  const onGetUsers = async () => {
    try {
      const {
        isFilter,
        page,
        limit,
        filter: dropdown,
        search,
        date,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
            [dropdown]: search,
            start_date: dateFormatter1(date?.[0], "start"),
            end_date: dateFormatter1(date?.[1], "end", date?.[0]),
            ...rest,
            page,
            limit,
          }
        : {
            page,
            limit,
          };
      const res = await getUsers({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
      if (filter?.isToast) {
        responseToaster(res);
      }
    } catch (error) {}
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isToast: true,
      isFilter: true,
    });
  };

  const onDelete = async () => {
    try {
      const res = await deleteUser({
        user_uuid: deleteId,
        group_name: pathnames?.[1],
      }).unwrap();
      responseToaster(res);
      if (res) {
        onGetUsers();
        setDeleteId("");
      }
    } catch (err) {}
  };

  useEffect(() => {
    setTableData(users?.data?.users);
    // eslint-disable-next-line
  }, [users]);

  useEffect(() => {
    onGetUsers();
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <div className="card_rounded mb-4">
        <div className="flex justify-end">
          <Button
            className="!h-10 !text-sm"
            color="primary"
            onClick={() => {
              setIsOpenUserModal(true);
            }}
          >
            Add User
          </Button>
        </div>
      </div>
      <div className={`card_rounded mb-4`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 items-end">
            <ControlledDropDown
              name="filter"
              label="Filter"
              width={100}
              isDefaultOption={DROPDOWN_SEARCH_VALUE}
              options={searchDataOptions}
              control={control}
            />
            <ControlledInput
              placeholder="Enter Search Value"
              name="search"
              label="Search"
              control={control}
            />
            <ControlledDatePicker
              inputClassName="!w-fit"
              name="date"
              placeholder="Select Date"
              label="Date"
              maxDate={new Date()}
              selectsRange
              control={control}
            />
            <Button className="!text-sm" type="submit" color="primary">
              Apply
            </Button>
            <Button
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isToast: false });
              }}
            >
              Reset
            </Button>
          </div>
        </form>
      </div>
      <div className={`card_rounded mb-4`}>
        <Table
          fileName="Members"
          isLoading={isUsersLoading}
          columns={columns}
          data={users?.data?.users || []}
          isExpendable={false}
          setData={setTableData}
          className="!overflow-scroll order_table"
          count={users?.data?.total}
          tableDataCount={tableData?.length}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
              isToast: false,
            });
          }}
        >
          {tableData?.map?.((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  className="min-h-[350px]"
                  columns={columns}
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
      <DeleteModal
        text="Are you sure you want to delete this user ?"
        isOpen={!!deleteId}
        onDoneClick={() => onDelete()}
        onHide={() => setDeleteId("")}
        isButtonLoading={isDeleteLoading}
      />
      {!!isOpenUserModal ? (
        <>
          <UserModal
            isOpen={isOpenUserModal}
            onGet={() => setFilter({ ...filter })}
            onHide={() => setIsOpenUserModal(false)}
          />
        </>
      ) : null}
      {!!userResetPassword ? (
        <ResetPasswordModal
          id={userResetPassword}
          onHide={() => setUserResetPassword(false)}
        />
      ) : null}
    </>
  );
};

export default Users;
