import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { responseToaster, showToaster } from "../../helperFunctions";
import { useResetPasswordUserMutation } from "./usersApi";
import { Button } from "../../ui";
import { useLocation } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";

const ResetPasswordModal = ({ id, onHide }: any) => {
  const [isCopy, setIsCopy] = useState<any>(false);
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const [resetPasswordUser, { isLoading }] = useResetPasswordUserMutation();

  const ResetPassword = async () => {
    try {
      let res = await resetPasswordUser({
        user_uuid: id,
        group_name: pathnames?.[1],
      }).unwrap();
      responseToaster(res);
      if (res) {
        setIsCopy(res);
      }
    } catch (err) {}
  };

  const onClose = () => {
    onHide();
    setIsCopy(false);
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
          onClick={() => {
            onHide();
          }}
        />

        <div className="modal-container w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-7/12 sm:w-7/12 mx-auto z-50 overflow-y-auto">
          <div>
            {isCopy?.data?.password ? (
              <>
                <div className="modal-header">
                  <h4 className="text-lg font-semibold text-primary-950">
                    Reset Password !
                  </h4>
                  <MdOutlineCancel
                    color="var(--primary-600--)"
                    size={24}
                    onClick={() => {
                      onHide();
                    }}
                  />
                </div>
                <div className="modal-content !py-12 text-center ">
                  <div>
                    <span>Email :- </span>
                    <span className="bold">{isCopy?.data?.email}</span>
                  </div>
                  <div>
                    <span>Password :- </span>
                    <span className="bold">{isCopy?.data?.password}</span>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="flex justify-end button-space">
                    <CopyToClipboard
                      text={`${isCopy?.data?.email} ${isCopy?.data?.password}`}
                    >
                      <Button
                        className="submitbutton"
                        onClick={() => {
                          onHide();
                          showToaster("Copied");
                        }}
                      >
                        Copy & Close
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="modal-header">
                  <strong className="text-primary-950">Reset Password !</strong>
                  <MdOutlineCancel
                    color="var(--primary-600--)"
                    size={24}
                    onClick={() => {
                      onClose();
                    }}
                  />
                </div>
                <div className="modal-content !py-12 text-center ">
                  <p> Are you sure you want to reset user password ?</p>
                </div>
                <div className="modal-footer w-full">
                  {/* <Button className="graybutton" onClick={() => onClose()}>
                Cancel
              </Button> */}
                  <Button
                    onClick={ResetPassword}
                    className="submitbutton"
                    isLoading={isLoading}
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordModal;
