import React, { memo } from "react";
import { IconProps } from ".";

export interface EmailIconProps extends IconProps {
}
const EmailIcon = (props: EmailIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M17.9028 9.1239L13.4596 12.7369C12.6201 13.4029 11.4389 13.4029 10.5994 12.7369L6.11865 9.1239"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[0] || ""}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9089 21.2727C19.9502 21.2811 22 18.7823 22 15.7111V8.84272C22 5.77153 19.9502 3.27271 16.9089 3.27271H7.09114C4.04979 3.27271 2 5.77153 2 8.84272V15.7111C2 18.7823 4.04979 21.2811 7.09114 21.2727H16.9089Z"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${pathClassNames?.[1] || ""}`}
      />
    </svg>
  );
};

const MemoEmailIcon = memo(EmailIcon);
export default MemoEmailIcon;
