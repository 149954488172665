interface IStrategyStatistics {
  totalOrders?: {
    total: number;
    long: number;
    short: number;
    title?: string;
  };
  winRate?: {
    total: number;
    win: number;
    loss: number;
  };
  longPnL?: {
    usdPnL: number;
    winRate: number;
  };
  shortPnL?: {
    usdPnL: number;
    winRate: number;
  };
}

const StrategyStatistics = ({
  totalOrders,
  winRate,
  longPnL,
  shortPnL,
}: IStrategyStatistics) => {
  return (
    <>
      {!totalOrders && !winRate && !longPnL && !shortPnL ? null : (
        <>
          <div className="stat text-primary-950 mb-2">
            <div className="grid grid-cols-4 gap-2">
              <div className="position_card">
                <div className="flex flex-col gap-1 ">
                  {/* <Icon name="stategyOrder" /> */}
                  <h4>{totalOrders?.title || "Total Orders"}</h4>
                  <h6>
                    {totalOrders?.long} Long / {totalOrders?.short} Short
                  </h6>
                </div>
                <p>{totalOrders?.total}</p>
              </div>
              {!!winRate ? (
                <>
                  <div className="position_card">
                    <div className="flex flex-col gap-1 ">
                      {/* <Icon name="winRate" /> */}
                      <h4>Win Rate of Orders</h4>
                      <h6>
                        {winRate?.win} Win / {winRate?.loss} Loss
                      </h6>
                    </div>
                    <p>{winRate?.total}%</p>
                  </div>
                </>
              ) : null}
              {!!longPnL ? (
                <>
                  <div className="position_card">
                    <div className="flex flex-col gap-1">
                      {/* <Icon name="longOrder" /> */}
                      <h4>P&L of Long Orders</h4>
                      <h6>Win Rate: {longPnL?.winRate}%</h6>
                    </div>
                    <p>
                      {`${longPnL?.usdPnL >= 0 ? "+" : "-"}` +
                        Math.abs(longPnL?.usdPnL)}{" "}
                      <span>USD</span>
                    </p>
                  </div>
                </>
              ) : null}
              {!!shortPnL ? (
                <>
                  <div className="position_card">
                    <div className="flex flex-col gap-1">
                      {/* <Icon name="shortOrder" /> */}
                      <h4>P&L of Short Orders</h4>
                      <h6>Win Rate: {shortPnL?.winRate}%</h6>
                    </div>
                    <p>
                      {`${shortPnL?.usdPnL >= 0 ? "+" : "-"}` +
                        Math.abs(shortPnL?.usdPnL)}{" "}
                      <span>USD</span>
                    </p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StrategyStatistics;
