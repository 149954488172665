import { PropsWithChildren, useRef, useState } from "react";
import { ButtonTypes, ButtonVariants, getButtonVariants } from "../theme";
import Loader from "./Loader";
import { CSVLink } from "react-csv";
interface ButtonProps extends PropsWithChildren<{}> {
  className?: string;
  type?: ButtonTypes;
  variant?: ButtonVariants;
  href?: any;
  download?: string;
  target?: string;
  disabled?: boolean;
  isLoading?: boolean;
  color?: string;
  style?: any;
  onClick?: (e?: any) => void;
  onFetchCSV?: (e?: any) => any;
}

// const initialCsvData = {
//   headers: [],
//   data: [],
//   filename: "",
// };

const Button = (props: ButtonProps) => {
  const {
    className,
    type = "button",
    download,
    variant = "Primary",
    href,
    disabled,
    isLoading = false,
    children,
    color,
    onClick,
    onFetchCSV,
    ...rest
  } = props;

  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);
  const ref = useRef<any>();

  const buttonClassName = getButtonVariants(variant);

  const prepareIsDisabled = () => disabled || isLoading || isLocalLoading;

  return (
    <>
      {href ? (
        <a
          href={href}
          className={`py-[10px] px-[18px] rounded-sm h-8 flex items-center justify-center whitespace-nowrap ${buttonClassName} ${className} ${
            !disabled ? "" : "px-4"
          }`}
          onClick={onClick}
          {...rest}
        >
          {children}
        </a>
      ) : (
        <>
          <button
            type={type}
            color="#2B80F7"
            disabled={prepareIsDisabled()}
            className={`py-[10px] px-[18px] rounded-sm h-8 flex items-center justify-center whitespace-nowrap ${buttonClassName} ${className} ${
              disabled ? "bg-opacity-60" : ""
            } ${!disabled ? "" : "disabled-btn"}`}
            onClick={async (e) => {
              try {
                if (!Object.hasOwn(props, "isLoading")) {
                  setIsLocalLoading(true);
                }
                await onClick?.(e);
                if (onFetchCSV) {
                  const res = await onFetchCSV?.();

                  ref.current.csvData = res;

                  setTimeout(() => {
                    ref?.current?.link?.click();
                  }, 500);
                }

                if (!Object.hasOwn(props, "isLoading")) {
                  setIsLocalLoading(false);
                }
              } catch (err) {
                if (!Object.hasOwn(props, "isLoading")) {
                  setIsLocalLoading(false);
                }
              }
            }}
            {...rest}
          >
            {isLoading || isLocalLoading ? (
              <Loader loaderClassName="create-edit-bonus" fill="fill-white" />
            ) : (
              children
            )}
          </button>
          {onFetchCSV ? (
            <CSVLink
              ref={ref}
              filename={ref?.current?.csvData?.filename || ""}
              headers={ref?.current?.csvData?.headers || []}
              data={ref?.current?.csvData?.data || []}
            ></CSVLink>
          ) : null}
        </>
      )}
    </>
  );
};

export default Button;
