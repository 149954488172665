export const sideOptions = [
  { value: "buy", label: "Buy" },
  { value: "sell", label: "Sell" },
];

export const TypeOptions = [
  {
      value: 1,
      label: 'Market'
  },
  {
      value: 2,
      label: 'Limit'
  }
]


