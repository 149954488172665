// PROJECT IMPORT
import authSlice from "../features/auth/authSlice";
import commonSlice from "../features/common/commonSlice";
import dashboardSlice from "../features/dashboard/dashboardSlice";
import walletSlice from "../features/wallet/walletSlice";

import { api } from "./api";
import { logout, showToaster } from "../helperFunctions";

// THIRD - PARTY IMPORT
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import {
  Middleware,
  MiddlewareAPI,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import tradeSlice from "../features/trade/tradeSlice";
import accountStatementSlice from "../features/accountStatement/accountStatementSlice";
import walletHistorySlice from "../features/walletHistory/walletHistorySlice";
import convertHistorySlice from "../features/convertHistory/convertHistorySlice";
import strategySlice from "../features/strategy/strategySlice";
import exchangeSlice from "../features/exchange/exchangeSlice";
import usersSlice from "../features/users/usersSlice";
import rolesSlice from "../features/roles/rolesSlice";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  common: commonSlice,
  auth: authSlice,
  user: dashboardSlice,
  wallet: walletSlice,
  trade: tradeSlice,
  accountStatement: accountStatementSlice,
  walletHistory: walletHistorySlice,
  convertHistory: convertHistorySlice,
  strategy: strategySlice,
  exchange: exchangeSlice,
  users: usersSlice,
  roles: rolesSlice,
  dashboard: dashboardSlice,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["api"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const middleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    const payload = action?.payload;

    const { dispatch } = api;

    if (payload?.data?.status === false) {
      showToaster(payload?.data?.message || "", "Error");

      if (payload?.data?.message === "Unauthorized") {
        logout(dispatch);
      }
    } else if (payload?.error) {
      showToaster("", "Error");
    }
    return next(action);
  };
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(api.middleware)
      .concat(middleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
