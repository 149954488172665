import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ListItem from "./ListItem";
import useWebSocketContext from "../../context/useWebSocketContext";
import { onFixed } from "../../helperFunctions";

const Orderbook = () => {
  const { base, quote } = useParams();
  const baseUppercase = base?.toUpperCase();
  const quoteUppercase = quote?.toUpperCase();
  const { orderBook, setBaseQuote } = useWebSocketContext();

  const [bestAsk, setBestAsk] = useState<any>();

  const LIMIT = 12;

  const [state, setState] = useState<any>({
    limit: {
      asks: LIMIT,
      bids: LIMIT,
    },
    showMoreAsks: false,
    showMoreBids: false,
    sideLength: LIMIT,
    refreshRate: 500,
    best_ask: "",
    best_ask_icon: "",
    lastUpdated: "",
    activeItem: "pills-graph",
    data: {},
    asks: [],
    bids: [],
    isLoading: true,
    bestAsker: 0,
  });

  const updateOrderbook = async () => {
    const now = Date.now();
    const { a, b } = orderBook || { a: [], b: [], lastUpdateId: null };

    const asks = a.filter(([_, quantity]: any) => +quantity !== 0);
    const bids = b.filter(([_, quantity]: any) => +quantity !== 0);

    let combinedAsks = [...asks, ...(state.asks || [])].filter(
      (value, index, self) => index === self.findIndex((v) => v[0] === value[0])
    );
    let combinedBids = [...bids, ...(state.bids || [])].filter(
      (value, index, self) => index === self.findIndex((v) => v[0] === value[0])
    );

    combinedAsks = combinedAsks.slice(0, state.limit.asks);
    combinedBids = combinedBids.slice(0, state.limit.bids);

    combinedAsks?.sort((x: any, y: any) => x?.[0] - y?.[0]);
    combinedBids?.sort((x: any, y: any) => y?.[0] - x?.[0]);

    const isBetter = combinedAsks?.[0]?.[0] > state.bestAsker;
    setState((prevState: any) => ({
      ...prevState,
      best_ask: isBetter ? "text-primary-green" : "text-primary-red",
      best_ask_icon: isBetter
        ? "bi-arrow-up text-primary-green"
        : "bi-arrow-down text-primary-red",
      bestAsker: combinedAsks?.[0]?.[0],
      lastUpdated: now,
      asks: combinedAsks?.map(([price, volume]: any) => [price, volume]),
      bids: combinedBids?.map(([price, volume]: any) => [price, volume]),
    }));

    setBestAsk(combinedAsks?.[0]?.[0]);

    if (now - state.lastUpdated <= state.refreshRate) {
      await new Promise((resolve) => requestAnimationFrame(resolve));
      await new Promise((resolve) =>
        setTimeout(resolve, state.refreshRate / 2)
      );
    }
  };

  const formattedAsks = () => {
    const reversedAsks = state.asks.slice().reverse();

    return reversedAsks.map(([price, volume]: any) => [
      onFixed(price),
      onFixed(volume),
      onFixed(price * volume),
    ]);
  };

  const formattedBids = () =>
    state.bids?.map(([price, volume]: any) => [
      onFixed(price),
      onFixed(volume),
      onFixed(price * volume),
    ]);

  useEffect(() => {
    updateOrderbook();
    // eslint-disable-next-line
  }, [orderBook]);

  useEffect(() => {
    if (!!setBaseQuote) {
      setBaseQuote?.({ base: baseUppercase, quote: quoteUppercase });
    }
    // eslint-disable-next-line
  }, [base, quote]);

  return (
    <div className="Orderbooktext-white relative">
      <ul className="flex p-1 xs:hidden md:flex" role="tablist">
        <li className="p-1">
          <button
            className={
              state.showMoreAsks ||
              state.showMoreBids ||
              (state.showMoreAsks && state.showMoreBids)
                ? "text-gray-120 dark:text-gray-600"
                : ""
            }
            onClick={() => {
              setState({
                ...state,
                showMoreAsks: false,
                showMoreBids: false,
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/1200/svg"
              viewBox="0 0 24 24"
              fill="#F6465D"
              className="css-3kwgah"
              style={{
                margin: "0px",
                minWidth: "0px",
                fontSize: "24px",
                width: "1em",
                height: "1em",
              }}
            >
              <path d="M4 4h7v7H4V4z"></path>
              <path d="M4 13h7v7H4v-7z"></path>
              <path
                d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </li>
        <li className="p-1">
          <button
            className={
              !state.showMoreBids || state.showMoreAsks
                ? "text-gray-120 dark:text-gray-600"
                : ""
            }
            onClick={() => {
              setState({
                ...state,
                showMoreAsks: false,
                showMoreBids: true,
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/1200/svg"
              viewBox="0 0 24 24"
              fill="#0ECB81"
              className="css-3kwgah"
              style={{
                margin: "0px",
                minWidth: "0px",
                fontSize: "24px",
                width: "1em",
                height: "1em",
              }}
            >
              <path d="M4 4h7v16H4V4z"></path>
              <path
                d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </li>
        <li className="p-1">
          <button
            className={
              state.showMoreBids || !state.showMoreAsks
                ? "text-gray-120 dark:text-gray-600"
                : ""
            }
            onClick={() => {
              setState({
                ...state,
                showMoreAsks: true,
                showMoreBids: false,
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/1200/svg"
              viewBox="0 0 24 24"
              fill="#F6465D"
              className="css-3kwgah"
              style={{
                margin: "0px",
                minWidth: "0px",
                fontSize: "24px",
                width: "1em",
                height: "1em",
              }}
            >
              <path d="M4 4h7v16H4V4z"></path>
              <path
                d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </li>
      </ul>
      <div key={state.limit.asks} className="OrderbookGrid">
        {(formattedAsks()?.length && state.showMoreAsks) ||
        (!state.showMoreBids && !state.showMoreAsks) ? (
          <div className={state.limit.asks !== 0 ? "Asks" : ""}>
            <table style={{ overflowX: "hidden" }} className="text-primary-950">
              <thead className="">
                <tr>
                  <th className="text-start" style={{ paddingLeft: "5px" }}>
                    Price
                  </th>
                  <th className="text-center">Amount</th>
                  <th className="text-end" style={{ paddingRight: "5px" }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <ListItem
                  data={formattedAsks()}
                  type="ask"
                  show-less={state.showMoreBids}
                  limit={state.limit.asks}
                />
              </tbody>
            </table>
          </div>
        ) : null}
        <div>
          <div className="LastPrice xs:hidden md:block">
            <div className="p-3">
              <span className="text-primary-950">Last Price: </span>
              <span className={state.best_ask}>{onFixed(bestAsk)}</span>
              <i className={"bi " + state.best_ask_icon}></i>
            </div>
          </div>
          {(formattedBids()?.length && state.showMoreBids) ||
          (!state.showMoreBids && !state.showMoreAsks) ? (
            <div className={state.limit.bids !== 0 ? "Bids" : ""}>
              <table
                style={{ overflowX: "hidden" }}
                className="text-primary-950"
              >
                <thead
                  className={state.limit.asks === 0 ? "" : "xs:flex md:hidden"}
                >
                  <tr>
                    <th className="pl-2 text-start">Price</th>
                    <th className="text-center">Amount</th>
                    <th className="text-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <ListItem
                    data={formattedBids()}
                    type="bid"
                    showLess={state.showMoreAsks}
                    limit={state.limit.bids}
                  />
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Orderbook;
