import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { walletApi } from "./walletApi";

interface WalletState {
  data: any;
  allWalletTypes: any;
  binanceOpenOrder: any;
}

const initialState: WalletState = {
  data: {},
  allWalletTypes: {},
  binanceOpenOrder: {},
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    clearWallet: () => initialState,
    setBinanceOpenOrderData: (state, { payload }) => {
      state.binanceOpenOrder = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      walletApi.endpoints.getAllWallet.matchFulfilled,
      (state: any, { payload }) => {
        state.data = payload || {};
      }
    );

    builder.addMatcher(
      walletApi.endpoints.getAllWalletTypes.matchFulfilled,
      (state, { payload }) => {
        state.allWalletTypes = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default walletSlice.reducer;
export const { clearWallet, setBinanceOpenOrderData } = walletSlice.actions;

export const selectWalletData = (state: RootState) => state.wallet.data;
export const useWalletData = () => {
  const data = useSelector(selectWalletData);
  return useMemo(() => data, [data]);
};

export const selectWalletTypes = (state: RootState) =>
  state.wallet.allWalletTypes;
export const useWalletTypes = () => {
  const types = useSelector(selectWalletTypes);
  return useMemo(() => types, [types]);
};

export const selectBinanceOpenOrder = (state: RootState) =>
  state.wallet.binanceOpenOrder;
export const useBinanceOpenOrder = () => {
  const binanceOpenOrderData = useSelector(selectBinanceOpenOrder);
  return useMemo(() => binanceOpenOrderData, [binanceOpenOrderData]);
};
