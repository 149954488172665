// INPUT
export const inputTypes = {
  text: "text",
  password: "password",
  number: "number",
  date: "date",
};

export type InputTypes = keyof typeof inputTypes;

// SELECT
export interface OptionTypes {
  value: string | number;
  label: string | number;
  disabled?: boolean;
}

// BUTTON
export const buttonTypes = {
  submit: "submit",
  button: "button",
};

export type ButtonTypes = keyof typeof buttonTypes;

export const buttonVariants = {
  Primary: "primary-btn",
  Transaparent: "transperent-btn",
  Disabled: "disabled-btn",
  Secondary: "bg-[#afadad] text-primary-950",
  Default: "",
};
export type ButtonVariants = keyof typeof buttonVariants;

export const getButtonVariants = (variant: ButtonVariants) =>
  buttonVariants?.[variant] || buttonVariants["Default"];

// STATUS
export const statusTypes = {
  All: "All",
  Open: "Open",
  Closed: "Closed",
  Cancelled: "Cancelled",
  Expired: "Expired",
  Filled: "Filled",
  "Partially Filled": "Partially Filled",
  "STP expired": "STP expired",
  Pending: "Pending",


  Success: "Success",
  Failed: "Failed",
  Processing: "Processing",
  Initialized: "Initialized",
  INITIALIZED: "INITIALIZED",
  "Not Attempted": "Not Attempted",
  Confirmed: "Confirmed",
  Default: "Default",
};

export const statusStyles: typeof statusTypes = {
  All: "bg-[#e0e0e0] text-primary-50",
  Open: "bg-[#28a745] text-primary-50",
  Closed: "bg-[#007bff] text-primary-50",
  Cancelled: "bg-[#dc3545] text-primary-50",
  Expired: "bg-[#6c757d] text-primary-50",
  Filled: "bg-[#ffc107] text-primary-50",
  "Partially Filled": "bg-[#17a2b8] text-primary-50",
  "STP expired": "bg-[#fd7e14] text-primary-50",
  Pending: "bg-[#ffc107] text-primary-50",
  Confirmed: "text-[#fff] bg-[#066A22c4]",


  Success: "bg-[#15CD2B] text-primary-50",
  Failed: "bg-[#FF4E58F5] text-primary-50",
  Processing: "bg-[#AB78FF] text-primary-50",
  Initialized: "bg-[#347AE2] text-primary-50",
  INITIALIZED: "bg-[#3B9EF9] text-primary-50",
  "Not Attempted": "bg-[#FF8E26] text-primary-50",
  Default: "bg-white text-black",
};
export type StatusTypes = keyof typeof statusTypes;

export const getStatusTypes = (type: StatusTypes) =>
  `${statusStyles?.[type]} px-1 py-1 w-[100px] rounded-sm text-xs text-center`;

// TOASTER
export const toasterTypes = {
  Success: "Success",
  Error: "Error",
};

export type ToasterTypes = keyof typeof toasterTypes;
