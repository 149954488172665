import { menuItems, MenuItemsTypes } from "../features/layouts/menuItems";

export const prepareAllMenu = (role: any) => {
  const allRoutes: MenuItemsTypes[] = [];
  const items = prepareMenuItems(role);

  items?.forEach((item) => {
    if (item?.subItems?.length) {
      allRoutes.push(...item?.subItems);
    } else {
      allRoutes.push(item);
    }
  });

  return allRoutes;
};

export const prepareMenuItems = (role: any) => {
  const allRoutes: MenuItemsTypes[] = [];
  const keys = Object.keys(role);

  menuItems.forEach((item) => {
    const subs: MenuItemsTypes[] = [];
    if (item?.subItems?.length) {
      item?.subItems?.forEach((element) => {
        if (keys.includes(element?.url?.split("/")?.[1])) {
          subs?.push(element);
        }
      });
    } else if (!keys.includes(item?.url?.split("/")?.[1])) {
      return;
    }

    if (item?.subItems?.length && !subs?.length) {
      return;
    }
    allRoutes.push(subs?.length ? { ...item, subItems: [...subs] } : item);
  });

  return allRoutes;
};
