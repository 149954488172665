import React from "react";

interface Props {
  data: any;
  type: "bid" | "ask" | "";
  showLess?: boolean;
  limit: number;
  width?: string;
}

const ListItem: React.FC<Props> = ({ data, type, showLess, limit, width }) => {
  const items = showLess ? data.slice(0, limit) : data;

  return (
    <>
      {items.map((item: any[], index: React.Key | null | undefined) => (
        <tr key={index}>
          <td
            className={`tdd price ${
              parseFloat(item[1]) > 0
                ? type === "bid"
                  ? "text-green-400"
                  : "text-red-500"
                : "text-gray-300 dark:text-gray-500"
            }`}
          >
            <span>{item[0]}</span>
          </td>
          <td
            className={`tdd quantity text-center ${
              parseFloat(item[1]) > 0
                ? "text-dark"
                : "text-gray-300 dark:text-gray-500"
            }`}
          >
            <span>{item[1]}</span>
          </td>
          <td
            className={`tdd btc ${
              parseFloat(item[2]) > 0
                ? "text-dark"
                : "text-gray-300 dark:text-gray-500"
            }`}
          >
            <span>{item[2]}</span>
            <div
              className={`percent_${type}`}
              style={{ width: `${item[3]}px` }}
            ></div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default ListItem;
