import { api } from "../../utills/api";

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStrategyReport: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/strategy/report",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetStrategyReportMutation,
} = dashboardApi;

export const {
  endpoints: {
    getStrategyReport,
  },
} = dashboardApi;

