import { useCallback, useEffect, useRef, useState } from "react";
import { useUser } from "../features/auth/authSlice";
import {
  disconnectLaravelSocket,
  getLaravelSocket,
  initializeLaravelSocket,
} from "../utills/laravelSocketConnection";
import {
  convertBybitToBinanceTicker,
  responseToaster,
} from "../helperFunctions";
import { useActiveCoins, useSpotMarket } from "../features/common/commonSlice";
import { useKeys } from "../features/exchange/exchangeSlice";
import { useGetUserPermissionsMutation } from "../features/auth/authApi";
import { useGetActiveCoinsMutation } from "../features/common/commonApi";

export interface Trade {
  e: string; // Event type
  E: number; // Event time
  s: string; // Symbol
  t: number; // Trade ID
  p: string; // Price
  q: string; // Quantity
  b: number; // Buyer order ID
  a: number; // Seller order ID
  T: number; // Trade time
  m: boolean; // Is the buyer the market maker?
  M: boolean; // Ignore
}

interface OrderUpdate {
  e: string; // Event type
  E: number; // Event time
  s: string; // Symbol
  c: string; // Client order ID
  S: string; // Side
  o: string; // Order type
  f: string; // Time in force
  q: string; // Original quantity
  p: string; // Price
  ap: string; // Average price
  sp: string; // Stop price
  x: string; // Execution type
  X: string; // Order status
  i: number; // Order ID
  l: string; // Order last filled quantity
  z: string; // Order filled accumulation quantity
  L: string; // Last filled price
  n: string; // Commission amount
  N: string; // Commission asset
  T: number; // Transaction time
  t: number; // Trade
  I: number; // Ignore
  w: boolean; // Is the order working
  m: boolean; // Is this trade the maker side
  O: number; // Order creation time
  Z: string; // Cumulative quote asset transacted quantity
  Y: string; // Last quote asset transacted quantity
  Q: string; // Quote order quantity
}
interface ExtendedWindow extends Window {
  ccxt?: {
    pro?: {
      binance: new () => any;
      bybit: new () => any;
      kraken: new () => any;
    };
  };
}

const useWebSocket = (
  base?: string,
  quote?: string,
  // laravelUrl?: string,
  binanceUrl?: string
): any | null => {
  const [tickers, setTickers] = useState<any>({
    0: [],
    1: [],
    current: 0,
  });

  const [tickersCombined] = useState<any[]>([]);

  const [orderBook, setOrderBook] = useState<any>(null);
  const [binanceOrderHistory, setBinanceOrderHistory] = useState<any>([]);
  const [binanceOpenOrders, setBinanceOpenOrders] = useState<any>({});
  const [binanceAllOpenOrders, setBinanceAllOpenOrders] = useState<any>({});
  const [laravelData, setLaravelData] = useState<any>(null);
  const [klineData] = useState<any>(null);
  const [trades, setTrades] = useState<Trade[]>([]);
  const [binanceClosingData, setBinanceClosingData] = useState<any>();
  const [loading, setLoading] = useState<any>({
    buy: false,
    sell: false,
    updateLimitOrderPrice: false,
    orderHistory: false,
    openOrder: false,
    cancelOrder: false,
    closingData: false,
  });
  const [exchangeInstances] = useState<any[]>([]);
  // const [prices, setPrices] = useState({});
  const allSpotMarket = useSpotMarket();

  const [orderUpdates] = useState<OrderUpdate[]>([]);

  const user: any = useUser();
  const token: any = user?.data?.token;
  const socketInitialized = useRef(false);

  const {
    currentKey: { exchange_name },
  } = useKeys();
  const activeCoins = useActiveCoins();

  const [getPermissions] = useGetUserPermissionsMutation();
  const [getActiveCoins] = useGetActiveCoinsMutation();

  const onGetActiveCoins = async () => {
    try {
      await getActiveCoins({}).unwrap();
    } catch (err) {}
  };

  // Binance
  // Tickers

  const allSymbol = () => {
    const rr = Object.entries(allSpotMarket?.data || {})?.map(
      ([key, value]: any) => {
        const rrr = value?.map((value2: any, index2: any) => {
          return value2?.symbol;
        });
        return rrr;
      }
    );
    return rr;
  };

  useEffect(() => {
    const windowWithCcxt: any = window as ExtendedWindow;

    // Updated function to fetch OHLCV data
    const fetchOHLCVFromExchange = async (
      exchange: any,
      symbol: any,
      timeframe: string = "1d"
    ) => {
      const startOfDay = new Date();
      startOfDay.setUTCHours(0, 0, 0, 0);
      const since = startOfDay.getTime();
      try {
        const ohlcv = await exchange.fetchOHLCV(symbol, timeframe, since);
        if (ohlcv && ohlcv.length > 0) {
          // Return the closing price of the latest candle
          const lastCandle = ohlcv[ohlcv.length - 1];
          return lastCandle[4]; // Closing price
        }
        return;
      } catch (error) {
        console.error(
          `Error fetching OHLCV for ${symbol} on ${exchange.id}:`,
          error
        );
        return null;
      }
    };

    const setupWebSocket = async () => {
      if (windowWithCcxt.ccxt?.pro) {
        const exchangeId = "bybit"; // Specify the exchange you want to use
        const exchangeInstance = new windowWithCcxt.ccxt[exchangeId]({
          enableRateLimit: true,
          options: {
            defaultType: "future",
          },
        });

        // Load all available markets from the exchange
        const markets = await exchangeInstance.loadMarkets();

        // Your coins array
        const symbols = allSymbol();

        const availableOHLCV: any = [];

        for (const symbol of symbols) {
          symbol?.map(async (symb: any) => {
            if (markets[symb]) {
              const ohlcv = await fetchOHLCVFromExchange(
                exchangeInstance,
                symb
              );
              if (ohlcv) {
                availableOHLCV.push({ [symb]: ohlcv });
              }
            } else {
              console.warn(`Symbol ${symb} not available on ${exchangeId}`);
            }
          });
        }

        // setPrices(availableOHLCV);
      } else {
        console.error("ccxt.pro is not available");
      }
    };

    setupWebSocket();

    // Cleanup function to close the exchange instance
    return () => {
      exchangeInstances.forEach(({ instance }) => {
        instance?.close?.();
      });
    };
    // eslint-disable-next-line
  }, []);

  const handleBinanceTickers = useCallback(() => {
    const binanceWebSocket = new WebSocket("wss://stream.binance.com:9443/ws");

    binanceWebSocket.onopen = () => {
      console.log("WebSocket connection to Binance established");

      binanceWebSocket.send(
        JSON.stringify({
          method: "SUBSCRIBE",
          params: ["!ticker@arr"],
          id: 1,
        })
      );
    };

    binanceWebSocket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data.toString());

        if (Array.isArray(message)) {
          setTickers((tickers: any) => {
            const newTickers = [...(tickers?.[tickers?.current] || [])];

            message?.forEach((item: any) => {
              const index = newTickers?.findIndex(
                (subItem: any) => subItem?.s === item?.s
              );
              if (index > -1) {
                newTickers[index] = item;
              } else {
                newTickers.push(item);
              }
            });
            return {
              [tickers.current]: tickers?.[tickers.current] || [],
              [tickers.current + 1]: newTickers,
              current: +tickers.current + 1,
            };
          });

          // setTickersCombined((tickers: any[]) => {
          //   const newTickers = [...(tickers || [])];

          //   message?.forEach((item: any) => {
          //     const bybitIndex = activeCoins?.data?.findIndex(
          //       (subItem: any) => `${subItem?.symbol}USDT` === item?.s
          //     );

          //     if (bybitIndex > -1) {
          //       return;
          //     }

          //     const index = newTickers?.findIndex(
          //       (subItem: any) => subItem?.s === item?.s
          //     );

          //     if (index > -1) {
          //       newTickers[index] = item;
          //     } else {
          //       newTickers.push(item);
          //     }

          //   });
          //   return newTickers;
          // });
        }
      } catch (err) {
        console.error("message error:", err);
      }
    };

    return () => {
      binanceWebSocket.close();
    };
  }, []);

  useEffect(() => {
    onGetActiveCoins();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Trades
    const ws = new WebSocket(
      `wss://stream.binance.com:9443/ws/${base?.toLowerCase()}${quote?.toLowerCase()}@trade`
    );

    ws.onmessage = (event) => {
      const trade: Trade = JSON.parse(event.data);
      setTrades((prevTrades) => {
        const lastTrade: any = prevTrades.slice(0, 1)?.[0];
        return [
          {
            ...trade,
            class:
              +trade?.p === +lastTrade?.p
                ? lastTrade?.class
                : +trade?.p > +lastTrade?.p
                ? "text-primary-green"
                : "text-primary-red",
          },
          ...prevTrades.slice(0, 9),
        ];
      });
    };

    // Orderbook
    const binanceOrderBookSocket = new WebSocket(
      `wss://stream.binance.com:9443/ws/${base?.toLowerCase()}${quote?.toLowerCase()}@depth`
    );

    binanceOrderBookSocket.addEventListener("message", (data: any) => {
      setOrderBook(JSON.parse(data?.data));
    });

    //BinanceOpenOrder

    const binanceOpenOrderSocket = new WebSocket(
      `wss://stream.binance.com:9443/ws/${base?.toLowerCase()}${quote?.toLowerCase()}@depth`
    );

    binanceOpenOrderSocket.addEventListener("message", (data: any) => {
      // setBinanceOpenOrder(JSON.parse(data?.data));
    });

    return () => {
      ws.close();
      binanceOrderBookSocket.close();
    };
    // eslint-disable-next-line
  }, [base, quote]);

  // Laravel Sockets

  const emitCreateFutureTradeEvent = useCallback(
    async (payload: any, orderPlaced?: any) => {
      console.log("🚀 ~ emitCreateFutureTradeEvent - payload:", payload);
      setLoading((loading: any) => ({
        ...loading,
        [payload?.side === "buy" ? "buy" : "sell"]: true,
      }));
      const socket = getLaravelSocket();

      if (socket) {
        socket.emit("create-future-trade", {
          data: payload,
        });

        socket.on("create-future-trade-response", async (data) => {
          console.log("🚀 ~ create-future-trade-response ~ data:", data);
          socket.off("create-future-trade-response");
          responseToaster(data);
          await orderPlaced(data);
          setLoading((loading: any) => ({
            ...loading,
            [payload?.side === "buy" ? "buy" : "sell"]: false,
          }));
        });
      }
    },
    // eslint-disable-next-line
    [token]
  );

  const emitCreateSpotTradeEvent = useCallback(
    async (payload: any, orderPlaced?: any) => {
      console.log("🚀 ~ emitCreateSpotTradeEvent payload:", payload);
      setLoading((loading: any) => ({
        ...loading,
        [payload?.side === "buy" ? "buy" : "sell"]: true,
      }));
      const socket = getLaravelSocket();

      if (socket) {
        socket.emit("create-spot-trade", {
          data: payload,
        });

        socket.on("create-spot-trade-response", async (data) => {
          console.log("🚀 ~ create-spot-trade-response ~ data:", data);
          socket.off("create-spot-trade-response");

          responseToaster(data);
          await orderPlaced(data);
          setLoading((loading: any) => ({
            ...loading,
            [payload?.side === "buy" ? "buy" : "sell"]: false,
          }));
        });
      }
    },
    // eslint-disable-next-line
    [token]
  );

  const emitUpdateLimitOrderEvent = useCallback(
    async (payload: any, orderPlaced?: any) => {
      console.log("🚀 ~ emitUpdateLimitOrderEvent - payload:", payload);
      setLoading((loading: any) => ({
        ...loading,
        updateLimitOrderPrice: true,
      }));

      const socket = await getLaravelSocket();
      if (socket) {
        socket.emit("update-limit-order", {
          token: `Bearer ${token}`,
          data: payload,
        });

        socket.on("update-limit-order-response", (data) => {
          console.log("🚀 ~ update-limit-order-response ~ data:", data);
          setLoading((loading: any) => ({
            ...loading,
            updateLimitOrderPrice: false,
          }));
          socket.off("update-limit-order-response");
          responseToaster(data);
          orderPlaced();
        });
      }
    },
    [token]
  );

  const emitCancelOrderEvent = useCallback(
    async (payload: any) => {
      setLoading((loading: any) => ({
        ...loading,
        cancelOrder: true,
      }));

      const socket = await getLaravelSocket();
      if (socket) {
        socket.emit("cancel-limit-order", {
          data: payload,
        });

        socket.on("cancel-limit-order-response", (data) => {
          setLoading((loading: any) => ({
            ...loading,
            cancelOrder: false,
          }));
          socket.off("cancel-limit-order-response");
          responseToaster(data);
        });
      }
    },
    // eslint-disable-next-line
    [token]
  );

  const emitClosingDataEvent = useCallback(
    async (payload: any) => {
      setLoading((loading: any) => ({
        ...loading,
        closingData: true,
      }));
      const socket = await getLaravelSocket();
      if (socket) {
        socket.emit("get-closing-data", {
          data: payload,
        });

        socket.on("get-closing-data-response", (data) => {
          socket.off("get-closing-data-response");
          setLoading((loading: any) => ({
            ...loading,
            closingData: false,
          }));
          setBinanceClosingData(data);
        });
      }
    },
    // eslint-disable-next-line
    [token]
  );

  const emitOrderHistory = async ({
    is_connect,
    start_date,
    end_date,
    limit,
    page,
    since,
    until,
    order_type,
    is_tab_connect,
    symbol,
    strategy_name,
    exchange_name,
    platform,
    trade_type,
    group_name,
  }: any) => {
    setLoading((loading: any) => ({
      ...loading,
      orderHistory: true,
    }));

    const socket = await getLaravelSocket();
    if (socket) {
      socket.off("order-history-response");
      const payload = {
        data: {
          is_connect: is_connect,
          ...(start_date && { start_date }),
          ...(end_date && { end_date }),
          ...(symbol && { symbol }),
          ...(limit && { limit }),
          ...(page && { page }),
          ...(since && { since }),
          ...(until && { until }),
          order_type,
          ...(strategy_name && { strategy_name }),
          ...(symbol && { symbol }),
          ...(exchange_name && { exchange_name }),
          ...(platform && { platform }),
          ...(trade_type && { trade_type }),
          order_status: "all",
          group_name,
        },
      };
      socket.emit("order-history", payload);

      socket.on("order-history-response", (data) => {
        console.log("🚀 ~ socket.on order-history-response ~ data:", data);
        // responseToaster(data);

        setBinanceOrderHistory(data);
        // setBinanceOrderHistory(data?.AI);
        setLoading((loading: any) => ({
          ...loading,
          orderHistory: false,
        }));
      });
    }
  };

  const emitOpenOrder = async ({
    is_connect,
    order_type,
    is_tab_connect,
    exchange_name,
    symbol,
    strategy,
    start_date,
    end_date,
    page,
    limit,
    since,
    until,
    platform,
    trade_type,
    group_name,
  }: any) => {
    setLoading((loading: any) => ({
      ...loading,
      openOrder: true,
    }));

    const socket = await getLaravelSocket();
    if (socket) {
      socket.off("open-order-history-response");
      const payload = {
        data: {
          is_connect: is_connect,
          order_status: 1,
          order_type,
          exchange_name: exchange_name,
          ...(symbol && { symbol }),
          ...(start_date && { start_date }),
          ...(end_date && { end_date }),
          ...(page && { page }),
          ...(limit && { limit }),
          ...(since && { since }),
          ...(until && { until }),
          ...(strategy && { strategy_name: strategy }),
          ...(platform && { platform }),
          ...(trade_type && { trade_type }),
          group_name,
        },
      };
      console.log("🚀 ~ open-order-history payload:", payload);

      socket.emit("open-order-history", payload);

      socket.on("open-order-history-response", (data) => {
        console.log("🚀 ~ socket.on open-order-history-response ~ data:", data);
        // responseToaster(data);

        setBinanceOpenOrders({
          1: data?.exchangeData || [],
          0: data?.AI || [],
          exchangeCount: data?.exchangeCount || 0,
          aiCount: data?.aiCount || 0,
        });
        setLoading((loading: any) => ({
          ...loading,
          openOrder: false,
        }));
      });
    }
  };

  const emitAllOpenOrder = async ({
    is_connect,
    order_type,
    exchange_name,
    symbol,
    strategy,
    start_date,
    end_date,
    since,
    until,
    group_name,
  }: any) => {
    setLoading((loading: any) => ({
      ...loading,
      openOrder: true,
    }));

    const socket = await getLaravelSocket();
    if (socket) {
      socket.off("open-order-history-response");
      const payload = {
        data: {
          is_connect: is_connect,
          order_status: 1,
          order_type,
          exchange_name: exchange_name,
          ...(symbol && { symbol }),
          ...(start_date && { start_date }),
          ...(end_date && { end_date }),
          ...(since && { since }),
          ...(until && { until }),
          ...(strategy && { strategy_name: strategy }),
          group_name,
        },
      };
      console.log("🚀 ~ open-order-history payload:", payload);

      socket.emit("open-order-history", payload);

      socket.on("open-order-history-response", (data) => {
        console.log("🚀 ~ socket.on open-order-history-response ~ data:", data);
        const res = {
          1: data?.exchangeData || [],
          0: data?.AI || [],
          exchangeCount: data?.exchangeCount || 0,
          aiCount: data?.aiCount || 0,
        };
        setBinanceAllOpenOrders(res);
        setLoading((loading: any) => ({
          ...loading,
          openOrder: false,
        }));
      });
    }
  };

  useEffect(() => {
    if (!token || socketInitialized.current) return;

    const socket = initializeLaravelSocket(token);
    socketInitialized.current = true;

    socket.on("updated-order", (data) => {
      setLaravelData(data);
    });

    socket.on("updated-role", async (data) => {
      console.log("🚀 ~ socket.on updated-role ~ data:", data);
      await getPermissions().unwrap();
    });

    return () => {
      disconnectLaravelSocket();
      socketInitialized.current = false;
    };
    // eslint-disable-next-line
  }, [token, setLaravelData]);

  // bybit

  // Bybit Tickers
  const handleBybitTickers = useCallback(() => {
    const ws = new WebSocket("wss://stream.bybit.com/v5/public/spot");

    ws.onopen = () => {
      (activeCoins?.data || [])
        ?.map((item: any) => `${item.symbol}USDT`)
        .forEach((symbol: string) => {
          // TODO: for USDT only
          ws.send(
            JSON.stringify({ op: "subscribe", args: [`tickers.${symbol}`] })
          );
        });
    };

    const bybitTickers: any[] = [...(tickers?.[tickers?.current] || [])];
    // const combinedTickers: any[] = [...(tickersCombined || [])];
    ws.onmessage = (message) => {
      try {
        const data = JSON.parse(message.data);
        if (data.type === "snapshot") {
          const convertedData = convertBybitToBinanceTicker(data.data);

          // BYBIT
          const bybitIndex = bybitTickers?.findIndex(
            (subItem: any) => subItem?.s === data.data.symbol
          );
          if (bybitIndex > -1) {
            bybitTickers[bybitIndex] = convertedData;
          } else {
            bybitTickers.push(convertedData);
          }

          // COMBINED
          // const combinedIndex = combinedTickers?.findIndex(
          //   (subItem: any) => subItem?.s === data.data.symbol
          // );
          // if (combinedIndex > -1) {
          //   combinedTickers[combinedIndex] = convertedData;
          // } else {
          //   combinedTickers.push(convertedData);
          // }
        }
      } catch (err) {}
    };
    setInterval(() => {
      setTickers((tickers: any) => {
        return {
          [tickers.current]: tickers?.[tickers.current] || [],
          [tickers.current + 1]: bybitTickers,
          current: +tickers.current + 1,
        };
      });

      // setTickersCombined(combinedTickers);
    }, 500);

    return () => {
      ws.close();
    };
    // eslint-disable-next-line
  }, [activeCoins]);

  // General
  useEffect(() => {
    switch (exchange_name) {
      case "binance":
        handleBinanceTickers();
        // handleBybitTickers();
        break;
      case "bybit":
        // handleBinanceTickers();
        handleBybitTickers();
        break;
      default:
        handleBinanceTickers();
        // handleBybitTickers();
        break;
    }
    // eslint-disable-next-line
  }, [exchange_name, activeCoins, handleBinanceTickers, handleBybitTickers]);

  return {
    tickers: tickers?.[tickers?.current] || [],
    allTickers: tickers,
    tickersCombined: tickersCombined || [],
    orderBook,
    laravelData,
    klineData,
    trades,
    loading,
    orderUpdates,
    binanceOrderHistory,
    binanceOpenOrders,
    binanceAllOpenOrders,
    binanceClosingData,
    setLoading,
    emitUpdateLimitOrderEvent,
    emitCreateFutureTradeEvent,
    emitCreateSpotTradeEvent,
    emitOrderHistory,
    emitOpenOrder,
    emitAllOpenOrder,
    emitCancelOrderEvent,
    emitClosingDataEvent,
    // bybit
    // bybitTickers,
  };
};

export default useWebSocket;
