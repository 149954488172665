import { Fragment } from "react/jsx-runtime";
import {
  SideEnumKeys,
  cancelEnum,
  orderStatuses,
  sideEnum,
  typeEnum,
} from "../../../constant";
import { getStatusTypes, Loader, Table, TableRow } from "../../../ui";
import OpenOrderPrice from "./OpenOrderPrice";
import { MdDelete } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { onFixed } from "../../../helperFunctions";
import useWebSocketContext from "../../../context/useWebSocketContext";
import { useKeys } from "../../exchange/exchangeSlice";
import { useRole } from "../../auth/authSlice";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";
import { useGetAllOrderDetailsMutation } from "../tradeApi";

interface IOpenOrdersList {
  cancelOpenOrder: (data?: any, type?: any) => {};
  callUpdateLimitOrderSocket?: any;
  cancelOrderId?: any;
  cancelOpenOrderLoading?: any;
  setCancelOrderId?: any;
}

const OpenOrdersList = ({
  cancelOpenOrder,
  cancelOrderId,
  callUpdateLimitOrderSocket,
  setCancelOrderId,
}: IOpenOrdersList) => {
  const [tableData, setTableData] = useState<any>([]);
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const isFutureTrade = pathname.pathname.includes("future-trade");
  const role = useRole(isFutureTrade ? "future-trade" : "spot-trade");
  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();
  const { loading, binanceOpenOrders, emitOpenOrder } = useWebSocketContext();

  const [getAllOrderDetails] = useGetAllOrderDetailsMutation();

  const columnsOpenOrders = useMemo(
    () => [
      {
        title: "Date",
        name: "created_at",
        Cell: (data: any) => <>{`${data?.created_at}`}</>,
      },
      {
        title: "Pair",
        name: "symbol",
      },
      {
        title: "Type",
        name: "type",
        prepareValue: ({
          type = "1",
        }: {
          type: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
        }) => typeEnum?.[type],
        Cell: ({
          type = "1",
        }: {
          type: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
        }) => (
          <>
            <span>{typeEnum?.[type]}</span>
          </>
        ),
        isMenu: true,
        enum: typeEnum,
      },
      {
        title: "Side",
        name: "side",
        prepareValue: ({ side }: { side: SideEnumKeys }) => sideEnum?.[side],
        Cell: ({ side }: { side: SideEnumKeys }) => (
          <>
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
        isMenu: true,
        enum: sideEnum,
      },
      {
        title: "Price",
        name: "stock_price",
        prepareValue: (data: any) =>
          ["8", "9"]?.includes(data?.type.toString())
            ? data?.type?.toString() === "8"
              ? onFixed(data?.take_profit)
              : onFixed(data?.stop_loss)
            : onFixed(
                data?.stock_price ||
                  (data?.type === "STOP_MARKET" ||
                  data?.type === "TAKE_PROFIT_MARKET"
                    ? data?.stopPrice
                    : data?.price)
              ),
        Cell: (data: any) => (
          <>
            {["8", "9"]?.includes(data?.type.toString()) ? (
              <>
                <span>
                  {data?.type?.toString() === "8"
                    ? onFixed(data?.take_profit)
                    : onFixed(data?.stop_loss)}
                </span>
              </>
            ) : (
              <>
                <OpenOrderPrice
                  data={data}
                  title="Price"
                  callUpdateLimitOrderSocket={callUpdateLimitOrderSocket}
                />
              </>
            )}
          </>
        ),
      },
      {
        title: "Amount",
        name: "amount",
        prepareValue: (data: any) =>
          ["8", "9"]?.includes(data?.type.toString())
            ? data?.amount
            : onFixed(data?.amount || data?.origQty),
        Cell: (data: any) => (
          <>
            {["8", "9"]?.includes(data?.type.toString()) ? (
              <>
                <span>{data?.amount}</span>
              </>
            ) : (
              <>
                <OpenOrderPrice
                  data={data}
                  title="Amount"
                  callUpdateLimitOrderSocket={callUpdateLimitOrderSocket}
                />
              </>
            )}
          </>
        ),
      },
      {
        title: "Status",
        prepareValue: ({
          order_status,
        }: {
          order_status: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
        }) => orderStatuses?.[order_status],
        Cell: ({
          order_status,
        }: {
          order_status: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
        }) => (
          <div
            className={`${getStatusTypes(orderStatuses?.[order_status])}`}
          >{`${orderStatuses?.[order_status]}`}</div>
        ),
      },
      {
        title: "Total",
        prepareValue: (data: any) => onFixed(data?.total),
        Cell: (data: any) => (
          <>
            <span>{onFixed(data?.total)}</span>
          </>
        ),
      },
      {
        title: "Cancel All",
        name: "cancelAll",
        exportHide: ["ALL"],
        showColumn:
          role.includes("Cancel Spot Order") ||
          role.includes("Cancel Future Trade Order"),
        Cell: (data: any) => {
          return (
            <>
              <span
                className="cursor-pointer"
                onClick={() => {
                  cancelOpenOrder(data);
                  setCancelOrderId(data?.order_uuid);
                }}
              >
                {cancelOrderId === data?.order_uuid && loading?.cancelOrder ? (
                  <Loader loaderClassName="w-[15px] " />
                ) : (
                  <MdDelete />
                )}
              </span>
            </>
          );
        },
        isMenu: true,
        menuType: "non-filterable",
        enum: cancelEnum,
        dropdownClassName: "!left-auto !right-0",
      },
    ],
    // eslint-disable-next-line
    [tableData, cancelOrderId, loading?.cancelOrder]
  );

  const prepareOpenOrders = () => {
    return binanceOpenOrders?.[is_tab_connect] || [];
  };

  useEffect(() => {
    setTableData(prepareOpenOrders());
    // eslint-disable-next-line
  }, [binanceOpenOrders, is_tab_connect]);

  useEffect(() => {
    setTimeout(() => {
      emitOpenOrder({
        is_connect: is_connect,
        order_type: isFutureTrade ? 1 : 2,
        is_tab_connect: is_tab_connect,
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
      });
    }, 300);
    // eslint-disable-next-line
  }, [is_connect]);

  const onMenuClick = (name: any, menuKey: any) => {
    if (name === "cancelAll") {
      cancelOpenOrder(null, menuKey);
    }
  };

  return (
    <>
      <div className={`pt-3`} style={{ minHeight: "300px" }}>
        <Table
          fileName="Trade_open_orders"
          onFetchExportData={async () => {
            try {
              const payload: any = {
                symbol: "ALL",
                exchange_name: exchange_name,
                order_status: 1,
                order_type: isFutureTrade ? 1 : 2,
                group_name: pathnames?.[1],
              };

              const res: any = await getAllOrderDetails(payload).unwrap();
              return { data: res?.data, columns: columnsOpenOrders };
            } catch (err) {
              return { data: [], columns: columnsOpenOrders };
            }
          }}
          isLoading={is_tab_connect === 1 && loading?.openOrder}
          columns={
            // is_tab_connect === 1 ? columnsBinanceOpenOrders : columnsOpenOrders
            columnsOpenOrders
          }
          data={prepareOpenOrders() || []}
          tableDataCount={tableData?.length}
          isExpendable={false}
          count={prepareOpenOrders()?.length}
          setData={setTableData}
          onMenuClick={onMenuClick}
          className="!overflow-scroll order_table"
        >
          {tableData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  // className="min-h-[150px]"
                  columns={
                    // is_tab_connect === 1
                    //   ? columnsBinanceOpenOrders
                    //   : columnsOpenOrders
                    columnsOpenOrders
                  }
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default OpenOrdersList;
