// UI IMPORT
import { Button, ControlledInput } from "../../ui";

// PROJECT IMPORT
import { responseToaster } from "../../helperFunctions";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useLazyGetAllWalletQuery, useDepositMutation } from "./walletApi";
import { useEffect } from "react";
import { useKeys } from "../exchange/exchangeSlice";
import { useLocation } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";

const DepositModal = ({ onClose }: any) => {
  // const coins = useCoins();
  const [deposit, { isLoading }] = useDepositMutation();
  const [getAllWallet] = useLazyGetAllWalletQuery();
  // const [getCoins] = useGetCoinsMutation();
  const {
    currentKey: { is_connect, exchange_name },
  } = useKeys();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  // const prepareCurrencyOptions = () => {
  //   const currencyOptions: any = coins?.data
  //     // ?.filter((currency: any) => currency?.name !== null)
  //     ?.slice()
  //     ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
  //     ?.map((val: any) => ({
  //       value: val?.coin_uuid,
  //       label: `${val?.symbol} (${val?.name})`,
  //     }));
  //   return currencyOptions || [];
  // };

  const schema: any = yup.object({
    // coin_uuid: yup.string().required("Please select a currency"),
    amount: yup.string().required("Please enter a amount"),
  });

  const initialValues: any = {};

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver<any>(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async () => {
    try {
      const values = getValues();
      const payload = {
        ...values,
        coin_uuid: "COINUSDT", // TODO: USDT for now
      };
      const res = await deposit({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
      responseToaster(res);
      reset();
      onClose();
      getAllWallet({
        is_connect: is_connect,
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // getCoins({});
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
          onClick={() => {
            onClose();
          }}
        />

        <div className="modal-container w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-7/12 sm:w-7/12 z-50 mx-auto overflow-y-auto">
          <div className="modal-header">
            <h4 className="text-lg font-semibold text-primary-950">Deposit</h4>
            <MdOutlineCancel
              color="var(--primary-600--)"
              size={24}
              onClick={() => {
                onClose();
              }}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content">
              <div className="space-y-5 pb-10">
                <ControlledInput
                  name="amount"
                  label="Amount"
                  placeholder="Enter Amount"
                  type="number"
                  isShowError
                  control={control}
                  errors={errors}
                />
                {/* <ControlledDropDown
                  className="!w-full"
                  inputClassName="!h-12 !border-0"
                  name="coin_uuid"
                  label="Currency"
                  options={prepareCurrencyOptions()}
                  errors={errors}
                  control={control}
                /> */}
              </div>
            </div>
            <div className="modal-footer w-full">
              <Button type="submit" isLoading={isLoading}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DepositModal;
