import { getStatusTypes, Table, TableRow } from "../../../ui";
import {
  SideEnumKeys,
  orderStatuses,
  sideEnum,
  typeEnum,
} from "../../../constant";
import { Fragment, useEffect, useMemo, useState } from "react";
import binanceImg from "../../../assets/images/binanceIcon.svg";
import { dateFormatter1, onFixed } from "../../../helperFunctions";
import useWebSocketContext from "../../../context/useWebSocketContext";
import { useKeys } from "../../exchange/exchangeSlice";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";
import { useLocation } from "react-router-dom";
import { useGetAllOrderDetailsMutation } from "../../trade/tradeApi";

// THIRD - PARTY IMPORT

interface FutureOrderHistoryProps {
  filter?: any;
  setFilter?: any;
}

const FutureOrderHistory = (props: FutureOrderHistoryProps) => {
  const { filter, setFilter } = props;

  const [tableData, setTableData] = useState<any>([]);

  const { loading, binanceOrderHistory } = useWebSocketContext();
  const {
    currentKey: { is_tab_connect, exchange_name },
  } = useKeys();

  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const [getAllOrderDetails] = useGetAllOrderDetailsMutation();

  const columnsOrderHistory = useMemo(
    () => [
      {
        title: "Exchange",
        name: "is_connect_order",
        exportHide: ["ALL"],
        Cell: ({ is_connect_order }: any) => (
          <div className="flex items-center justify-center">
            {is_connect_order ? (
              <img className="w-7" src={binanceImg} alt="" />
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        title: "Date",
        name: "created_at",
        Cell: (data: any) => <>{data?.created_at}</>,
      },
      {
        title: "Pair",
        name: "symbol",
        // Cell: (data: any) => <>{`${data?.to_coin}${data?.from_coin}`}</>,
      },
      {
        title: "Type",
        name: "type",
        prepareValue: ({ type }: any) => typeEnum?.[type],
        Cell: ({ type }: any) => (
          <>
            <span>{typeEnum?.[type]}</span>
          </>
        ),
        isMenu: true,
        enum: typeEnum,
      },
      {
        title: "Side",
        name: "side",
        prepareValue: ({ side }: { side: SideEnumKeys }) => sideEnum?.[side],
        Cell: ({ side }: { side: SideEnumKeys }) => (
          <>
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
        isMenu: true,
        enum: sideEnum,
      },
      {
        title: "Platform",
        name: "platform",
      },
      {
        title: "Trade Type",
        name: "trade_type",
      },
      {
        title: "Average",
        name: "exchange_rate",
        prepareValue: (data: any) => onFixed(data?.exchange_rate),
        Cell: (data: any) => <span>{onFixed(data?.exchange_rate)}</span>,
      },
      {
        title: "Price",
        name: "stock_price",
        prepareValue: (data: any) =>
          data?.type === 1
            ? "Market"
            : ["8", "9"]?.includes(data?.type.toString())
            ? data?.type?.toString() === "8"
              ? onFixed(data?.take_profit)
              : onFixed(data?.stop_loss)
            : onFixed(data?.stock_price),
        Cell: (data: any) => (
          <>
            <span>
              {data?.type === 1
                ? "Market"
                : ["8", "9"]?.includes(data?.type.toString())
                ? data?.type?.toString() === "8"
                  ? onFixed(data?.take_profit)
                  : onFixed(data?.stop_loss)
                : onFixed(data?.stock_price)}
            </span>
          </>
        ),
      },
      {
        title: "Executed",
        name: "executedQty",
        prepareValue: (data: any) => onFixed(data?.executed),
        Cell: (data: any) => <span>{onFixed(data?.executed)}</span>,
      },
      {
        title: "Amount",
        name: "amount",
        prepareValue: (data: any) => onFixed(data?.amount),
        Cell: (data: any) => <span>{onFixed(data?.amount)}</span>,
      },
      {
        title: "Total",
        name: "total",
        prepareValue: (data: any) => onFixed(data?.total),
        Cell: (data: any) => (
          <>
            <span>{onFixed(data?.total)}</span>
          </>
        ),
      },
      {
        title: "Status",
        name: "order_status",
        prepareValue: ({
          order_status,
        }: {
          order_status: "1" | "2" | "3" | "4";
        }) => orderStatuses?.[order_status],
        Cell: ({ order_status }: { order_status: "1" | "2" | "3" | "4" }) => (
          <div
            className={`${getStatusTypes(orderStatuses?.[order_status])}`}
          >{`${orderStatuses?.[order_status]}`}</div>
        ),
        isMenu: true,
        enum: orderStatuses,
        dropdownClassName: "!left-auto !right-0",
      },
    ],
    // eslint-disable-next-line
    [tableData]
  );

  useEffect(() => {
    setTableData(prepareOrderHistory());
    // eslint-disable-next-line
  }, [binanceOrderHistory, is_tab_connect]);

  const prepareOrderHistory = () => {
    return is_tab_connect === 1
      ? binanceOrderHistory?.exchangeData || []
      : binanceOrderHistory?.AI || [];
  };

  return (
    <div className="flex flex-col mt-5">
      <div className={`pt-3`}>
        <Table
          fileName="Future_order_history"
          onFetchExportData={async () => {
            try {
              const payload: any = {
                symbol: filter?.symbol,
                start_date: dateFormatter1(filter?.date?.[0], "start"),
                end_date: dateFormatter1(
                  filter?.date?.[1],
                  "end",
                  filter?.date?.[0]
                ),
                exchange_name: exchange_name,
                order_type: 1,
                group_name: pathnames?.[1],
              };
              const res: any = await getAllOrderDetails(payload).unwrap();
              return { data: res?.data, columns: columnsOrderHistory };
            } catch (err) {
              return { data: [], columns: columnsOrderHistory };
            }
          }}
          isLoading={loading?.orderHistory}
          columns={columnsOrderHistory}
          data={prepareOrderHistory() || []}
          tableDataCount={tableData?.length}
          isExpendable={false}
          count={
            is_tab_connect === 1
              ? binanceOrderHistory?.exchangeCount
              : binanceOrderHistory?.aiCount
          }
          setData={setTableData}
          className="!overflow-scroll order_table"
          minHeight={300}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter((filter: any) => ({
              ...filter,
              ...pagination,
            }));
          }}
        >
          {tableData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  className="min-h-[350px]"
                  columns={columnsOrderHistory}
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default FutureOrderHistory;
