import React, { memo } from "react";
import { IconProps } from ".";

export interface DeleteIconProps extends IconProps {
}
const DeleteIcon = (props: DeleteIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M3 6h18" className={`${pathClassNames?.[0] || ""}`} />
      <path
        d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"
        className={`${pathClassNames?.[1] || ""}`}
      />
      <path
        d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"
        className={`${pathClassNames?.[2] || ""}`}
      />
    </svg>
  );
};

const MemoDeleteIcon = memo(DeleteIcon);
export default MemoDeleteIcon;
