import ReactPaginate from "react-paginate";
import { Select } from "../forms";
import { limitOptions } from "../../features/common/mock";
import { LIMIT } from "../../constant";

interface PaginationProps {
  className?: string;
  page?: number;
  count?: number;
  limit?: number;
  data?: any[];
  handlePageChange?: (page: number) => void;
  handleLimitChange?: (limit: number) => void;
}
const Pagination = (props: PaginationProps) => {
  const {
    className = "",
    page = 1,
    count = 0,
    limit = LIMIT,
    data,
    handlePageChange,
    handleLimitChange,
  } = props;

  const totalPageCount = Math.ceil(count / limit);
  // const availableCount = (+count || 0) - (+limit || 0) * (+page || 0);

  // const prepareLimitOptions = () => {
  //   return limitOptions?.map((item) => {
  //     return {
  //       ...item,
  //       disabled: (+item?.value || 0) > (+availableCount || 0),
  //     };
  //     // return (+item?.value || 0) >
  //   });
  // };

  return (
    <div className="z-50 flex absolute bottom-4 right-4 left-0 items-center justify-between px-2">
      <div>
        <Select
          className="h-8 w-16 text-sm"
          name="limit"
          options={limitOptions}
          disabled={!data?.length}
          isDefaultOption={true}
          value={limit}
          onChange={(e: any) => {
            handleLimitChange?.(+e.target.value);
          }}
        />
      </div>
      <div className="pagination-ul">
        <ReactPaginate
          className={`${className} flex whitespace-nowrap gap-3 text-xs`}
          pageLinkClassName="text-primary-950"
          breakClassName="text-primary-400"
          activeClassName="h-8 w-8 flex items-center justify-center rounded-sm text-primary-600 bg-secondary-200 text-xs"
          previousClassName="border border-1 border-lightblue rounded-sm p-2 text-primary-950 text-xs"
          nextClassName="border border-1 border-lightblue rounded-sm p-2 text-primary-950 text-xs"
          breakLabel="..."
          nextLabel="Next >"
          forcePage={count > 0 ? +page - 1 : -1}
          onPageChange={(e) => {
            handlePageChange?.(+e.selected + 1);
          }}
          pageRangeDisplayed={0}
          pageCount={totalPageCount}
          previousLabel="< Previous"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default Pagination;
