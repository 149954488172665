import { Fragment, useEffect, useState } from "react";
import { Button, Table, TableRow } from "../../ui";
import { useDeleteRoleMutation, useGetRolesMutation } from "./rolesApi";
import { useRoles } from "./rolesSlice";
import { FILTER } from "../../constant";
import {
  dateFormatter,
  dateFormatterUTCToLocal,
  responseToaster,
} from "../../helperFunctions";
import DeleteModal from "../common/DeleteModal";
import { useLocation, useNavigate } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";

// const searchDataOptions = [
//   { value: "rolename", label: "Name" },
//   { value: "email", label: "Email" },
// ];

// const initialValues = {
//   filter: DROPDOWN_SEARCH_VALUE,
//   search: "",
//   date: [null, null],
// };

const Roles = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any>([]);
  const [filter] = useState<any>({ ...FILTER, isToast: false });
  const [deleteId, setDeleteId] = useState<string>("");

  const [getRoles, { isLoading: isRolesLoading }] = useGetRolesMutation();
  const [deleteRole, { isLoading: isDeleteLoading }] = useDeleteRoleMutation();
  const roles = useRoles();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  // const { control, getValues, handleSubmit, reset }: any = useForm({
  //   defaultValues: initialValues,
  // });

  const columns = [
    {
      title: "Date",
      name: "created_at",
      prepareValue: (data: any) => dateFormatter(data?.created_at, ""),
      Cell: (data: any) => (
        <>
          <span>{dateFormatterUTCToLocal(data?.created_at)}</span>
        </>
      ),
    },
    {
      title: "Name",
      name: "role_name",
    },
    // {
    //   title: "Guard Name",
    //   name: "guard_name",
    // },
    {
      title: "Action",
      exportHide: ["ALL"],
      Cell: (data: any) => (
        <div className="flex gap-2">
          <Button
            className="!h-8 !w-8 !p-0"
            onClick={() => {
              navigate(`/roles/update-role/${data?.id}`);
            }}
          >
            {/* Edit */}
            <CiEdit size={24} />
          </Button>
          <Button
            className="!h-8 !w-8 !p-0"
            onClick={() => {
              setDeleteId(data?.id);
            }}
          >
            {/* Delete */}
            <GoTrash size={20} />
          </Button>
        </div>
      ),
    },
  ];

  const onGetRoles = async () => {
    try {
      // const payload = isFilter
      //   ? {
      //       [dropdown]: search,
      //       start_date: dateFormatter(date?.[0], "start"),
      //       end_date: dateFormatter(date?.[1], "end", date?.[0]),
      //       ...rest,
      //       // page,
      //       // limit,
      //     }
      //   : {
      //       // page,
      //       // limit,
      //     };
      // const res = await getRoles(payload).unwrap();
      const res = await getRoles({
        group_name: pathnames?.[1],
      }).unwrap();
      if (filter?.isToast) {
        responseToaster(res);
      }
    } catch (error) {}
  };

  const onDelete = async () => {
    try {
      const res = await deleteRole({
        id: deleteId,
        group_name: pathnames?.[1],
      }).unwrap();
      responseToaster(res);
      if (res) {
        onGetRoles();
        setDeleteId("");
      }
    } catch (err) {}
  };

  useEffect(() => {
    setTableData(roles?.data);
    // eslint-disable-next-line
  }, [roles]);

  useEffect(() => {
    onGetRoles();
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <div className="card_rounded mb-4">
        <div className="flex justify-end">
          <Button
            className="!h-10 !text-sm"
            color="primary"
            onClick={() => {
              navigate("/roles/add-role");
            }}
          >
            Add Role
          </Button>
        </div>
      </div>
      {/* <div className={`card_rounded mb-4`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 items-end">
            <ControlledDropDown
              name="filter"
              label="Filter"
              width={100}
              isDefaultOption={DROPDOWN_SEARCH_VALUE}
              options={searchDataOptions}
              control={control}
            />
            <ControlledInput
              placeholder="Enter Search Value"
              name="search"
              label="Search"
              control={control}
            />
            <ControlledDatePicker
              inputClassName="!w-fit"
              name="date"
              placeholder="Select Date"
              label="Date"
              maxDate={new Date()}
              selectsRange
              control={control}
            />
            <Button className="!h-10 !text-sm" type="submit" color="primary">
              Apply
            </Button>
            <Button
              className="!h-10 !text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER, isToast: false });
              }}
            >
              Reset
            </Button>
          </div>
        </form>
      </div> */}
      <div className={`card_rounded mb-4`}>
        <Table
          fileName="Roles"
          isLoading={isRolesLoading}
          columns={columns}
          data={roles?.data || []}
          isExpendable={false}
          setData={setTableData}
          className="!overflow-scroll order_table"
          count={roles?.data?.total}
          tableDataCount={tableData?.length}
          // pagination={filter}
          // handlePaginationChange={(pagination) => {
          //   setFilter({
          //     ...filter,
          //     ...pagination,
          //     isToast: false,
          //   });
          // }}
        >
          {tableData?.map?.((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  className="min-h-[350px]"
                  columns={columns}
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
      <DeleteModal
        text="Are you sure you want to delete this role ?"
        isOpen={!!deleteId}
        onDoneClick={() => onDelete()}
        onHide={() => setDeleteId("")}
        isButtonLoading={isDeleteLoading}
      />
    </>
  );
};

export default Roles;
