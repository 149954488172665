import { useState, Fragment } from "react";

// UI IMPORT
import { Table, TableRow } from "../../ui";
import { SideEnumKeys, sideEnum } from "../../constant";
import OpenOrdersList from "./orders/OpenOrdersList";
import OrderHistoryList from "./orders/OrderHistoryList";
import PositionList from "./orders/PositionList";
import { useLocation } from "react-router-dom";
import useWebSocketContext from "../../context/useWebSocketContext";
import { useKeys } from "../exchange/exchangeSlice";
import { useRole } from "../auth/authSlice";

const Orders = (props: any) => {
  const {
    tradeHistoryItems,
    positions,
    getPositions,
    openPosition,
    positionHistoryItems,
    positionsHistory,
    isPositionHistoryLoading,
    onOrderPlaced,
    isPositionLoading,
    cancelOpenOrder,
    cancelOrderId,
    cancelOpenOrderLoading,
    callUpdateLimitOrderSocket,
    setCancelOrderId,
  } = props;
  const location = useLocation();
  const isFutureTrade = location.pathname.includes("future-trade");
  const role = useRole(isFutureTrade ? "future-trade" : "spot-trade");
  const {
    currentKey: { is_tab_connect },
  } = useKeys();
  const { binanceOpenOrders } = useWebSocketContext();
  const tabs = [];

  if (role.includes("View Future Trade Position History")) {
    tabs.push({
      name: `Positions`,
      label: `Open Positions(${
        is_tab_connect
          ? openPosition?.["exchangeData"]?.position_history?.length || 0
          : openPosition?.["aiData"]?.position_history?.length || 0
      })`,
    });
  }

  if (
    role.includes("View Future Trade Open Order") ||
    role.includes("View Spot Trade Open Order")
  ) {
    tabs.push({
      name: `Open Orders`,
      label: `Open Orders(${binanceOpenOrders?.[is_tab_connect]?.length || 0})`,
    });
  }

  if (
    role.includes("View Future Trade Order History") ||
    role.includes("View Spot Trade Order History")
  ) {
    tabs.push({ name: "Order History", label: "Order History" });
  }

  if (role.includes("View Future Trade Position History")) {
    tabs.push({ name: "Positions History", label: "Positions History" });
  }

  const [activeTab, setActiveTab] = useState(tabs?.[0]?.name);
  // useEffect(() => {
  //   setActiveTab(tabs?.[0]?.name);
  // }, [openPosition]);

  const columnsTradeHistory = [
    {
      title: "Time",
      name: "created_at",
      Cell: (data: any) => (
        // <>{`${new Date(data?.created_at * 1000).toLocaleString()}`}</>
        <>{`${data?.created_at}`}</>
      ),
    },
    {
      title: "Symbol",
      Cell: (data: any) => <>{`${data?.to_coin}${data?.from_coin}`}</>,
    },
    {
      title: "Side",
      Cell: ({ order_type }: { order_type: SideEnumKeys }) => (
        <>{`${sideEnum?.[order_type]}`}</>
      ),
    },
    {
      title: "Price",
      name: "stock_price",
    },
    {
      title: "Quantity",
      name: "order_amount",
    },
    {
      title: "Fee",
      name: "fees",
    },
    // {
    //   title: "Role",
    //   name: "fee",
    // },
    {
      title: "Realized Profit",
      name: "take_profit",
    },
  ];

  const columnsPositionHistory = [
    {
      title: "Time",
      name: "created_at",
      Cell: (data: any) => (
        <>{`${new Date(data?.created_at * 1000).toLocaleString()}`}</>
        // <>{`${data?.created_at}`}</>
      ),
    },
    {
      title: "Symbol",
      Cell: (data: any) => <>{`${data?.to_coin}${data?.from_coin}`}</>,
    },
    {
      title: "Side",
      Cell: ({ order_type }: { order_type: SideEnumKeys }) => (
        <>{`${sideEnum?.[order_type]}`}</>
      ),
    },
    {
      title: "Price",
      name: "stock_price",
    },
    {
      title: "Quantity",
      name: "order_amount",
    },
    {
      title: "Fee",
      name: "fees",
    },
    {
      title: "Role",
      name: "fee",
    },
    {
      title: "Realized Profit",
      name: "take_profit",
    },
  ];

  const prepareTradeHistory = () => {
    return tradeHistoryItems?.data?.length ? tradeHistoryItems?.data : [];
  };

  const preparePositionHistory = () => {
    return positionHistoryItems?.data?.length ? positionHistoryItems?.data : [];
  };

  return (
    <div className={`card_rounded mt-4`}>
      <div>
        <div className="flex gap-4">
          {tabs?.map((item: any) => {
            return (
              <div key={item?.name}>
                <div
                  className={`cursor-pointer flex flex-row justify-center items-center relative activetab_hover ${
                    item?.name === activeTab
                      ? "activetab font-semibold text-primary-950 "
                      : "font-normal text-primary-950 opacity-50"
                  }`}
                  onClick={() => {
                    setActiveTab(item?.name);
                  }}
                >
                  <p>{item?.label}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {activeTab === `Positions` ? (
        <div className="pt-3">
          <PositionList
            positions={positions}
            isPositionLoading={isPositionLoading}
            getPositions={getPositions}
            onOrderPlaced={onOrderPlaced}
            isOpenPosition={true}
          />
        </div>
      ) : activeTab === `Open Orders` ? (
        <OpenOrdersList
          cancelOpenOrder={cancelOpenOrder}
          cancelOrderId={cancelOrderId}
          cancelOpenOrderLoading={cancelOpenOrderLoading}
          callUpdateLimitOrderSocket={callUpdateLimitOrderSocket}
          setCancelOrderId={setCancelOrderId}
        />
      ) : activeTab === "Order History" ? (
        <OrderHistoryList />
      ) : activeTab === "Positions History" ? (
        <PositionList
          positions={positionsHistory}
          isPositionLoading={isPositionHistoryLoading}
          getPositions={getPositions}
          isOpenPosition={false}
        />
      ) : activeTab === "3" ? (
        <div className={`pt-3`} style={{ minHeight: "300px" }}>
          <Table
            columns={columnsTradeHistory}
            data={prepareTradeHistory() || []}
            tableDataCount={prepareTradeHistory()?.length}
            isExpendable={false}
          >
            {prepareTradeHistory()?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    // className="cursor-pointer"
                    columns={columnsTradeHistory}
                    item={item}
                    isExpendable={false}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : activeTab === "4" ? (
        <div className={`pt-3`} style={{ minHeight: "300px" }}>
          <Table
            columns={columnsPositionHistory}
            data={preparePositionHistory() || []}
            tableDataCount={preparePositionHistory()?.length}
            isExpendable={false}
          >
            {preparePositionHistory()?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    // className="cursor-pointer"
                    columns={columnsPositionHistory}
                    item={item}
                    isExpendable={false}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
    </div>
  );
};

export default Orders;
