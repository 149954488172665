import { api } from "../../utills/api";

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.mutation<{ data: any }, any>({
      query: (params: any) => ({
        url: "/user/get",
        body: params,
        method: "POSt",
      }),
    }),
    addUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/user/add",
        body: params,
        method: "POST",
      }),
    }),
    updateUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/user/update",
        body: params,
        method: "POST",
      }),
    }),
    deleteUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/user/delete",
        body: params,
        method: "POST",
      }),
    }),
    getRoles: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/role/get",
        body: params,
        method: "POST",
      }),
    }),
    resetPasswordUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/user/reset-password",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetUsersMutation,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetRolesMutation,
  useResetPasswordUserMutation,
} = usersApi;

export const {
  endpoints: {
    getUsers,
    addUser,
    updateUser,
    deleteUser,
    getRoles,
    resetPasswordUser,
  },
} = usersApi;
