import { InputTypes } from "../theme";
import { Icon, IconKeys } from "../icons";

export interface InputProps {
  className?: string;
  inputClassName?: string;
  lableClassName?: string;
  name: string;
  label?: string;
  value?: any;
  onChange?: (e?: any) => void;
  righticonClick?: (e?: any) => void;
  type?: InputTypes;
  icon?: IconKeys;
  rightIcon?: IconKeys | null;
  placeholder?: string;
  errors?: any;
  isShowError?: any;
  err?: any;
  register?: any;
  disabled?: boolean;
  min?: number;
  autoComplete?: any;
  max?: number;
}

const Input = (props: InputProps) => {
  const {
    className = "",
    inputClassName = "",
    lableClassName = "",
    label,
    icon,
    rightIcon,
    righticonClick,
    errors,
    isShowError = false,
    err,
    register,
    name,
    min,
    max,
    type,
    onChange,
    ...rest
  } = props;

  const getRegister = (register: any) =>
    register && name ? { ...register?.(name) } : {};

  const message = errors?.[name]?.message || err || "";

const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (onChange) {
    let inputValue:any = e.target.value;

    if (type === "number") {
      // Convert to a number only if the input is a valid number
      const parsedValue = parseFloat(inputValue);
      inputValue = inputValue === "" || isNaN(parsedValue) ? null : parsedValue;
    }
    onChange(inputValue);
  }
};

  return (
    <div
      className={` w-full flex flex-col justify-center relative ${className}`}
    >
      {label ? (
        <label className={`pb-1 text-sm text-primary-950 ${lableClassName} `}>
          {label}
        </label>
      ) : null}
      <div className="flex flex-col justify-center">
        {icon ? (
          <Icon className="absolute me-4 text-xl ms-4" name={icon} />
        ) : null}
        <input
          type={type}
          minLength={min}
          title=""
          maxLength={max}
          step="any"
          name={name}
          className={`text-sm text-primary-950 w-full h-8 rounded-sm bg-transparent outline-none border-[1px] border-secondary-200 focus:border-chatlook-primary hover:border-chatlook-primary ${
            icon ? "pl-11" : "pl-3"
          } ${rightIcon ? "pr-11" : "pr-3"} ${
            message ? "border-red-500" : ""
          } ${inputClassName}`}
          onChange={handleChange}
          {...(type === "number" && {
            onFocus: (e) =>
              e.target.addEventListener(
                "wheel",
                (e) => {
                  e.preventDefault();
                },
                { passive: false }
              ),
          })}
          {...getRegister(register)}
          {...rest}
        />
        {rightIcon ? (
          <Icon
            className="absolute right-0 me-4 text-xl"
            name={rightIcon}
            onClick={righticonClick}
          />
        ) : null}
      </div>
      {message && isShowError ? (
        <span className="text-red-500 text-sm">{message}</span>
      ) : null}
    </div>
  );
};

export default Input;
