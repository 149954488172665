import React, { useEffect, useState } from "react";
import MarketInfo from "./MarketInfo";
import Orderbook from "./OrderBook";
import Markets from "./Markets";
import Trades from "./Trades";
import Order from "./Order";
import Orders from "./Orders";
import {
  useGetAllFilledOrderHistoryMutation,
  useGetAllFuturePositionHistoryMutation,
  useGetTradePositionHistoryMutation,
  useGetTradePositionMutation,
} from "./tradeApi";
import {
  useAllFuturePositionHistory,
  useAllOrderHistory,
  useOpenPosition,
  useTradeHistoryItems,
  useTradePositionHistory,
} from "./tradeSlice";
import {
  dateFormatter,
  onFixed,
  prepareLastDate,
  sortByDate,
} from "../../helperFunctions";
import useWebSocketContext from "../../context/useWebSocketContext";
import { useLazyGetAllWalletQuery } from "../wallet/walletApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Chart2 from "./Chart2";
import { useGetSpotMarketMutation } from "../common/commonApi";
import { useKeys } from "../exchange/exchangeSlice";
import { useRole } from "../auth/authSlice";

const Trade: React.FC<any> = () => {
  const navigate = useNavigate();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const { base, quote } = useParams();
  const isFutureTrade = pathname.pathname.includes("future-trade");
  const role = useRole(isFutureTrade ? "future-trade" : "spot-trade");
  const symbol = `${base?.toUpperCase()}${quote?.toUpperCase()}`;

  const [
    getTradPositionHistory,
    { data: positionsHistory, isLoading: isPositionHistoryLoading },
  ] = useGetTradePositionHistoryMutation();

  const [getAllFilledOrderHistory] = useGetAllFilledOrderHistoryMutation();
  const [getAllFuturePositionHistory] =
    useGetAllFuturePositionHistoryMutation();
  const [getTradPosition, { data: positions, isLoading: isPositionLoading }] =
    useGetTradePositionMutation();
  const [getAllWallet] = useLazyGetAllWalletQuery();
  const [getSpotMarket] = useGetSpotMarketMutation();

  const {
    currentKey: { is_connect, is_tab_connect, exchange_name },
  } = useKeys();
  const [cancelOrderId, setCancelOrderId] = useState(null);
  const { binanceOpenOrders, emitOpenOrder } = useWebSocketContext();

  const {
    tickers,
    laravelData,
    emitUpdateLimitOrderEvent,
    emitCreateSpotTradeEvent,
    emitCancelOrderEvent,
  } = useWebSocketContext();

  const tradeHistoryItems = useTradeHistoryItems();
  const positionHistoryItems = useTradePositionHistory();

  const openPosition = useOpenPosition();
  const allOrderHistory = useAllOrderHistory();
  const allFuturePositionHistory = useAllFuturePositionHistory();

  const onOrderPlaced = async (fetchTable: any[], tab?: any, values?: any) => {
    try {
      if (fetchTable?.includes("All Order History")) {
        if (isFutureTrade) {
          if (role.includes("View Future Trade Position History")) {
            await getAllFuturePositionHistory({
              group_name: pathnames?.[1],
            }).unwrap();
          }
        } else {
          if (role.includes("View Spot Trade Order History")) {
            await getAllFilledOrderHistory({
              group_name: pathnames?.[1],
            }).unwrap();
          }
        }
      }
    } catch (err) {}
  };

  const onPostionPlaced = async (fetchTable: any, tab?: any, values?: any) => {
    try {
      if (fetchTable === "Position History") {
        let dates = {
          start_date: dateFormatter(new Date()),
          end_date: dateFormatter(new Date(), "end"),
        };

        if (tab === 1) {
          dates = {
            start_date: dateFormatter(prepareLastDate(7)),
            end_date: dateFormatter(new Date(), "end"),
          };
        }

        if (tab === 2) {
          dates = {
            start_date: dateFormatter(prepareLastDate(31)),
            end_date: dateFormatter(new Date(), "end"),
          };
        }

        if (tab === 3) {
          dates = {
            start_date: dateFormatter(prepareLastDate(31 * 3)),
            end_date: dateFormatter(new Date(), "end"),
          };
        }

        if (tab === 4) {
          dates = {
            start_date: dateFormatter(values?.start_date),
            end_date: dateFormatter(values?.end_date, "end"),
          };
        }

        const payload: any = {
          ...values,
          ...dates,
          is_connect: is_connect,
          exchange_name: exchange_name,
        };
        delete payload["isFilter"];
        await getTradPositionHistory({
          ...payload,
          group_name: pathnames?.[1],
        }).unwrap();
      } else if (fetchTable === "Position List") {
        await getTradPosition({
          group_name: pathnames?.[1],
        }).unwrap();
      }
    } catch (error) {}
  };

  const prepareMarkerData = () => {
    try {
      if (isFutureTrade) {
        const newData: any[] = [];

        sortByDate(
          allFuturePositionHistory?.data || [],
          "created_at_ist",
          "Desc"
        )?.forEach((item: any) => {
          const tradeTime = new Date(item?.created_at_ist).getTime() / 1000;

          if (+tradeTime && symbol === `${item?.to_coin}${item?.from_coin}`) {
            newData.unshift({
              time: +tradeTime,
              position: item?.side === "buy" ? "aboveBar" : "belowBar",
              color: item?.side === "buy" ? "green" : "red",
              shape: item?.side === "buy" ? "arrowUp" : "arrowDown",
              text: item?.side === "buy" ? "BUY" : "SELL",
            });
          }
        });

        return newData;
      } else {
        const newData: any[] = [];
        allOrderHistory?.data?.forEach((item: any) => {
          const tradeTime = new Date(item?.created_at_ist).getTime() / 1000;

          if (+tradeTime && symbol === `${item?.to_coin}${item?.from_coin}`) {
            newData.unshift({
              time: +tradeTime,
              position: item?.side === "buy" ? "aboveBar" : "belowBar",
              color: item?.side === "buy" ? "green" : "red",
              shape: item?.side === "buy" ? "arrowUp" : "arrowDown",
              text: item?.side === "buy" ? "BUY" : "SELL",
            });
          }
        });
        return newData;
        // return [
        //   {
        //     time: new Date(new Date().setDate(new Date().getDate() - 3)).getTime(),
        //     position: "aboveBar",
        //     color: "green",
        //     shape: "arrowUp",
        //     text: "BUY",
        //   },
        // ];
      }
    } catch (err) {}
  };

  const cancelOpenOrder = async (data: any, type: any = "single") => {
    try {
      if (type === "single" && is_connect === 0) {
        setCancelOrderId(
          data?.order_uuid || data?.info?.orderId || data?.orderId
        );
      }
      let allBinanceOrderIds = "";
      let allCancelOrder = [];

      // if (keys?.is_connect) {
      (binanceOpenOrders?.[is_tab_connect] || [])?.forEach?.((item: any) => {
        allBinanceOrderIds = allBinanceOrderIds.concat(
          allBinanceOrderIds === "" ? "" : ",",
          item?.binance_order_id || item?.info?.orderId || item?.orderId
        );
      });
      // }
      // if (keys?.is_connect) {
      allCancelOrder = (binanceOpenOrders?.[is_tab_connect] || [])?.map?.(
        (item: any) => {
          return {
            id: isFutureTrade ? item?.orderId : item?.binance_order_id,
            symbol: item?.symbol,
          };
        }
      );
      // }
      const payload = {
        ...(type === "single" && {
          order_uuid: data?.order_uuid || data?.info?.orderId,
        }),
        ...(!is_connect
          ? {}
          : type === "single"
          ? { binance_order_id: data?.binance_order_id }
          : { binance_order_id: allBinanceOrderIds }),
        ...(is_connect && {
          binance_order_id:
            type === "single"
              ? data?.info?.orderId || data?.orderId || data?.binance_order_id
              : allBinanceOrderIds,
          cancelOrder: allCancelOrder,
        }),
        type,
        is_connect: is_connect ? 1 : 0,
        order_type: isFutureTrade ? 1 : 2,
        symbol: data?.symbol,
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
      };
      emitCancelOrderEvent(payload);
      getAllWallet({
        is_connect: is_connect,
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
      });
      setCancelOrderId(null);
    } catch (err) {
      setCancelOrderId(null);
    }
  };

  const callBuySocket = async (payload: any, fn: any) => {
    const index = tickers?.findIndex((i: any) => i?.s === `${symbol}`);
    let newPayload = {
      ...payload,
      side: "buy",
      amount: payload?.order_amount,
      price:
        payload?.type === 1
          ? onFixed(tickers?.[index]?.c)
          : payload?.limit_price,
      symbol: `${payload?.to_coin}/${payload?.from_coin}`,
      is_connect: is_connect ? 1 : 0,
      exchange_name: exchange_name,
      group_name: pathnames?.[1],
    };
    delete newPayload?.["order_amount"];
    delete newPayload?.["limit_price"];
    await emitCreateSpotTradeEvent(newPayload, fn);
  };

  const callSellSocket = async (payload: any, fn: any) => {
    const index = tickers?.findIndex((i: any) => i?.s === `${symbol}`);
    let newPayload = {
      ...payload,
      side: "sell",
      amount: payload?.order_amount,
      price:
        payload?.type === 1
          ? onFixed(tickers?.[index]?.c)
          : payload?.limit_price,
      symbol: `${payload?.from_coin}/${payload?.to_coin}`,
      is_connect: is_connect ? 1 : 0,
      exchange_name: exchange_name,
      group_name: pathnames?.[1],
    };
    delete newPayload?.["order_amount"];
    delete newPayload?.["limit_price"];
    await emitCreateSpotTradeEvent(newPayload, fn);
  };

  const callUpdateLimitOrderSocket = (payload: any, fn: any) => {
    emitUpdateLimitOrderEvent(payload, fn);
  };

  const onGetAllSpotMarket = async () => {
    try {
      await getSpotMarket({
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (isFutureTrade && role.includes("View Future Trade Open Order")) {
      emitOpenOrder({
        is_connect: is_connect,
        order_type: isFutureTrade ? 1 : 2,
        is_tab_connect: is_tab_connect,
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
      });
    }
    getAllWallet({
      is_connect: is_connect,
      exchange_name: exchange_name,
      group_name: pathnames?.[1],
    });
    // eslint-disable-next-line
  }, [laravelData, isFutureTrade]);

  useEffect(() => {
    onGetAllSpotMarket();
    // onGetKeys();
    onOrderPlaced(["All Order History"]);
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   const fn = async () => {
  //     try {
  //       if (isFutureTrade) {
  //         if (role.includes("View Future Trade Position History")) {
  //           await getAllFuturePositionHistory({
  //             group_name: pathnames?.[1],
  //           }).unwrap();
  //         }
  //       } else {
  //         await getAllFilledOrderHistory({
  //           group_name: pathnames?.[1],
  //         }).unwrap();
  //       }
  //     } catch (err) {}
  //   };

  //   fn();
  // }, []);

  if (!role?.length) {
    navigate("/");
  }

  return (
    <div className="p-4">
      <MarketInfo />
      <div className="flex gap-4 trade_first">
        <div className="w-1/5 card_rounded overflow-scroll">
          <Orderbook />
        </div>
        <div className="w-3/5 card_rounded">
          <div className="h-[400px] border-t-3 border-secondary-200  bg-secondary-950 p-[2px]">
            <Chart2 markerData={prepareMarkerData()} symbol={symbol} />
            {/* <Chart /> */}
          </div>
          <Order
            onOrderPlaced={onOrderPlaced}
            callBuySocket={callBuySocket}
            callSellSocket={callSellSocket}
            getTradPosition={getTradPosition}
          />
        </div>
        <div className="w-1/5 card_rounded overflow-scroll">
          <Markets />
          <Trades />
        </div>
      </div>
      <Orders
        tradeHistoryItems={tradeHistoryItems}
        openPosition={openPosition}
        positions={positions}
        getPositions={onPostionPlaced}
        isPositionLoading={isPositionLoading}
        positionsHistory={positionsHistory}
        isPositionHistoryLoading={isPositionHistoryLoading}
        positionHistoryItems={positionHistoryItems}
        onOrderPlaced={onOrderPlaced}
        cancelOpenOrder={cancelOpenOrder}
        cancelOrderId={cancelOrderId}
        setCancelOrderId={setCancelOrderId}
        callUpdateLimitOrderSocket={callUpdateLimitOrderSocket}
      />
    </div>
  );
};

export default Trade;
