import { MdOutlineCancel } from "react-icons/md";
import { Button, Loader } from "../../ui";

const DeleteModal = (props: any) => {
  const {
    isOpen,
    isLoading,
    onHide,
    onDoneClick,
    text = "Are you sure you want to delete this?",
    doneText = "Yes",
    cancelText = "No",
  } = props;

  const onClose = () => {
    onHide();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
        onClick={() => {
          onClose();
        }}
      />

      <div className="modal-container w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-7/12 sm:w-7/12 z-50 mx-auto overflow-y-auto">
        <div className="modal-header">
          <h4 className="text-lg font-semibold text-primary-950">Delete</h4>
          <MdOutlineCancel
            color="var(--primary-600--)"
            size={24}
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <div className="modal-content py-12! text-center ">
          <p className="text-lg text-primary-black">{text}</p>
        </div>
        <div className="modal-footer w-full">
          <Button variant="Transaparent" onClick={onClose}>
            {cancelText}
          </Button>
          {!isLoading ? (
            <Button onClick={onDoneClick}>{doneText}</Button>
          ) : (
            <div className="flex item-center justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
