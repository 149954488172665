import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, CheckBox, ControlledInput, Loader } from "../../ui";
import { responseToaster } from "../../helperFunctions";
import {
  useAddRoleMutation,
  useGetPermissionsByGroupMutation,
  useGetRoleByIDMutation,
  useUpdateRoleMutation,
} from "./rolesApi";

const UpdateRole = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [checkedPermissions, setCheckedPermissions] = useState<string[]>([]);
  const [roleID, setRoleID] = useState<any>();
  const [getRoleByID, { isLoading }] = useGetRoleByIDMutation();
  const [addRole, { isLoading: isAddLoading }] = useAddRoleMutation();
  const [updateRole, { isLoading: isUpdateLoading }] = useUpdateRoleMutation();
  const [getPermissions, { data: permissionsRes }] =
    useGetPermissionsByGroupMutation();

  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Please enter a role name"),
    permissions: Yup.array().min(1, "Please check a permission"),
  });

  const onGetDataById = async () => {
    try {
      const payload = {
        id,
      };
      const res = await getRoleByID({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
      if (res) {
        setFormData(res?.data?.[0]);
        setRoleID(res?.data?.[0]?.id);
      }
    } catch (err) {}
  };

  const getAllPermissionsAPI = async () => {
    try {
      await getPermissions({
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err) {}
  };

  const getAllPermissions = () => {
    const arr: string[] = [];

    permissionsRes?.data?.permissions?.forEach((item: any) => {
      const value: string[] = Object.keys(Object.values(item)?.[0] || {}) || [];
      arr.push(...value);
    });

    return arr;
  };

  const setFormData = (data: any) => {
    setValue("name", data?.role_name);
    setCheckedPermissions(
      (data?.permissions || []).map?.((permission: any) => {
        return permission?.name;
      })
    );
  };

  const onSubmit = async (formData: any) => {
    try {
      const payload = {
        ...formData,
        permissions: checkedPermissions,
      };
      let res;
      if (id) {
        res = await updateRole({
          ...payload,
          id: roleID,
          group_name: pathnames?.[1],
        }).unwrap();
      } else {
        res = await addRole({
          ...payload,
          group_name: pathnames?.[1],
        }).unwrap();
      }
      if (res) {
        onClose();
      }
      responseToaster(res);
    } catch (error) {}
  };

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleCheckboxChange = (
    event: Event,
    group_name: string,
    level: string | null
  ) => {
    const { value, checked } = event.target as HTMLInputElement;
    setCheckedPermissions((prev) => {
      let updatedPermissions = [...prev];
      if (checked) {
        if (level === "successor" && !hasPredecessorChecked(group_name)) {
          return prev;
        }
        clearErrors("permissions");
        const relatedPermissions = getRelatedPermissions(group_name, level);
        updatedPermissions = [...prev, value, ...relatedPermissions];
      } else {
        if (
          level &&
          level.includes("parent") &&
          hasRelatedPermissionsChecked(group_name, level)
        ) {
          return prev;
        }
        updatedPermissions = prev.filter((permission) => permission !== value);
      }
      return updatedPermissions;
    });
  };

  const getRelatedPermissions = (
    group_name: string,
    level: string | null
  ): string[] => {
    const arr: string[] = [];
    if (level && level.includes("child")) {
      getAllPermissionByGroupNameWithLevel(group_name)?.forEach(
        ([permission, l]: any) => {
          if (level?.replace("child", "parent") === l) {
            arr.push(permission);
          }
        }
      );
    }
    return arr;
  };

  const hasRelatedPermissionsChecked = (
    group_name: string,
    level: string
  ): boolean => {
    return getAllPermissionByGroupNameWithLevel(group_name)?.some(
      ([permission, l]) => {
        if (level?.replace("parent", "child") === l) {
          return checkedPermissions.includes(permission);
        }
        return false;
      }
    );
  };

  const hasPredecessorChecked = (group_name: string): boolean => {
    let bool = false;
    getAllPermissionByGroupNameWithLevel(group_name)?.forEach(
      ([permission, l]) => {
        if ("predecessor" === l && checkedPermissions.includes(permission)) {
          bool = true;
        }
      }
    );
    return bool;
  };

  const hasRelatedPredecessorChecked = (
    group_name: string,
    p: string
  ): boolean => {
    let bool = false;
    getAllPermissionByGroupNameWithLevel(group_name)
      ?.filter(([permission, _]) => permission !== p)
      ?.forEach(([permission, l]) => {
        if ("predecessor" === l && checkedPermissions.includes(permission)) {
          bool = true;
        }
      });
    return bool;
  };

  const hasSuccessorChecked = (group_name: string): boolean => {
    let bool = false;
    getAllPermissionByGroupNameWithLevel(group_name)?.forEach(
      ([permission, l]) => {
        if ("successor" === l && checkedPermissions.includes(permission)) {
          bool = true;
        }
      }
    );
    return bool;
  };

  const handleAllCheckboxChange = (event: Event, group_name: string) => {
    const { checked } = event.target as HTMLInputElement;
    setCheckedPermissions((prev) => {
      let updatedPermissions = [...prev];
      const relatedPermissions: string[] =
        getAllPermissionByGroupName(group_name);
      if (checked) {
        clearErrors("permissions");
        updatedPermissions = [...prev, ...relatedPermissions];
      } else {
        updatedPermissions = prev.filter(
          (permission) => !relatedPermissions.includes(permission)
        );
      }
      return updatedPermissions;
    });
  };

  const getAllPermissionByGroupName = (group_name: string) => {
    return Object.keys(
      Object.values(
        permissionsRes?.data?.permissions?.find(
          (item: any) => Object.keys(item)?.[0] === group_name
        ) || {}
      )?.[0] || {}
    );
  };

  const getAllPermissionByGroupNameWithLevel = (group_name: string) => {
    return Object.entries(
      Object.values(
        permissionsRes?.data?.permissions?.find(
          (item: any) => Object.keys(item)?.[0] === group_name
        ) || {}
      )?.[0] || {}
    );
  };

  const isAllChecked = (group_name: string) => {
    return (
      getAllPermissionByGroupName(group_name)?.filter((item: any) =>
        checkedPermissions?.includes(item)
      ).length === getAllPermissionByGroupName(group_name)?.length
    );
  };

  const handleSuperAllCheckboxChange = (event: Event) => {
    const { checked } = event.target as HTMLInputElement;
    setCheckedPermissions(() => {
      if (checked) {
        clearErrors("permissions");
        return getAllPermissions();
      } else {
        return [];
      }
    });
  };

  const isSuperAllChecked = () => {
    return getAllPermissions()?.length &&
      getAllPermissions()?.filter((item) => checkedPermissions?.includes(item))
        .length === getAllPermissions()?.length
      ? true
      : false;
  };

  const onClose = () => {
    setCheckedPermissions([]);
    reset();
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      onGetDataById();
    }
    getAllPermissionsAPI();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setValue("permissions", checkedPermissions);
    // eslint-disable-next-line
  }, [checkedPermissions, setValue]);

  return (
    <>
      <div className="card_rounded mb-5">
        <div className="flex flex-wrap items-center justify-between pt-0">
          <h4 className="main-title">
            <b>{`${id ? "Edit" : "Add"} Roles`}</b>
          </h4>
          {!isLoading ? (
            <div className="addrole-footer">
              <Button
                className="submitbutton"
                isLoading={isAddLoading || isUpdateLoading}
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {id ? "Update" : "Add"} Role
              </Button>
              <Button type="button" className="graybutton" onClick={onClose}>
                Cancel
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="card_rounded position-relative">
        <div className="flex-column items-start">
          <div className="addrole_header w-100">
            <div className="flex flex-wrap items-start justify-between py-50">
              <div className="flex items-center gap-2 w-25">
                {/* <div className="select-div input-field text-nowrap mt-25">
                  <span>Role Name</span>
                </div> */}
                <div className="text-nowrap mt-25">
                  <strong>Role Name</strong>
                </div>
                <ControlledInput
                  name="name"
                  // label="Name"
                  placeholder="Enter Role Name"
                  errors={errors}
                  isShowError
                  control={control}
                  className="w-100"
                />
              </div>
              <div className="flex py-2 gap-2">
                <CheckBox
                  checked={isSuperAllChecked()}
                  onChange={(e) => handleSuperAllCheckboxChange(e)}
                />
                <div>
                  <strong>Select All Permission</strong>
                </div>
              </div>
            </div>
          </div>

          <form>
            <div>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {errors?.permissions?.message ? (
                    <div style={{ color: "red", fontSize: 12 }}>
                      {errors?.permissions?.message}
                    </div>
                  ) : null}
                  <div>
                    {permissionsRes?.data?.permissions?.map?.((item: any) => {
                      const key = Object.keys(item)?.[0];
                      const value = Object.values(item)?.[0];
                      return (
                        <div
                          className="flex gap-4 py-2 border-b border-secondary-200"
                          key={key}
                        >
                          <div className="w-[25%] border-r border-secondary-200">
                            <div className="flex gap-2">
                              <CheckBox
                                value={key}
                                checked={isAllChecked(key)}
                                onChange={(e) =>
                                  handleAllCheckboxChange(e, key)
                                }
                              />
                              <div>
                                <strong>{key}</strong>
                              </div>
                            </div>
                          </div>
                          <div className="w-[75%]">
                            <div className="grid grid-cols-2 gap-2">
                              {Object.entries(value || {})?.map(
                                ([permission, level]) => {
                                  const disabled =
                                    (level &&
                                      level.includes("parent") &&
                                      hasRelatedPermissionsChecked(
                                        key,
                                        level
                                      )) ||
                                    (level === "successor" &&
                                      !hasPredecessorChecked(key)) ||
                                    (level === "predecessor" &&
                                      checkedPermissions.includes(permission) &&
                                      !hasRelatedPredecessorChecked(
                                        key,
                                        permission
                                      ) &&
                                      hasSuccessorChecked(key));
                                  return (
                                    <div key={permission} className="">
                                      <CheckBox
                                        value={permission}
                                        checked={checkedPermissions.includes(
                                          permission
                                        )}
                                        label={permission}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, key, level)
                                        }
                                        className="checkbox_margin"
                                        disabled={disabled}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateRole;
