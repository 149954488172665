import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { walletHistoryApi } from "./walletHistoryApi";

interface WalletState {
  depositHistory: any;
  withdrawalHistory: any;
}

const initialState: WalletState = {
  depositHistory: {},
  withdrawalHistory: {},
};

export const walletSlice = createSlice({
  name: "walletHistory",
  initialState,
  reducers: {
    clearWalletHistory: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      walletHistoryApi.endpoints.getDepositHistory.matchFulfilled,
      (state, { payload }) => {
        state.depositHistory = payload || {};
      }
    );
    builder.addMatcher(
      walletHistoryApi.endpoints.getWithdrawalHistory.matchFulfilled,
      (state, { payload }) => {
        state.withdrawalHistory = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default walletSlice.reducer;
export const { clearWalletHistory } = walletSlice.actions;

export const selectDepositHistoryData = (state: RootState) => state.walletHistory.depositHistory;
export const useDepositHistoryData = () => {
  const depositHistory = useSelector(selectDepositHistoryData);
  return useMemo(() => depositHistory, [depositHistory]);
};

export const selectWithdrawalHistoryData = (state: RootState) =>
  state.walletHistory.withdrawalHistory;
export const useWithdrawalHistoryData = () => {
  const withdrawalHistory = useSelector(selectWithdrawalHistoryData);
  return useMemo(() => withdrawalHistory, [withdrawalHistory]);
};
