import { useEffect, useState } from "react";
import { onFixed } from "../../helperFunctions";
import { walletCoins } from "./mock";
import useWebSocketContext from "../../context/useWebSocketContext";
import DepositModal from "./DepositModal";
import WithdrawModal from "./WithdrawModal";
import ConvertModal from "./ConvertModal";
import { useRole } from "../auth/authSlice";

export const WalletBalance = ({
  totalUSD,
  totalPnL,
  totalPnLPer,
  is_tab_connect,
}: any) => {
  const { tickers, loading } = useWebSocketContext();

  const [totalCoin] = useState(walletCoins?.[1]?.value); // TODO: static USDT for now
  const [amountOfCoin, setAmountOfCoin] = useState();
  const [openModal, setOpenModal] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const role = useRole("wallet");

  const prepareIsLoading = () =>
    !!(isLoading || !tickers || loading?.closingData);
  // const coinsDefine = () =>
  //   Object?.entries(coinUsd ?? {})?.forEach(([key, val]: any) => {
  //     if (key?.split(":")?.includes(totalCoin)) {
  //       const coinVal: any = totalUSD / val;
  //       setAmountOfCoin(coinVal);
  //     }
  //   });

  const coinsDefine = () => {
    const index = tickers?.findIndex((i: any) => i?.s === `${totalCoin}USDT`);
    const price = totalCoin === "USDT" ? 1 : tickers?.[index]?.c;
    const coinVal: any = totalUSD / price;
    setAmountOfCoin(coinVal);
    setIsLoading(false);
  };

  useEffect(() => {
    if (totalCoin) {
      coinsDefine();
    }
    // eslint-disable-next-line
  }, [totalCoin, totalUSD]);

  const onClose = () => {
    setOpenModal("");
  };

  return (
    <>
      <div className="card_rounded mb-4">
        <div className="flex justify-between">
          <div className="text-primary-950">
            <h4 className="text-xl font-semibold">Estimated Balance</h4>
            <div className="flex items-end gap-2 py-4">
              {/* <DropDown
                name="totalCoin"
                placeholder=""
                options={walletCoins}
                value={totalCoin}
                onChange={(item: any) => {
                  setTotalCoin(item);
                }}
              /> */}
              <p className="text-primary-950 text-2xl font-extrabold leading-6">
                {prepareIsLoading() ? "--" : amountOfCoin || "0"}
              </p>
              <span className="font-semibold text-xs">USDT</span>
            </div>
            <p className="text-sm pb-2">
              ≈ {prepareIsLoading() ? "--" : `$ ${totalUSD}`} USD
            </p>
            <div className="flex gap-3 items-center">
              {(!is_tab_connect && role.includes("View AI PnL")) ||
              (is_tab_connect && role.includes("View Exchange PnL")) ? (
                <>
                  <p className="text-sm">Today‘s PnL </p>
                  <div className="text-sm">
                    {totalPnL && totalPnLPer ? (
                      <>
                        <span
                          className={`${
                            prepareIsLoading()
                              ? "text-primary-950"
                              : totalPnL >= 0
                              ? "text-primary-green"
                              : "text-primary-red"
                          }`}
                        >
                          {prepareIsLoading() ? "--" : totalPnL}
                        </span>
                        <span
                          title={`${totalPnLPer}%`}
                          className={`cursor-pointer ${
                            prepareIsLoading()
                              ? "text-primary-950"
                              : totalPnLPer >= 0
                              ? "text-primary-green"
                              : "text-primary-red"
                          }`}
                        >
                          ({prepareIsLoading() ? "--" : onFixed(totalPnLPer, 2)}
                          %)
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div>
            <div className="flex gap-2 ">
              {role?.includes("Deposit") ? (
                <>
                  <span
                    onClick={() => setOpenModal("deposit")}
                    className="p-2 font-semibold rounded-sm border border-secondary-400 text-primary-400 cursor-pointer"
                  >
                    Deposit
                  </span>
                </>
              ) : null}
              {/* <span
                onClick={() => setOpenModal("withdraw")}
                className="p-2 rounded-sm border border-secondary-400 text-primary-400 cursor-pointer"
              >
                Withdraw
              </span> */}
              {role?.includes("Convert") ? (
                <>
                  <span
                    onClick={() => setOpenModal("convert")}
                    className="p-2 font-semibold rounded-sm border border-secondary-400 text-primary-400 cursor-pointer"
                  >
                    Convert
                  </span>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {openModal === "deposit" ? (
        <>
          <DepositModal onClose={onClose} />
        </>
      ) : openModal === "withdraw" ? (
        <>
          <WithdrawModal onClose={onClose} />
        </>
      ) : openModal === "convert" ? (
        <>
          <ConvertModal onClose={onClose} />
        </>
      ) : null}
    </>
  );
};
