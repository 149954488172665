import React, { memo } from "react";
import { IconProps } from ".";

export interface TransferIconProps extends IconProps {}
const TransferIcon = (props: TransferIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M8 3 4 7l4 4" className={`${pathClassNames?.[0] || ""}`} />
      <path d="M4 7h16" className={`${pathClassNames?.[1] || ""}`} />
      <path d="m16 21 4-4-4-4" className={`${pathClassNames?.[2] || ""}`} />
      <path d="M20 17H4" className={`${pathClassNames?.[3] || ""}`} />
    </svg>
  );
};

const MemoTransferIcon = memo(TransferIcon);
export default MemoTransferIcon;
