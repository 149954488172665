import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { strategyApi } from "./strategyApi";

interface StrategyState {
  strategyList: any;
  allStrategyHistory: any;
}

const initialState: StrategyState = {
  strategyList: {},
  allStrategyHistory: {},
};

export const strategySlice = createSlice({
  name: "strategy",
  initialState,
  reducers: {
    clearStrategy: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      strategyApi.endpoints.getStrategy.matchFulfilled,
      (state: any, { payload }) => {
        state.strategyList = payload || {};
      }
    );
    builder.addMatcher(
      strategyApi.endpoints.getAllStrategyHistory.matchFulfilled,
      (state: any, { payload }) => {
        state.allStrategyHistory = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default strategySlice.reducer;
export const { clearStrategy } = strategySlice.actions;

export const selectStrategyList = (state: RootState) =>
  state.strategy.strategyList;
export const useStrategyList = () => {
  const data = useSelector(selectStrategyList);
  return useMemo(() => data, [data]);
};

export const selectAllStrategyHistory = (state: RootState) =>
  state.strategy.allStrategyHistory;
export const useAllStrategyHistory = () => {
  const data = useSelector(selectAllStrategyHistory);
  return useMemo(() => data, [data]);
};
