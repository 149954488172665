import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { commonApi } from "./commonApi";

interface CommonState {
  isDrawerOpen: boolean;
  isThemeOpen: boolean;
  coins: any;
  activeCoins?: any;
  users: any;
  spotMarket: any;
  symbols: any;
  platforms: any;
}

const initialState: CommonState = {
  isDrawerOpen: true,
  isThemeOpen: false,
  coins: [],
  activeCoins: [],
  users: [],
  spotMarket: {},
  symbols: {},
  platforms: {},
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleDrawer: (
      state: CommonState,
      { payload }: PayloadAction<boolean | undefined>
    ) => {
      state.isDrawerOpen =
        payload === undefined ? !state.isDrawerOpen : payload;
    },
    toggleTheme: (
      state: CommonState,
      { payload }: PayloadAction<boolean | undefined>
    ) => {
      state.isThemeOpen = !!payload;
    },
    clearCommon: (state) => ({
      ...initialState,
      spotMarket: state.spotMarket,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      commonApi.endpoints.getCoins.matchFulfilled,
      (state, { payload }) => {
        state.coins = payload || [];
      }
    );
    builder.addMatcher(
      commonApi.endpoints.getActiveCoins.matchFulfilled,
      (state, { payload }) => {
        state.activeCoins = payload || [];
      }
    );
    builder.addMatcher(
      commonApi.endpoints.getSpotMarket.matchFulfilled,
      (state, { payload }) => {
        state.spotMarket = payload || {};
      }
    );
    builder.addMatcher(
      commonApi.endpoints.getSymbols.matchFulfilled,
      (state, { payload }) => {
        state.symbols = payload || {};
      }
    );
    builder.addMatcher(
      commonApi.endpoints.getPlatforms.matchFulfilled,
      (state, { payload }) => {
        state.platforms = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default commonSlice.reducer;
export const { toggleDrawer, toggleTheme, clearCommon } = commonSlice.actions;

export const selectIsDrawerOpen = (state: RootState) =>
  state.common.isDrawerOpen;
export const useIsDrawerOpen = () => {
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  return useMemo(() => isDrawerOpen, [isDrawerOpen]);
};

export const selectIsThemeOpen = (state: RootState) => state.common.isThemeOpen;
export const useIsThemeOpen = () => {
  const isThemeOpen = useSelector(selectIsThemeOpen);
  return useMemo(() => isThemeOpen, [isThemeOpen]);
};

export const selectCoins = (state: RootState) => state.common.coins;
export const useCoins = () => {
  const coins = useSelector(selectCoins);
  return useMemo(() => coins, [coins]);
};

export const selectActiveCoins = (state: RootState) => state.common.activeCoins;
export const useActiveCoins = () => {
  const activeCoins = useSelector(selectActiveCoins);
  return useMemo(() => activeCoins, [activeCoins]);
};

export const selectUsers = (state: RootState) => state.common.users;
export const useUsers = () => {
  const users = useSelector(selectUsers);
  return useMemo(() => users, [users]);
};

export const selectSpotMarket = (state: RootState) => state.common.spotMarket;
export const useSpotMarket = () => {
  const spotMarket = useSelector(selectSpotMarket);
  return useMemo(() => spotMarket, [spotMarket]);
};

export const selectSymbols = (state: RootState) => state.common.symbols;
export const useSymbols = () => {
  const symbols = useSelector(selectSymbols);
  return useMemo(() => symbols, [symbols]);
};

export const selectPlatforms = (state: RootState) => state.common.platforms;
export const usePlatforms = () => {
  const platforms = useSelector(selectPlatforms);
  return useMemo(() => platforms, [platforms]);
};
