import React, { PropsWithChildren, useEffect, useState } from "react";

interface MediaProviderProps extends PropsWithChildren<{}> {}
interface MediaContext {
  media: any;
  setMedia: any;
}
// eslint-disable-next-line
export const MediaContext = React.createContext<MediaContext | null>(null);

const MediaProvider = ({ children }: MediaProviderProps) => {
  const [media, setMedia] = useState<any>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = (e: any) => {
      setMedia({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <MediaContext.Provider value={{ media, setMedia }}>
      {children}
    </MediaContext.Provider>
  );
};

export default MediaProvider;
