import React, { memo } from "react";
import { IconProps } from ".";

export interface AddIconProps extends IconProps {
}
const AddIcon = (props: AddIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <circle cx="12" cy="12" r="10" className={`${pathClassNames?.[0] || ""}`} />
      <path d="M8 12h8" className={`${pathClassNames?.[1] || ""}`} />
      <path d="M12 8v8" className={`${pathClassNames?.[2] || ""}`} />
    </svg>
  );
};

const MemoAddIcon = memo(AddIcon);
export default MemoAddIcon;
