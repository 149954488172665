import { memo } from "react";
import { IconProps } from ".";

export interface StrategyIconProps extends IconProps {}
const StrategyIcon = (props: StrategyIconProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 24,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path d="m16.02 12 5.48 3.13a1 1 0 0 1 0 1.74L13 21.74a2 2 0 0 1-2 0l-8.5-4.87a1 1 0 0 1 0-1.74L7.98 12" />
      <path d="M13 13.74a2 2 0 0 1-2 0L2.5 8.87a1 1 0 0 1 0-1.74L11 2.26a2 2 0 0 1 2 0l8.5 4.87a1 1 0 0 1 0 1.74Z" />
    </svg>
  );
};

const MemoStrategyIcon = memo(StrategyIcon);
export default MemoStrategyIcon;
