// UI IMPORT
import { Button, ControlledDropDown, ControlledInput } from "../../ui";

// PROJECT IMPORT
import { responseToaster, showToaster } from "../../helperFunctions";
import {
  useAddUserMutation,
  useGetRolesMutation,
  useUpdateUserMutation,
} from "./usersApi";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useRoleList } from "./usersSlice";
import { useLocation } from "react-router-dom";

const UserModal = ({ onHide, isOpen, onGet }: any) => {
  const isEdit = !!isOpen?.user_uuid;
  const [isCopy, setIsCopy] = useState<any>(false);
  const [addUser, { isLoading: isAddUserLoading }] = useAddUserMutation();
  const [updateUser, { isLoading: isUpdateUserLoading }] =
    useUpdateUserMutation();
  const [getRoles] = useGetRolesMutation();

  const rolelist = useRoleList();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const schema: any = yup.object({
    username: yup.string().required("Please enter a username"),
    email: yup.string().required("Please enter a email"),
    role: yup.string().required("Please select a role"),
  });

  const initialValues: any = {
    username: "",
    role: "",
    email: "",
  };

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver<any>(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async () => {
    try {
      const values = getValues();
      const payload = {
        ...values,
      };
      let res;
      if (isEdit) {
        res = await updateUser({
          ...payload,
          user_uuid: isOpen?.user_uuid,
          group_name: pathnames?.[1],
        }).unwrap();
        onHide();
      } else {
        res = await addUser({
          ...payload,
          group_name: pathnames?.[1],
        }).unwrap();
        setIsCopy(res);
      }
      responseToaster(res);
      reset();
      onGet();
    } catch (err) {}
  };

  const onGetRoles = async () => {
    try {
      await getRoles({
        group_name: pathnames?.[1],
      });
    } catch (err) {}
  };

  const prepareRoleOption = () => {
    const options = rolelist?.data
      ?.slice()
      ?.sort((a: any, b: any) => a?.role_name?.localeCompare(b?.role_name))
      ?.map((val: any) => ({
        value: val?.role_name,
        label: val?.role_name,
      }));
    return options;
  };

  useEffect(() => {
    if (isEdit) {
      setValue("username", isOpen?.username);
      setValue("role", isOpen?.role);
      setValue("email", isOpen?.email);
    }
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    onGetRoles();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
          onClick={() => {
            onHide();
          }}
        />

        <div className="modal-container bg-secondary-50 w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-7/12 sm:w-7/12 md:max-w-md mx-auto shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-9 px-6">
            {isCopy?.data?.password ? (
              <>
                <div>
                  <div className="modal-header">
                    <h3 className="modal-title mt-0">
                      You're in! User account created.
                    </h3>
                    <i
                      onClick={() => {
                        onHide();
                      }}
                      className="fa-solid fa-xmark"
                    ></i>
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      padding: 50,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>
                      <span>Email :- </span>
                      <span className="bold">{isCopy?.data?.email}</span>
                    </span>
                    <span>
                      <span>Password :- </span>
                      <span className="bold">{isCopy?.data?.password}</span>
                    </span>
                  </div>
                  <div className="modal-footer">
                    <div className="flex justify-end button-space">
                      <CopyToClipboard
                        text={`${isCopy?.data?.email} ${isCopy?.data?.password}`}
                      >
                        <Button
                          className="submitbutton"
                          onClick={() => {
                            onHide();
                            showToaster("Copied");
                          }}
                        >
                          Copy & Close
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="text-center">
                  <strong className="text-primary-950">{`${
                    isEdit ? "Edit" : "Add"
                  } User`}</strong>
                  <i
                    onClick={() => {
                      onHide();
                    }}
                    className="fa-solid fa-xmark"
                  ></i>
                </div>
                <form className="grid" onSubmit={handleSubmit(onSubmit)}>
                  <div className="space-y-5 pb-10">
                    <ControlledInput
                      name="username"
                      label="Username"
                      placeholder="Enter username"
                      control={control}
                      errors={errors}
                      isShowError
                    />
                    <ControlledInput
                      name="email"
                      label="Email"
                      placeholder="Enter email"
                      control={control}
                      errors={errors}
                      isShowError
                    />
                    <ControlledDropDown
                      name="role"
                      label="Role"
                      isDefaultOption={false}
                      placeholder="Select role"
                      options={prepareRoleOption()}
                      control={control}
                      errors={errors}
                    />
                  </div>
                  <div className="flex items-center justify-end w-full">
                    <Button
                      type="submit"
                      isLoading={isAddUserLoading || isUpdateUserLoading}
                    >
                      {isEdit ? "Update" : "Submit"}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserModal;
