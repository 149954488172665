import { Route, Routes } from "react-router";
import { Outlet } from "react-router-dom";
import { Fragment } from "react";

// PROJECT IMPORT
import { Dashboard, Login, Roles } from "../../features";
import { useRole, useUser } from "../../features/auth/authSlice";
import { Navigate } from "react-router-dom";
import Layout from "../../features/layouts/Layout";
// import Register from "../../features/auth/Register";
import { Wallet } from "../../features/wallet";
import { Trade } from "../../features/trade";
import AccountStatement from "../../features/accountStatement/AccountStatement";
import MarkerChart from "../../features/MarkerChart/MarkerChart";
import { WalletHistory } from "../../features/walletHistory";
import { ConvertHistory } from "../../features/convertHistory";
import Strategy from "../../features/strategy/Strategy";
import Exchange from "../../features/exchange/Exchange";
import { StrategyProvider } from "../../context/StrategyProvider";
import SpotOrder from "../../features/orders/spot-order/SpotOrder";
import FutureOrder from "../../features/orders/future-order/FutureOrder";
import ManageStrategy from "../../features/strategy/manage-strategy/ManageStrategy";
import { Users } from "../../features/users";
import UpdateRole from "../../features/roles/UpdateRole";
import { prepareAllMenu } from "../../utills/helperFunctions";

const AllRoutes = () => {
  const user = useUser();
  const role = useRole();

  const Components: any = {
    Dashboard,
    Wallet,
    Statement: AccountStatement,
    "Marker Chart": MarkerChart,
    "Wallet History": WalletHistory,
    "Convert History": ConvertHistory,
    Strategy: () => (
      <StrategyProvider>
        <Strategy />
      </StrategyProvider>
    ),
    Exchange: Exchange,
    "Spot Order": SpotOrder,
    "Future Order": FutureOrder,
    Roles: Roles,
    Member: Users,
  };

  return (
    <Routes>
      <Route path={`/spot-trade/:base/:quote`} element={<Trade />} />
      <Route path={`/future-trade/:base/:quote`} element={<Trade />} />
      {user?.data?.token ? (
        <Route path={``} element={<Layout />}>
          {prepareAllMenu(role)?.map((item: any, index: number) => {
            const Element = Components?.[item?.title];
            if (!Element) {
              return <></>;
            }
            return (
              <Fragment key={index}>
                {index === 0 ? (
                  <>
                    <Route
                      index
                      path="*"
                      element={<Navigate to="/" replace />}
                    />
                    <Route path="/" element={<Navigate to={item?.url} />} />
                  </>
                ) : null}

                {item?.title === "Strategy" ? (
                  <>
                    <Route
                      path={`/strategy/manage-strategy`}
                      element={<ManageStrategy />}
                    />
                  </>
                ) : null}

                {item?.title === "Roles" ? (
                  <>
                    <Route path={`/roles/add-role`} element={<UpdateRole />} />
                    <Route
                      path={`/roles/update-role/:id`}
                      element={<UpdateRole />}
                    />
                  </>
                ) : null}

                <Route path={item?.url} element={<Element />} />
              </Fragment>
            );
          })}

          <Route
            path="*"
            element={<Navigate to={user?.sidebar?.[0]?.url} replace />}
          />
        </Route>
      ) : (
        <Route element={<Outlet />}>
          <Route index path="*" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}
        </Route>
      )}
    </Routes>
  );
};

export default AllRoutes;
