import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Button,
  ControlledDatePicker,
  Loader,
  Table,
  TableRow,
} from "../../../ui";
import { FILTER, SideEnumKeys, sideEnum } from "../../../constant";
import {
  useGetTradePositionDetailsMutation,
  useUpdatePositionMutation,
} from "../tradeApi";
import useWebSocketContext from "../../../context/useWebSocketContext";
import { useLocation } from "react-router-dom";
import {
  dateFormatter,
  onFixed,
  prepareLastDate,
  preparePriceStyle,
  responseToaster,
  showToaster,
} from "../../../helperFunctions";

import { useForm } from "react-hook-form";
import { useLazyGetAllWalletQuery } from "../../wallet/walletApi";
import { useKeys } from "../../exchange/exchangeSlice";
import { useRole } from "../../auth/authSlice";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";

const tabs = [
  { name: `1 Day` },
  { name: "1 Week" },
  { name: "1 Month" },
  { name: "3 Month" },
  { name: "Time" },
];

const PositionList = (props: any) => {
  const {
    positions,
    isPositionLoading,
    isOpenPosition = false,
    getPositions,
    onOrderPlaced,
  } = props;
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const [updatePosition, { isLoading }] = useUpdatePositionMutation();
  const { tickers } = useWebSocketContext();
  const [activeTab, setActiveTab] = useState(0);
  const [marketId, setMarketId] = useState(null);
  const [filterForPositionHistory, setFilterForPositionHistory] =
    useState<any>(FILTER);
  const isFutureTrade = pathname.pathname.includes("future-trade");
  const role = useRole(isFutureTrade ? "future-trade" : "spot-trade");

  const [getAllWallet] = useLazyGetAllWalletQuery();
  const [getTradePositionDetails] = useGetTradePositionDetailsMutation();
  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();

  const onUpdatePosition = async (
    uuid: any,
    stock_price: any,
    symbol?: any,
    quantity?: any,
    side?: any
  ) => {
    try {
      setMarketId(uuid);
      let payload = {
        position_uuid: uuid,
        stock_price: stock_price,
        is_connect: is_connect,
        exchange_name: exchange_name,
        type: "single",
        ...(is_connect && {
          symbol,
          quantity,
          side: side === "long" ? "buy" : "sell",
        }),
      };
      const res = await updatePosition({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
      if (res) {
        responseToaster(res);
        getPositions?.("Position List");
        onOrderPlaced?.(["All Order History"]);
        getAllWallet({
          is_connect: is_connect,
          exchange_name: exchange_name,
        });
      }
      setMarketId(null);
    } catch (err: any) {
      setMarketId(null);
    }
  };

  const columnsPositions = useMemo(
    () => [
      {
        title: "Position Time",
        name: "created_at",
        Cell: (data: any) => (
          // <>{`${new Date(data?.created_at * 1000).toLocaleString()}`}</>
          <>
            {!isOpenPosition ? <p>{`${data?.children?.created_at}`}</p> : null}
            <p>{`${data?.created_at}`}</p>
          </>
        ),
      },
      {
        title: "Symbol",
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? <p>{`${data?.children?.symbol}`}</p> : null}
            <p>{`${data?.symbol}`}</p>
          </>
        ),
      },
      {
        title: "Size",
        prepareValue: (data: any) => onFixed(data?.total),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.total)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.total)}`}</p>
          </>
        ),
      },
      {
        title: "Entry Price",
        name: "current_price",
        prepareValue: (data: any) => onFixed(data?.current_price),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.current_price)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.current_price)}`}</p>
          </>
        ),
      },
      // {
      //   title: "Break Even Price",
      //   name: "current_price",
      //   // Cell: (data: any) => (
      //   //   <>{`${new Date(data?.created_at * 1000).toLocaleString()}`}</>
      //   // ),
      // },
      {
        title: "Mark Price",
        name: "stock_price",
        prepareValue: (data: any) => onFixed(data?.stock_price),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.stock_price)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.stock_price)}`}</p>
          </>
        ),
      },
      {
        title: "Trade Value",
        name: "trade_value",
        prepareValue: (data: any) => onFixed(data?.trade_value),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.trade_value)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.trade_value)}`}</p>
          </>
        ),
      },
      {
        title: "Side",
        prepareValue: ({
          side,
        }: {
          side: SideEnumKeys;
          children: { side: SideEnumKeys };
        }) => `${sideEnum?.[side]}`,
        Cell: ({
          side,
          children,
        }: {
          side: SideEnumKeys;
          children: { side: SideEnumKeys };
        }) => (
          <>
            {!isOpenPosition ? (
              <>
                {/* {`${sideEnum?.[children?.side]}`} */}
                <div className="flex gap-2 items-center">
                  {sideEnum?.[children?.side] === "Buy" ? (
                    <GoArrowUpRight size={20} color="var(--primary-green--)" />
                  ) : (
                    <GoArrowDownRight size={20} color="var(--primary-red--)" />
                  )}
                  <p>{`${sideEnum?.[children?.side]}`}</p>
                </div>
              </>
            ) : null}
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
      },
      {
        title: "PNL(ROI %)",
        name: "amount",
        showColumn: isOpenPosition,
        prepareValue: (data: any) => {
          const oldValue =
            (+data?.trade_value || 0) * (+data?.current_price || 0);
          const currentValue =
            (+data?.trade_value || 0) * (+data?.ticker?.c || 0);

          const diffValue =
            data?.side === "buy"
              ? currentValue - oldValue
              : oldValue - currentValue;
          const per = (diffValue / currentValue) * 100;

          return `${
            data?.tickerIndex > -1 ? Math.abs(+onFixed(diffValue)) : 0
          } = ${data?.tickerIndex > -1 ? onFixed(Math.abs(per), 2) : 0} %`;
        },
        Cell: (val: any) => {
          const oldValue =
            (+val?.trade_value || 0) * (+val?.current_price || 0);
          const currentValue =
            (+val?.trade_value || 0) * (+val?.ticker?.c || 0);

          const diffValue =
            val?.side === "buy"
              ? currentValue - oldValue
              : oldValue - currentValue;
          const per = (diffValue / currentValue) * 100;
          return (
            <div className="flex flex-col gap-2">
              <span
                className={`${
                  preparePriceStyle(+onFixed(diffValue))?.className
                }`}
              >
                {val?.tickerIndex > -1 ? Math.abs(+onFixed(diffValue)) : 0}
              </span>
              <span className={`${preparePriceStyle(+per)?.className}`}>
                {val?.tickerIndex > -1 ? onFixed(Math.abs(per), 2) : 0} %
              </span>
            </div>
          );
        },
      },
      {
        title: "PNL(ROI %)",
        name: "profit_loss",
        showColumn: !isOpenPosition,
        prepareValue: (data: any) => onFixed(data?.profit_loss) || 0,
        Cell: (val: any) => {
          return (
            <div className="flex flex-col gap-2">
              <span
                className={`${
                  preparePriceStyle(+val?.children?.profit_loss)?.className
                }`}
              >
                {onFixed(val?.children?.profit_loss) || 0}
              </span>
            </div>
          );
        },
      },
      {
        title: "Fees",
        name: "fees",
        showColumn: !isOpenPosition,
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.fees)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.fees)}`}</p>
          </>
        ),
      },
      {
        title: "Close All Positions",
        name: "close_all_positions",
        showColumn: isOpenPosition && role.includes("Update Position"),
        exportHide: ["ALL"],
        Cell: (val: any) => {
          return (
            <>
              <span
                className="cursor-pointer text-primary-500 font-semibold hover:text-primary-950"
                onClick={async () => {
                  if (isLoading) {
                    return;
                  }
                  await onUpdatePosition(val?.position_uuid, val?.ticker?.c);
                }}
              >
                {isLoading && val?.position_uuid === marketId ? (
                  <Loader loaderClassName="w-[15px] " />
                ) : (
                  "Market"
                )}
              </span>
            </>
          );
        },
      },
      {
        title: "Market Value",
        name: "market_value",
        showColumn: !isOpenPosition,
        prepareValue: (data: any) => onFixed(data?.market_value),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.market_value)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.market_value)}`}</p>
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    [positions, isLoading, marketId, isOpenPosition]
  );
  // const columnsBinancePositions = useMemo(
  //   () => [
  //     {
  //       title: "Position Time",
  //       name: "created_at",
  //       Cell: (data: any) => (
  //         <>
  //           {!isOpenPosition ? (
  //             <div className="flex flex-col">
  //               <p>{data?.opened}</p>
  //               <p>{data?.closed || "-"}</p>
  //             </div>
  //           ) : (
  //             <p>{`${dateFormatter(data?.datetime, "default")}`}</p>
  //           )}
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Symbol",
  //       Cell: (data: any) => (
  //         <>
  //           {!isOpenPosition ? (
  //             <p>{`${data?.symbol}`}</p>
  //           ) : (
  //             <p>{`${data?.symbol?.split(":")?.[0]}`}</p>
  //           )}
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Size",
  //       showColumn: isOpenPosition,
  //       Cell: (data: any) => {
  //         const positionAmt = +data?.info?.positionAmt || 0;
  //         const currentPrice = +data?.ticker?.c || 0;
  //         const currentValue = positionAmt * currentPrice;
  //         return (
  //           <>
  //             {!isOpenPosition ? (
  //               <p>{`${onFixed(data?.qty)}`}</p>
  //             ) : (
  //               <p>{`${onFixed(currentValue)}`}</p>
  //             )}
  //           </>
  //         );
  //       },
  //     },
  //     {
  //       title: "Entry Price",
  //       name: "current_price",
  //       showColumn: isOpenPosition,
  //       Cell: (data: any) => (
  //         <>
  //           {!isOpenPosition ? (
  //             <p>{`${onFixed(data?.price)}`}</p>
  //           ) : (
  //             <p>{`${onFixed(data?.entryPrice)}`}</p>
  //           )}
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Price",
  //       name: "current_price",
  //       showColumn: !isOpenPosition,
  //       Cell: (data: any) => (
  //         <>
  //           <div className="flex flex-col">
  //             <p>{onFixed(data?.avg_cost) || "-"}</p>
  //             <p>{onFixed(data?.avg_close_price) || "-"}</p>
  //           </div>
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Mark Price",
  //       name: "stock_price",
  //       showColumn: isOpenPosition,
  //       Cell: (data: any) => (
  //         <>
  //           {!isOpenPosition ? (
  //             <p>{`${onFixed(data?.children?.stock_price)}`}</p>
  //           ) : null}
  //           <p>{`${onFixed(data?.markPrice)}`}</p>
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Trade Value",
  //       name: "initialMargin",
  //       Cell: (data: any) => (
  //         <>
  //           {!isOpenPosition ? (
  //             <div className="flex flex-col">
  //               <p>{onFixed(data?.max_open_interest) || "-"}</p>
  //               <p>{onFixed(data?.closed_volume) || "-"}</p>
  //             </div>
  //           ) : (
  //             <p>{`${onFixed(data?.initialMargin)}`}</p>
  //           )}
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Side",
  //       Cell: ({ side }: any) => (
  //         <>
  //           <p>{side}</p>
  //         </>
  //       ),
  //     },
  //     {
  //       title: "PNL(ROI %)",
  //       name: "amount",
  //       showColumn: isOpenPosition,
  //       Cell: (val: any) => {
  //         const positionAmt = +val?.info?.positionAmt || 0;
  //         const currentPrice = +val?.ticker?.c || 0;
  //         const entryPrice = +val?.entryPrice || 0;
  //         const initialMargin = +val?.initialMargin || 0;

  //         const oldValue = positionAmt * entryPrice;
  //         const currentValue = positionAmt * currentPrice;
  //         const diffValue =
  //           val?.side === "long"
  //             ? currentValue - oldValue
  //             : oldValue - currentValue;

  //         const per = (diffValue / oldValue) * 100;

  //         // Calculate PnL percentage relative to initial margin
  //         const perForPer = (diffValue / initialMargin) * 100;

  //         return (
  //           <div className="flex flex-col gap-2">
  //             <span
  //               className={`${
  //                 preparePriceStyle(+onFixed(diffValue))?.className
  //               }`}
  //             >
  //               {val?.tickerIndex > -1 ? Math.abs(+onFixed(diffValue)) : 0}
  //             </span>
  //             <span className={`${preparePriceStyle(+per)?.className}`}>
  //               {val?.tickerIndex > -1 ? onFixed(Math.abs(perForPer), 2) : 0} %
  //             </span>
  //           </div>
  //         );
  //       },
  //     },
  //     {
  //       title: "PNL(ROI %)",
  //       name: "profit_loss",
  //       showColumn: !isOpenPosition,
  //       Cell: (val: any) => {
  //         return (
  //           <div className="flex flex-col">
  //             <span
  //               className={`${preparePriceStyle(+val?.closing_pnl)?.className}`}
  //             >
  //               {onFixed(val?.closing_pnl) || 0}
  //             </span>
  //             <span
  //               className={`${preparePriceStyle(+val?.closing_pnl)?.className}`}
  //             >
  //               {onFixed(val?.children?.profit_loss) || 0}
  //             </span>
  //           </div>
  //         );
  //       },
  //     },
  //     /*  {
  //       title: "Fees",
  //       name: "fees",
  //       showColumn: !isOpenPosition,
  //       Cell: (data: any) => (
  //         <>
  //           {!isOpenPosition ? (
  //             <p>{`${onFixed(data?.children?.fees)}`}</p>
  //           ) : null}
  //           <p>{`${onFixed(data?.fees)}`}</p>
  //         </>
  //       ),
  //     }, */
  //     {
  //       title: "Close All Positions",
  //       name: "close_all_positions",
  //       showColumn: isOpenPosition,
  //       Cell: (val: any) => {
  //         return (
  //           <>
  //             <span
  //               className="cursor-pointer text-primary-500 font-semibold hover:text-primary-950"
  //               onClick={async () => {
  //                 if (isLoading) {
  //                   return;
  //                 }
  //                 await onUpdatePosition(
  //                   val?.position_uuid,
  //                   val?.ticker?.c,
  //                   val?.info?.symbol,
  //                   val?.notional,
  //                   val?.side
  //                 );
  //               }}
  //             >
  //               {isLoading && val?.position_uuid === marketId ? (
  //                 <Loader loaderClassName="w-[15px] " />
  //               ) : (
  //                 "Market"
  //               )}
  //             </span>
  //             {/* <Input
  //             name="amount"
  //             // label="Amount"
  //             placeholder="Price"
  //             type="number"
  //             // disabled
  //           /> */}
  //           </>
  //         );
  //       },
  //       // <>{`${orderStatuses?.[order_status]}`}</>
  //     },
  //     /*   {
  //       title: "Market Value",
  //       name: "market_value",
  //       showColumn: !isOpenPosition,
  //       Cell: (data: any) => (
  //         <>
  //           {!isOpenPosition ? (
  //             <p>{`${onFixed(data?.children?.market_value)}`}</p>
  //           ) : null}
  //           <p>{`${onFixed(data?.market_value)}`}</p>
  //         </>
  //       ),
  //     }, */
  //   ],
  //   // eslint-disable-next-line
  //   [positions, isLoading, marketId, isOpenPosition, isFutureTrade]
  // );

  const preparePositions = () => {
    const newItems: any[] = [];

    (is_tab_connect
      ? positions?.data?.exchangeData?.position_history || []
      : positions?.data?.aiData?.position_history || []
    )?.forEach((item: any) => {
      let tickerIndex;
      if (is_connect) {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.split(":")?.[0]?.replace("/", "")
        );
      }
      if (!is_connect) {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.replace("/", "")
        );
      }

      newItems.push({
        ...item,
        tickerIndex,
        ticker: tickers?.[tickerIndex],
      });
    });

    return newItems;
  };

  const { control, handleSubmit, reset } = useForm({});

  const onSubmit = async (values: any) => {
    if (!values?.date) {
      showToaster("Please select date", "Warning");
    }
    setActiveTab(4);
    setFilterForPositionHistory({
      ...filterForPositionHistory,
      start_date: values?.date?.[0],
      end_date: values?.date?.[1],
    });
  };

  useEffect(() => {
    if (!isOpenPosition) {
      getPositions?.("Position History", activeTab, filterForPositionHistory);
    }
    // eslint-disable-next-line
  }, [filterForPositionHistory, activeTab, isOpenPosition]);

  useEffect(() => {
    if (isOpenPosition) {
      getPositions?.("Position List");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col mt-5">
      <div className="flex gap-4 items-center">
        {!isOpenPosition ? (
          <>
            {tabs?.map((item, index) => (
              <div
                key={index}
                className={`cursor-pointer flex justify-center items-center text-sm relative activetab_hover ${
                  index === activeTab
                    ? "activetab font-semibold text-primary-950"
                    : "text-primary-950 font-normal opacity-50"
                }`}
                onClick={() => {
                  if (index === 4) {
                    return;
                  }
                  setActiveTab(index);
                }}
              >
                <p className={`text-nowrap`}>{item?.name}</p>
              </div>
            ))}
            <ControlledDatePicker
              inputClassName="!w-fit"
              name="date"
              placeholder="Select Date"
              maxDate={new Date()}
              selectsRange
              control={control}
            />
            {/* <ControlledDatePicker
              name="start_date"
              selectesStart
              control={control}
              startDate={watch("start_date")}
              endDate={watch("end_date")}
              inputClassName="h-8 text-xs"
            />
            <ControlledDatePicker
              name="end_date"
              selectsEnd
              startDate={watch("start_date")}
              endDate={watch("end_date")}
              control={control}
              inputClassName="h-8 text-xs"
            /> */}
            <Button className="!h-8 !text-sm" onClick={handleSubmit(onSubmit)}>
              Search
            </Button>
            <Button
              className="!h-8 !text-sm"
              onClick={() => {
                reset();
                setActiveTab(0);
                getPositions?.("Position History", 0);
              }}
            >
              Reset
            </Button>
          </>
        ) : null}
      </div>
      <Table
        fileName={
          isOpenPosition ? "Future_open_positions" : "Future_position_history"
        }
        onFetchExportData={async () => {
          try {
            let dates = {
              start_date: dateFormatter(new Date()),
              end_date: dateFormatter(new Date(), "end"),
            };

            if (activeTab === 1) {
              dates = {
                start_date: dateFormatter(prepareLastDate(7)),
                end_date: dateFormatter(new Date(), "end"),
              };
            }

            if (activeTab === 2) {
              dates = {
                start_date: dateFormatter(prepareLastDate(31)),
                end_date: dateFormatter(new Date(), "end"),
              };
            }

            if (activeTab === 3) {
              dates = {
                start_date: dateFormatter(prepareLastDate(31 * 3)),
                end_date: dateFormatter(new Date(), "end"),
              };
            }

            if (activeTab === 4) {
              dates = {
                start_date: dateFormatter(filterForPositionHistory?.start_date),
                end_date: dateFormatter(
                  filterForPositionHistory?.end_date,
                  "end"
                ),
              };
            }

            const payload: any = {
              is_connect: is_connect,
              exchange_name: exchange_name,
              symbol: "All",
              group_name: pathnames?.[1],
              status: isOpenPosition ? 1 : 0,
              ...(!isOpenPosition ? dates : {}),
            };

            const res: any = await getTradePositionDetails(payload).unwrap();
            const newData: any[] = [];

            const d = is_tab_connect
              ? res?.data?.exchangeData?.position_history
              : res?.data?.aiData?.position_history;

            if (!isOpenPosition) {
              d?.forEach((item: any) => {
                newData.push(item?.children);
                newData.push({ ...item, children: item?.children });
              });
            }
            return {
              data: isOpenPosition ? d : newData,
              columns: columnsPositions,
            };
          } catch (err) {
            return { data: [], columns: columnsPositions };
          }
        }}
        isLoading={!!(!tickers || isPositionLoading)}
        columns={
          /* is_tab_connect ? columnsBinancePositions :  */ columnsPositions
        }
        data={preparePositions() || []}
        tableDataCount={preparePositions()?.length}
        isExpendable={false}
        className="!overflow-scroll"
        minHeight={300}
        count={
          is_tab_connect
            ? positions?.data?.exchangeData?.total
            : positions?.data?.aiData?.total
        }
        {...(!isOpenPosition && {
          pagination: filterForPositionHistory,
          handlePaginationChange: (pagination) => {
            setFilterForPositionHistory({
              ...filterForPositionHistory,
              ...pagination,
            });
          },
        })}
        // pagination={filterForOrder}
      >
        {preparePositions()?.map((item: any, index: number) => {
          return (
            <Fragment key={index}>
              <TableRow
                // className="cursor-pointer"
                columns={
                  /* is_tab_connect ? columnsBinancePositions :  */ columnsPositions
                }
                item={item}
                isExpendable={false}
              />
            </Fragment>
          );
        })}
      </Table>
    </div>
  );
};

export default PositionList;
