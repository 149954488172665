export const searchDataOptions = [
  { value: "userName", label: "User Name" },
  { value: "merchantName", label: "Merchant Name" },
];

export const devices = [
  { value: "mobile", label: "Mobile" },
  { value: "desktop", label: "Desktop" },
];

export const walletCoins = [
  { value: "BTC", label: "BTC" },
  { value: "USDT", label: "USDT" },
  { value: "ETH", label: "ETH" },
  { value: "BNB", label: "BNB" },
];
