import { Fragment } from "react/jsx-runtime";
import { SideEnumKeys, sideEnum } from "../../../constant";
import { Table, TableRow } from "../../../ui";
import { useMemo } from "react";
import {
  dateFormatter1,
  onFixed,
  preparePriceStyle,
} from "../../../helperFunctions";
import { useKeys } from "../../exchange/exchangeSlice";
import useWebSocketContext from "../../../context/useWebSocketContext";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";
import { useLocation } from "react-router-dom";
import { useGetTradePositionDetailsMutation } from "../../trade/tradeApi";

interface FuturePositionProps {
  filter: any;
  setFilter: any;
  positions: any;
  isPositionLoading: any;
  isOpenPosition: any;
}

const FuturePosition = (props: FuturePositionProps) => {
  const {
    filter,
    setFilter,
    positions,
    isPositionLoading,
    isOpenPosition = false,
  } = props;

  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const [getTradePositionDetails] = useGetTradePositionDetailsMutation();

  const { tickers } = useWebSocketContext();

  const columnsPositions = useMemo(
    () => [
      {
        title: "Position Time",
        name: "created_at",
        Cell: (data: any) => (
          // <>{`${new Date(data?.created_at * 1000).toLocaleString()}`}</>
          <>
            {!isOpenPosition ? <p>{`${data?.children?.created_at}`}</p> : null}
            <p>{`${data?.created_at}`}</p>
          </>
        ),
      },
      {
        title: "Symbol",
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? <p>{`${data?.children?.symbol}`}</p> : null}
            <p>{`${data?.symbol}`}</p>
          </>
        ),
      },
      {
        title: "Size",
        prepareValue: (data: any) => onFixed(data?.total),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.total)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.total)}`}</p>
          </>
        ),
      },
      {
        title: "Entry Price",
        name: "current_price",
        prepareValue: (data: any) => onFixed(data?.current_price),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.current_price)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.current_price)}`}</p>
          </>
        ),
      },
      // {
      //   title: "Break Even Price",
      //   name: "current_price",
      //   // Cell: (data: any) => (
      //   //   <>{`${new Date(data?.created_at * 1000).toLocaleString()}`}</>
      //   // ),
      // },
      {
        title: "Mark Price",
        name: "stock_price",
        // prepareValue: (data: any) =>
        //   `${!isOpenPosition ? onFixed(data?.children?.stock_price) + " | " : ""
        //   }${onFixed(data?.stock_price)}`,
        prepareValue: (data: any) => onFixed(data?.stock_price),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.stock_price)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.stock_price)}`}</p>
          </>
        ),
      },
      {
        title: "Trade Value",
        name: "trade_value",
        prepareValue: (data: any) => onFixed(data?.trade_value),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.trade_value)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.trade_value)}`}</p>
          </>
        ),
      },
      {
        title: "Side",
        prepareValue: ({
          side,
        }: {
          side: SideEnumKeys;
          children: { side: SideEnumKeys };
        }) => `${sideEnum?.[side]}`,
        Cell: ({
          side,
          children,
        }: {
          side: SideEnumKeys;
          children: { side: SideEnumKeys };
        }) => (
          <>
            {!isOpenPosition ? (
              <>
                {/* {`${sideEnum?.[children?.side]}`} */}
                <div className="flex gap-2 items-center">
                  {sideEnum?.[children?.side] === "Buy" ? (
                    <GoArrowUpRight size={20} color="var(--primary-green--)" />
                  ) : (
                    <GoArrowDownRight size={20} color="var(--primary-red--)" />
                  )}
                  <p>{`${sideEnum?.[children?.side]}`}</p>
                </div>
              </>
            ) : null}
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
      },
      {
        title: "Platform",
        name: "platform",
      },
      {
        title: "Trade Type",
        name: "trade_type",
      },
      {
        title: "PNL(ROI %)",
        name: "amount",
        prepareValue: (data: any) => {
          const oldValue =
            (+data?.trade_value || 0) * (+data?.current_price || 0);
          const currentValue =
            (+data?.trade_value || 0) * (+data?.ticker?.c || 0);

          const diffValue =
            data?.side === "buy"
              ? currentValue - oldValue
              : oldValue - currentValue;
          const per = (diffValue / currentValue) * 100;

          return `${data?.tickerIndex > -1 ? Math.abs(+onFixed(diffValue)) : 0
            } = ${data?.tickerIndex > -1 ? onFixed(Math.abs(per), 2) : 0} %`;
        },
        showColumn: isOpenPosition,
        Cell: (data: any) => {
          const oldValue =
            (+data?.trade_value || 0) * (+data?.current_price || 0);
          const currentValue =
            (+data?.trade_value || 0) * (+data?.ticker?.c || 0);

          const diffValue =
            data?.side === "buy"
              ? currentValue - oldValue
              : oldValue - currentValue;
          const per = (diffValue / currentValue) * 100;

          return (
            <div className="flex flex-col gap-2">
              <span
                className={`${preparePriceStyle(+onFixed(diffValue))?.className
                  }`}
              >
                {data?.tickerIndex > -1 ? Math.abs(+onFixed(diffValue)) : 0}
              </span>
              <span className={`${preparePriceStyle(+per)?.className}`}>
                {data?.tickerIndex > -1 ? onFixed(Math.abs(per), 2) : 0} %
              </span>
            </div>
          );
        },
      },
      {
        title: "PNL(ROI %)",
        name: "profit_loss",
        showColumn: !isOpenPosition,
        prepareValue: (data: any) => onFixed(data?.profit_loss) || 0,
        Cell: (data: any) => {
          return (
            <div className="flex flex-col gap-2">
              <span
                className={`${preparePriceStyle(+data?.children?.profit_loss)?.className
                  }`}
              >
                {onFixed(data?.children?.profit_loss) || 0}
              </span>
            </div>
          );
        },
      },
      {
        title: "Fees",
        name: "fees",
        showColumn: !isOpenPosition,
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.fees)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.fees)}`}</p>
          </>
        ),
      },
      // {
      //   title: "Close All Positions",
      //   name: "close_all_positions",
      //   showColumn: isOpenPosition,
      //   Cell: (val: any) => {
      //     return (
      //       <>
      //         <span
      //           className="cursor-pointer text-primary-500 font-semibold hover:text-primary-950"
      //           onClick={async () => {
      //             if (isLoading) {
      //               return;
      //             }
      //             await onUpdatePosition(val?.position_uuid, val?.ticker?.c);
      //           }}
      //         >
      //           {isLoading && val?.position_uuid === marketId ? (
      //             <Loader loaderClassName="w-[15px] " />
      //           ) : (
      //             "Market"
      //           )}
      //         </span>
      //         {/* <Input
      //         name="amount"
      //         // label="Amount"
      //         placeholder="Price"
      //         type="number"
      //         // disabled
      //       /> */}
      //       </>
      //     );
      //   },
      //   // <>{`${orderStatuses?.[order_status]}`}</>
      // },
      {
        title: "Market Value",
        name: "market_value",
        showColumn: !isOpenPosition,
        // prepareValue: (data: any) =>
        //   `${!isOpenPosition ? onFixed(data?.children?.market_value) + " | " : ""
        //   }${onFixed(data?.market_value)}`,
        prepareValue: (data: any) => onFixed(data?.market_value),
        Cell: (data: any) => (
          <>
            {!isOpenPosition ? (
              <p>{`${onFixed(data?.children?.market_value)}`}</p>
            ) : null}
            <p>{`${onFixed(data?.market_value)}`}</p>
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    [positions, isOpenPosition]
  );

  const preparePositions = () => {
    const newItems: any[] = [];

    (is_tab_connect
      ? positions?.data?.exchangeData?.position_history || []
      : positions?.data?.aiData?.position_history || []

    )?.forEach((item: any) => {
      let tickerIndex;
      if (is_connect) {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.split(":")?.[0]?.replace("/", "")
        );
      }
      if (!is_connect) {
        tickerIndex = tickers?.findIndex(
          (i: any) => i?.s === item?.symbol?.replace("/", "")
        );
      }

      newItems.push({
        ...item,
        tickerIndex,
        ticker: tickers?.[tickerIndex],
      });
    });

    return newItems;
  };

  return (
    <>
      <div className={`pt-3`} style={{ minHeight: "300px" }}>
        <Table
          fileName={
            isOpenPosition ? "Future_open_positions" : "Future_position_history"
          }
          onFetchExportData={async () => {
            try {
              const payload: any = {
                start_date: dateFormatter1(filter?.date?.[0], "start"),
                end_date: dateFormatter1(
                  filter?.date?.[1],
                  "end",
                  filter?.date?.[0]
                ),
                is_connect: is_connect,
                exchange_name: exchange_name,
                platform: filter?.platform,
                trade_type: filter?.trade,
                symbol: filter?.symbol,
                group_name: pathnames?.[1],
                status: isOpenPosition ? 1 : 0,
              };

              const res: any = await getTradePositionDetails(payload).unwrap();
              const newData: any[] = [];

              const d = is_tab_connect
                ? res?.data?.exchangeData?.position_history
                : res?.data?.aiData?.position_history;

              if (!isOpenPosition) {
                d?.forEach((item: any) => {
                    newData.push(item?.children);
                    newData.push({ ...item, children: item?.children });
                });
              }
              return {
                data: isOpenPosition ? d : newData,
                columns: columnsPositions,
              };
            } catch (err) {
              return { data: [], columns: columnsPositions };
            }
          }}
          isLoading={!!(!tickers || isPositionLoading)}
          columns={columnsPositions}
          data={preparePositions() || []}
          tableDataCount={preparePositions()?.length}
          isExpendable={false}
          className="!overflow-scroll"
          minHeight={300}
          count={
            is_tab_connect
              ? positions?.data?.exchangeData?.total
              : positions?.data?.aiData?.total
          }
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter((filter: any) => ({
              ...filter,
              ...pagination,
            }));
          }}
        >
          {preparePositions()?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columnsPositions}
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default FuturePosition;
