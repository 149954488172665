import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { convertHistoryApi } from "./convertHistoryApi";

interface ConvertHistoryState {
  data: any;
}

const initialState: ConvertHistoryState = {
  data: {},
};

export const convertHistorySlice = createSlice({
  name: "convertHistory",
  initialState,
  reducers: {
    clearconvertHistory: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      convertHistoryApi.endpoints.getConvertHistory.matchFulfilled,
      (state, { payload }) => {
        state.data = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default convertHistorySlice.reducer;
export const { clearconvertHistory } = convertHistorySlice.actions;

export const selectConvertHistoryData = (state: RootState) => state.convertHistory.data;
export const useConvertHistoryData = () => {
  const convertHistory = useSelector(selectConvertHistoryData);
  return useMemo(() => convertHistory, [convertHistory]);
};
