import React, { createContext, useEffect, useState } from "react";
import { FILTER } from "../constant";
import { dateFormatter, responseToaster } from "../helperFunctions";
import { useKeys } from "../features/exchange/exchangeSlice";
import useWebSocketContext from "./useWebSocketContext";
import {
  useGetTradePositionMutation,
  useGetTradPositionHistoryMutation,
} from "../features/strategy/strategyApi";
import { useUpdatePositionMutation } from "../features/trade/tradeApi";
import { useLocation } from "react-router-dom";

interface Strategy {
  activeTab: any;
  setActiveTab: any;
  filter: any;
  setFilter: any;
  filterForOrderHistory: any;
  setFilterForOrderHistory: any;
  onClear: () => void;
  positions: any;
  isPositionLoading: any;
  positionHistory: any;
  isPositionHistoryLoading: any;
  filterForPositionHistory: any;
  setFilterForPositionHistory: any;
  isUpdatePositionLoading: any;
  onUpdatePosition: (
    uuid: any,
    stock_price: any,
    symbol?: any,
    quantity?: any,
    side?: any
  ) => void;
  marketId: any;
  chartData: any;
  setChartData: any;
  setSymbolWisePnL: any;
  symbolWisePnL: any;
}

const StrategyContext = createContext<Strategy | null>(null);

const StrategyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeTab, setActiveTab] = useState("");
  const [filter, setFilter] = useState<any>();
  const [filterForOrderHistory, setFilterForOrderHistory] =
    useState<any>(FILTER);
  const [filterForPositionHistory, setFilterForPositionHistory] =
    useState<any>(FILTER);
  const [marketId, setMarketId] = useState<any>(null);
  const [chartData, setChartData] = useState<any>(null);
  const [symbolWisePnL, setSymbolWisePnL] = useState<any>(null);

  const [
    getTradPositionHistory,
    { data: positionHistory, isLoading: isPositionHistoryLoading },
  ] = useGetTradPositionHistoryMutation();
  const [getTradPosition, { data: positions, isLoading: isPositionLoading }] =
    useGetTradePositionMutation();
  const [updatePosition, { isLoading: isUpdatePositionLoading }] =
    useUpdatePositionMutation();
  const { emitOrderHistory, emitOpenOrder } = useWebSocketContext();
  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const getTradePositionHistory = async () => {
    try {
      let dates = {
        start_date: dateFormatter(
          filter?.start_date ? filter?.start_date : new Date()
        ),
        end_date: dateFormatter(
          filter?.end_date ? filter?.end_date : new Date(),
          "end"
        ),
      };
      const payload: any = {
        ...filterForPositionHistory,
        ...dates,
        is_connect: is_connect,
        exchange_name: exchange_name,
        symbol: filter?.symbol,
        strategy_name: filter?.strategy,
        platform: filter?.platform,
        trade_type: filter?.trade,
      };
      delete payload["isFilter"];
      await getTradPositionHistory({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (error) {}
  };

  const getTradePosition = async () => {
    try {
      let dates = {
        start_date: dateFormatter(
          filter?.start_date ? filter?.start_date : new Date()
        ),
        end_date: dateFormatter(
          filter?.end_date ? filter?.end_date : new Date(),
          "end"
        ),
      };
      const payload: any = {
        is_connect: is_connect,
        exchange_name: exchange_name,
        symbol: filter?.symbol,
        strategy_name: filter?.strategy,
        platform: filter?.platform,
        trade_type: filter?.trade,
        ...dates,
      };
      delete payload["isFilter"];
      await getTradPosition({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (error) {}
  };

  const onUpdatePosition = async (uuid: any, stock_price: any, side: any) => {
    try {
      setMarketId(uuid);
      let payload = {
        position_uuid: uuid,
        stock_price: stock_price,
        is_connect: is_connect,
        exchange_name: exchange_name,
        strategy_name: filter?.strategy,
        type: "single",
      };
      const res = await updatePosition({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
      if (res) {
        responseToaster(res);
        getTradePosition();
        setMarketId(null);
      }
    } catch (err: any) {
      setMarketId(null);
    }
  };

  const onClear = () => {
    setFilterForOrderHistory(FILTER);
    setFilterForPositionHistory(FILTER);
  };

  useEffect(() => {
    onClear();

    let dates = {
      start_date: dateFormatter(
        filter?.start_date ? filter?.start_date : new Date()
      ),
      end_date: dateFormatter(
        filter?.end_date ? filter?.end_date : new Date(),
        "end"
      ),
    };

    if (activeTab === "Orders History") {
      emitOrderHistory({
        is_connect: is_connect,
        start_date: dateFormatter(dates?.start_date, "start"),
        end_date: dateFormatter(dates?.end_date, "end"),
        limit: FILTER?.limit,
        page: FILTER?.page,
        order_type: 1, // future
        is_tab_connect: is_tab_connect,
        exchange_name: exchange_name,
        ...(filter?.strategy && { strategy_name: filter?.strategy }),
        ...(filter?.symbol && { symbol: filter?.symbol }),
        platform: filter?.platform,
        trade_type: filter?.trade,
        group_name: pathnames?.[1],
      });
    }

    if (activeTab === "Position History") {
      getTradePositionHistory();
    }

    if (activeTab === "Open Orders") {
      emitOpenOrder({
        is_connect: is_connect,
        order_type: 1,
        is_tab_connect: is_tab_connect,
        exchange_name: exchange_name,
        symbol: filter?.symbol,
        strategy: filter?.strategy,
        platform: filter?.platform,
        trade_type: filter?.trade,
        ...dates,
        group_name: pathnames?.[1],
      });
    }

    // if (activeTab === "Open Positions" || activeTab === "Position History") {
    if (activeTab === "Open Positions") {
      getTradePosition();
    }
    // eslint-disable-next-line
  }, [activeTab, is_connect, filter]);

  useEffect(() => {
    let dates = {
      start_date: dateFormatter(
        filter?.start_date ? filter?.start_date : new Date()
      ),
      end_date: dateFormatter(
        filter?.end_date ? filter?.end_date : new Date(),
        "end"
      ),
    };

    if (activeTab === "Orders History") {
      emitOrderHistory({
        is_connect: is_connect,
        start_date: dateFormatter(dates?.start_date, "start"),
        end_date: dateFormatter(dates?.end_date, "end"),
        limit: filterForOrderHistory?.limit,
        page: filterForOrderHistory?.page,
        order_type: 1, // future
        is_tab_connect: is_tab_connect,
        exchange_name: exchange_name,
        ...(filter?.strategy && { strategy_name: filter?.strategy }),
        ...(filter?.symbol && { symbol: filter?.symbol }),
        platform: filter?.platform,
        trade_type: filter?.trade,
        group_name: pathnames?.[1],
      });
    }
    // eslint-disable-next-line
  }, [filterForOrderHistory]);

  useEffect(() => {
    if (activeTab === "Position History") {
      getTradePositionHistory();
    }
    // eslint-disable-next-line
  }, [filterForPositionHistory]);

  return (
    <StrategyContext.Provider
      value={{
        activeTab,
        setActiveTab,
        filter,
        setFilter,
        filterForOrderHistory,
        setFilterForOrderHistory,
        onClear,
        positions,
        isPositionLoading,
        positionHistory,
        isPositionHistoryLoading,
        filterForPositionHistory,
        setFilterForPositionHistory,
        isUpdatePositionLoading,
        onUpdatePosition,
        marketId,
        chartData,
        setChartData,
        setSymbolWisePnL,
        symbolWisePnL,
      }}
    >
      {children}
    </StrategyContext.Provider>
  );
};

export { StrategyContext, StrategyProvider };
