import { Fragment } from "react/jsx-runtime";
import {
  SideEnumKeys,
  orderStatuses,
  sideEnum,
  typeEnum,
} from "../../../constant";
import { getStatusTypes, Table, TableRow } from "../../../ui";
import { useEffect, useMemo, useState } from "react";
import { dateFormatter1, onFixed } from "../../../helperFunctions";
import useWebSocketContext from "../../../context/useWebSocketContext";
import { useKeys } from "../../exchange/exchangeSlice";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";
import { useLocation } from "react-router-dom";
import { useGetAllOrderDetailsMutation } from "../../trade/tradeApi";

interface FutureOpenOrderProps {
  filter: any;
  setFilter: any;
}

const FutureOpenOrder = (props: FutureOpenOrderProps) => {
  const { filter, setFilter } = props;
  const [tableData, setTableData] = useState<any>([]);
  const {
    currentKey: { is_tab_connect, exchange_name },
  } = useKeys();
  const { loading, binanceOpenOrders } = useWebSocketContext();

  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const [getAllOrderDetails] = useGetAllOrderDetailsMutation();

  const columnsOpenOrders = useMemo(
    () => [
      {
        title: "Date",
        name: "created_at",
        Cell: (data: any) => <>{`${data?.created_at}`}</>,
      },
      {
        title: "Pair",
        name: "symbol",
      },
      {
        title: "Type",
        name: "type",
        prepareValue: ({
          type = "1",
        }: {
          type: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
        }) => typeEnum?.[type],
        Cell: ({
          type = "1",
        }: {
          type: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
        }) => (
          <>
            <span>{typeEnum?.[type]}</span>
          </>
        ),
        isMenu: true,
        enum: typeEnum,
      },
      {
        title: "Side",
        name: "side",
        prepareValue: ({ side }: { side: SideEnumKeys }) => sideEnum?.[side],
        Cell: ({ side }: { side: SideEnumKeys }) => (
          <>
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
        isMenu: true,
        enum: sideEnum,
      },
      {
        title: "Platform",
        name: "platform",
      },
      {
        title: "Trade Type",
        name: "trade_type",
      },
      {
        title: "Price",
        name: "stock_price",
        prepareValue: (data: any) =>
          ["8", "9"]?.includes(data?.type.toString())
            ? data?.type?.toString() === "8"
              ? onFixed(data?.take_profit)
              : onFixed(data?.stop_loss)
            : onFixed(
                data?.stock_price ||
                  (data?.type === "STOP_MARKET" ||
                  data?.type === "TAKE_PROFIT_MARKET"
                    ? data?.stopPrice
                    : data?.price)
              ),
        Cell: (data: any) => (
          <>
            {["8", "9"]?.includes(data?.type.toString()) ? (
              <>
                <span>
                  {data?.type?.toString() === "8"
                    ? onFixed(data?.take_profit)
                    : onFixed(data?.stop_loss)}
                </span>
              </>
            ) : (
              <>
                {onFixed(
                  data?.stock_price ||
                    (data?.type === "STOP_MARKET" ||
                    data?.type === "TAKE_PROFIT_MARKET"
                      ? data?.stopPrice
                      : data?.price)
                )}
              </>
            )}
          </>
        ),
      },
      {
        title: "Amount",
        name: "amount",
        Cell: (data: any) => (
          <>
            <span>{data?.amount}</span>
          </>
        ),
      },
      {
        title: "Status",
        prepareValue: ({
          order_status,
        }: {
          order_status: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
        }) => orderStatuses?.[order_status],
        Cell: ({
          order_status,
        }: {
          order_status: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
        }) => (
          <div
            className={`${getStatusTypes(orderStatuses?.[order_status])}`}
          >{`${orderStatuses?.[order_status]}`}</div>
        ),
      },
      {
        title: "Total",
        prepareValue: (data: any) => onFixed(data?.total),
        Cell: (data: any) => (
          <>
            <span>{onFixed(data?.total)}</span>
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    [tableData]
  );

  const prepareOpenOrders = () => {
    return binanceOpenOrders?.[is_tab_connect] || [];
  };

  useEffect(() => {
    setTableData(prepareOpenOrders());
    // eslint-disable-next-line
  }, [binanceOpenOrders, is_tab_connect]);

  return (
    <>
      <div className={`pt-3`} style={{ minHeight: "300px" }}>
        <Table
          fileName="Future_open_orders"
          onFetchExportData={async () => {
            try {
              const payload: any = {
                symbol: filter?.symbol,
                start_date: dateFormatter1(filter?.date?.[0], "start"),
                end_date: dateFormatter1(
                  filter?.date?.[1],
                  "end",
                  filter?.date?.[0]
                ),
                exchange_name: exchange_name,
                order_status: 1,
                order_type: 1,
                group_name: pathnames?.[1],
              };
              const res: any = await getAllOrderDetails(payload).unwrap();
              return { data: res?.data, columns: columnsOpenOrders };
            } catch (err) {
              return { data: [], columns: columnsOpenOrders };
            }
          }}
          isLoading={loading?.openOrder}
          columns={columnsOpenOrders}
          data={prepareOpenOrders() || []}
          tableDataCount={tableData?.length}
          isExpendable={false}
          count={
            is_tab_connect === 1
              ? binanceOpenOrders?.exchangeCount
              : binanceOpenOrders?.aiCount
          }
          setData={setTableData}
          className="!overflow-scroll order_table"
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter((filter: any) => ({
              ...filter,
              ...pagination,
            }));
          }}
        >
          {tableData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columnsOpenOrders}
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default FutureOpenOrder;
