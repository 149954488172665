import { Fragment, useEffect, useState } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  ControlledDropDown,
  ControlledInput,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import { useWalletData } from "../wallet/walletSlice";
import { useLazyGetAllWalletQuery } from "../wallet/walletApi";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import {
  dateFormatter,
  onFixed,
  prepareLastDate,
  preparePriceStyle,
  seperator,
  seperator2,
} from "../../helperFunctions";
import useWebSocketContext from "../../context/useWebSocketContext";
import { useGetClosingDataMutation } from "../trade/tradeApi";
import { useClosingData } from "../trade/tradeSlice";
import { useActiveCoins } from "../common/commonSlice";
import {
  useGetActiveCoinsMutation,
  useGetSpotMarketMutation,
} from "../common/commonApi";
import { getLaravelSocket } from "../../utills/laravelSocketConnection";
import { BINANCE_ORDER_TAX } from "../../constant";
import { useKeys } from "../exchange/exchangeSlice";
import { useLocation } from "react-router-dom";
import { useRole } from "../auth/authSlice";
import { useGetStrategyReportMutation } from "./dashboardApi";
import { useStrategyReport } from "./dashboardSlice";
import { MdOutlineCancel } from "react-icons/md";

const tabs = [{ name: "Holding Cryptos" }, { name: "All Cryptos" }];
const tabs1 = [{ name: "Leader Board" }, { name: "Hot" }];

const Dashboard = () => {
  const wallet = useWalletData();
  const closingData = useClosingData();
  const activeCoins = useActiveCoins();
  const strategyReport = useStrategyReport();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const role = useRole("dashboard");
  const { tickers, emitClosingDataEvent, loading, binanceClosingData } =
    useWebSocketContext();
  const {
    currentKey: { is_tab_connect, is_valid_api_key, is_connect, exchange_name },
  } = useKeys();
  const [activeTab, setActiveTab] = useState(0);
  const [activeTab1, setActiveTab1] = useState(0);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [filter1, setFilter1] = useState<any>({
    // ...FILTER,
  });

  const [getSpotMarket] = useGetSpotMarketMutation();
  const [getActiveCoins, { isLoading: isActiveCoinsLoading }] =
    useGetActiveCoinsMutation();
  const [getAllWallet, { isLoading: isAllWalletLoading }] =
    useLazyGetAllWalletQuery();
  const [getClosingData, { isLoading: isClosingLoading }] =
    useGetClosingDataMutation();
  const [getStrategyReport, { isLoading: isReportLoading }] =
    useGetStrategyReportMutation();

  const allColumns = [
    {
      title: "Symbol",
      name: "base",
      Cell: (data: any) => (
        <div className="flex gap-3 items-start">
          <img
            alt=""
            className="w-7"
            src={`/images/cryptoCurrency/${data?.base?.toLowerCase()}.png`}
          />
          <div>
            <span className="font-semibold text-base leading-5">
              {data?.base}
            </span>
            <div className="text-xs">{data?.name}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Amount",
      name: "amount",
      prepareValue: (data: any) =>
        `${onFixed(data?.balance)} = ${seperator2(+data?.coinTotalUsd || 0)}`,
      Cell: (data: any) => {
        return (
          <>
            <p className="font-semibold text-base">{onFixed(data?.balance)}</p>
            <div className="text-xs">{seperator(+data?.coinTotalUsd || 0)}</div>
          </>
        );
      },
    },
    {
      title: "Coin Price",
      name: "coinPrice",
      defaultValue: "0",
      type: "price",
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">{data?.coinPrice}</span>
        </>
      ),
    },
    {
      title: "Change",
      name: "change",
      prepareValue: (data: any) =>
        `${onFixed(Math.abs(data?.change_per), 2)} % = ${seperator2(
          data?.change,
          true,
          "price"
        )}`,
      Cell: (data: any) => {
        return (
          <div className="">
            <span
              className={`font-semibold text-base ${
                preparePriceStyle(data?.change_per)?.className
              }`}
            >
              {onFixed(Math.abs(data?.change_per), 2)} %
            </span>
            <div
              className={`text-xs ${
                preparePriceStyle(data?.change)?.className
              }`}
            >
              {seperator(data?.change, true, "price")}
            </div>
          </div>
        );
      },
    },
  ];

  const binanceAllColumns = [
    {
      title: "Symbol",
      name: "base",
      Cell: (data: any) => (
        <div className="flex gap-3 items-start">
          <img
            className="w-7"
            src={`/images/cryptoCurrency/${data?.base?.toLowerCase()}.png`}
            alt=""
          />
          <div>
            <span className="font-semibold text-base leading-5">
              {data?.base}
            </span>
            <div className="text-xs">{data?.name}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Amount",
      name: "amount",
      prepareValue: (data: any) =>
        `${onFixed(data?.balance)} = ${seperator2(+data?.coinTotalUsd || 0)}`,
      Cell: (data: any) => {
        return (
          <>
            <p className="font-semibold text-base">{onFixed(data?.balance)}</p>
            <div className="text-xs">{seperator(+data?.coinTotalUsd || 0)}</div>
          </>
        );
      },
    },
    {
      title: "Coin Price",
      name: "coinPrice",
      defaultValue: "0",
      type: "price",
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">{data?.coinPrice}</span>
        </>
      ),
    },
    {
      title: "Change",
      name: "change",
      prepareValue: (data: any) =>
        `${onFixed(Math.abs(data?.change_per), 2)} % = ${seperator2(
          data?.change,
          true,
          "price"
        )}`,
      Cell: (data: any) => {
        return (
          <div className="">
            <span
              className={`font-semibold text-base ${
                preparePriceStyle(data?.change_per)?.className
              }`}
            >
              {onFixed(Math.abs(data?.change_per), 2)} %
            </span>
            <div
              className={`text-xs ${
                preparePriceStyle(data?.change)?.className
              }`}
            >
              {seperator(data?.change, true, "price")}
            </div>
          </div>
        );
      },
    },
  ];

  const holdingColumns = [
    {
      title: "Symbol",
      name: "base",
      Cell: (data: any) => (
        <div className="flex gap-3 items-start">
          <img
            className="w-7"
            src={`/images/cryptoCurrency/${data?.base?.toLowerCase()}.png`}
            alt=""
          />
          <div>
            <span className="font-semibold text-base leading-5">
              {data?.base}
            </span>
            <div className="text-xs">{data?.name}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Amount",
      name: "balance",
      prepareValue: (data: any) =>
        `${onFixed(data?.balance)} = ${seperator2(+data?.coinTotalUsd || 0)}`,
      Cell: (data: any) => {
        return (
          <>
            <p className="font-semibold text-base">{onFixed(data?.balance)}</p>
            <div className="text-xs">{seperator(+data?.coinTotalUsd || 0)}</div>
          </>
        );
      },
    },
    {
      title: "Coin Price",
      name: "coinPrice",
      defaultValue: "0",
      type: "price",
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">{data?.coinPrice}</span>
        </>
      ),
    },
    {
      title: "Today's PnL",
      name: "pnl",
      showColumn: role.includes("View Dashboard AI PnL"),
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">{data?.pnl}</span>
        </>
      ),
    },
  ];

  const binanceHoldingColumns = [
    {
      title: "Symbol",
      name: "base",
      Cell: (data: any) => (
        <div className="flex gap-3 items-start">
          <img
            className="w-7"
            src={`/images/cryptoCurrency/${data?.base?.toLowerCase()}.png`}
            alt=""
          />
          <div>
            <span className="font-semibold text-base leading-5">
              {data?.base}
            </span>
            <div className="text-xs">{data?.name}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Amount",
      name: "amount",
      prepareValue: (data: any) =>
        `${onFixed(data?.balance)} = ${seperator2(+data?.coinTotalUsd || 0)}`,
      Cell: (data: any) => {
        return (
          <>
            <p className="font-semibold text-base">{onFixed(data?.balance)}</p>
            <div className="text-xs">{seperator(+data?.coinTotalUsd || 0)}</div>
          </>
        );
      },
    },
    {
      title: "Coin Price",
      name: "coinPrice",
      defaultValue: "0",
      type: "price",
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">{data?.coinPrice}</span>
        </>
      ),
    },
    {
      title: "Today's PnL",
      name: "pnl",
      showColumn: role.includes("View Dashboard Exchange PnL"),
      Cell: (data: any) => (
        <>
          <span className="font-semibold text-base">
            {data?.pnlLoading ? "-" : data?.pnl}
          </span>
        </>
      ),
    },
  ];

  const leaderboardColumns = [
    {
      title: "Strategy Name",
      name: "strategy_name",
    },
    {
      title: "Closed Trade",
      name: "total_trade",
    },
    {
      title: "Open Trade",
      name: "live_trade",
    },
    {
      title: "Total Trade",
      name: "trade_count",
    },
    {
      title: "Total PnL Amount",
      name: "total_pnl_amount",
      prepareValue: (data: any) => `${onFixed(data?.total_pnl_amount)}`,
      Cell: (data: any) => (
        <>
          <span>{onFixed(data?.total_pnl_amount)}</span>
        </>
      ),
    },
    {
      title: "Total PnL Percentage",
      name: "total_pnl_percentage",
      prepareValue: (data: any) =>
        `${onFixed(data?.total_pnl_percentage, 2)} %`,
      Cell: (data: any) => (
        <>
          <span>{onFixed(data?.total_pnl_percentage, 2)} %</span>
        </>
      ),
    },
  ];

  const hotColumns = [
    {
      title: "Strategy Name",
      name: "strategy_name",
    },
    {
      title: "Closed Trade",
      name: "total_trade",
    },
    {
      title: "Open Trade",
      name: "live_trade",
    },
    {
      title: "Total Trade",
      name: "trade_count",
    },
    {
      title: "Total PnL Amount",
      name: "total_pnl_amount",
      prepareValue: (data: any) => `${onFixed(data?.total_pnl_amount)}`,
      Cell: (data: any) => (
        <>
          <span>{onFixed(data?.total_pnl_amount)}</span>
        </>
      ),
    },
    {
      title: "Total PnL Percentage",
      name: "total_pnl_percentage",
      prepareValue: (data: any) =>
        `${onFixed(data?.total_pnl_percentage, 2)} %`,
      Cell: (data: any) => (
        <>
          <span>{onFixed(data?.total_pnl_percentage, 2)} %</span>
        </>
      ),
    },
  ];

  const prepapreOptions = () => {
    // const options = Object.entries(spotMarket || {})?.map(
    //   ([key, item]: any) => {
    //     return {
    //       value: key,
    //       label: key,
    //     };
    //   }
    // );
    const options = [
      {
        value: "USDT",
        label: "USDT",
      },
    ];
    return options;
  };

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      coin: prepapreOptions()?.[0]?.value,
    },
  });

  const {
    control: control1,
    reset: reset1,
    setValue: setValue1,
    watch: watch1,
    getValues: getValues1,
  } = useForm<any>({
    defaultValues: {
      date: 1,
    },
  });

  const prepareCoins = () => {
    try {
      const arr: any[] = [];
      const loadingArr: any[] = [];

      activeCoins?.data
        // ?.slice()
        // ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
        ?.forEach((val: any) => {
          let currentmoney;
          let closeItem: any = {};
          if (is_tab_connect) {
            Object.entries(wallet?.data?.exchangeData || {})?.forEach(
              ([key, val2]: any) => {
                if (key?.toLowerCase() === val?.code?.toLowerCase()) {
                  currentmoney = {
                    balance: val2?.free,
                    on_hold: val2?.used,
                    coin: {
                      symbol: val?.code,
                      name: val?.name,
                    },
                  };
                }
              }
            );

            if (binanceClosingData?.data) {
              const midnightPriceObj =
                binanceClosingData?.data?.midnightPrices?.find(
                  (item: any) => item?.symbol === val?.code
                );
              closeItem = {
                quantity:
                  +midnightPriceObj?.walletFreeQuantity ||
                  0 + +midnightPriceObj?.walletLockedQuantity ||
                  0,
                current_rate: +midnightPriceObj?.prevClosingPrice || 0,
                orders: binanceClosingData?.data?.allOrder
                  ?.filter?.(
                    (order: any) =>
                      order.status === "closed" || order.status === "filled"
                  )
                  ?.filter(
                    (item: any) =>
                      item?.symbol?.toString()?.split("/")?.[0] === val?.code
                  )
                  ?.map((item: any) => ({
                    executed:
                      +item?.info?.executedQty * (1 - BINANCE_ORDER_TAX / 100),
                    exchange_rate: +item?.average,
                    side: item?.side === "buy" ? 1 : 2,
                  })),
                wallet_transactions: [
                  ...binanceClosingData?.data?.depositAveragePrices
                    ?.filter((item: any) => item?.depositCurrency === val?.code)
                    ?.map((item: any) => ({
                      transaction_type: 0,
                      amount: +item?.depositAmount,
                      exchange_rate:
                        val?.code === "USDT" ? 1 : +item?.averagePrice,
                    })),
                  ...binanceClosingData?.data?.withdrawalAveragePrices
                    ?.filter(
                      (item: any) => item?.withdrawalCurrency === val?.code
                    )
                    ?.map((item: any) => ({
                      transaction_type: 1,
                      amount: +item?.withdrawalAmount,
                      exchange_rate:
                        val?.code === "USDT" ? 1 : +item?.averagePrice,
                    })),
                ],
              };
            }
          } else {
            currentmoney = wallet?.data?.aiData?.find(
              (val2: any) => val2?.coin?.coin_uuid === val?.coin_uuid
            );
            closeItem = (
              Array.isArray(closingData?.data) ? closingData?.data : []
            )?.find((element: any) => element?.wallet_name === val?.code);

            closeItem = {
              ...closeItem,
              orders: closeItem?.orders?.map((item: any) => ({
                ...item,
                side: item?.side === "buy" ? 1 : 2,
              })),
            };
          }

          const ticker = tickers?.find((i: any) => i?.s === `${val?.code}USDT`);

          const price = val?.code === "USDT" ? 1 : ticker?.c || 0;

          const netInflow1 = closeItem?.orders?.reduce(
            (sum: any, item: any) => {
              if (item?.side === 1) {
                // 1 for Buy
                return sum + item?.executed * item?.exchange_rate;
              } else {
                return sum - item?.executed * item?.exchange_rate;
              }
            },
            0
          );

          const netInflow2 = closeItem?.wallet_transactions?.reduce(
            (sum: any, item: any) => {
              if (item?.transaction_type === 0) {
                // 0 for deposit
                return sum + item?.amount * item?.exchange_rate;
              } else if (item?.transaction_type === 1) {
                // 1 for withdrawal
                return sum - item?.amount * item?.exchange_rate;
              }
              return sum
            },
            0
          );

          const pnl = onFixed(
            price * (+currentmoney?.balance + +currentmoney?.on_hold) -
              +closeItem?.quantity * +closeItem?.current_rate -
              (netInflow1 + netInflow2)
          );

          const balance = currentmoney
            ? +currentmoney?.balance + +currentmoney?.on_hold
            : "0";
          if (+balance) {
            arr.push({
              ...val,
              base: currentmoney ? currentmoney?.coin?.symbol : val?.symbol,
              balance: balance,
              coinPrice: onFixed(price),
              change: currentmoney?.coin?.symbol === "USDT" ? 0 : ticker?.p,
              change_per: currentmoney?.coin?.symbol === "USDT" ? 0 : ticker?.P,
              coinTotalUsd:
                price * (+currentmoney?.balance + +currentmoney?.on_hold || 0),
              pnl: currentmoney?.coin?.symbol === "USDT" ? "-" : pnl.toString(),
              pnlLoading:
                loading?.closingData ||
                (is_tab_connect && !binanceClosingData?.data),
            });
          }

          if (price) {
            loadingArr.push(0);
          }
        });

      return {
        data:
          arr?.sort((a: any, b: any) => b?.coinTotalUsd - a?.coinTotalUsd) ||
          [],
        isLoading: loadingArr?.length <= 1,
      };
    } catch (err) {
      return {
        data: [],
        isLoading: false,
      };
    }
  };

  const prepareAllData = () => {
    try {
      const search: any = watch("search");

      const arr: any[] = [];
      const loadingArr: any[] = [];

      activeCoins?.data
        // ?.slice()
        // ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
        ?.forEach((val: any) => {
          let currentmoney;
          let closeItem: any = {};
          if (is_tab_connect) {
            Object.entries(wallet?.data?.exchangeData || {})?.forEach(
              ([key, val2]: any) => {
                if (key?.toLowerCase() === val?.code?.toLowerCase()) {
                  currentmoney = {
                    balance: val2?.free,
                    on_hold: val2?.used,
                    coin: {
                      symbol: val?.code,
                      name: val?.name,
                    },
                  };
                }
              }
            );

            if (binanceClosingData?.data) {
              const midnightPriceObj =
                binanceClosingData?.data?.midnightPrices?.find(
                  (item: any) => item?.symbol === val?.code
                );
              closeItem = {
                quantity:
                  +midnightPriceObj?.walletFreeQuantity ||
                  0 + +midnightPriceObj?.walletLockedQuantity ||
                  0,
                current_rate: +midnightPriceObj?.prevClosingPrice || 0,
                orders: binanceClosingData?.data?.allOrder
                  ?.filter?.(
                    (order: any) =>
                      order.status === "closed" || order.status === "filled"
                  )
                  ?.filter(
                    (item: any) =>
                      item?.symbol?.toString()?.split("/")?.[0] === val?.code
                  )
                  ?.map((item: any) => ({
                    executed:
                      +item?.info?.executedQty * (1 - BINANCE_ORDER_TAX / 100),
                    exchange_rate: +item?.average,
                    side: item?.side === "buy" ? 1 : 2,
                  })),
                wallet_transactions: [
                  ...binanceClosingData?.data?.depositAveragePrices
                    ?.filter((item: any) => item?.depositCurrency === val?.code)
                    ?.map((item: any) => ({
                      transaction_type: 0,
                      amount: +item?.depositAmount,
                      exchange_rate:
                        val?.code === "USDT" ? 1 : +item?.averagePrice,
                    })),
                  ...binanceClosingData?.data?.withdrawalAveragePrices
                    ?.filter(
                      (item: any) => item?.withdrawalCurrency === val?.code
                    )
                    ?.map((item: any) => ({
                      transaction_type: 1,
                      amount: +item?.withdrawalAmount,
                      exchange_rate:
                        val?.code === "USDT" ? 1 : +item?.averagePrice,
                    })),
                ],
              };
            }
          } else {
            currentmoney = wallet?.data?.aiData?.find(
              (val2: any) => val2?.coin?.coin_uuid === val?.coin_uuid
            );
            closeItem = (
              Array.isArray(closingData?.data) ? closingData?.data : []
            )?.find((element: any) => element?.wallet_name === val?.code);

            closeItem = {
              ...closeItem,
              orders: closeItem?.orders?.map((item: any) => ({
                ...item,
                side: item?.side === "buy" ? 1 : 2,
              })),
            };
          }

          const ticker = tickers?.find((i: any) => i?.s === `${val?.code}USDT`);

          const price = val?.code === "USDT" ? 1 : ticker?.c || 0;

          if (
            search
              ? (currentmoney ? currentmoney?.coin?.symbol : val?.symbol)
                  ?.toLowerCase()
                  ?.includes(search?.toLowerCase())
              : true
          ) {
            arr.push({
              ...val,
              base: currentmoney ? currentmoney?.coin?.symbol : val?.symbol,
              balance: currentmoney
                ? +currentmoney?.balance + +currentmoney?.on_hold
                : "0",
              coinPrice: onFixed(price),
              change: currentmoney?.coin?.symbol === "USDT" ? 0 : ticker?.p,
              change_per: currentmoney?.coin?.symbol === "USDT" ? 0 : ticker?.P,
              coinTotalUsd:
                price * (+currentmoney?.balance + +currentmoney?.on_hold || 0),
            });
          }

          if (price) {
            loadingArr.push(0);
          }
        });

      return {
        data:
          arr?.sort((a: any, b: any) => b?.coinTotalUsd - a?.coinTotalUsd) ||
          [],
        isLoading: loadingArr?.length <= 1,
      };
    } catch (err) {
      return {
        data: [],
        isLoading: false,
      };
    }
  };

  const getStrategyReportList = async () => {
    try {
      const { date, ...rest } = filter1;
      const payload: any = {
        group_name: pathnames?.[1],
        ...rest,
      };
      delete payload["isFilter"];
      await getStrategyReport(payload);
    } catch (error) {}
  };

  const prepapreOptionsForDate = () => {
    const options = [
      {
        value: 1,
        label: "Past 7 days",
      },
      {
        value: 2,
        label: "Past 30 days",
      },
      {
        value: 3,
        label: "All",
      },
      {
        value: 4,
        label: "Customized",
      },
    ];
    return options;
  };

  useEffect(() => {
    if (role.includes("View Dashboard")) {
      getAllWallet({
        is_connect: is_connect,
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
      });
    }
    // eslint-disable-next-line
  }, [is_connect, exchange_name]);

  useEffect(() => {
    const fn = async () => {
      if (role.includes("View Dashboard")) {
        await getActiveCoins({
          group_name: pathnames?.[1],
        });
        await getSpotMarket({
          group_name: pathnames?.[1],
        });
      }
      if (role.includes("View Dashboard AI PnL")) {
        await getClosingData({
          group_name: pathnames?.[1],
        });
      }
    };
    fn();
    // eslint-disable-next-line
  }, []);

  const socket = getLaravelSocket();
  useEffect(() => {
    if (
      socket?.connected &&
      is_valid_api_key &&
      role.includes("View Dashboard Exchange PnL")
    ) {
      emitClosingDataEvent({
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
      });
    }
    // eslint-disable-next-line
  }, [socket?.connected, exchange_name]);

  useEffect(() => {
    const dateValue = getValues1()?.date;

    let dates = {
      start_date: dateFormatter(new Date()),
      end_date: dateFormatter(new Date(), "end"),
    };

    if (watch1("date")?.toString()?.length) {
      if (dateValue === 1) {
        dates = {
          start_date: dateFormatter(prepareLastDate(7)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      if (dateValue === 2) {
        dates = {
          start_date: dateFormatter(prepareLastDate(31)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      if (dateValue === 3) {
        dates = {
          start_date: null,
          end_date: null,
        };
      }

      if (dateValue !== 4) {
        setFilter1({
          ...filter1,
          start_date: dateFormatter(dates?.start_date, "start"),
          end_date: dateFormatter(dates?.end_date, "end"),
        });
      }
    }
    // eslint-disable-next-line
  }, [watch1("date")]);

  useEffect(() => {
    if (Object.keys(filter1)?.length && role.includes("View Strategy Report")) {
      getStrategyReportList();
    }
    // eslint-disable-next-line
  }, [filter1]);

  return (
    <>
      {role.includes("View Dashboard") ? (
        <>
          <div className="App tableborder card_rounded mb-5">
            <div className="flex justify-between items-center pb-4">
              <div>
                <div className="flex justify-center">
                  <div className="flex gap-4 ">
                    {tabs?.map((item: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className={`cursor-pointer flex justify-center items-center relative activetab_hover ${
                            index === activeTab
                              ? "activetab font-semibold text-primary-950"
                              : "font-normal text-primary-950 opacity-50"
                          }`}
                          onClick={() => {
                            setActiveTab(index);
                            setValue("coin", prepapreOptions()?.[0]?.value);
                          }}
                        >
                          <p>{item?.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center gap-2">
                  {activeTab === 0 ? (
                    <ControlledDropDown
                      width={100}
                      name="coin"
                      options={prepapreOptions()}
                      control={control}
                    />
                  ) : (
                    <ControlledInput
                      icon="SearchIcon"
                      name="search"
                      placeholder="Search"
                      control={control}
                      errors={errors}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="pt-4">
              {activeTab === 0 ? (
                <Table
                  isLoading={
                    !tickers ||
                    isActiveCoinsLoading ||
                    isAllWalletLoading ||
                    prepareCoins()?.isLoading
                  }
                  fileName="Holding_cryptos"
                  columns={
                    is_tab_connect === 1
                      ? binanceHoldingColumns
                      : holdingColumns
                  }
                  data={prepareCoins()?.data || []}
                  isExpendable={false}
                  // tableClassName="!h-[500px] overflow-y-scroll"
                  tableDataCount={prepareCoins()?.data?.length}
                  tableClassName="!h-[500px] overflow-y-scroll hidden_scroll"
                >
                  {prepareCoins()?.data?.map((item: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <TableRow
                          className="cursor-pointer dash_table"
                          columns={
                            is_tab_connect === 1
                              ? binanceHoldingColumns
                              : holdingColumns
                          }
                          item={item}
                          isExpendable={false}
                        />
                      </Fragment>
                    );
                  })}
                </Table>
              ) : (
                <Table
                  isLoading={
                    !tickers ||
                    isActiveCoinsLoading ||
                    isAllWalletLoading ||
                    isClosingLoading ||
                    prepareAllData()?.isLoading
                  }
                  fileName="All_cryptos"
                  columns={
                    is_tab_connect === 1 ? binanceAllColumns : allColumns
                  }
                  data={prepareAllData()?.data || []}
                  isExpendable={false}
                  // tableClassName="!h-[500px] overflow-y-scroll"
                  tableDataCount={prepareAllData()?.data?.length}
                  tableClassName="!h-[500px] overflow-y-scroll hidden_scroll"
                >
                  {prepareAllData()?.data?.map((item: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <TableRow
                          className="cursor-pointer dash_table"
                          columns={
                            is_tab_connect === 1
                              ? binanceAllColumns
                              : allColumns
                          }
                          item={item}
                          isExpendable={false}
                        />
                      </Fragment>
                    );
                  })}
                </Table>
              )}
            </div>
          </div>
        </>
      ) : null}

      {role.includes("View Strategy Report") ? (
        <>
          <div className="App tableborder card_rounded mb-5">
            <div className="flex justify-between items-center pb-4">
              <div>
                <div className="flex justify-center">
                  <div className="flex gap-4 ">
                    {tabs1?.map((item: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className={`cursor-pointer flex justify-center items-center relative activetab_hover ${
                            index === activeTab1
                              ? "activetab font-semibold text-primary-950 "
                              : "font-normal text-primary-950 opacity-50"
                          }`}
                          onClick={() => {
                            setActiveTab1(index);
                          }}
                        >
                          <p>{item?.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <div className="flex gap-2 items-center">
                    <ControlledDropDown
                      name="date"
                      width={200}
                      options={prepapreOptionsForDate()}
                      control={control1}
                      onChange={(e: any) => {
                        setValue1("date", e);
                        if (e === 4) {
                          setOpenDateModal(true);
                        }
                      }}
                    />
                    <Button
                      disabled={isReportLoading}
                      className="!h-8 !text-sm"
                      onClick={() => {
                        reset1();
                        setFilter1({
                          // ...FILTER,
                        });
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              {activeTab1 === 0 ? (
                <Table
                  isLoading={isReportLoading}
                  columns={leaderboardColumns}
                  fileName="Strategy_leaderboard"
                  data={strategyReport?.data?.leaderboardData || []}
                  isExpendable={false}
                  tableDataCount={strategyReport?.data?.leaderboardData?.length}
                  className="order_table"
                  tableClassName="!h-[500px] overflow-y-scroll hidden_scroll"
                >
                  {strategyReport?.data?.leaderboardData?.map(
                    (item: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          <TableRow
                            className="cursor-pointer"
                            columns={leaderboardColumns}
                            item={item}
                            isExpendable={false}
                          />
                        </Fragment>
                      );
                    }
                  )}
                </Table>
              ) : (
                <Table
                  isLoading={isReportLoading}
                  columns={hotColumns}
                  fileName="Strategy_hot"
                  data={strategyReport?.data?.hotData || []}
                  tableDataCount={strategyReport?.data?.hotData?.length}
                  isExpendable={false}
                  className="order_table"
                  tableClassName="!h-[500px] overflow-y-scroll hidden_scroll"
                >
                  {strategyReport?.data?.hotData?.map(
                    (item: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          <TableRow
                            className="cursor-pointer"
                            columns={hotColumns}
                            item={item}
                            isExpendable={false}
                          />
                        </Fragment>
                      );
                    }
                  )}
                </Table>
              )}
            </div>
          </div>
          {openDateModal ? (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div
                className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
                onClick={() => {
                  setOpenDateModal(false);
                  setValue1("start_date", null);
                  setValue1("end_date", null);
                }}
              />

              <div className="modal-container w-fit mx-auto z-50">
                <div className="modal-header">
                  <h4 className="text-lg font-semibold text-primary-950">
                    Select a Time Range
                  </h4>
                  <MdOutlineCancel
                    color="var(--primary-600--)"
                    size={24}
                    onClick={() => {
                      setOpenDateModal(false);
                      setValue1("start_date", null);
                      setValue1("end_date", null);
                    }}
                  />
                </div>
                <div className="modal-content py-6 px-6 ">
                  <div className="flex gap-4 items-end justify-center my-5">
                    <ControlledDatePicker
                      name="start_date"
                      selectesStart
                      control={control1}
                      startDate={watch1("start_date")}
                      endDate={watch1("end_date")}
                      label="Start Date"
                      className="items-start text-primary-950"
                      inputClassName="h-8 text-xs"
                    />
                    <span className="text-primary-950">to</span>
                    <ControlledDatePicker
                      name="end_date"
                      selectsEnd
                      startDate={watch1("start_date")}
                      endDate={watch1("end_date")}
                      control={control}
                      label="End Date"
                      className="items-start text-primary-950"
                      inputClassName="h-8 text-xs"
                    />
                  </div>
                </div>
                <div className="modal-footer w-full">
                  <Button
                    onClick={() => {
                      setFilter1({
                        ...filter1,
                        start_date: dateFormatter(
                          dateFormatter(watch1("start_date")),
                          "start"
                        ),
                        end_date: dateFormatter(
                          dateFormatter(watch1("end_date")),
                          "end"
                        ),
                      });
                      setOpenDateModal(false);
                      setValue1("start_date", null);
                      setValue1("end_date", null);
                    }}
                    className="w-full"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Dashboard;
