import React, { CSSProperties, useState } from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import { RiExpandUpDownFill } from "react-icons/ri";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaCaretUp } from "react-icons/fa";
import DropdownMenu from "./DropDownMenu";

interface TableHeaderProps {
  className?: string;
  style?: CSSProperties;
  isExpendable?: boolean;
  columns: any[];
  data: any[];
  onSort: any;
  onMenuClick: any;
  count: any;
  onTableHeaderClick: any;
}
const TableHeader = (props: TableHeaderProps) => {
  const {
    className = "",
    style = {},
    columns = [],
    isExpendable = true,
    onSort,
    onMenuClick,
    count,
    onTableHeaderClick,
  } = props;

  const [sortType, setSortType] = useState("Asce");
  const [activeOpenMenu, setactiveOpenMenu] = useState<any>("");
  const toggleSortType = () => {
    const newSortType = sortType === "Asce" ? "Desc" : "Asce";
    setSortType(newSortType);
    return newSortType;
  };

  const toggleMenu = (activeTitle: any) => {
    if (count) {
      setactiveOpenMenu(activeTitle);
    }
  };

  return (
    <>
      <thead
        className={`${className} table-header sticky text-sm top-0 h-[42px] bg-secondary-white`}
        style={{ ...style }}
      >
        <tr>
          {isExpendable ? (
            <th className="no-wrap">
              <BiMenuAltLeft />
            </th>
          ) : null}
          {columns?.map((column, index) => {
            const { showColumn = true, isButton = false, ...rest } = column;
            return showColumn ? (
              <th
                key={index}
                className="text-start no-wrap relative"
                onClick={(event) => {
                  event.stopPropagation();
                  if (rest?.onTableHeaderClick) {
                    rest.onTableHeaderClick();
                  }
                  onTableHeaderClick();
                }}
              >
                <div
                  className={`${
                    isButton ? "primary-btn p-1 cursor-pointer" : ""
                  } flex flex-row items-center gap-3`}
                >
                  <p>{rest?.title}</p>
                  {rest?.isMenu ? (
                    <>
                      <div className="dropdown">
                        <div className="cursor-pointer">
                          {activeOpenMenu === rest?.title ? (
                            <>
                              <FaCaretUp onClick={() => toggleMenu("")} />
                            </>
                          ) : (
                            <>
                              <IoMdArrowDropdown
                                onClick={() => toggleMenu(rest?.title)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <DropdownMenu
                    activeOpenMenu={activeOpenMenu}
                    className={`${column?.dropdownClassName || ""}`}
                    column={column}
                    onMenuClick={(menuItem: any) => {
                      toggleMenu("");
                      onMenuClick(column?.menuType, column?.name, menuItem);
                    }}
                  />

                  {column?.sortable ? (
                    <div
                      onClick={() => {
                        const newSortType = toggleSortType();
                        onSort(column?.sortableKey, column?.type, newSortType);
                      }}
                      className="cursor-pointer"
                    >
                      <RiExpandUpDownFill fill="#9e9393" />
                    </div>
                  ) : null}
                </div>
              </th>
            ) : null;
          })}
        </tr>
      </thead>
    </>
  );
};

export default TableHeader;
