import React, { memo } from "react";
import { IconProps } from ".";

export interface LockIconProps extends IconProps {
}
const LockIcon = (props: LockIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24" className={`${pathClassNames?.[0] || ""}`} />
      <path
        d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
        className={`${pathClassNames?.[1] || ""}`}
      />
      <path
        d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
        className={`${pathClassNames?.[2] || ""}`}
      />
      <line x1="2" x2="22" y1="2" y2="22" className={`${pathClassNames?.[3] || ""}`} />
    </svg>
  );
};

const MemoLockIcon = memo(LockIcon);
export default MemoLockIcon;
