import { memo } from "react";
import { IconProps } from ".";

export interface CSVIconProps extends IconProps {}
const CSVIcon = (props: CSVIconProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 30,
    // stroke = "var(--primary-950--)",
    fill = "none",
    // strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill={fill}
      {...rest}
    >
      <g clip-path="url(#clip0_6561_1178)">
        <path
          d="M15.0023 0H4.41406V28.2353L10.0611 28.8235L15.0023 28.2353L15.5905 14.2694L15.0023 0Z"
          fill="#EAF0FD"
        />
        <path
          d="M18.5294 0H15V28.2353L20.8824 28.8235L25.5882 28.2353V7.05882L20.5882 5.29412L18.5294 0Z"
          fill="#D8E3FD"
        />
        <path
          d="M4.41406 28.2344V29.9991H15.0023L15.5905 29.1167L15.0023 28.2344H4.41406Z"
          fill="#D8E3FD"
        />
        <path d="M15 28.2344H25.5882V29.9991H15V28.2344Z" fill="#B3C9F0" />
        <path
          d="M2.64844 10.5879V26.4702H15.0014L15.5896 18.5291L15.0014 10.5879H2.64844Z"
          fill="#89D76B"
        />
        <path d="M15 10.5879H27.3529V26.4702H15V10.5879Z" fill="#5CB067" />
        <path d="M18.5312 7.05882H25.5901L18.5312 0V7.05882Z" fill="#B3C9F0" />
        <path
          d="M8.82422 7.05859H15.0007V8.8233H8.82422V7.05859Z"
          fill="#4D4D80"
        />
        <path
          d="M8.82422 3.5293H15.0007V5.294H8.82422V3.5293Z"
          fill="#4D4D80"
        />
        <path
          d="M11.0294 22.9407H10.1471C8.68753 22.9407 7.5 21.7533 7.5 20.2937V16.7642C7.5 15.3046 8.68753 14.1172 10.1471 14.1172H11.0294V15.8819H10.1471C9.66053 15.8819 9.26471 16.2777 9.26471 16.7642V20.2937C9.26471 20.7802 9.66053 21.176 10.1471 21.176H11.0294V22.9407Z"
          fill="#EAF0FD"
        />
        <path
          d="M20.3986 22.9423L19.2409 22.939L17.207 18.731V14.1172H18.9717V18.3269L19.8376 20.1184L20.7364 18.3207V14.1172H22.5011V18.7372L20.3986 22.9423Z"
          fill="#D8E3FD"
        />
        <path
          d="M13.6788 17.2054V16.3231C13.6788 16.0798 13.8767 15.8819 14.1199 15.8819H15.0023L15.3367 14.9995L15.0023 14.1172H14.1199C12.9036 14.1172 11.9141 15.1067 11.9141 16.3231V17.2054C11.9141 18.4218 12.9036 19.4113 14.1199 19.4113C14.3632 19.4113 14.5611 19.6092 14.5611 19.8525V20.7348C14.5611 20.9781 14.3632 21.176 14.1199 21.176H11.9141V22.9407H14.1199C14.4336 22.9407 14.7319 22.8745 15.0023 22.756L15.3367 20.2937L15.0023 17.8314C14.7319 17.7128 14.4336 17.6466 14.1199 17.6466C13.8767 17.6466 13.6788 17.4487 13.6788 17.2054Z"
          fill="#EAF0FD"
        />
        <path
          d="M16.3235 20.7355V19.8531C16.3235 18.9504 15.7782 18.1731 15 17.832V22.7567C15.7782 22.4155 16.3235 21.6382 16.3235 20.7355Z"
          fill="#D8E3FD"
        />
        <path d="M15 14.1172H16.3235V15.8819H15V14.1172Z" fill="#D8E3FD" />
      </g>
      <defs>
        <clipPath id="clip0_6561_1178">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MemoCSVIcon = memo(CSVIcon);
export default MemoCSVIcon;
