import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { Button, ControlledInput, Loader } from "../../../ui";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { OpenOrderPriceModalTypes } from "../types";
import { useLocation, useParams } from "react-router-dom";
import { onFixed } from "../../../helperFunctions";
import useWebSocketContext from "../../../context/useWebSocketContext";
import { useKeys } from "../../exchange/exchangeSlice";
import { useRole } from "../../auth/authSlice";

interface IOpenOrderPrice {
  data: any;
  title?: any;
  callUpdateLimitOrderSocket?: any;
}

const OpenOrderPrice = ({
  data,
  title,
  callUpdateLimitOrderSocket,
}: IOpenOrderPrice) => {
  const [editModal, setEditModal] = useState<boolean>(false);
  const { loading } = useWebSocketContext();
  const { base, quote } = useParams();
  const baseUppercase = base?.toUpperCase();
  const quoteUppercase = quote?.toUpperCase();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const isFutureTrade = pathname.pathname.includes("future-trade");
  const role = useRole(isFutureTrade ? "future-trade" : "spot-trade");
  const {
    currentKey: { exchange_name, is_connect },
  } = useKeys();

  const schema: any = yup.object({
    limit_price: yup.string().required("Please enter a price"),
    amount: yup.string().required("Please enter a amount"),
  });

  const initialValues: any = {
    limit_price: onFixed(
      data?.stock_price ||
        (data?.type === "STOP_MARKET" || data?.type === "TAKE_PROFIT_MARKET"
          ? data?.stopPrice
          : data?.price)
    ),
    amount: onFixed(data?.amount || data?.origQty),
  };

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver<OpenOrderPriceModalTypes>(schema),
    defaultValues: initialValues,
  });

  const onClose = () => {
    setEditModal(false);
    reset();
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      const payload = {
        order_uuid: data?.order_uuid,
        is_connect: is_connect,
        ...(is_connect === 1 && {
          binance_order_id:
            data?.binance_order_id || data?.info?.orderId || data?.orderId,
          symbol: data?.symbol,
          type: data?.type,
          side: data?.side,
        }),
        order_type: isFutureTrade ? 1 : 2,
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
        ...values,
      };

      callUpdateLimitOrderSocket(payload, () => {
        onClose();
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <span>
          {title === "Price"
            ? onFixed(
                data?.stock_price ||
                  (data?.type === "STOP_MARKET" ||
                  data?.type === "TAKE_PROFIT_MARKET"
                    ? data?.stopPrice
                    : data?.price)
              )
            : onFixed(data?.amount || data?.origQty)}
        </span>
        {role.includes("Update Spot Order") ||
        role.includes("Update Future Trade Order") ? (
          <>
            <span className="cursor-pointer" onClick={() => setEditModal(true)}>
              <MdEdit />
            </span>
          </>
        ) : null}
      </div>
      {editModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div
              className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
              onClick={() => {
                onClose();
              }}
            />

            <div className="modal-container w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12  md:w-7/12 sm:w-7/12 mx-auto z-50 overflow-y-auto">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-content py-9 px-6 text-center">
                  <div className="space-y-5 pb-2">
                    <div className="flex items-center justify-between gap-1">
                      <ControlledInput
                        name="limit_price"
                        // label="Amount"
                        placeholder="Price"
                        type="number"
                        control={control}
                        errors={errors}
                      />
                      <span className="text-primary-950 uppercase">
                        {quoteUppercase}
                      </span>
                    </div>
                    <div className="flex items-center justify-between gap-1">
                      <ControlledInput
                        name="amount"
                        // label="Amount"
                        placeholder="Amount"
                        type="number"
                        control={control}
                        errors={errors}
                      />
                      <span className="text-primary-950 uppercase">
                        {baseUppercase}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="modal-footer w-full">
                  <Button variant="Transaparent" onClick={onClose}>
                    Cancel
                  </Button>
                  {!loading?.updateLimitOrderPrice ? (
                    <Button type="submit">Update</Button>
                  ) : (
                    <div className="flex item-center justify-center">
                      <Loader />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default OpenOrderPrice;
