import { useEffect, useState } from "react";

// UI IMPORT
import { Fragment } from "react/jsx-runtime";
import {
  Button,
  ControlledDatePicker,
  ControlledDropDown,
  getStatusTypes,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import {
  useGetDepositHistoryMutation,
  useGetWithdrawalHistoryMutation,
} from "./walletHistoryApi";
import {
  useDepositHistoryData,
  useWithdrawalHistoryData,
} from "./walletHistorySlice";
import { FILTER, transactionStatusEnum } from "../../constant";
import { useCoins } from "../common/commonSlice";
import { dateFormatter, prepareLastDate } from "../../helperFunctions";
import { prepapreOptionsForDate } from "../common/mock";
import { useGetCoinsMutation } from "../common/commonApi";
import { useForm } from "react-hook-form";
import { useKeys } from "../exchange/exchangeSlice";
import { useLocation } from "react-router-dom";
import { useRole } from "../auth/authSlice";
import { MdOutlineCancel } from "react-icons/md";

const WalletHistory = () => {
  const [getDepositHistory, { isLoading: isLoadingDeposit }] =
    useGetDepositHistoryMutation();
  const [getWithdrawalHistory, { isLoading: isLoadingWithdrawal }] =
    useGetWithdrawalHistoryMutation();
  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();
  const [getCoins] = useGetCoinsMutation();
  const coins = useCoins();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const role = useRole("wallet-history");

  const depositHistory = useDepositHistoryData();
  const withdrawalHistory = useWithdrawalHistoryData();

  const [openDateModal, setOpenDateModal] = useState(false);
  const [tableDataDeposit, setTableDataDeposit] = useState<any[]>([]);
  const [tableDataWithdrawal, setTableDataWithdrawal] = useState<any[]>([]);

  const [filterDeposit, setFilterDeposit] = useState<any>({
    ...FILTER,
  });
  const [filterWithdrawal, setFilterWithdrawal] = useState<any>({
    ...FILTER,
  });

  const columnsDeposit: any[] = [
    {
      title: "Time",
      name: "created_at",
    },
    {
      title: "Coin",
      name: "coin_uuid",
    },
    {
      title: "Amount",
      name: "amount",
    },
    {
      title: "TxnID",
      name: "transaction_id",
    },
    {
      title: "Status",
      name: "status",
      prepareValue: ({
        transaction_status,
      }: {
        transaction_status: "0" | "1" | "2";
      }) => `${transactionStatusEnum?.[transaction_status]}`,
      Cell: ({
        transaction_status,
      }: {
        transaction_status: "0" | "1" | "2";
      }) => (
        <>
          <div
            className={` ${getStatusTypes(
              transactionStatusEnum?.[transaction_status] as
                | "Pending"
                | "Confirmed"
                | "Failed"
            )}`}
          >
            {transactionStatusEnum?.[transaction_status]}
          </div>
          {/* <span>{transactionStatusEnum?.[transaction_status]}</span> */}
        </>
      ),
    },
  ];
  const columnsDepositBinance: any[] = [
    {
      title: "Time",
      name: "created_at",
      prepareValue: (data: any) => `${dateFormatter(data?.datetime, " ")}`,
      Cell: (data: any) => (
        <>
          <span>{dateFormatter(data?.datetime, " ")}</span>
        </>
      ),
    },
    {
      title: "Type",
      name: "type",
      Cell: (data: any) => (
        <>
          <span>{data?.type}</span>
        </>
      ),
    },
    {
      title: "Deposit wallet",
      name: "type",
      prepareValue: () => `Spot`,
      Cell: (data: any) => (
        <>
          {/* <span>{data?.info?.type}</span> */}
          <span>Spot</span>
        </>
      ),
    },
    {
      title: "Amount",
      name: "amount",
    },
    {
      title: "Coin",
      name: "coin",
      prepareValue: (data: any) => `${data?.info?.coin}`,
      Cell: (data: any) => (
        <>
          <span>{data?.info?.coin}</span>
        </>
      ),
    },

    {
      title: "Destination",
      name: "addressTo",
      Cell: (data: any) => <>{data?.addressTo}</>,
    },
    // {
    //   title: "TxnID",
    //   name: "transaction_id",
    //   Cell: (data: any) => {
    //     // const traID = data?.info?.txId?.toString()?.split(" ");
    //     return (
    //       <>
    //         {/* {traID?.[2]}
    //         <span className="border">{traID?.[0]}</span> */}
    //         <span>{data?.txid}</span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Status",
      name: "status",
      Cell: (data: any) => (
        <>
          <div className={`${getStatusTypes(data?.status)}`}>
            {data?.status}
          </div>
        </>
      ),
    },
  ];
  const columnsWithdrawal: any[] = [
    {
      title: "Time",
      name: "created_at",
      prepareValue: (data: any) => `${dateFormatter(data?.datetime, " ")}`,
      Cell: (data: any) => (
        <>
          <span>{dateFormatter(data?.datetime, " ")}</span>
        </>
      ),
    },
    {
      title: "Type",
      name: "type",
      Cell: (data: any) => (
        <>
          <span>{data?.type}</span>
        </>
      ),
    },
    {
      title: "Withdrawal wallet",
      name: "type",
      prepareValue: () => `Spot`,
      Cell: (data: any) => (
        <>
          {/* <span>{data?.info?.type}</span> */}
          <span>Spot</span>
        </>
      ),
    },
    {
      title: "Amount",
      name: "amount",
    },
    {
      title: "Coin",
      name: "coin_uuid",
      prepareValue: (data: any) => `${data?.info?.coin}`,
      Cell: (data: any) => (
        <>
          <span>{data?.info?.coin}</span>
        </>
      ),
    },
    {
      title: "Destination",
      name: "addressTo",
      Cell: (data: any) => <>{data?.addressTo}</>,
    },
    // {
    //   title: "TxnID",
    //   name: "transaction_id",
    // },
    {
      title: "Status",
      name: "status",
      prepareValue: ({
        transaction_status,
      }: {
        transaction_status: "0" | "1" | "2";
      }) => `${transactionStatusEnum?.[transaction_status]}`,
      Cell: ({
        transaction_status,
      }: {
        transaction_status: "0" | "1" | "2";
      }) => (
        <>
          <div
            className={` ${getStatusTypes(
              transactionStatusEnum?.[transaction_status] as
                | "Pending"
                | "Confirmed"
                | "Failed"
            )}`}
          >
            {transactionStatusEnum?.[transaction_status]}
          </div>
          {/* <span>{transactionStatusEnum?.[transaction_status]}</span> */}
        </>
      ),
    },
  ];

  useEffect(() => {
    const fn = async () => {
      getCoins({
        group_name: pathnames?.[1],
      });
    };
    fn();
    // eslint-disable-next-line
  }, []);

  const prepareToCoinOptions = () => {
    const coinOptions: any = coins?.data
      ?.filter((coin: any) => coin?.name !== null)
      ?.slice()
      ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
      ?.map((val: any) => ({
        value: val?.code,
        label: val?.symbol,
      }));
    return coinOptions || [];
  };

  const { control, getValues, setValue, watch, reset }: any = useForm({
    defaultValues: {
      date: 1,
    },
  });

  const {
    control: controlWithdrawal,
    getValues: getValuesWithdrawal,
    setValue: setValueWithdrawal,
    watch: watchWithdrawal,
    reset: resetWithdrawal,
  }: any = useForm({
    defaultValues: {
      date: 1,
    },
  });

  const getDepositHistoryFn = async () => {
    try {
      const payload: any = {
        ...filterDeposit,
        is_connect: is_connect,
        exchange_name: exchange_name,
      };
      delete payload["isFilter"];
      await getDepositHistory({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err) {}
  };
  const getWithdrawalHistoryFn = async () => {
    try {
      const payload: any = {
        ...filterWithdrawal,
        is_connect: is_connect,
        exchange_name: exchange_name,
      };
      delete payload["isFilter"];
      await getWithdrawalHistory({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (filterDeposit?.start_date && role.includes("View Deposits")) {
      getDepositHistoryFn();
    }
    // eslint-disable-next-line
  }, [filterDeposit]);

  useEffect(() => {
    if (filterWithdrawal?.start_date && role.includes("View Withdrawals")) {
      getWithdrawalHistoryFn();
    }
    // eslint-disable-next-line
  }, [filterWithdrawal]);

  useEffect(() => {
    setTableDataDeposit(
      is_tab_connect
        ? depositHistory?.data?.exchangeData
        : depositHistory?.data?.aiData?.deposit_history
    );
    // eslint-disable-next-line
  }, [depositHistory, is_tab_connect]);

  useEffect(() => {
    setTableDataWithdrawal(
      is_tab_connect
        ? withdrawalHistory?.data?.exchangeData
        : withdrawalHistory?.data?.aiData?.withdrawal_history
    );
    // eslint-disable-next-line
  }, [withdrawalHistory, is_tab_connect]);

  useEffect(() => {
    const dateValue = getValues()?.date;
    const codeValue = getValues()?.code;

    let dates = {
      start_date: dateFormatter(new Date()),
      end_date: dateFormatter(new Date(), "end"),
    };

    if (watch("date")?.toString()?.length || watch("code")?.length) {
      if (dateValue === 1) {
        dates = {
          start_date: dateFormatter(prepareLastDate(7)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      if (dateValue === 2) {
        dates = {
          start_date: dateFormatter(prepareLastDate(31)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      if (dateValue === 3) {
        dates = {
          start_date: dateFormatter(prepareLastDate(31 * 3)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      /*  if (dateValue === 4) {
        setOpenDateModal(true);
      } */

      if (dateValue !== 4) {
        setFilterDeposit({
          ...filterDeposit,
          start_date: dateFormatter(dates?.start_date || Date.now(), "start"),
          end_date: dateFormatter(dates?.end_date || Date.now(), "end"),
          since: new Date(dates?.start_date || Date.now()).getTime(),
          until: new Date(dates?.end_date || Date.now()).getTime(),
          code: codeValue,
        });
      }
    }
    // eslint-disable-next-line
  }, [watch("date"), watch("code")]);

  useEffect(() => {
    const dateValue = getValuesWithdrawal()?.date;
    const codeValue = getValuesWithdrawal()?.code;

    let dates = {
      start_date: dateFormatter(new Date()),
      end_date: dateFormatter(new Date(), "end"),
    };

    if (
      watchWithdrawal("date")?.toString()?.length ||
      watchWithdrawal("code")?.length
    ) {
      if (dateValue === 1) {
        dates = {
          start_date: dateFormatter(prepareLastDate(7)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      if (dateValue === 2) {
        dates = {
          start_date: dateFormatter(prepareLastDate(31)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      if (dateValue === 3) {
        dates = {
          start_date: dateFormatter(prepareLastDate(31 * 3)),
          end_date: dateFormatter(new Date(), "end"),
        };
      }

      /*  if (dateValue === 4) {
        setOpenDateModal(true);
      } */

      if (dateValue !== 4) {
        setFilterWithdrawal({
          ...filterWithdrawal,
          start_date: dateFormatter(dates?.start_date || Date.now(), "start"),
          end_date: dateFormatter(dates?.end_date || Date.now(), "end"),
          since: new Date(dates?.start_date || Date.now()).getTime(),
          until: new Date(dates?.end_date || Date.now()).getTime(),
          code: codeValue,
        });
      }
    }
    // eslint-disable-next-line
  }, [watchWithdrawal("date"), watchWithdrawal("code")]);

  return (
    <>
      {role.includes("View Deposits") ? (
        <>
          <div className="card_rounded mb-5">
            <div>
              <h4 className="text-primary-950 font-semibold text-xl">
                Deposit
              </h4>
              <div className="flex gap-2 items-center py-4">
                <>
                  <ControlledDropDown
                    name="date"
                    width={200}
                    options={prepapreOptionsForDate()}
                    control={control}
                    onChange={(e: any) => {
                      setValue("date", e);
                      if (e === 4) {
                        setOpenDateModal(true);
                      }
                    }}
                  />
                  <ControlledDropDown
                    name="code"
                    width={200}
                    placeholder="Select Coin"
                    options={prepareToCoinOptions()}
                    control={control}
                  />
                  <Button
                    className="!h-8 !text-sm"
                    onClick={() => {
                      reset();
                    }}
                  >
                    Reset
                  </Button>
                </>
              </div>
            </div>
            <div className="App tableborder">
              <Table
                columns={
                  is_tab_connect === 1 ? columnsDepositBinance : columnsDeposit
                }
                fileName="Deposit_wallet_history"
                isLoading={!!isLoadingDeposit}
                data={tableDataDeposit || []}
                tableDataCount={tableDataDeposit?.length}
                setData={setTableDataDeposit}
                isExpendable={false}
                className="!overflow-scroll order_table"
                count={
                  is_tab_connect
                    ? depositHistory?.data?.exchangeData?.length
                    : depositHistory?.data?.aiData?.deposit_history?.length
                }
                pagination={filterDeposit}
                minHeight={300}
                handlePaginationChange={(pagination) => {
                  setFilterDeposit({
                    ...filterDeposit,
                    ...pagination,
                  });
                }}
              >
                {tableDataDeposit?.map((item: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <TableRow
                        className="!align-middle"
                        columns={
                          is_tab_connect === 1
                            ? columnsDepositBinance
                            : columnsDeposit
                        }
                        item={item}
                        isExpendable={false}
                      />
                    </Fragment>
                  );
                })}
              </Table>
            </div>
          </div>
        </>
      ) : null}
      {role.includes("View Withdrawals") ? (
        <>
          <div className="card_rounded mb-5">
            <div>
              <h4 className="text-primary-950 font-semibold text-xl">
                Withdrawal
              </h4>
              <div className="flex gap-2 items-center py-4">
                <>
                  <ControlledDropDown
                    name="date"
                    width={200}
                    options={prepapreOptionsForDate()}
                    control={controlWithdrawal}
                    onChange={(e: any) => {
                      setValueWithdrawal("date", e);
                      if (e === 4) {
                        setOpenDateModal(true);
                      }
                    }}
                  />
                  <ControlledDropDown
                    name="code"
                    width={200}
                    placeholder="Select Coin"
                    options={prepareToCoinOptions()}
                    control={controlWithdrawal}
                  />
                  <Button
                    className="!h-8 !text-sm"
                    onClick={() => {
                      resetWithdrawal();
                    }}
                  >
                    Reset
                  </Button>
                </>
              </div>
            </div>
            <div className="App tableborder">
              <Table
                columns={columnsWithdrawal}
                isLoading={!!isLoadingWithdrawal}
                fileName="withdrawal_wallet_history"
                data={tableDataWithdrawal || []}
                tableDataCount={tableDataWithdrawal?.length}
                setData={setTableDataWithdrawal}
                isExpendable={false}
                className="!overflow-scroll order_table"
                count={
                  is_tab_connect
                    ? withdrawalHistory?.data?.exchangeData?.length
                    : withdrawalHistory?.data?.aiData?.withdrawal_history
                        ?.length
                }
                pagination={filterWithdrawal}
                minHeight={300}
                handlePaginationChange={(pagination) => {
                  setFilterWithdrawal({
                    ...filterWithdrawal,
                    ...pagination,
                  });
                }}
              >
                {tableDataWithdrawal?.map((item: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <TableRow
                        className="!align-middle"
                        columns={columnsWithdrawal}
                        item={item}
                        isExpendable={false}
                      />
                    </Fragment>
                  );
                })}
              </Table>
            </div>
          </div>
        </>
      ) : null}
      {openDateModal ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
            onClick={() => {
              setOpenDateModal(false);
              // setValue("date", 2);
              setValue("start_date", null);
              setValue("end_date", null);
              setValueWithdrawal("start_date", null);
              setValueWithdrawal("end_date", null);
            }}
          />

          <div className="modal-container w-fit mx-auto shadow-lg z-50">
            <div className="modal-header">
              <h4 className="text-lg font-semibold text-primary-950">
                Select a Time Range
              </h4>
              <MdOutlineCancel
                color="var(--primary-600--)"
                size={24}
                onClick={() => {
                  setOpenDateModal(false);
                  // setValue("date", 2);
                  setValue("start_date", null);
                  setValue("end_date", null);
                  setValueWithdrawal("start_date", null);
                  setValueWithdrawal("end_date", null);
                }}
              />
            </div>
            <div className="modal-content">
              <div className="flex gap-4 items-end justify-center my-5">
                <ControlledDatePicker
                  name="start_date"
                  selectesStart
                  control={control}
                  startDate={watch("start_date")}
                  endDate={watch("end_date")}
                  label="Start Date"
                  className="items-start text-primary-950"
                  inputClassName="h-8 text-xs"
                />
                <span className="text-primary-950">to</span>
                <ControlledDatePicker
                  name="end_date"
                  selectsEnd
                  startDate={watch("start_date")}
                  endDate={watch("end_date")}
                  control={control}
                  label="End Date"
                  className="items-start text-primary-950"
                  inputClassName="h-8 text-xs"
                />
              </div>
            </div>
            <div className="modal-footer w-full">
              <Button
                onClick={() => {
                  setFilterDeposit({
                    ...filterDeposit,
                    start_date: dateFormatter(
                      dateFormatter(watch("start_date")) || Date.now(),
                      "start"
                    ),
                    end_date: dateFormatter(
                      dateFormatter(watch("end_date")) || Date.now(),
                      "end"
                    ),
                    since: new Date(
                      dateFormatter(watch("start_date")) || Date.now()
                    ).getTime(),
                    until: new Date(
                      dateFormatter(watch("end_date")) || Date.now()
                    ).getTime(),
                  });
                  setOpenDateModal(false);
                  setValue("start_date", null);
                  setValue("end_date", null);
                  setValueWithdrawal("start_date", null);
                  setValueWithdrawal("end_date", null);
                }}
                className="w-full"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WalletHistory;
