import { api } from "../../utills/api";

export const walletApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllWallet: build.query<{ data: any }, any>({
      query: (params: any) => ({
        url: "/get-all-wallets",
        body: params,
        method: "POST",
      }),
    }),
    convert: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/transfer",
        body: params,
        method: "POST",
      }),
    }),
    getAllWalletTypes: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/get-all-wallet-types",
        body: params,
        method: "POST",
      }),
    }),
    deposit: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deposit",
        body: params,
        method: "POST",
      }),
    }),
    withdrawal: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/withdrawal",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyGetAllWalletQuery,
  useConvertMutation,
  useGetAllWalletTypesMutation,
  useDepositMutation,
  useWithdrawalMutation,
} = walletApi;
export const {
  endpoints: { getAllWallet, convert, getAllWalletTypes, deposit, withdrawal },
} = walletApi;
