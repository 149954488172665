import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { usersApi } from "./usersApi";

interface UsersState {
  users: any;
  rolelist: any;
}

const initialState: UsersState = {
  users: {},
  rolelist: {},
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsers: () => initialState,
    setUsers: (state, { payload }) => {
      state.users = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      usersApi.endpoints.getUsers.matchFulfilled,
      (state: any, { payload }) => {
        state.users = payload || {};
      }
    );
    builder.addMatcher(
      usersApi.endpoints.getRoles.matchFulfilled,
      (state: any, { payload }) => {
        state.rolelist = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default usersSlice.reducer;
export const { clearUsers } = usersSlice.actions;

export const selectUsers = (state: RootState) =>
  state.users.users;
export const useUsers = () => {
  const data = useSelector(selectUsers);
  return useMemo(() => data, [data]);
};

export const selectRoleList = (state: RootState) =>
  state.users.rolelist;
export const useRoleList = () => {
  const data = useSelector(selectRoleList);
  return useMemo(() => data, [data]);
};
