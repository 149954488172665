// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import DatePicker, { DatePickerProps } from "./DatePicker";

interface ControlledDatePickerProps extends DatePickerProps {
  control: any;
}

const ControlledDatePicker = (props: ControlledDatePickerProps) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...restt } }) => {
        return(
        <DatePicker
          {...restt}
          {...rest}
        />
      )}}
    />
  );
};

export default ControlledDatePicker;
