// UI IMPORT
import { Icon, IconKeys } from "../icons";

// THIRD - PARTY IMPORT
import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DatePickerProps {
  className?: string;
  inputClassName?: string;
  name?: any;
  label?: string;
  value?: any;
  minDate?: Date;
  maxDate?: Date;
  selectesStart?: boolean;
  selectsEnd?: boolean;
  selectsRange?: boolean;
  onChange?: (dates?: any) => void;
  icon?: IconKeys;
  rightIcon?: IconKeys;
  placeholder?: string;
  errors?: any;
  startDate?: any;
  endDate?: any;
}

const DatePicker = (props: DatePickerProps) => {
  const {
    className = "",
    inputClassName = "",
    label,
    icon,
    rightIcon,
    errors,
    value,
    placeholder = "Enter Date",
    name,
    minDate,
    maxDate = new Date(),
    selectesStart,
    selectsEnd,
    startDate,
    endDate,
    onChange,
    ...rest
  } = props;

  const prepareMinDate = () => {
    if (selectsEnd) {
      if (startDate) {
        return startDate;
      }
    }

    return minDate || null;
  };

  const prepareMaxDate = () => {
    if (selectesStart) {
      if (endDate) {
        return endDate;
      }
    }
    return maxDate || null;
  };

  const message = errors?.[name]?.message || "";
  return (
    <div className={`${className} w-fit flex flex-col relative`}>
      {label ? (
        <label className="text-primary-950 text-sm pb-1">{label}</label>
      ) : null}
      {icon ? (
        <Icon className="absolute me-4 text-xl ms-4" name={icon} />
      ) : null}
      <Picker
        className={`w-[195px] text-sm text-primary-950 h-8 rounded-sm bg-chatlook-tertiary outline-none border-[1px] border-secondary-200 focus:border-chatlook-primary hover:border-chatlook-primary ${icon ? "pl-11" : "pl-3"
          } ${rightIcon ? "pr-11" : "pr-3"} ${message ? "border-red-500" : ""
          } ${inputClassName}`}
        placeholderText={placeholder}
        dateFormat="dd/MM/yyyy"
        maxDate={prepareMaxDate()}
        minDate={prepareMinDate()}
        selected={!Array.isArray(value) ? value : null}
        startDate={
          startDate ? startDate : Array.isArray(value) ? value?.[0] : null
        }
        endDate={endDate ? endDate : Array.isArray(value) ? value?.[1] : null}
        onChange={(dates) => onChange?.(dates)}
        selectsEnd={selectsEnd}
        selectsStart={selectesStart}
        {...rest}
      />
      {rightIcon ? (
        <Icon className="absolute right-0 me-4 text-xl" name={rightIcon} />
      ) : null}
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default DatePicker;
