// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import Switch, { SwitchProps } from "./Switch";

interface ControlledSwitchProps extends SwitchProps {
  control: any;
}

const ControlledSwitch = (props: ControlledSwitchProps) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, value = false, ...restt } }) => {
        return <Switch value={value} {...restt} {...rest} />;
      }}
    />
  );
};

export default ControlledSwitch;
