// RadioButton.tsx
import React, { ChangeEvent } from "react";

interface RadioButtonProps {
  label?: string;
  labelClassName?: string;
  name?: any;
  value?: string;
  checked?: boolean;
  defaultChecked?: any;
  disabled?: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { label, labelClassName="",...rest } = props;
  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      <input
        type="radio"
        className="form-radio text-primary-500"
        {...rest}
      />
      <span className={`text-ev-secondary ${labelClassName}`}>{label}</span>
    </label>
  );
};

export default RadioButton;
