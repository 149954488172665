interface DropdownMenuProps {
  activeOpenMenu: any;
  column: any;
  className?: string;
  onMenuClick: (menuKey: any) => void;
}

const DropdownMenu = (props: DropdownMenuProps) => {
  const { activeOpenMenu, column, onMenuClick, className = "" } = props;
  return (
    <div
      className={`dropdown-content ${
        activeOpenMenu === column?.title ? "open" : ""
      } ${className}`}
    >
      <ul className="">
        {Object.entries(column?.enum || {})?.map(([menuKey, menuItem]: any) => (
          <li
            className="cursor-pointer"
            key={menuKey}
            onClick={() => onMenuClick(menuKey)}
          >
            <span>{menuItem}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownMenu;
