import React, { memo } from "react";
import { IconProps } from ".";

export interface BackButtonIconProps extends IconProps {
}

const BackButtonIcon = (props: BackButtonIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 25,
    stroke = "var(--chatlook-primary--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;

  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path className={pathClassNames?.[1] || ""} d="M15 19l-7-7 7-7" />
    </svg>
  );
};

const MemoBackButtonIcon = memo(BackButtonIcon);
export default MemoBackButtonIcon;
