// PROJECT IMPORT
import DropDown, { DropDownProps } from "./DropDown";

// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";

interface ControlledDropDownProps extends DropDownProps {
  control?: any;
  [key:string]:any
}

const ControlledDropDown = (props: ControlledDropDownProps) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...restt } }) => {
        return <DropDown {...restt} {...rest} />;
      }}
    />
  );
};

export default ControlledDropDown;
