import { useEffect, useRef, useState } from "react";
import { createChart } from "lightweight-charts";

interface Chart2Props {
  markerData?: any[];
  style?: any;
  symbol?: string;
}
const Chart2 = (props: Chart2Props) => {
  const {
    markerData = [],
    style = { width: "100%", height: "100%" },
    symbol = "BTCUSDT",
  } = props;

  const [timeframe] = useState("15m");

  const container = useRef<HTMLDivElement | any>(null);

  const chartRef = useRef<any>();
  const seriesRef = useRef<any>();

  useEffect(() => {
    const chart = createChart(container.current, {
      width: container.current.clientWidth,
      height: container.current.clientHeight,
      layout: {
        // backgroundColor: "#ffffff",
        background: {
          color: "#11192d",
        },
        textColor: "white",
      },
      grid: {
        vertLines: {
          color: "rgba(255,255,255, 0.1)",
        },
        horzLines: {
          color: "rgba(255,255,255, 0.1)",
        },
      },
    });

    chartRef.current = chart;
    const candlestickSeries = chart.addCandlestickSeries();
    seriesRef.current = candlestickSeries;

    const handleResize = () => {
      chart.applyOptions({
        width: container.current.clientWidth,
        height: container.current.clientHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (seriesRef.current) {
      const binanceSocket = new WebSocket(
        `wss://stream.binance.com:9443/ws/${symbol?.toLowerCase()}@kline_${timeframe}`
      );

      binanceSocket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        const candlestick = message.k;

        seriesRef.current.update({
          time: candlestick.t / 1000,
          open: parseFloat(candlestick.o),
          high: parseFloat(candlestick.h),
          low: parseFloat(candlestick.l),
          close: parseFloat(candlestick.c),
        });
      };

      return () => {
        binanceSocket.close();
      };
    }
    // eslint-disable-next-line
  }, [symbol, timeframe, seriesRef]);

  useEffect(() => {
    if (seriesRef.current) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${timeframe}`
          );
          const data = await response.json();

          const formattedData = data?.map((item: any) => ({
            time: item[0] / 1000,
            open: parseFloat(item[1]),
            high: parseFloat(item[2]),
            low: parseFloat(item[3]),
            close: parseFloat(item[4]),
          }));
          seriesRef.current.setData(formattedData);
          seriesRef.current.setMarkers(markerData);
        } catch (err) {}
      };

      fetchData();
    }
    // eslint-disable-next-line
  }, [symbol, timeframe, seriesRef]);

  useEffect(() => {
    if (seriesRef.current) {
      seriesRef.current.setMarkers(markerData?.sort((a, b) => a.time - b.time));
    }
    // eslint-disable-next-line
  }, [seriesRef, markerData]);

  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={style}
    ></div>
  );
};

export default Chart2;
