import React, { memo } from "react";
import { IconProps } from ".";

export interface MenuBarIconProps extends IconProps {
}

const MenuBarIcon = (props: MenuBarIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 20,
    stroke = "var(--primary-950)--",
    fill = "var(--primary-950--)",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M3 4h18v2H3zm0 7h12v2H3zm0 7h18v2H3z"
        className={`${pathClassNames?.[0] || ""}`}
      />
    </svg>
  );
};

const MemoMenuBarIcon = memo(MenuBarIcon);
export default MemoMenuBarIcon;
