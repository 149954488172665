const ProgressBar = ({ value, maxValue, color }) => {
  const progressStyle = Math.round((value / maxValue) * 100);

  return (
    <div className=" border-sm progress-bar-container bg-secondary-200 h-[10px]">
      <div
        className={`progress-bar ${
          color === "green" ? "progress-bar-green" : "progress-bar-red"
        } h-[10px]`}
        style={{ width: `${progressStyle}%` }}
      />
    </div>
  );
};

export default ProgressBar;
