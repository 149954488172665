const DisableDiv = ({
  isDisabled,
  children,
  message,
}: {
  isDisabled: boolean;
  children: any;
  message: string;
}) => {
  return (
    <div className="relative">
      <div
        className={`absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center ${
          isDisabled ? "opacity-100 z-10" : "opacity-0 z-0"
        }`}
      >
        <div className="bg-white p-4 rounded shadow-md">
          <p>{message}</p>
        </div>
      </div>
      <div className={`relative ${isDisabled ? "blur-sm" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default DisableDiv;
