import { createContext, useEffect, useState } from "react";
import useWebSocket, { Trade } from "../hooks/useWebSocket";
import { Loader } from "../ui";

import { useUser } from "../features/auth/authSlice";
import { useGetExchangeMutation } from "../features/exchange/exchangeApi";
import { useLocation } from "react-router-dom";

interface WebSocketContextType {
  tickers: any;
  allTickers: any;
  tickersCombined: any[];
  coinUsd: any;
  midnightPrice: any;
  orderBook: any;
  laravelData: any;
  loading: any;
  isLoading: boolean;
  setLoading: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  initializeBinanceWebSocket?: (binanceUrl: any) => void;
  setBaseQuote?: (obj: IBaseQuote) => void;
  emitUpdateLimitOrderEvent?: any;
  klineData: any;
  trades: Trade[];
  emitCreateFutureTradeEvent: any;
  emitCreateSpotTradeEvent: any;
  orderUpdates: any;
  binanceOrderHistory: any;
  emitOrderHistory: any;
  binanceOpenOrders: any;
  binanceAllOpenOrders: any;
  emitOpenOrder: any;
  emitAllOpenOrder: any;
  emitCancelOrderEvent: any;
  emitClosingDataEvent: any;
  binanceClosingData: any;
  bybitTickers: any;
}

interface IBaseQuote {
  base?: string;
  quote?: string;
}

export const WebSocketContext = createContext<WebSocketContextType | null>(
  null
);

const WebSocketProvider = ({ children }: any) => {
  // const [laravelUrl, setLaravelUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [binanceUrl, setBinanceUrl] = useState<string | undefined>(undefined);
  const [baseQuote, setbaseQuote] = useState<IBaseQuote | undefined>(undefined);

  const user = useUser();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  const [getExchange] = useGetExchangeMutation();

  const initializeBinanceWebSocket = (binanceUrl: string) => {
    setBinanceUrl(binanceUrl);
  };

  const setBaseQuote = (obj: IBaseQuote) => {
    setbaseQuote(obj);
  };

  const {
    tickers,
    allTickers,
    tickersCombined,
    coinUsd,
    midnightPrice,
    orderBook,
    laravelData,
    klineData,
    trades,
    emitUpdateLimitOrderEvent,
    loading,
    setLoading,
    emitCreateFutureTradeEvent,
    emitCreateSpotTradeEvent,
    orderUpdates,
    binanceOrderHistory,
    emitOrderHistory,
    binanceOpenOrders,
    binanceAllOpenOrders,
    emitOpenOrder,
    emitAllOpenOrder,
    emitCancelOrderEvent,
    emitClosingDataEvent,
    binanceClosingData,
    // bybit
    bybitTickers,
  } = useWebSocket(baseQuote?.base, baseQuote?.quote, binanceUrl);

  const onExchangeListGet = async () => {
    try {
      setIsLoading(true);
      await getExchange({
        group_name: pathnames?.[1],
      }).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("Err =-=>", err);
    }
  };

  useEffect(() => {
    if (user?.data?.token) {
      onExchangeListGet();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <WebSocketContext.Provider
      value={{
        tickers,
        allTickers,
        tickersCombined,
        coinUsd,
        midnightPrice,
        orderBook,
        laravelData,
        loading,
        setLoading,
        isLoading,
        setIsLoading,
        initializeBinanceWebSocket,
        setBaseQuote,
        emitUpdateLimitOrderEvent,
        klineData,
        trades,
        emitCreateFutureTradeEvent,
        emitCreateSpotTradeEvent,
        orderUpdates,
        binanceOrderHistory,
        emitOrderHistory,
        binanceOpenOrders,
        binanceAllOpenOrders,
        emitOpenOrder,
        emitAllOpenOrder,
        emitCancelOrderEvent,
        emitClosingDataEvent,
        binanceClosingData,
        //bybit
        bybitTickers,
      }}
    >
      {isLoading ? (
        <div className="fixed h-[100vh] top-0 bottom-0 right-0 left-0 w-[100vw] z-[200] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        children
      )}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
