import React, { Fragment, useEffect, useState } from "react";
import Chart2 from "../trade/Chart2";
import useWebSocketContext from "../../context/useWebSocketContext";
import { ControlledDropDown, Table, TableRow } from "../../ui";
import { useGetAllTradeHistoryMutation } from "../trade/tradeApi";
import { useAllTradeHistory } from "../trade/tradeSlice";
import { onFixed } from "../../helperFunctions";
import { FILTER } from "../../constant";
import { useForm } from "react-hook-form";

const MarkerChart = () => {
  const [getAllTradeHistory, { isLoading }] = useGetAllTradeHistoryMutation();
  // const [getAllDataTradeHistory, { isLoading: allDataLoading }] =
  //   useGetAllDataTradeHistoryMutation();
  const [filter, setFilter] = useState<any>({
    ...FILTER,
  });
  const [allTotal, setAllTotal] = useState(0);
  const [symbolAllTotal, setSymbolAllTotal] = useState(0);
  const { tickers } = useWebSocketContext();
  const allTradeHistory = useAllTradeHistory();
  // const allDataTradeHistory = useAllDataTradeHistory();

  const onGet = async () => {
    const values = getValues();
    try {
      let payload = {
        ...filter,
        symbol: values?.symbol || "BTCUSDT",
      };
      await getAllTradeHistory(payload).unwrap();
    } catch (err: any) {}
  };

  // const onAllTradeDataGet = async () => {
  //   const values = getValues();
  //   try {
  //     let payload:any = {
  //       symbol: values?.symbol || "BTCUSDT",
  //     };
  //     await getAllDataTradeHistory(payload).unwrap();
  //   } catch (err: any) {}
  // };

  const prepareMarketOptions = () => {
    try {
      const tickerSet: Set<string> = new Set();
      let filteredMarkets: any = [];
      // Object.entries(spotMarket || {})?.forEach(([key, item]: any) => {
      //   Object.entries(item || {})?.forEach(([key2, item2]: any) => {
      //     if (item2?.active) {
      //       filteredMarkets = [
      //         ...filteredMarkets,
      //         {
      //           value: `${item2?.base}${item2?.quote}`,
      //           label: `${item2?.base}${item2?.quote}`,
      //         },
      //       ];
      //     }
      //   });
      // });
      allTradeHistory?.data?.all_orders?.forEach((val: any, index: any) => {
        const parseData = JSON.parse(val?.pg_res);
        const ticker = parseData?.ticker?.replace(
          parseData?.currency,
          `/${parseData?.currency}`
        );
        if (ticker && !tickerSet.has(ticker)) {
          tickerSet.add(ticker);
          filteredMarkets.push({
            value: ticker,
            label: ticker,
          });
        }
      });
      return filteredMarkets;
    } catch (error) {
      console.error("Error handling ticker data:", error);
      return {};
    }
  };
  useEffect(() => {
    prepareMarketOptions();
    // eslint-disable-next-line
  }, []);

  const prepareMarkerData = () => {
    const newData: any[] = [];

    allTradeHistory?.data?.trade_history?.forEach((item: any) => {
      const parseData = JSON.parse(item?.pg_res);
      const tradeTime = new Date(item?.created_at_ist).getTime() / 1000;

      if (+tradeTime) {
        newData.unshift({
          time: +tradeTime,
          position:
            parseData?.signal_direction === "bull" ? "belowBar" : "aboveBar",
          color: parseData?.signal_direction === "bull" ? "green" : "red",
          shape:
            parseData?.signal_direction === "bull" ? "arrowUp" : "arrowDown",
          text: parseData?.signal_direction === "bull" ? "BUY" : "SELL",
        });
      }
    });

    return newData;
  };

  const {
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      symbol: "BTC/USDT",
    },
  });

  useEffect(() => {
    onGet();
    // eslint-disable-next-line
  }, [filter, watch("symbol")]);

  const onTotal = () => {
    let tt: number = 0;
    allTradeHistory?.data?.all_orders?.forEach((val: any, index: any) => {
      const parseData = JSON.parse(val?.pg_res);
      const profit = (parseData?.close / parseData?.entry_price) * 100 - 100;
      tt += +profit || 0;
    });
    setAllTotal(tt);
  };

  useEffect(() => {
    if (allTradeHistory?.data?.all_orders) {
      onTotal();
    }
    // eslint-disable-next-line
  }, [allTradeHistory]);

  const columnsTradedHistory = [
    {
      title: "ID",
      name: "pg_id",
      Cell: (data: any) => (
        // <>{`${new Date(data?.created_at * 1000).toLocaleString()}`}</>
        <>{`${data?.pg_id || "-"}`}</>
      ),
    },
    {
      title: "Pair",
      Cell: (data: any) => {
        const parseData = JSON.parse(data?.pg_res);
        return <>{`${parseData?.ticker || "-"}`}</>;
      },
    },
    {
      title: "Amount",
      Cell: (data: any) => <>{`${onFixed(data?.order_amount) || "-"}`}</>,
    },
    {
      title: "Stack Amount",
      Cell: (data: any) => <>{`${onFixed(data?.order_amount) || "-"}`}</>,
    },
    {
      title: "Open rate",
      Cell: (data: any) => {
        const parseData = JSON.parse(data?.pg_res);
        return <>{`${parseData?.entry_price || "-"}`}</>;
      },
    },
    {
      title: "Close rate",
      Cell: (data: any) => {
        const parseData = JSON.parse(data?.pg_res);
        return <>{`${parseData?.close || "-"}`}</>;
      },
    },
    {
      title: "Profit %",
      Cell: (data: any) => {
        let tt: number = 0;
        const parseData = JSON.parse(data?.pg_res);
        const profit = (parseData?.close / parseData?.entry_price) * 100 - 100;
        tt += +profit || 0;
        setSymbolAllTotal(tt);
        // setAllTotal(allTotal + profit);
        return (
          <div
            className={`${
              profit < 0
                ? "text-primary-red"
                : profit > 0
                ? "text-primary-green"
                : ""
            }`}
          >{`${profit || "-"}`}</div>
        );
      },
    },
    {
      title: "Open date",
      Cell: (data: any) => <>{`${onFixed(data?.created_at) || "-"}`}</>,
    },
    {
      title: "Close date",
      Cell: (data: any) => <>{`${onFixed(data?.order_amount) || "-"}`}</>,
    },
    {
      title: "Close Reason",
      Cell: (data: any) => <>{`${onFixed(data?.order_amount) || "-"}`}</>,
    },
  ];

  return (
    <div>
      <div className="card_rounded mb-5 flex items-start gap-5">
        <ControlledDropDown
          className="w-[400px]"
          name="symbol"
          label="Chart Name"
          control={control}
          errors={errors}
          options={prepareMarketOptions()}
        />
        <div
          className={`text-xl ${
            symbolAllTotal < 0
              ? "text-primary-red"
              : symbolAllTotal > 0
              ? "text-primary-green"
              : "text-primary-950"
          }`}
        >
          <p className="text-primary-950 text-base">Total( symb ) : </p>
          <span className="text-sm">
            {isLoading ? (
              <span className="text-primary-950 text-sm"> 0</span>
            ) : (
              onFixed(symbolAllTotal)
            )}
          </span>
        </div>

        <div
          className={`text-xl ${
            allTotal < 0
              ? "text-primary-red"
              : allTotal > 0
              ? "text-primary-green"
              : "text-primary-950"
          }`}
        >
          <p className="text-primary-950 text-base">Total : </p>
          <span className="text-sm">
            {isLoading ? (
              <span className="text-primary-950 text-sm"> 0</span>
            ) : (
              onFixed(allTotal)
            )}
          </span>
        </div>
      </div>
      <Chart2
        markerData={prepareMarkerData()}
        style={{
          height: "500px",
          width: "100%",
          border: "1px solid #2b2b43",
          marginBottom: "20px",
        }}
        symbol={watch("symbol")?.split?.("/")?.join?.("")}
      />
      <Table
        isLoading={!!(!tickers || isLoading)}
        columns={columnsTradedHistory}
        data={allTradeHistory?.data?.trade_history || []}
        tableDataCount={allTradeHistory?.data?.trade_history?.length}
        isExpendable={false}
        className="!overflow-scroll order_table"
        minHeight={100}
        maxHeight={300}
        count={allTradeHistory?.data?.total}
        pagination={filter}
        handlePaginationChange={(pagination) => {
          setFilter({
            ...filter,
            ...pagination,
          });
        }}
      >
        {allTradeHistory?.data?.trade_history?.map(
          (item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow
                  // className="cursor-pointer"
                  columns={columnsTradedHistory}
                  item={item}
                  isExpendable={false}
                />
              </Fragment>
            );
          }
        )}
      </Table>
    </div>
  );
};

export default MarkerChart;
