import React, { memo } from "react";
import { IconProps } from ".";

export interface TradeIconProps extends IconProps {}
const TradeIcon = (props: TradeIconProps) => {
  const {
    className = "",
    pathClassNames = {},
    size = 24,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M9 5v4" className={`${pathClassNames?.[0] || ""}`} />
      <rect
        width="4"
        height="6"
        x="7"
        y="9"
        rx="1"
        className={`${pathClassNames?.[1] || ""}`}
      />
      <path d="M9 15v2" className={`${pathClassNames?.[2] || ""}`} />
      <path d="M17 3v2" className={`${pathClassNames?.[3] || ""}`} />
      <rect
        width="4"
        height="8"
        x="15"
        y="5"
        rx="1"
        className={`${pathClassNames?.[4] || ""}`}
      />
      <path d="M17 13v3" className={`${pathClassNames?.[5] || ""}`} />
      <path d="M3 3v18h18" className={`${pathClassNames?.[6] || ""}`} />
    </svg>
  );
};

const MemoTradeIcon = memo(TradeIcon);
export default MemoTradeIcon;
