import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { tradeApi } from "./tradeApi";
import { RootState } from "../../utills/store";

interface TradingState {
  positionHistory: any;
  history: any;
  closingData: any;
  filledOrderHistory: any;
  allOrderHistory: any;
  allFuturePositionHistory: any;
  allTradeHistory: any;
  // allDataTradeHistory: any;
  futureOrderHistory: any;
  openOrders: any;
  futureOpenOrders: any;
  openPositions: any;
}

const initialState: TradingState = {
  positionHistory: {},
  history: {},
  closingData: {},
  filledOrderHistory: {},
  allOrderHistory: {},
  allFuturePositionHistory: {},
  allTradeHistory: {},
  // allDataTradeHistory: {},
  futureOrderHistory: {},
  openOrders: {},
  futureOpenOrders: {},
  openPositions: {},
};

export const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {
    clearTrading: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tradeApi.endpoints.getTradeHistory.matchFulfilled,
      (state, { payload }) => {
        state.history = payload || {};
      }
    );
    builder.addMatcher(
      tradeApi.endpoints.getAllOrder.matchFulfilled,
      (state, { payload }) => {
        state.allOrderHistory = payload || {};
      }
    );
    builder.addMatcher(
      tradeApi.endpoints.getAllFuturePositionHistory.matchFulfilled,
      (state, { payload }) => {
        state.allFuturePositionHistory = payload || {};
      }
    );
    builder.addMatcher(
      tradeApi.endpoints.getAllTradeHistory.matchFulfilled,
      (state, { payload }) => {
        state.allTradeHistory = payload || {};
      }
    );
    // builder.addMatcher(
    //   tradeApi.endpoints.getAllDataTradeHistory.matchFulfilled,
    //   (state, { payload }) => {
    //     state.allDataTradeHistory = payload || {};
    //   }
    // );
    builder.addMatcher(
      tradeApi.endpoints.getTradePositionHistory.matchFulfilled,
      (state, { payload }) => {
        state.positionHistory = payload || {};
      }
    );
    builder.addMatcher(
      tradeApi.endpoints.getClosingData.matchFulfilled,
      (state, { payload }) => {
        state.closingData = payload || {};
      }
    );

    builder.addMatcher(
      tradeApi.endpoints.getTradePosition.matchFulfilled,
      (state, { payload }) => {
        state.openPositions = payload?.data || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default tradeSlice.reducer;
export const { clearTrading } = tradeSlice.actions;

export const selectTradeHistoryItems = (state: RootState) =>
  state.trade.history;
export const useTradeHistoryItems = () => {
  const history = useSelector(selectTradeHistoryItems);
  return useMemo(() => history, [history]);
};

export const selectTradePositionHistory = (state: RootState) =>
  state.trade.positionHistory;
export const useTradePositionHistory = () => {
  const positionHistory = useSelector(selectTradePositionHistory);
  return useMemo(() => positionHistory, [positionHistory]);
};

export const selectClosingData = (state: RootState) => state.trade.closingData;
export const useClosingData = () => {
  const closingData = useSelector(selectClosingData);
  return useMemo(() => closingData, [closingData]);
};

export const selectOpenPosition = (state: RootState) =>
  state.trade.openPositions;
export const useOpenPosition = () => {
  const openOrders = useSelector(selectOpenPosition);
  return useMemo(() => openOrders, [openOrders]);
};

export const selectAllOrderHistory = (state: RootState) =>
  state.trade.allOrderHistory;
export const useAllOrderHistory = () => {
  const allOrderHistory = useSelector(selectAllOrderHistory);
  return useMemo(() => allOrderHistory, [allOrderHistory]);
};
export const selectAllFuturePositionHistory = (state: RootState) =>
  state.trade.allFuturePositionHistory;
export const useAllFuturePositionHistory = () => {
  const allFuturePositionHistory = useSelector(selectAllFuturePositionHistory);
  return useMemo(() => allFuturePositionHistory, [allFuturePositionHistory]);
};
export const selectAllTradeHistory = (state: RootState) =>
  state.trade.allTradeHistory;
export const useAllTradeHistory = () => {
  const allTradeHistory = useSelector(selectAllTradeHistory);
  return useMemo(() => allTradeHistory, [allTradeHistory]);
};
// export const selectAllDataTradeHistory = (state: RootState) =>
//   state.trade.allDataTradeHistory;
// export const useAllDataTradeHistory = () => {
//   const allDataTradeHistory = useSelector(selectAllDataTradeHistory);
//   return useMemo(() => allDataTradeHistory, [allDataTradeHistory]);
// };
