import { memo } from "react";
import { IconProps } from ".";

export interface winRateProps extends IconProps {}
const winRate = (props: winRateProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 50,
    stroke = "currentColor",
    fill = "none",
    strokeWidth = 0.5,
    ...rest
  } = props;
  return (
    <svg
      className={`${className}`}
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M48.7793 44.043H37.8803C37.4757 44.043 37.1479 44.3708 37.1479 44.7754C37.1479 45.18 37.4757 45.5078 37.8803 45.5078H48.5352V46.9727H1.46484V45.5078H34.483C34.8876 45.5078 35.2154 45.18 35.2154 44.7754C35.2154 44.3708 34.8876 44.043 34.483 44.043H1.2207C0.547559 44.043 0 44.5905 0 45.2637V47.2168C0 47.8899 0.547559 48.4375 1.2207 48.4375H48.7793C49.4524 48.4375 50 47.8899 50 47.2168V45.2637C50 44.5905 49.4524 44.043 48.7793 44.043Z"
        fill="#3D3D3D"
      />
      <path
        d="M46.8262 30.3389C47.2308 30.3389 47.5586 30.011 47.5586 29.6064V25.7324C47.5586 25.0593 47.011 24.5117 46.3379 24.5117H42.4316C41.7585 24.5117 41.2109 25.0593 41.2109 25.7324V41.3574C41.2109 42.0306 41.7585 42.5781 42.4316 42.5781H46.3379C47.011 42.5781 47.5586 42.0306 47.5586 41.3574V33.0224C47.5586 32.6178 47.2308 32.2899 46.8262 32.2899C46.4216 32.2899 46.0938 32.6178 46.0938 33.0224V41.1133H42.6758V25.9766H46.0938V29.6064C46.0938 30.011 46.4216 30.3389 46.8262 30.3389Z"
        fill="#3D3D3D"
      />
      <path
        d="M47.5586 14.0137C47.5586 13.3405 47.011 12.793 46.3379 12.793H42.4316C41.7585 12.793 41.2109 13.3405 41.2109 14.0137V15.9668C41.2109 16.6399 41.7585 17.1875 42.4316 17.1875H46.3379C47.011 17.1875 47.5586 16.6399 47.5586 15.9668V14.0137ZM46.0938 15.7227H42.6758V14.2578H46.0938V15.7227Z"
        fill="#3D3D3D"
      />
      <path
        d="M47.5586 19.873C47.5586 19.1999 47.011 18.6523 46.3379 18.6523H42.4316C41.7585 18.6523 41.2109 19.1999 41.2109 19.873V21.8262C41.2109 22.4993 41.7585 23.0469 42.4316 23.0469H46.3379C47.011 23.0469 47.5586 22.4993 47.5586 21.8262V19.873ZM46.0938 21.582H42.6758V20.1172H46.0938V21.582Z"
        fill="#3D3D3D"
      />
      <path
        d="M33.6426 42.5781H37.5488C38.222 42.5781 38.7695 42.0306 38.7695 41.3574V26.6113C38.7695 25.9382 38.222 25.3906 37.5488 25.3906H33.6426C32.9694 25.3906 32.4219 25.9382 32.4219 26.6113V41.3574C32.4219 42.0306 32.9694 42.5781 33.6426 42.5781ZM33.8867 26.8555H37.3047V41.1133H33.8867V26.8555Z"
        fill="#3D3D3D"
      />
      <path
        d="M24.3652 37.5509C23.9607 37.5509 23.6328 37.8787 23.6328 38.2833V41.3574C23.6328 42.0306 24.1804 42.5781 24.8535 42.5781H28.7598C29.4329 42.5781 29.9805 42.0306 29.9805 41.3574V31.8848C29.9805 31.2116 29.4329 30.6641 28.7598 30.6641H24.8535C24.1804 30.6641 23.6328 31.2116 23.6328 31.8848V34.8591C23.6328 35.2637 23.9607 35.5915 24.3652 35.5915C24.7697 35.5915 25.0977 35.2637 25.0977 34.8591V32.1289H28.5156V41.1133H25.0977V38.2833C25.0977 37.8788 24.7697 37.5509 24.3652 37.5509Z"
        fill="#3D3D3D"
      />
      <path
        d="M16.0645 33.2031C15.3913 33.2031 14.8438 33.7507 14.8438 34.4238V41.3574C14.8438 42.0306 15.3913 42.5781 16.0645 42.5781H19.9707C20.6438 42.5781 21.1914 42.0306 21.1914 41.3574V34.4238C21.1914 33.7507 20.6438 33.2031 19.9707 33.2031H16.0645ZM19.7266 41.1133H16.3086V34.668H19.7266V41.1133Z"
        fill="#3D3D3D"
      />
      <path
        d="M11.1816 36.1328H7.27539C6.60225 36.1328 6.05469 36.6804 6.05469 37.3535V41.3574C6.05469 42.0306 6.60225 42.5781 7.27539 42.5781H11.1816C11.8548 42.5781 12.4023 42.0306 12.4023 41.3574V37.3535C12.4023 36.6804 11.8548 36.1328 11.1816 36.1328ZM10.9375 41.1133H7.51953V37.5977H10.9375V41.1133Z"
        fill="#3D3D3D"
      />
      <path
        d="M40.7196 11.3281H48.0491C48.6669 11.3281 49.2202 11.0087 49.5292 10.4736C49.8381 9.93857 49.8381 9.29971 49.5292 8.76465L45.8644 2.41699C45.5554 1.88193 45.0022 1.5625 44.3843 1.5625C43.7664 1.5625 43.2132 1.88193 42.9043 2.41699L39.2395 8.76465C38.9306 9.29971 38.9306 9.93857 39.2395 10.4736C39.5484 11.0087 40.1017 11.3281 40.7196 11.3281ZM40.508 9.49707L44.1729 3.14941C44.2342 3.04316 44.3317 3.02734 44.3843 3.02734C44.4369 3.02734 44.5344 3.04316 44.5957 3.14941L48.2606 9.49707C48.3219 9.60322 48.2869 9.69561 48.2606 9.74121C48.2342 9.78682 48.1717 9.86328 48.0491 9.86328H40.7195C40.5968 9.86328 40.5343 9.78682 40.5079 9.74121C40.4816 9.69561 40.4467 9.60322 40.508 9.49707Z"
        fill="#3D3D3D"
      />
      <path
        d="M5.73483 16.0089C6.02448 16.2911 6.48825 16.2855 6.77057 15.9955C7.0529 15.7059 7.04694 15.2422 6.75729 14.9599L6.13034 14.3488C5.83766 14.0635 5.73835 13.6611 5.86462 13.2725C5.99089 12.8838 6.30778 12.6166 6.71227 12.5579L12.2901 11.7474C13.1093 11.6282 13.8172 11.1141 14.1836 10.3717L16.678 5.31729C16.8589 4.95079 17.2109 4.73204 17.6196 4.73204C18.0284 4.73204 18.3803 4.95079 18.5612 5.31729L21.0556 10.3717C21.422 11.1141 22.1298 11.6282 22.9492 11.7474L28.527 12.5579C28.9315 12.6167 29.2483 12.8838 29.3746 13.2725C29.5009 13.6611 29.4015 14.0636 29.1089 14.3487L25.0727 18.2831C24.4799 18.8609 24.2096 19.6931 24.3495 20.509L25.3023 26.0643C25.3714 26.4671 25.2152 26.8511 24.8846 27.0913C24.554 27.3315 24.1406 27.3614 23.7789 27.1712L18.7898 24.5483C18.0571 24.1633 17.1822 24.1633 16.4494 24.5483L11.4605 27.1712C11.0987 27.3613 10.6854 27.3316 10.3548 27.0913C10.0241 26.8511 9.86794 26.4671 9.93708 26.0643L10.8899 20.509C11.0298 19.6931 10.7594 18.8609 10.1667 18.2831L9.1985 17.3394C8.90876 17.0569 8.44508 17.0628 8.16276 17.3525C7.88044 17.6422 7.8863 18.106 8.17594 18.3882L9.14411 19.3319C9.39167 19.5732 9.50456 19.9207 9.44616 20.2614L8.49333 25.8167C8.09587 27.7637 10.3388 29.4494 12.1423 28.4678L17.1311 25.8449C17.4371 25.6842 17.8025 25.6841 18.1084 25.8449L23.0973 28.4678C24.7246 29.4406 27.1694 27.8545 26.7462 25.8167L25.7934 20.2614C25.7349 19.9208 25.8479 19.5732 26.0954 19.332L30.1315 15.3978C31.6026 14.0376 30.7643 11.3707 28.7378 11.1082L23.16 10.2977C22.8179 10.2479 22.5223 10.0332 22.3694 9.72325L19.875 4.66886C19.0126 2.82305 16.2418 2.84044 15.3646 4.66886L12.87 9.72335C12.7171 10.0333 12.4215 10.248 12.0794 10.2978L6.50163 11.1083C4.52692 11.3318 3.61696 13.9858 5.10788 15.3979L5.73483 16.0089Z"
        fill="#3D3D3D"
      />
      <path
        d="M17.5713 19.0936C16.8977 19.0992 16.7049 19.0675 16.1937 18.7334C15.8551 18.512 15.4011 18.6071 15.1799 18.9459C14.9587 19.2846 15.0538 19.7384 15.3924 19.9597C15.9592 20.33 16.376 20.4786 16.8865 20.5319V21.1585C16.8865 21.5631 17.2144 21.8909 17.6189 21.8909C18.0234 21.8909 18.3513 21.5631 18.3513 21.1585V20.4414C19.3546 20.131 19.9962 19.2497 20.1421 18.3801C20.3386 17.2098 19.7031 16.1524 18.5608 15.7492C18.1501 15.5859 17.2416 15.4313 16.9657 15.0645C16.8623 14.9413 16.8244 14.7025 16.8714 14.4704C16.8997 14.3312 17.0031 13.9881 17.3531 13.8092C17.8495 13.5558 18.3729 13.9536 18.4728 14.036C18.7847 14.2935 19.2463 14.2491 19.5039 13.9372C19.7613 13.6252 19.7171 13.1635 19.405 12.9061C19.1199 12.6708 18.756 12.4646 18.3513 12.3514V11.7988C18.3513 11.3942 18.0234 11.0664 17.6189 11.0664C17.2144 11.0664 16.8865 11.3942 16.8865 11.7988V12.4146C16.8197 12.4411 16.753 12.4708 16.6865 12.5048C16.0405 12.835 15.5846 13.4453 15.4359 14.1794C15.2988 14.8558 15.4513 15.5388 15.8439 16.0063C16.498 16.7354 17.1316 16.8198 18.0732 17.1305C18.7493 17.3691 18.7351 17.9142 18.6976 18.1375C18.6208 18.5948 18.2356 19.0892 17.5713 19.0936Z"
        fill="#3D3D3D"
      />
    </svg>
  );
};

const MemowinRate = memo(winRate);
export default MemowinRate;
