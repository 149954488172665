import { Fragment, useEffect, useMemo, useState } from "react";

// UI IMPORT
import { Table, TableRow } from "../../ui";

// PROJECT IMPORT
import { useGetTradePositionHistoryMutation } from "../trade/tradeApi";
import { useKeys } from "../exchange/exchangeSlice";
import { MdOutlineCancel } from "react-icons/md";
import { onFixed, preparePriceStyle } from "../../helperFunctions";
import { sideEnum, SideEnumKeys } from "../../constant";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";

// THIRD - PARTY IMPORT

const StrategySymbolModal = ({ isOpen, onClose, preparePayload }: any) => {
  const [getTradePositionHistory, { isLoading }] =
    useGetTradePositionHistoryMutation();

  const {
    currentKey: { is_tab_connect },
  } = useKeys();

  const [data, setData] = useState<any>({});

  const columns = useMemo(
    () => [
      {
        title: "Position Time",
        name: "created_at",
        Cell: (data: any) => (
          <>
            <p>{`${data?.children?.created_at}`}</p>
            <p>{`${data?.created_at}`}</p>
          </>
        ),
      },
      {
        title: "Symbol",
        Cell: (data: any) => (
          <>
            <p>{`${data?.children?.symbol}`}</p>
            <p>{`${data?.symbol}`}</p>
          </>
        ),
      },
      {
        title: "Size",
        Cell: (data: any) => (
          <>
            <p>{`${onFixed(data?.children?.total)}`}</p>

            <p>{`${onFixed(data?.total)}`}</p>
          </>
        ),
      },
      {
        title: "Entry Price",
        name: "current_price",
        Cell: (data: any) => (
          <>
            <p>{`${onFixed(data?.children?.current_price)}`}</p>
            <p>{`${onFixed(data?.current_price)}`}</p>
          </>
        ),
      },
      {
        title: "Mark Price",
        name: "stock_price",
        Cell: (data: any) => (
          <>
            <p>{`${onFixed(data?.children?.stock_price)}`}</p>
            <p>{`${onFixed(data?.stock_price)}`}</p>
          </>
        ),
      },
      {
        title: "Trade Value",
        name: "trade_value",
        Cell: (data: any) => (
          <>
            <p>{`${onFixed(data?.children?.trade_value)}`}</p>
            <p>{`${onFixed(data?.trade_value)}`}</p>
          </>
        ),
      },
      {
        title: "Side",
        Cell: ({
          side,
          children,
        }: {
          side: SideEnumKeys;
          children: { side: SideEnumKeys };
        }) => (
          <>
            <>
              {/* {`${sideEnum?.[children?.side]}`} */}
              <div className="flex gap-2 items-center">
                {sideEnum?.[children?.side] === "Buy" ? (
                  <GoArrowUpRight size={20} color="var(--primary-green--)" />
                ) : (
                  <GoArrowDownRight size={20} color="var(--primary-red--)" />
                )}
                <p>{`${sideEnum?.[children?.side]}`}</p>
              </div>
            </>
            <div className="flex gap-2 items-center">
              {sideEnum?.[side] === "Buy" ? (
                <GoArrowUpRight size={20} color="var(--primary-green--)" />
              ) : (
                <GoArrowDownRight size={20} color="var(--primary-red--)" />
              )}
              <p>{`${sideEnum?.[side]}`}</p>
            </div>
          </>
        ),
      },
      {
        title: "Platform",
        name: "platform",
      },
      {
        title: "Trade Type",
        name: "trade_type",
      },
      {
        title: "PNL(ROI %)",
        name: "profit_loss",
        Cell: (val: any) => {
          return (
            <div className="flex flex-col gap-2">
              <span
                className={`${
                  preparePriceStyle(+val?.children?.profit_loss)?.className
                }`}
              >
                {onFixed(val?.children?.profit_loss) || 0}
              </span>
            </div>
          );
        },
      },
      {
        title: "Fees",
        name: "fees",
        Cell: (data: any) => (
          <>
            <p>{`${onFixed(data?.children?.fees)}`}</p>
            <p>{`${onFixed(data?.fees)}`}</p>
          </>
        ),
      },
      {
        title: "Market Value",
        name: "market_value",
        Cell: (data: any) => (
          <>
            <p>{`${onFixed(data?.children?.market_value)}`}</p>
            <p>{`${onFixed(data?.market_value)}`}</p>
          </>
        ),
      },
    ],
    []
  );
  // const columnsBinance = useMemo(
  //   () => [
  //     {
  //       title: "Position Time",
  //       name: "created_at",
  //       Cell: (data: any) => (
  //         <>
  //           <div className="flex flex-col">
  //             <p>{data?.opened}</p>
  //             <p>{data?.closed || "-"}</p>
  //           </div>
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Symbol",
  //       Cell: (data: any) => (
  //         <>
  //           <p>{`${data?.symbol}`}</p>
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Price",
  //       name: "current_price",
  //       Cell: (data: any) => (
  //         <>
  //           <div className="flex flex-col">
  //             <p>{onFixed(data?.avg_cost) || "-"}</p>
  //             <p>{onFixed(data?.avg_close_price) || "-"}</p>
  //           </div>
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Trade Value",
  //       name: "initialMargin",
  //       Cell: (data: any) => (
  //         <>
  //           <div className="flex flex-col">
  //             <p>{onFixed(data?.max_open_interest) || "-"}</p>
  //             <p>{onFixed(data?.closed_volume) || "-"}</p>
  //           </div>
  //         </>
  //       ),
  //     },
  //     {
  //       title: "Side",
  //       Cell: ({ side }: any) => (
  //         <>
  //           <p>{side}</p>
  //         </>
  //       ),
  //     },
  //     {
  //       title: "PNL(ROI %)",
  //       name: "profit_loss",
  //       Cell: (val: any) => {
  //         return (
  //           <div className="flex flex-col">
  //             <span
  //               className={`${preparePriceStyle(+val?.closing_pnl)?.className}`}
  //             >
  //               {onFixed(val?.closing_pnl) || 0}
  //             </span>
  //             <span
  //               className={`${preparePriceStyle(+val?.closing_pnl)?.className}`}
  //             >
  //               {onFixed(val?.children?.profit_loss) || 0}
  //             </span>
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  //   []
  // );

  const onGetData = async () => {
    try {
      const payload = preparePayload();
      payload["symbol"] = isOpen;
      const res: any = await getTradePositionHistory(payload).unwrap();
      setData(res?.data);
    } catch (err) {
      console.error("Err", err);
    }
  };

  useEffect(() => {
    if (!!isOpen) {
      onGetData();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const onHide = () => {
    onClose();
    setData([]);
  };

  const parepareData = () => {
    return is_tab_connect
      ? data?.exchangeData?.position_history
      : data?.aiData?.position_history;
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
          onClick={() => {
            onHide();
          }}
        />

        <div className="modal-container w-8/12 md:w-10/12 sm:w-11/12 z-50 mx-auto overflow-y-auto">
          <div className="modal-header">
            <h4 className="text-lg font-semibold text-primary-950">
              {isOpen} Trade History
            </h4>
            <MdOutlineCancel
              color="var(--primary-600--)"
              size={24}
              onClick={() => {
                onHide();
              }}
            />
          </div>
          <div className="modal-content">
            <div className="card_rounded">
              <Table
                isLoading={isLoading}
                columns={columns}
                data={parepareData() || []}
                tableDataCount={parepareData()?.length}
                count={parepareData()?.length}
                isExpendable={false}
                className="!overflow-scroll"
                minHeight={300}
                setData={setData}
              >
                {parepareData()?.map((item: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <TableRow
                        columns={columns}
                        item={item}
                        isExpendable={false}
                      />
                    </Fragment>
                  );
                })}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StrategySymbolModal;
