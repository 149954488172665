import {
  Button,
  ControlledDatePicker,
  getStatusTypes,
  Loader,
  Table,
  TableRow,
} from "../../ui";
import { useForm } from "react-hook-form";
import { useGetAllAccountStatementMutation } from "./accountStatementApi";
import { dateFormatter1, formatDateYMD, onFixed } from "../../helperFunctions";
import { Fragment, useEffect, useState } from "react";
import { useAccountStatementData } from "./accountStatementSlice";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import {
  FILTER,
  SideEnumKeys,
  orderStatuses,
  orderTypeEnum,
  sideEnum,
  typeEnum,
} from "../../constant";
import useWebSocketContext from "../../context/useWebSocketContext";
import { useLocation } from "react-router-dom";
import { useRole } from "../auth/authSlice";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";

const initialFilter = {
  date: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
};

ChartJS.register(ArcElement, Tooltip, Legend);

const AccountStatement = () => {
  const accData = useAccountStatementData();
  const accStatementData = accData?.data?.filter?.(
    (item: any) => item?.quantity > 0
  );
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const role = useRole("account-statement");

  const [tableData, setTableData] = useState<any>([]);
  const [filter, setFilter] = useState<any>(initialFilter);
  const [filterForOrder, setFilterForOrder] = useState<any>({
    ...FILTER,
    ...initialFilter,
  });

  const [getAllAccountStatement, { isLoading }] =
    useGetAllAccountStatementMutation();

  const { loading, binanceOrderHistory, emitOrderHistory } =
    useWebSocketContext();

  const initialValues = {
    date: new Date(new Date().setDate(new Date().getDate() - 1)),
  };

  const columnsOrderHistory = [
    {
      title: "Order Time",
      name: "created_at",
      Cell: (data: any) => <>{`${data?.created_at}`}</>,
    },
    {
      title: "Pair",
      name: "symbol",
    },
    {
      title: "Order Type",
      name: "order_type",
      prepareValue: ({ order_type = "1" }: { order_type: "1" | "2" }) =>
        `${orderTypeEnum?.[order_type]}`,
      Cell: ({ order_type = "1" }: { order_type: "1" | "2" }) => (
        <>
          <span>{orderTypeEnum?.[order_type]}</span>
        </>
      ),
      isMenu: true,
      enum: orderTypeEnum,
    },
    {
      title: "Type",
      name: "type",
      prepareValue: ({ type = "1" }: { type: "1" | "2" }) =>
        `${typeEnum?.[type]}`,
      Cell: ({ type = "1" }: { type: "1" | "2" }) => (
        <>
          <span>{typeEnum?.[type]}</span>
        </>
      ),
      isMenu: true,
      enum: typeEnum,
    },
    {
      title: "Side",
      name: "side",
      prepareValue: ({ side }: { side: SideEnumKeys }) => `${sideEnum?.[side]}`,
      Cell: ({ side }: { side: SideEnumKeys }) => (
        <>
          <div className="flex gap-2 items-center">
            {sideEnum?.[side] === "Buy" ? (
              <GoArrowUpRight size={20} color="var(--primary-green--)" />
            ) : (
              <GoArrowDownRight size={20} color="var(--primary-red--)" />
            )}
            <p>{`${sideEnum?.[side]}`}</p>
          </div>
        </>
      ),
      isMenu: true,
      enum: sideEnum,
    },
    {
      title: "Average",
      name: "exchange_rate",
      prepareValue: (data: any) => `${onFixed(data?.exchange_rate)}`,
      Cell: (data: any) => {
        return <>{`${onFixed(data?.exchange_rate)}`}</>;
      },
    },
    {
      title: "Price",
      name: "stock_price",
      prepareValue: (data: any) =>
        `${data?.type === 1 ? "Market" : onFixed(data?.stock_price)}`,
      Cell: (data: any) => (
        <>
          <span>
            {data?.type === 1 ? "Market" : onFixed(data?.stock_price)}
          </span>
        </>
      ),
    },
    {
      title: "Executed",
      name: "executed",
      prepareValue: (data: any) => `${onFixed(data?.executed)}`,
      Cell: (data: any) => {
        return <>{`${onFixed(data?.executed)}`}</>;
      },
    },
    {
      title: "Amount",
      name: "amount",
      prepareValue: (data: any) => `${onFixed(data?.amount)}`,
      Cell: (data: any) => {
        return <>{`${onFixed(data?.amount)}`}</>;
      },
    },
    {
      title: "Total",
      name: "total",
      prepareValue: (data: any) => `${onFixed(data?.total)}`,
      Cell: (data: any) => <span>{onFixed(data?.total)}</span>,
    },
    {
      title: "Status",
      name: "order_status",
      prepareValue: ({
        order_status,
      }: {
        order_status: "1" | "2" | "3" | "4";
      }) => `${orderStatuses?.[order_status]}`,
      Cell: ({ order_status }: { order_status: "1" | "2" | "3" | "4" }) => (
        <div
          className={`${getStatusTypes(orderStatuses?.[order_status])}`}
        >{`${orderStatuses?.[order_status]}`}</div>
      ),
      isMenu: true,
      enum: orderStatuses,
      dropdownClassName: "!left-auto !right-0",
    },
  ];

  const calculateTotalUSDT = (data: any) => {
    const total = data?.reduce?.(
      (acc: number, item: any) =>
        acc + (+item?.current_rate || 0) * (+item?.quantity || 0),
      0
    );
    return total;
  };

  const getAccountStatement = async () => {
    try {
      const { date } = filter;
      const payload: any = {
        start_date: dateFormatter1(date, "start"),
        end_date: dateFormatter1(date, "end", date),
      };
      await getAllAccountStatement({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
    } catch (error) {}
  };

  const getOrderHistoryList = async () => {
    try {
      const { date, ...rest } = filterForOrder;
      const payload: any = {
        start_date: dateFormatter1(date, "start"),
        end_date: dateFormatter1(date, "end", date),
        order_type: 0,
        is_connect: 0,
        group_name: pathnames?.[1],
        ...rest,
      };
      delete payload["isFilter"];
      setTimeout(() => {
        emitOrderHistory(payload);
      }, 900);
    } catch (error) {}
  };

  const prepareChartData = (chartData: any) => {
    const prepareColorCode = chartData?.map?.((item: any) => {
      return item?.coins?.color_code;
    });

    const dataValues = chartData?.map?.((item: any) => {
      return item?.quantity * item?.current_rate;
    });

    const allValuesZero = dataValues?.every?.((value: number) => value === 0);

    const finalDataValues = allValuesZero ? [100] : dataValues;
    const finalColorCode = allValuesZero ? ["#293449"] : prepareColorCode;

    const data = {
      labels: finalColorCode,
      datasets: [
        {
          label: "# of Votes",
          data: finalDataValues,
          backgroundColor: finalColorCode,
          borderColor: finalColorCode,
          borderWidth: 1,
        },
      ],
    };
    return data;
  };

  const prepareOptions = (chartData: any[]) => {
    const labels = chartData?.map?.((data: any) => ({
      label: `${data?.wallet_name}`,
      color: data?.coins?.color_code,
      total: data?.current_rate * data?.quantity,
      percentage: 0,
    }));

    const dataValues = chartData?.map?.((item: any) => {
      return item?.quantity * item?.current_rate;
    });

    const allValuesZero = dataValues?.every?.((value: number) => value === 0);

    labels?.forEach?.((label: any) => {
      label.percentage =
        (label?.total / calculateTotalUSDT(chartData)) * 100 || 0;
    });

    const chartOptions: ChartOptions<"pie"> = {
      cutout: "0%",
      maintainAspectRatio: false,
      aspectRatio: 1,
      plugins: {
        legend: {
          position: "right",
          align: "start",
          labels: {
            usePointStyle: true,
            generateLabels: () => {
              return labels?.map?.((label: any, index: number) => ({
                text: `${label?.label}  ${onFixed(label?.percentage)}%`,
                fillStyle: label?.color,
                strokeStyle: label?.color,
                lineWidth: 0,
                pointStyle: "rect",
                fontColor: "var(--primary-950--)",
                fontSize: 12,
                index,
              }));
            },
          },
        },
        tooltip: {
          callbacks: {
            ...(allValuesZero
              ? {
                  label: () => {
                    return "No Binance Found";
                  },
                  title: () => {
                    return "";
                  },
                }
              : {
                  label: (context) => {
                    const label = labels?.[context?.dataIndex];
                    return `${onFixed(label?.percentage)}%`;
                  },
                  title: (context: any) => {
                    const label = labels?.[context?.[0]?.dataIndex];
                    return label?.label;
                  },
                }),
          },
        },
      },
    };

    return chartOptions;
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = async (values: any) => {
    setFilter({
      ...filter,
      date: values?.date,
    });
    setFilterForOrder({
      ...filterForOrder,
      date: values?.date,
    });
  };

  useEffect(() => {
    setTableData(binanceOrderHistory?.AI);
    // eslint-disable-next-line
  }, [binanceOrderHistory]);

  useEffect(() => {
    if (role.includes("View Account Statements")) {
      getAccountStatement();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (role.includes("View Account Statement Order History")) {
      getOrderHistoryList();
    }
    // eslint-disable-next-line
  }, [filterForOrder]);

  const columns = [
    {
      title: "Color",
      name: "color",
      prepareValue: (data: any) => `${data?.coins?.color_code}`,
      Cell: (data: any) => {
        return (
          <div
            className={`w-5 h-5 rounded-full`}
            style={{
              backgroundColor: data?.coins?.color_code,
            }}
          ></div>
        );
      },
    },
    {
      title: "Holding",
      name: "wallet_name",
      Cell: (data: any) => (
        <div className="flex gap-2 items-center">
          <img
            className="w-7"
            src={`/images/cryptoCurrency/${data?.wallet_name?.toLowerCase()}.png`}
            alt="coin"
          />
          <div className="flex flex-col items-start justify-center">
            <p className="text-primary-950">{data?.wallet_name}</p>
            <p className="text-primary-950">{data?.coins?.name}</p>
          </div>
        </div>
      ),
    },

    {
      title: "Amount",
      name: "amount",
      prepareValue: (data: any) =>
        `${onFixed(data?.quantity)} = ${onFixed(
          data?.quantity * data?.current_rate
        )} ${data?.wallet_name}`,
      Cell: (data: any) => (
        <div className="flex flex-col items-start justify-center">
          <p className="text-primary-950">{onFixed(data?.quantity)}</p>
          <p className="text-primary-950">
            ≈ {onFixed(data?.quantity * data?.current_rate)} {data?.wallet_name}
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="card_rounded mb-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 items-center">
            <ControlledDatePicker
              name="date"
              selectesStart
              maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
              control={control}
              inputClassName="h-8 text-xs"
            />
            <Button
              disabled={isLoading || loading?.orderHistory}
              className="!h-8 !text-sm"
              type="submit"
            >
              Search
            </Button>
            <Button
              disabled={isLoading || loading?.orderHistory}
              className="!h-8 !text-sm"
              onClick={() => {
                reset();
                setFilter(initialFilter);
                setFilterForOrder({
                  ...FILTER,
                  ...initialFilter,
                });
              }}
            >
              Reset
            </Button>
          </div>
        </form>
      </div>
      {role.includes("View Account Statements") ? (
        <>
          <div className="card_rounded mb-4">
            <div className="text-primary-950 flex gap-4">
              <div>
                <div className="text-xl font-semibold ">Date</div>
                <div className="text-sm">
                  {formatDateYMD(filter?.date)}
                  {/* {moment(new Date(filter.date).toISOString()).format("YYYY-MM-DD")} */}
                </div>
              </div>
              <div>
                <div className="text-xl font-semibold">Total Value</div>
                <div className="text-sm">
                  {isLoading ? (
                    <Loader loaderClassName="w-[15px] " />
                  ) : (
                    calculateTotalUSDT(accStatementData)
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card_rounded mb-4">
            {accStatementData?.length ? (
              <div
                className="flex justify-between w-1/2"
                style={{
                  position: "relative",
                  height: "40vh",
                  width: "60vw",
                }}
              >
                <Pie
                  data={prepareChartData(accStatementData)}
                  options={prepareOptions(accStatementData)}
                />
              </div>
            ) : (
              <p className="text-primary-950">
                No data available for the chart.
              </p>
            )}
          </div>
          <div className="card_rounded mb-4">
            {isLoading ? (
              <div className="flex justify-center">
                <Loader />
              </div>
            ) : accStatementData?.length ? (
              <Table
                fileName="Account_Statement"
                columns={columns}
                data={accStatementData || []}
                tableDataCount={accStatementData?.length}
                isExpendable={false}
                className="!overflow-scroll order_table"
                minHeight={100}
                maxHeight={300}
                count={accStatementData?.length}
              >
                {accStatementData?.map((item: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <TableRow
                        // className="cursor-pointer"
                        columns={columns}
                        item={item}
                        isExpendable={false}
                      />
                    </Fragment>
                  );
                })}
              </Table>
            ) : (
              /*  <div className="flex">
                <div className="flex flex-col text-primary-950 w-1/2">
                  <div className="flex justify-between px-4 py-2 border-b-2 border-secondary-200 bg-chatlook-quaternary">
                    <span>Coin</span>
                    <span>Total</span>
                  </div>
                  <div>
                    {accStatementData?.map?.(
                      (statement: any, index: number) => {
                        return (
                          <div
                            className="flex justify-between px-4 py-2 border-b border-secondary-200"
                            key={index}
                          >
                            <div className="flex gap-2 items-center">
                              <img
                                className="w-7"
                                src={`/images/cryptoCurrency/${statement?.wallet_name?.toLowerCase()}.png`}
                              />
                              <div className="flex flex-col items-start justify-center">
                                <p className="text-primary-950">
                                  {statement?.wallet_name}
                                </p>
                                <p className="text-primary-950">
                                  {statement?.coins?.name}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-col items-end justify-center">
                              <p className="text-primary-950">
                                {onFixed(statement?.quantity)}
                              </p>
                              <p className="text-primary-950">
                                ≈{" "}
                                {onFixed(
                                  statement?.quantity * statement?.current_rate
                                )}{" "}
                                {statement?.wallet_name}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div
                  className="flex justify-between w-1/2"
                  style={{
                    position: "relative",
                    height: "40vh",
                    width: "60vw",
                  }}
                >
                  <Doughnut
                    data={prepareChartData(accStatementData)}
                    options={prepareOptions(accStatementData)}
                  />
                </div>
              </div> */
              <p className="text-primary-950">No data found</p>
            )}
          </div>
        </>
      ) : null}
      {role.includes("View Account Statement Order History") ? (
        <>
          <div className={`card_rounded mb-4`}>
            <Table
              fileName="Statement_order_history"
              isLoading={loading?.orderHistory}
              columns={columnsOrderHistory}
              data={binanceOrderHistory?.AI || []}
              isExpendable={false}
              setData={setTableData}
              className="!overflow-scroll order_table"
              count={binanceOrderHistory?.aiCount}
              tableDataCount={tableData?.length}
              pagination={filterForOrder}
              minHeight={300}
              handlePaginationChange={(pagination) => {
                setFilterForOrder({
                  ...filterForOrder,
                  ...pagination,
                });
              }}
            >
              {tableData?.map?.((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      className="min-h-[350px]"
                      columns={columnsOrderHistory}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AccountStatement;
