import { useEffect, useState } from "react";
import { useRole, useUser } from "../auth/authSlice";
import { useLazyGetAllWalletQuery } from "../wallet/walletApi";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { clearBinanceAIRedux, responseToaster } from "../../helperFunctions";
import { useAppDispatch } from "../../utills/hooks";
import {
  Button,
  ControlledInput,
  ControlledSwitch,
  Input,
  Loader,
} from "../../ui";
import { useKeys } from "../exchange/exchangeSlice";
import {
  useAddKeysMutation,
  useDeleteKeysMutation,
  useEditKeysMutation,
} from "../exchange/exchangeApi";
import { useLocation } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import OTPInput from "../../ui/forms/OtpInput";

const ConnectToBinanceModal = (props: any) => {
  const { modalDetails, setModalDetails, initialModalDetails, setFilter } =
    props;

  const user = useUser();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const [whiteListIp, setWhiteListIp] = useState(null);
  const [addKeys, { isLoading: isAddLoading }] = useAddKeysMutation();
  const [editKeys, { isLoading: isLoadingEdit }] = useEditKeysMutation();
  const [deleteKeys, { isLoading: isLoadingDelete }] = useDeleteKeysMutation();
  const [getAllWallet] = useLazyGetAllWalletQuery();
  const {
    currentKey: { is_connect, exchange_name },
  } = useKeys();

  const [step, setStep] = useState("details");
  const dispatch = useAppDispatch();
  const role = useRole("exchange");
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [triggerVibrate, setTriggerVibrate] = useState(false);

  const schema: any = yup.object({
    // api_key: yup.string().required("Please enter a api key"),
    // secret_key: yup.string().required("Please enter a secret key"),
  });

  const initialValues: any = {
    api_key: "",
    secret_key: "",
  };

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver<any>(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async () => {
    try {
      const values = getValues();

      if (modalDetails?.api_key && modalDetails?.secret_key) {
        setModalDetails((modalDetails: any) => ({
          ...modalDetails,
          isConfirm: true,
          type: "update",
          exchange_name: modalDetails?.exchange_name,
        }));
      } else {
        const payload = {
          ...values,
          user_uuid: user?.data?.user?.user_uuid,
          //   is_active: 1,
          exchange_name: modalDetails?.exchange_name,
        };

        const res = await addKeys({
          ...payload,
          group_name: pathnames?.[1],
          otp: otp ? JSON.parse(otp) : null,
        }).unwrap();

        if (res?.status) {
          setFilter((filter: any) => ({
            ...filter,
          }));

          setStep("otp");
          // getConnectedExchangeList();
          clearBinanceAIRedux(dispatch).then(async () => {
            await getAllWallet({
              is_connect: is_connect,
              exchange_name: exchange_name,
              group_name: pathnames?.[1],
            });
          });
        }
        responseToaster(res);
        setModalDetails({
          ...initialModalDetails,
          isForm: true,
          api_key: modalDetails?.api_key,
          secret_key: modalDetails?.secret_key,
          exchange_name: modalDetails?.exchange_name,
        });
        if (res?.data?.is_valid_api_key) {
          setModalDetails(initialModalDetails);
          setStep("details");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onConfirmation = async () => {
    try {
      let res;
      if (modalDetails?.type === "delete") {
        const payload: any = {
          exchange_name: modalDetails?.exchange_name,
        };
        res = await deleteKeys({
          ...payload,
          group_name: pathnames?.[1],
        }).unwrap();
        if (res) {
          setFilter((filter: any) => ({
            ...filter,
          }));
        }
      } else {
        const values = getValues();
        const payload = {
          user_uuid: user?.data?.user?.user_uuid,
          is_active: user?.is_active,
          exchange_name: modalDetails?.exchange_name,
          ...values,
        };

        res = await editKeys({
          ...payload,
          otp: otp ? JSON.parse(otp) : null,
          group_name: pathnames?.[1],
          api_key: modalDetails?.api_key,
          is_active: modalDetails?.is_active,
          secret_key: modalDetails?.secret_key,
        }).unwrap();

        if (res?.data?.is_otp_sent) {
          setStep("otp");
          setModalDetails({
            ...initialModalDetails,
            isForm: true,
            type: "update",
            api_key: modalDetails?.api_key,
            is_active: modalDetails?.is_active,
            secret_key: modalDetails?.secret_key,
            exchange_name: modalDetails?.exchange_name,
          });
        }
        if (res?.data?.is_valid_api_key) {
          setModalDetails(initialModalDetails);
          setStep("details");
        }
      }

      if (res?.status) {
        setFilter((filter: any) => ({
          ...filter,
        }));
        clearBinanceAIRedux(dispatch).then(async () => {
          await getAllWallet({
            is_connect: is_connect,
            exchange_name: modalDetails?.exchange_name,
          });
        });
      }
      responseToaster(res);

      reset();
    } catch (err) {
      console.error(err);
    }
  };

  const handleOtpChange = (newOtp: any) => {
    setOtp(newOtp);
    if (newOtp.length === 6) {
      setIsValid(true);
      setTriggerVibrate(false);
    }
  };

  useEffect(() => {
    setValue("api_key", modalDetails?.api_key);
    setValue("secret_key", modalDetails?.secret_key);
    setValue("is_active", modalDetails?.is_key_active);
    // eslint-disable-next-line
  }, [setValue, modalDetails?.isForm]);

  const fetchData = async () => {
    try {
      const response = await fetch("https://ipecho.net/plain");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result: any = await response.text();
      setWhiteListIp(result);
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {modalDetails?.isForm ? (
        <>
          <div
            className={` fixed inset-0 z-50 flex items-center justify-center`}
          >
            <div
              className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
              onClick={() => {
                setModalDetails(initialModalDetails);
              }}
            />

            <div className="modal-container w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-7/12 sm:w-7/12 z-50 mx-auto overflow-y-auto">
              <div className="modal-header">
                <h4 className="text-lg font-semibold text-primary-950">
                  Connect To Binance
                </h4>
                <MdOutlineCancel
                  color="var(--primary-600--)"
                  size={24}
                  onClick={() => {
                    setModalDetails(initialModalDetails);
                  }}
                />
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {step === "details" ? (
                  <>
                    <div className="modal-content py-4 px-6">
                      <div className="flex justify-end">
                        {!!modalDetails?.api_key && modalDetails?.secret_key ? (
                          <ControlledSwitch
                            name="is_active"
                            control={control}
                            checked={watch("is_active")}
                            disabled={modalDetails?.type === "view"}
                            sliderClass={
                              modalDetails?.type === "view"
                                ? "!cursor-default"
                                : ""
                            }
                            onChange={(value) => {
                              if (modalDetails?.type === "view") {
                                return;
                              }
                              setValue("is_active", !!value ? 1 : 0);
                            }}
                          />
                        ) : null}
                      </div>
                      <div className="space-y-5 pb-2">
                        <div className="flex items-center justify-between gap-1 relative">
                          <Input
                            name="whiteListIp"
                            type="text"
                            value={whiteListIp}
                            label="WhiteList Ip"
                            placeholder="WhiteList Ip"
                            disabled={true}
                          />
                          <CopyToClipboard text={`${whiteListIp}`}>
                            <FaRegCopy className="absolute top-8 right-3 cursor-pointer" />
                          </CopyToClipboard>
                        </div>
                        <div className="flex items-center justify-between gap-1">
                          <ControlledInput
                            name="api_key"
                            type={modalDetails?.api_key ? "password" : "text"}
                            label="API Key"
                            placeholder="API Key"
                            disabled={modalDetails?.type === "view"}
                            control={control}
                            errors={errors}
                          />
                        </div>
                        <div className="flex items-center justify-between gap-1">
                          <ControlledInput
                            name="secret_key"
                            type={
                              modalDetails?.secret_key ? "password" : "text"
                            }
                            label="Secret Key"
                            placeholder="Secret Key"
                            disabled={modalDetails?.type === "view"}
                            control={control}
                            errors={errors}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer w-full">
                      <Button
                        variant="Transaparent"
                        onClick={() => {
                          setModalDetails(initialModalDetails);
                        }}
                      >
                        {modalDetails?.type === "view" ? "Close" : "Cancel"}
                      </Button>
                      {!!modalDetails?.api_key &&
                      !!modalDetails?.secret_key &&
                      modalDetails?.type !== "view" &&
                      role.includes("Delete Key") ? (
                        <Button
                          className="!bg-primary-red border-0"
                          onClick={() =>
                            setModalDetails((modalDetails: any) => ({
                              ...modalDetails,
                              type: "delete",
                              isConfirm: true,
                            }))
                          }
                        >
                          Delete
                        </Button>
                      ) : null}
                      {isAddLoading ? (
                        <div className="flex item-center justify-center">
                          <Loader />
                        </div>
                      ) : modalDetails?.type !== "view" ? (
                        <Button type="submit">
                          {!!modalDetails?.api_key && !!modalDetails?.secret_key
                            ? "Update"
                            : "Submit"}
                        </Button>
                      ) : null}
                    </div>
                  </>
                ) : step === "otp" ? (
                  <>
                    <div className="modal-content py-4 px-6">
                      <div className="space-y-5 pb-2">
                        <div className="flex items-center justify-between gap-1">
                          <OTPInput
                            numInputs={6}
                            onOtpChange={handleOtpChange}
                            isValid={isValid}
                            triggerVibrate={triggerVibrate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer w-full">
                      {isAddLoading ? (
                        <div className="flex item-center justify-center">
                          <Loader />
                        </div>
                      ) : modalDetails?.type !== "view" ? (
                        <Button type="submit">Submit</Button>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </form>
            </div>
          </div>
        </>
      ) : null}

      {modalDetails?.isConfirm ? (
        <>
          <div
            className={` fixed inset-0 z-50 flex items-center justify-center`}
          >
            <div
              className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
              onClick={() => {
                setModalDetails((modalDetails: any) => ({
                  ...modalDetails,
                  isConfirm: false,
                }));
              }}
            />

            <div className="modal-container w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-7/12 sm:w-7/12 z-50 mx-auto overflow-y-auto">
              <div className="modal-header">
                <h4 className="text-lg font-semibold text-primary-950">
                  Confirmation
                </h4>
                <MdOutlineCancel
                  color="var(--primary-600--)"
                  size={24}
                  onClick={() => {
                    setModalDetails((modalDetails: any) => ({
                      ...modalDetails,
                      isConfirm: false,
                    }));
                  }}
                />
              </div>
              <div className="modal-content !py-12">
                <h4 className="text-center text-lg text-primary-950">
                  Are you Sure you want to{" "}
                  {modalDetails?.type === "delete" ? "Disconnect" : "Update"} ?
                </h4>
              </div>
              <div className="modal-footer w-full">
                {isLoadingEdit || isLoadingDelete ? (
                  <div className="flex item-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <Button
                    // className="!bg-primary-red border-0"
                    onClick={() => {
                      onConfirmation();
                    }}
                  >
                    Yes
                  </Button>
                )}

                <Button
                  variant="Transaparent"
                  onClick={() =>
                    setModalDetails((modalDetails: any) => ({
                      ...modalDetails,
                      isConfirm: false,
                    }))
                  }
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ConnectToBinanceModal;
