import { useEffect } from "react";

// UI IMPORT
import { Button, ControlledDropDown, ControlledInput } from "../../ui";

// PROJECT IMPORT
import { useCoins } from "../common/commonSlice";
import {
  getConversionRate,
  onFixed,
  responseToaster,
  showToaster,
} from "../../helperFunctions";
import { useWalletData, useWalletTypes } from "./walletSlice";
import useWebSocketContext from "../../context/useWebSocketContext";
import {
  useGetAllWalletTypesMutation,
  useLazyGetAllWalletQuery,
  useConvertMutation,
} from "./walletApi";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useGetCoinsMutation } from "../common/commonApi";
import { useGetClosingDataMutation } from "../trade/tradeApi";
import { useKeys } from "../exchange/exchangeSlice";
import { useLocation } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";

interface ConvertFormTypes {}

const ConvertModal = ({ onClose }: any) => {
  const coins = useCoins();
  const wallet = useWalletData();
  const allWalletTypes = useWalletTypes();
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");
  const { tickers } = useWebSocketContext();
  const {
    currentKey: { is_tab_connect, is_connect, exchange_name },
  } = useKeys();

  const [convert, { isLoading }] = useConvertMutation();
  const [getAllWallet] = useLazyGetAllWalletQuery();
  const [getAllWalletTypes] = useGetAllWalletTypesMutation();
  const [getCoins] = useGetCoinsMutation();
  const [getClosingData] = useGetClosingDataMutation();

  const schema: any = yup.object({
    from_amount: yup.string(),
    to_amount: yup.string(),
    from_coin: yup.string().required("Please select a From Coin"),
    to_coin: yup.string().required("Please select a To Coin"),
  });
  // .test(
  //   "at-least-one-required",
  //   "Either From Amount or To Amount is required",
  //   (value)  => {
  //     return !!(value.from_amount || value.to_amount);
  //   }
  // );

  const initialValues: any = {};

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver<ConvertFormTypes>(schema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!!watch("from_amount") && !!watch("to_amount")) {
      setValue("to_amount", "");
    }
    // eslint-disable-next-line
  }, [watch("from_amount")]);

  useEffect(() => {
    if (!!watch("from_amount") && !!watch("to_amount")) {
      setValue("from_amount", "");
    }
    // eslint-disable-next-line
  }, [watch("to_amount")]);

  const selectedFromCoin = watch("from_coin");
  const selectedToCoin = watch("to_coin");

  const prepareFromCoinOptions = (toCoin: string) => {
    const coinOptions: any = coins?.data
      ?.filter((coin: any) => coin?.name !== null)
      ?.filter((coin: any) => coin?.symbol !== toCoin)
      ?.slice()
      ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
      ?.map((val: any) => ({
        value: val?.symbol,
        label: `${val?.symbol} (${val?.name})`,
      }));
    return coinOptions || [];
  };

  const prepareToCoinOptions = (fromCoin: string) => {
    const coinOptions: any = coins?.data
      ?.filter((coin: any) => coin?.name !== null)
      ?.filter((coin: any) => coin?.symbol !== fromCoin)
      ?.slice()
      ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
      ?.map((val: any) => ({
        value: val?.symbol,
        label: `${val?.symbol} (${val?.name})`,
      }));
    return coinOptions || [];
  };

  const onSubmit = async () => {
    try {
      const values = getValues();

      if (!values?.from_amount && !values?.to_amount) {
        showToaster("Either From Amount or To Amount is required", "Error");
        return;
      }

      const rate = getConversionRate(
        tickers,
        selectedFromCoin,
        selectedToCoin
        // prepareFrom(selectedFromCoin)?.coin?.symbol,
        // prepareTo(selectedToCoin)?.coin?.symbol
      );
      const payload = {
        ...values,
        ...(!!values?.from_amount
          ? { to_amount: onFixed(+rate * values?.from_amount) }
          : { from_amount: onFixed(values?.to_amount / +rate) }),
        wallet_type_uuid: allWalletTypes?.data?.find(
          (item: any) => item?.name === "spot" // for spot wallet
        )?.wallet_type_uuid,
        rate,
        is_connect: is_connect,
        exchange_name: exchange_name,
      };
      const res = await convert({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
      responseToaster(res);
      reset();
      onClose();
      getAllWallet({
        is_connect: is_connect,
        exchange_name: exchange_name,
        group_name: pathnames?.[1],
      });
      getClosingData({
        group_name: pathnames?.[1],
      });
    } catch (err) {
      console.error(err);
    }
  };

  const prepareFrom = (fromCoin: string) => {
    if (is_tab_connect) {
      let obj: any;
      Object.entries(wallet?.data?.exchangeData || {})?.forEach(
        ([key, item]: any) => {
          if (key?.toLowerCase() === fromCoin?.toLowerCase()) {
            obj = {
              balance: item?.free,
            };
          }
        }
      );
      return obj;
    } else {
      return wallet?.data?.aiData?.find?.(
        (item: any) => item?.coin?.symbol === fromCoin
      );
    }
  };

  const prepareTo = (toCoin: string) => {
    if (is_tab_connect) {
      let obj: any;
      Object.entries(wallet?.data?.exchangeData || {})?.forEach(
        ([key, item]: any) => {
          if (key?.toLowerCase() === toCoin?.toLowerCase()) {
            obj = {
              balance: item?.free,
            };
          }
        }
      );
      return obj;
    } else {
      return wallet?.data?.aiData?.find?.(
        (item: any) => item?.coin?.symbol === toCoin
      );
    }
  };

  useEffect(() => {
    const fn = async () => {
      getAllWalletTypes({
        group_name: pathnames?.[1],
      });
      getCoins({
        group_name: pathnames?.[1],
      });
    };
    fn();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
          onClick={() => {
            onClose();
          }}
        />

        <div className="modal-container w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12  md:w-7/12 sm:w-7/12 z-50 mx-auto  overflow-y-auto">
          <div className="modal-header">
            <h4 className="text-lg font-semibold text-primary-950">Convert</h4>
            <MdOutlineCancel
              color="var(--primary-600--)"
              size={24}
              onClick={() => {
                onClose();
              }}
            />
          </div>
          <form className="grid" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content py-9 px-6">
              <div className="space-y-5 pb-10">
                <div>
                  <span className="text-primary-950">
                    Balance :{" "}
                    {onFixed(prepareFrom(selectedFromCoin)?.balance) || 0}
                  </span>
                  <ControlledInput
                    name="from_amount"
                    label="From Amount"
                    placeholder="Enter From Amount"
                    type="number"
                    control={control}
                    errors={errors}
                  />
                </div>
                <ControlledDropDown
                  className="!w-full"
                  inputClassName="!h-12 !border-0"
                  name="from_coin"
                  label="From Coin"
                  options={prepareFromCoinOptions(selectedToCoin)}
                  errors={errors}
                  control={control}
                />
                <div>
                  <span className="text-primary-950">
                    Balance : {onFixed(prepareTo(selectedToCoin)?.balance) || 0}
                  </span>
                  <ControlledInput
                    name="to_amount"
                    label="To Amount"
                    placeholder="Enter To Amount"
                    type="number"
                    control={control}
                    errors={errors}
                  />
                </div>
                <ControlledDropDown
                  className="!w-full"
                  inputClassName="!h-12 !border-0"
                  name="to_coin"
                  label="To Coin"
                  options={prepareToCoinOptions(selectedFromCoin)}
                  errors={errors}
                  control={control}
                />
              </div>
              {!!selectedFromCoin && !!selectedToCoin ? (
                <>
                  <div className="flex justify-between text-primary-950">
                    <div>Rate</div>
                    <div>
                      1 {selectedFromCoin} ={" "}
                      {getConversionRate(
                        tickers,
                        selectedFromCoin,
                        selectedToCoin
                      )}{" "}
                      {selectedToCoin}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="modal-footer w-full">
              <Button type="submit" isLoading={isLoading}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ConvertModal;
