import React, { memo } from "react";
import { IconProps } from ".";

export interface MarkerChartIconProps extends IconProps {}
const MarkerChartIcon = (props: MarkerChartIconProps) => {
  const {
    className = "",
    // pathClassNames = {},
    size = 20,
    stroke = "var(--primary-950--)",
    fill = "none",
    strokeWidth = 1,
    ...rest
  } = props;
  return (
    <svg
    className={`${className}`}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
    >
      <path
        d="M2 2V19C2 20.66 3.34 22 5 22H22"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 17L9.59 11.64C10.35 10.76 11.7 10.7 12.52 11.53L13.47 12.48C14.29 13.3 15.64 13.25 16.4 12.37L21 7"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const MemoMarkerChartIcon = memo(MarkerChartIcon);
export default MemoMarkerChartIcon;
