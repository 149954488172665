import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { accountStatementApi } from "./accountStatementApi";

interface AccountStatementState {
  data: any;
  allAccountStatementTypes: any;
  orderHistory: any;
}

const initialState: AccountStatementState = {
  data: {},
  allAccountStatementTypes: {},
  orderHistory: {},
};

export const accountStatementSlice = createSlice({
  name: "accountStatement",
  initialState,
  reducers: {
    clearAccountStatement: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      accountStatementApi.endpoints.getAllAccountStatement.matchFulfilled,
      (state, { payload }) => {
        state.data = payload || {};
      }
    );
    builder.addMatcher(
      accountStatementApi.endpoints.getOrderHistoryList.matchFulfilled,
      (state, { payload }) => {
        state.orderHistory = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default accountStatementSlice.reducer;
export const { clearAccountStatement } = accountStatementSlice.actions;

export const selectAccountStatementData = (state: RootState) =>
  state.accountStatement.data;
export const useAccountStatementData = () => {
  const data = useSelector(selectAccountStatementData);
  return useMemo(() => data, [data]);
};

export const selectOrderHistoryData = (state: RootState) =>
  state.accountStatement.orderHistory;
export const useOrderHistoryData = () => {
  const data = useSelector(selectOrderHistoryData);
  return useMemo(() => data, [data]);
};

export const selectAccountStatementTypes = (state: RootState) =>
  state.accountStatement.allAccountStatementTypes;
export const useAccountStatementTypes = () => {
  const types = useSelector(selectAccountStatementTypes);
  return useMemo(() => types, [types]);
};
