import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { Button, CheckBox, Loader } from "../../../ui";
import { useForm } from "react-hook-form";
import { UnfollowPositionCheckout } from "./mock";
import {
  useApplyStrategyMutation,
  useGetStrategyMutation,
} from "../strategyApi";
import { useLocation } from "react-router-dom";
import { responseToaster } from "../../../helperFunctions";

const UnfollowModal = ({ onClose, unfollowModal }: any) => {
  const pathname = useLocation();
  const pathnames = pathname?.pathname?.split("/");

  // State to track checkbox values
  const [checkboxValues, setCheckboxValues] = useState<{
    [key: string]: boolean;
  }>({
    is_close_position: false,
    is_cancel_order: false,
  });

  const [getStrategy] = useGetStrategyMutation();
  const [applyStrategy, { isLoading: isApplyLoading }] =
    useApplyStrategyMutation();

  const onApplyStrategy = async (item: any) => {
    try {
      const payload = {
        strategy_name: item?.strategy_name,
        exchange_name: item?.user_strategy?.exchange_name || "ai",
        is_active: item?.is_active,
        is_close_position: checkboxValues.is_close_position ? 1 : 0,
        is_cancel_order: checkboxValues.is_cancel_order ? 1 : 0,
      };
      const res = await applyStrategy({
        ...payload,
        group_name: pathnames?.[1],
      }).unwrap();
      await onGetStrategy();
      onClose();
      responseToaster(res);
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const onGetStrategy = async () => {
    try {
      await getStrategy({
        group_name: pathnames?.[1],
      });
    } catch (error) {}
  };

  const onSubmit = () => {
    try {
      onApplyStrategy({
        ...unfollowModal,
        is_active: !!unfollowModal?.user_strategy?.is_active ? 0 : 1,
      });
    } catch (e: any) {}
  };

  const handleCheckboxChange = (isChecked: boolean, val: any) => {
    setCheckboxValues((prevState) => ({
      ...prevState,
      [val.value]: isChecked,
    }));
  };

  const {
    handleSubmit,
  } = useForm();

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-secondary-900 opacity-50"
          onClick={() => {
            onClose();
          }}
        />

        <div className="modal-container w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-7/12 sm:w-7/12 z-50 mx-auto  overflow-y-auto">
          <div className="modal-header  ">
            <div className="!text-center w-full">
              <h2 className="text-lg font-semibold text-primary-950">
                Unfollow Confirmation
              </h2>
            </div>
            <MdOutlineCancel
              color="var(--primary-600--)"
              size={24}
              onClick={() => {
                onClose();
              }}
            />
          </div>
          {/* <div className="p-6"> */}
          <div className="px-6">
            <div className="text-center font-semibold">
              <p className="pb-2">
                Are you sure you want to unfollow this{" "}
                {unfollowModal?.strategy_name}
              </p>
              <p className="pb-2">
                What happens to my existing trades placed in USDM market ?
              </p>
            </div>
            <div>Futures USDM</div>
          </div>
          <form className="grid" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content py-9 !px-6">
              <div className="gap-2">
                {UnfollowPositionCheckout?.map((val) => {
                  const isChecked = checkboxValues[val.value];

                  return (
                    <CheckBox
                      key={val.value}
                      value={val.value}
                      checked={isChecked}
                      label={val.label}
                      onChange={(e) =>
                        handleCheckboxChange(e.target.checked, val)
                      }
                      className="checkbox_margin"
                    />
                  );
                })}
              </div>
            </div>
            <div className="modal-footer w-full">
              {isApplyLoading ? (
                <div className="flex justify-center">
                  <Loader />
                </div>
              ) : (
                <Button type="submit">Submit</Button>
              )}
            </div>
          </form>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default UnfollowModal;
