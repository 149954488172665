import React, { Fragment, useEffect, useState } from "react";
import useWebSocketContext from "../../context/useWebSocketContext";
import { Table, TableRow } from "../../ui";
import { useParams } from "react-router-dom";
import { onFixed } from "../../helperFunctions";

const Trades = () => {
  const [activeItem, setActiveItem] = useState("marketTrades");
  const { base, quote } = useParams();
  const baseUppercase = base?.toUpperCase();
  const quoteUppercase = quote?.toUpperCase();
  const { trades, setBaseQuote } = useWebSocketContext();

  const isActive = (menuItem: string) => {
    return activeItem === menuItem;
  };
  const setActive = (menuItem: React.SetStateAction<string>) => {
    setActiveItem(menuItem);
  };

  const columns = [
    {
      title: "Price",
      name: "p",
      Cell: (data: any) => (
        <>
          <span className={`text-xs ${data?.class}`}>{onFixed(data?.p)}</span>
        </>
      ),
    },
    {
      title: "Amount",
      name: "q",
      Cell: (data: any) => (
        <>
          <span className="text-xs">{onFixed(data?.q)}</span>
        </>
      ),
    },
    {
      title: "Time",
      name: "T",
      Cell: (data: any) => (
        <>
          <span className="text-xs">{formatTime(data?.T)}</span>
        </>
      ),
    },
  ];

  const formatTime = (timestamp: number) => {
    return new Date(timestamp).toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  useEffect(() => {
    if (!!setBaseQuote) {
      setBaseQuote?.({ base: baseUppercase, quote: quoteUppercase });
    }
    // eslint-disable-next-line
  }, [base, quote]);

  return (
    <div className="Trades text-primary-950 relative">
      <div className="w-full">
        <ul>
          <li className="mr-2">
            <button
              className={`inline-block py-2 pl-3 pr-4 text-xs font-medium text-primary-950 ${
                isActive("marketTrades") ? "" : "cursor-pointer"
              }`}
              type="button"
              onClick={() => setActive("marketTrades")}
            >
              Market Trades
            </button>
          </li>
        </ul>
      </div>
      <div id="tradesContent">
        {activeItem === "marketTrades" && (
          <div id="marketTrades" className="px-1">
            <Table
              columns={columns}
              data={trades || []}
              tableDataCount={trades?.length}
              isExpendable={false}
              exports={[]}
              className="smallrow"
            >
              {trades?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Trades;
