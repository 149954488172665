// THIRD - PARTY IMPORT
import psl from "psl";

export const LIMIT = 50;
export const FILTER = {
  page: 1,
  limit: LIMIT,
  isFilter: false,
};

// export const BASE_URL = `https://${window.location.hostname}`;

export const LOGOUT_TIME = 1000 * 60 * 30;

export const LOGOUT_EVENTS = [
  "mousedown",
  "mousemove",
  "wheel",
  "keydown",
  "touchstart",
  "scroll",
];

export const DOMAINS: any = psl.parse("merchant-new.devteamandro.co.in");
// export const DOMAINS:any = psl.parse(window.location.hostname)

export const SMS_LOCAL = "smsPagination";
export const USER_LOCAL = "userPagination";
export const BANK_LOCAL = "bankPagination";

export const DEFAULT_FONT_FAMILY = "Roboto, sans-serif";

export const FIXED_DECIMAL = 8;
export const DROPDOWN_ALL_VALUE = "All";
export const DROPDOWN_ALL_LABEL = "All";

export const DROPDOWN_SELECT_VALUE = "";
export const DROPDOWN_SELECT_LABEL = "Select";

export const DROPDOWN_SEARCH_VALUE = "searchdata";
export const DROPDOWN_SEARCH_LABEL = "Search Data";

export const WEBSOCKET_URL = "ws://192.168.132.118:8000";
// export const LARAVEL_WEBSOCKET_URL = "ws://192.168.132.77:3001";

export const limitTypes = {
  "1": "Market",
  "2": "Limit",
};

export const orderStatuses: any = {
  all: "All",
  "1": "Open",
  "2": "Closed",
  "3": "Cancelled",
  "4": "Expired",
  "5": "Filled",
  "6": "Partially Filled",
  "7": "STP expired",
  "8": "Pending",
};

export const binanceOrderStatuses: any = {
  all: "All",
  open: "Open",
  closed: "Closed",
  canceled: "Cancelled",
  expired: "Expired",
  filled: "Filled",
  "partially filled": "Partially Filled",
  "STP expired": "STP expired",
  pending: "Pending",
};
export const binanceOrderStatuses1: any = {
  all: "All",
  FILLED: "Filled",
  NEW: "New",
  CLOSED: "Closed",
  CANCELED: "Cancelled",
  EXPIRED: "Expired",
  "PARTIALLY FILLED": "Partially Filled",
  "STP EXPIRED": "STP expired",
  PENDING: "Pending",
};

export const sideEnum: any = {
  all: "All",
  buy: "Buy",
  sell: "Sell",
};

export type SideEnumKeys = keyof typeof sideEnum;

export const binanceSideEnum1: any = {
  all: "All",
  BUY: "Buy",
  SELL: "Sell",
};

export const binanceSideEnum: any = {
  all: "All",
  buy: "Buy",
  sell: "Sell",
};

export const typeEnum: any = {
  all: "All",
  "1": "Market",
  "2": "Limit",
  "3": "stop limit",
  "4": "limit maker",
  "5": "stop loss limit",
  "6": "take profit limit",
  "7": "trailing stop",
  "8": "Take Profit Market",
  "9": "Stop market",
};

export const binanceTypeEnum: any = {
  all: "All",
  market: "Market",
  limit: "Limit",
};

export const binanceTypeEnum1: any = {
  all: "All",
  MARKET: "Market",
  LIMIT: "Limit",
  TAKE_PROFIT_MARKET: "Take Profit Market",
  STOP_LIMIT: "stop limit",
  LIMIT_MAKER: "limit maker",
  STOP_LOSS_LIMIT: "stop loss limit",
  TAKE_PROFIT_LIMIT: "take profit limit",
  STOP_MARKET: "Stop market",
  TRAILING_STOP: "trailing stop",
};

export const cancelEnum = {
  all: "All",
  limit: "Limit",
  // "stop_limit": "Stop-Limit",
  // "limit_maker": "Limit-Maker"
};

export const transactionStatusEnum = {
  "0": "Pending",
  "1": "Confirmed",
  "2": "Failed",
};

export const orderTypeEnum = {
  all: "All",
  "1": "Future",
  "2": "Spot",
};

export const tradeEnum = {
  aitrade: "AI Trade",
  bottrade: "Bot Trade",
  channeltrade: "Channel Trade",
};

export const BINANCE_BASE_URL = "https://api.binance.com";

export const BINANCE_ORDER_TAX = 0.1;

export const GET_APIS = [
  "get-all-wallet-types",
  "get-transfer-history",
  "get-all-strategy",
  "",
];

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
