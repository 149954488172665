import { useContext } from "react";
import { StrategyContext } from "./StrategyProvider";

const useStrategyContext = () => {
  const context = useContext(StrategyContext);
  if (!context) {
    throw new Error("useStrategyContext must be used within a StrategyProvider");
  }
  return context;
};

export default useStrategyContext;
